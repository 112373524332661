import React from "react";
import { Redirect as RouterRedirect } from "react-router";
import { UserGroup } from "../../modules/Auth";
import AbstractPermissions from "../../modules/Auth/AbstractPermission";
import PermissionBag from "../../modules/Auth/PermissionBag";

const user = AbstractPermissions.LoadFromToken();

// console.info("Hi, this is the PermissionBag of the current user:");
// console.info(user);

function userIs(group: UserGroup) : boolean {
    return user.isGroup(group, true);
}

function permissionsMatch(permission: PermissionBag | UserGroup | boolean | (PermissionBag | UserGroup | boolean)[], and: boolean) : boolean[] {

    let matchedPermissions: boolean[] = [];

    // We set isRedirect = false if...
    // [and === true]:  user has all permission(s)
    // [and === false]: user has some permission(s)

    // checks if User has specific permission
    const userHasPermission = (v: PermissionBag | UserGroup | boolean) : boolean => {
        let userHasPermission: boolean = false;
        if (v instanceof PermissionBag) {
            const result = user.hasPermission( ...v.cleanedPermissions() );
            userHasPermission = and ? result.all : result.some;
        } else if (typeof v === "boolean") {
            // if permission is of type boolean:
            // *true* triggers a redirect. 
            // as we check for permissions here and the *absence* of permission triggers the redirect
            // we need to inverse the boolean value here.
            userHasPermission = !v;
        } else {
            userHasPermission = user.isGroup(v, true);
        }
        return (userHasPermission);
    }

    if (Array.isArray(permission)) {
        permission.forEach(v => matchedPermissions.push(userHasPermission(v)))
    } else {
        matchedPermissions.push(userHasPermission(permission));
    }

    return matchedPermissions;
}

/**
 * Redirect to a specified route if specified permission requirements are not met. If `strict = true` all permissions must be met, otherwise the redirection is triggered. If `strict = false` at least one permission must be met.
 * 
 * @param group A {@link UserGroup} the user has to belong to
 * @param permission A {@link UserPermission} the user has to own. 
 * @param refRoute The route the user is redirected to, if requirements do not match. Can be either `"/sign-in"` or `"/sign-up"`.
 * 
 * @returns The children or a redirect element.
 * 
 * @since 
 */
export function RedirectIfNot( props: React.PropsWithChildren<{ permission: PermissionBag | UserGroup | boolean | (PermissionBag | UserGroup | boolean)[], strict?: boolean, refRoute?: "/sign-in" | "/sign-up" | "/"}>) : JSX.Element {
    let strict = props.strict || true;
    
    if (props.children === undefined || props.children === null) {
        console.warn("Unnused Authenticated HOC. This component has to have children");
        return(<></>);
    }

    const matchedPermissions = permissionsMatch(props.permission, strict);

    const allIsFalse = matchedPermissions.every(v => v === false); // all permissions do not match
    const someIsFalse = matchedPermissions.some(v => v === false); // at least one permission does not match

    const redirect = strict ? (someIsFalse) : (allIsFalse);

    return (    !redirect ? <>{props.children}</> : <RouterRedirect to={props.refRoute ? props.refRoute : "/sign-in"}></RouterRedirect> );
    
}

/**
 * Redirect to a specified route if specified permission requirements are met. If `strict = true`, no permission must be met, otherwise the redirection is triggered. If `strict = false`, at least one permission must not be met.
 * @param props 
 * @returns 
 */
export function RedirectIf(props: React.PropsWithChildren<{ permission: PermissionBag | UserGroup | boolean | (PermissionBag | UserGroup | boolean)[], strict?: boolean, refRoute?: "/sign-in" | "/sign-up" | "/"}>) : JSX.Element {

    const strict = props.strict || true;

    if (props.children === undefined || props.children === null) {
        console.warn("Unnused Authenticated HOC. This component has to have children");
        return(<></>);
    }

    // console.warn("REDIRECT IF NOT");

    // const checkFunction = (v: PermissionBag | UserGroup | boolean) : boolean => {
    //     let result: boolean = true;
    //     if (v instanceof PermissionBag) {
            
    //         result = user.hasPermission( ...v.cleanedPermissions() ).all;
    //         console.log("-- User has Permissions:", result)
    //     } else if (typeof v === "boolean") {
    //         result = v;
    //         console.log("-- Manually:", result)
    //     } else {            
    //         result = user.isGroup(v, true);
    //         console.log("-- User is Group (" + v + "):", result);
    //     }
    //     return (result);
    // }

    // let result: boolean;

    // if (Array.isArray(props.permission)) {
    //     console.warn("This time, it is an array.");
        
        
    //     const resultArray: boolean[] = [];
    //     props.permission.forEach(v => resultArray.push(checkFunction(v)))
    //     console.log("Checked this for each:", resultArray);
    //     result = and ? resultArray.every(v => v === true) : resultArray.some(v => v === true);
    // } else {
    //     result = checkFunction(props.permission)
    // }

    
    // console.log("Is Redirect (If): ", result);


    const matchedPermissions = permissionsMatch(props.permission, strict);

    const allIsTrue = matchedPermissions.every(v => v === true); // all permissions do not match
    const someIsTrue = matchedPermissions.some(v => v === true); // at least one permission does not match

    const redirect = strict ? (someIsTrue) : (allIsTrue);

    return (    !redirect ? <>{props.children}</> : <RouterRedirect to={props.refRoute ? props.refRoute : "/sign-in"}></RouterRedirect> );
    
    
    // if (result) console.info("Redirecting user, as they do not have these permissions: ", props.permission.cleanedPermissions().toString());

    // return ( !result ? <>{props.children}</> : <Redirect to={props.refRoute ? props.refRoute : "/sign-in"}></Redirect> );

}

/**
 * Renders children if current user holds specified permission(s).
 * 
 * @param permission The permissions in form of an instance of {@link PermissionBag}.
 * @param fallback Optional. What to be rendered if permission check fails.
 * @returns Children or fallback.
 * 
 * @since 1.0.0
 */
export function ShowIfPermission(props: React.PropsWithChildren<{permission: PermissionBag, fallback?: React.ReactNode}>): JSX.Element {
    // console.log("ShowIfPermission: ", props.permission.flatPermissions());
    // console.info("UserPermissions: ", user.flatPermissions());
    // console.log(props.children);
    
    return ( user.hasPermission(...props.permission.cleanedPermissions()).all ? <>{props.children}</> : <>{props.fallback}</> );
}

export function ShowIfNot(props: React.PropsWithChildren<{permission: PermissionBag, fallback?: React.ReactNode}>) : JSX.Element {
    return ( !user.hasPermission(...props.permission.cleanedPermissions()).all ? <>{props.children}</> : <>{props.fallback}</> );
}

/**
 * An alias for `ShowIfPermission`
 * 
 * @param permission The permissions in form of an instance of {@link PermissionBag}.
 * @param fallback Optional. What to be rendered if permission check fails.
 * @returns Children or fallback.
 * 
 * @since 1.0.0
 */
export function ShowIf(props: React.PropsWithChildren<{permission: PermissionBag, fallback?: React.ReactNode}>) : JSX.Element {
    return(ShowIfPermission(props));
}

export function ShowIfType(props: React.PropsWithChildren<{type: UserGroup, fallback?: React.ReactNode}>): JSX.Element {
    // console.log("SHOW IF TYPE required: ", props.type, " is current user.");
    
    return (userIs(props.type) ? <>{props.children}</> : <>{props.fallback}</>);
}

type AuthRequirement = PermissionBag | UserGroup | boolean;

type AuthRequirements = AuthRequirement | AuthRequirement[];


// the different keys the auth logic can be validated against
// NOTE: Only one key can hold true. If 
export type AuthLogicKeys = "all" | "some" | "none";

export type AuthLogic = {
    [key in AuthLogicKeys]: boolean
};

// type AuthLogicArray = [boolean, boolean, boolean];

type AuthLogicIfs = AuthLogicKeys | "someOrAll" | "someOrNone" | "allOrNone";

type AuthLogicProps = {
    if:  AuthLogicIfs,
    ofThisHolds: AuthRequirements,
    not?: boolean
}
type AuthRedirectProps = {
    to?: string
}

export function Redirect(props: React.PropsWithChildren<AuthLogicProps & AuthRedirectProps>): JSX.Element {
    let renderedResult: JSX.Element;

    // console.log("Redirect if " + (props.not ? "not " : "") + props.if.toString() + "of this holds:", props.ofThisHolds)


    let conditionHoldsFor: boolean[] = [];

    if (Array.isArray(props.ofThisHolds) && props.ofThisHolds.length === 1) {
        conditionHoldsFor.push(AbstractPermissions.userHasPermission(user, props.ofThisHolds[0]));
    } else if (! Array.isArray(props.ofThisHolds)) {
        conditionHoldsFor.push(AbstractPermissions.userHasPermission(user, props.ofThisHolds));
    } else {
        props.ofThisHolds.forEach(value => conditionHoldsFor.push(AbstractPermissions.userHasPermission(user, value)))
    }

    let holdsForAll: boolean;
    let holdsForSome: boolean;
    let holdsForNone: boolean;
    
    if (conditionHoldsFor.length === 1) {
        holdsForAll = conditionHoldsFor[0];
        holdsForSome = holdsForAll;
        holdsForNone = ! holdsForAll;
    } else if (conditionHoldsFor.length === 0) {
        holdsForAll = false;
        holdsForSome = false;
        holdsForNone = false;
    } else {
        holdsForAll = conditionHoldsFor.every(v => v);
        holdsForSome = holdsForAll ? true : conditionHoldsFor.some(v => v);
        holdsForNone =  ! (holdsForAll || holdsForSome);
    }


    //problem for some: you do not accept if all is true - differently it would also be false (would not accept if not all are true)
    //const requiredAuthLogic: AuthLogic = AuthLogicStringArrays[props.if];
    
    let logicHolds: boolean = false;
    switch (props.if) {
        case "some": logicHolds = holdsForSome; break;
        case "all": logicHolds = holdsForAll; break;
        case "none": logicHolds = holdsForNone; break;
        case "allOrNone": logicHolds = holdsForAll || holdsForNone; break;
        case "someOrAll": logicHolds = holdsForSome || holdsForAll; break;
        case "someOrNone": logicHolds = holdsForSome || holdsForNone; break;
    }
 
    renderedResult = (props.not ? !logicHolds : logicHolds) ? <RouterRedirect  to={props.to ? props.to : "/"} /> : <>{props.children}</>;
    return renderedResult;
}

// <>
// <Redirect if="some" ofThisHolds={true} to="/sign-in">
//     I will be redirected and you cannot see me.
//     Some of this holds: true; evaluates to true as for all true == true
// </Redirect>

// <Redirect not if="some" ofThisHolds={[true, false]} to="/sign-in">
//     I will not be redirected and you can see me.
// </Redirect>
// </>
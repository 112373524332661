import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken, UserDto} from "../APIInterfaces";

export function usersGet(userId: number): Promise<AxiosResponse<UserDto>> {
    return axios.get(API_BASE + "/users/" + userId, {headers: {Authorization: "Bearer " + getToken()}});
}

export function usersVerifyUser(userId: number | string, accept: boolean, reason: string): Promise<AxiosResponse> {
    return axios.post(API_BASE + "/users/" + userId + "/verify", {
        accept,
        reason
    }, {headers: {Authorization: "Bearer " + getToken()}});
}

export function usersEdit(user: UserDto): Promise<AxiosResponse<number>> {
    return axios.put(API_BASE + "/users/" + user.id, user, {headers: {Authorization: "Bearer " + getToken()}});
}

export function feedbackRequest(name: string, theme: string, message: string): Promise<AxiosResponse<number>> {
    return axios.post(API_BASE + "/users/feedback", {name, theme, message}, {headers: {Authorization: "Bearer " + getToken()}});
}
import {AxiosResponse} from "axios";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";
import {Choir} from "../../../modules/API/APIInterfaces";
import {memberChoirsDelete, memberChoirsGet, memberChoirsNew, memberChoirSort} from "../../../modules/API/Services/MemberChoirService";
import usePageTitle from "../../../hooks/usePageTitle";

export default function ChoirsController() : JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [choirs, setChoirs] = useState<Choir[]>();
    const [error, setError] = useState("");
    usePageTitle("Mitglied Chöre");
    
    useEffect(() => {
        memberChoirsGet().then((response: AxiosResponse<Choir[]>) => {
            setChoirs(response.data.sort(memberChoirSort));
            setError("");
        }).catch((error: any) => {
            setError(AxiosErrorMessageGetter(error));
        })
    }, []);
    
    const remove = useCallback((c: Choir) => {
        if(!choirs) {
            return;
        }
        setWaiting(true);

        memberChoirsDelete(c.name).then(() => {
            setWaiting(false);

            const newChoirs: Choir[] = [...choirs];
            const index = newChoirs.indexOf(c);
            if(index!==-1) {
                newChoirs.splice(index, 1);
            }
            setChoirs(newChoirs);
            setError("");
        }).catch((error: any) => {
            setError(AxiosErrorMessageGetter(error));
            setWaiting(false);
        });
    }, [choirs]);
    
    return <div className="p-3">
            <h3>Chor Management</h3>
            <p>
                <span>Löschen: Wird nur ausgeführt, wenn keine BenutzerIn den Chor benutzt.<br/></span>
                <span>Ersetzen: Ist nicht Umbenennen. Alter Chor wird gelöscht, nachdem der alte Chor von allen 
                    BenutzerInnen auf den neuen Chor gesetzt wird (neuer Chor muss bereits existieren).
                </span>
            </p>
            {error && <div className="alert alert-danger">{error}</div>}
            <table className="table">
                <tbody>
                    {choirs?.map((c: Choir) => <tr key={c.name}>
                        <td colSpan={2}>{c.name}</td>
                        <td><ButtonSpin spinning={waiting} disabled={waiting} variant="danger" skin="outline" onClick={() => {remove(c)}}>Löschen</ButtonSpin></td>
                    </tr>)}
                </tbody>
            </table>
            {choirs && <ChoirNew setError={setError} setChoirs={setChoirs} choirs={choirs}/>}
        </div>;
}

function ChoirNew(props: {
    setError: React.Dispatch<React.SetStateAction<string>>, 
    choirs: Choir[], 
    setChoirs: React.Dispatch<React.SetStateAction<Choir[] | undefined>>}
): JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [chor, setChor] = useState("");
    
    const submit = useCallback((chorName: string) => {
        setWaiting(true);

        memberChoirsNew(chorName).then(() => {
            setChor("");
            setWaiting(false);

            const choirs: Choir[] = [...props.choirs];
            choirs.push({name: chorName});
            props.setChoirs(choirs.sort(memberChoirSort));
            props.setError("");
        }).catch((error: any) => {
            props.setError(AxiosErrorMessageGetter(error));
            setWaiting(false);
        });
    }, [props]);
    
    return <>
            <input className="form-control" onChange={(e: ChangeEvent<HTMLInputElement>) => {setChor(e.target.value)}} value={chor} />
            <div className="d-inline-block mt-2" style={{marginLeft: "50%", transform: "translateX(-50%)"}}>
                <ButtonSpin spinning={waiting} type="button" variant="primary" onClick={() => submit(chor)} disabled={waiting}>Speichere Neuen Chor</ButtonSpin>
            </div>
        </>;
}
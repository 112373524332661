import ButtonSpin from "../Buttons/ButtonSpin";
import * as Icons from "../../Icons";
import React from "react";

export default function Pagination(props: {page: number, total: number, pageSize: number, waiting: boolean, handlePagination: (newPage: number) => void}) {
    const maxPage = Math.ceil(props.total/props.pageSize);
    return <>
        {(props.pageSize*props.page<props.total || props.page>1) && <div className="mt-3 input-group">
            <ButtonSpin classes="input-group-text" disabled={props.page<=1} flex={false} spinning={props.waiting} onClick={() => props.handlePagination(props.page-1)}><Icons.ChevronLeftNormal size={16}/></ButtonSpin>
            <span className="input-group-text bg-white">{props.page}/{maxPage}</span>
            <ButtonSpin classes="input-group-text" disabled={props.page>=maxPage} flex={false} spinning={props.waiting} onClick={() => props.handlePagination(props.page+1)}><Icons.ChevronRightNormal size={16}/></ButtonSpin>
        </div>}
    </>;
}

export interface PageDto<T> {
    content: T[],
    total: number,
    pageSize: number,
}

export function getDateStringFromDateAndTime(obj: {date: string, hour: string, minute: string}, weekday=false): string {
    return str_to_datestr(obj.date,weekday)+", "+zeropad(obj.hour, 2)+":"+zeropad(obj.minute, 2);
}

export function isDayGoneBy(date: Date) {
    return (new Date().getTime()-1000*60*60*24) > date.getTime();
}

export function getDefaultDate(): string {
    const d = new Date();
    return d.getFullYear()+"-"+zeropad(''+(d.getMonth()+1), 2)+"-"+zeropad(''+d.getDate(), 2);
}

export function zeropad(str: string, length: number): string {
    let s = ''+str;
    while(s.length<length) {
        s = '0'+s;
    }
    return s;
}

export const quillModules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const weekdayNames = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
];
const monthNames = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];

export function str_to_datestr(str: string, weekday=false): string {
    try {
        const date: Date = new Date(str);
        return  (weekday?weekdayNames[date.getDay()]+" ":"") + date.getDate() + ". " + monthNames[date.getMonth()] + " " + date.getFullYear();
    } catch (e) {

    } finally {
    }
    return "";
}

export function str_to_monthstr(str: string): string {
    try {
        const date: Date = new Date(str);
        return monthNames[date.getMonth()] + " " + date.getFullYear();
    } catch (e) {

    } finally {
    }
    return "";
}
import { NavLink } from "react-router-dom"

export type NavigationItemType = {
    to: string,
    name: string, 
    exact?: boolean,
    badge?: JSX.Element
}

// function isNavigationItem(item: Record<string, any>): item is NavigationItemType {
//     const exact = item.exact ? typeof item.exact === "boolean" : true
//     const badge = item.badge ? typeof item.badge === "object" : true
//         return  item.to !== undefined && typeof item.to === "string" &&
//                 item.name !== undefined && typeof item.name === "string" &&
//                 exact && badge
// }

type NavigationItemProps = NavigationItemType & { className?: string, activeClassName?: string }

export default function NavigationItem(props: NavigationItemProps): JSX.Element {
    return <NavLink
                exact={props.exact}
                to={props.to}
                className={props.className}
                activeClassName={props.activeClassName || "active"}
                style={{whiteSpace: "nowrap"}}>
                    {props.name}{props.badge && " " && props.badge}
                </NavLink>
}
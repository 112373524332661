import {useEffect, useState} from "react";
import {
    FriendDto,
    FriendPageDto, FriendRequestSimpleDto, networkFriendRequestAccept, networkFriendRequestIgnore,
    networkFriendRequestsActive, networkFriendRequestsIgnored, networkFriends
} from "../../../../modules/API/Services/NetworkService";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import {ProfileImage} from "./Profile";
import Pagination from "../../../../components/UI/Generic/Pagination";
import {useActions, useApiData} from "react-api-data";
import {NetworkEndpoint} from "../../../../store/APIStore";
import {UINotificationBadge} from "../../../../components/UI/Generic/UINotificationBadge";
import ApiCycle from "../../../../modules/API/ApiCycle";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import usePageTitle from "../../../../hooks/usePageTitle";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

export function ProfileSmall(props: {user: FriendDto, innerChildren?: JSX.Element, children?: JSX.Element}): JSX.Element {
    const u = props.user;
    if(!u) return <></>

    return  <div className="rounded d-flex flex-column flex-md-row justify-content-between mb-1 p-2" style={{background: "var(--bs-light)"}}>
                <div className="d-flex align-items-center mb-md-0 mb-3 px-2" style={{flexGrow: 1}}>
                    <ProfileImage id={u.id} size={90} editable={false}/>
                    <div className="ms-3" style={{flexGrow: 1}}>
                        <p className="h5 mb-0">{u.firstName} {u.lastName}</p>
                        <p className="mb-0 text-muted small">{u.memberVbc && <>{u.memberVbc.memberChoir.name}chor ({u.memberVbc.startYear} – {u.memberVbc.endYear}) </>}{u.memberHs && <>Oberstufe ({u.memberHs.startYear} – {u.memberHs.endYear})</>}</p>
                        {/*<p className="mb-0 text-muted small">Nachricht von {u.firstName}</p>*/}
                        <p className="mb-0 text-muted small">{props.children}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center" style={{minWidth: "150px"}}>
                    {props.innerChildren}
                </div>
            </div>
}

function Request(props: {active: boolean, list: FriendRequestSimpleDto[], request: FriendRequestSimpleDto, setError: (error: string) => void, ignoredRequests?: FriendRequestSimpleDto[]}): JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [ignored, setIgnored] = useState(false);

    const actions = useActions();

    const handleRequestAccept = () => {
        setWaiting(true);
        networkFriendRequestAccept(props.request.id).then(() => {
            setWaiting(false);
            props.request.accepted = true;
            actions.invalidateCache(NetworkEndpoint.GET.getActiveFriendRequests, {});
            actions.invalidateCache(NetworkEndpoint.GET.getIgnoredFriendRequests, {});
        }).catch((e) => {
            setWaiting(false);
            props.setError(AxiosErrorMessageGetter(e));
        });
    };
    const handleRequestIgnore = () => {
        setWaiting(true);
        networkFriendRequestIgnore(props.request.id).then(() => {
            setWaiting(false);
            setIgnored(true);
            actions.invalidateCache(NetworkEndpoint.GET.getActiveFriendRequests, {});
            actions.invalidateCache(NetworkEndpoint.GET.getIgnoredFriendRequests, {});
            if(props.active) {
                const index = props.list.indexOf(props.request);
                if(index!==-1) {
                    props.list.splice(index, 1);
                }
            }
            if(props.ignoredRequests) {
                props.ignoredRequests.unshift(props.request);
            }
        }).catch((e) => {
            setWaiting(false);
            props.setError(AxiosErrorMessageGetter(e));
        });
    };

    if(ignored) return <></>
    
    const innerChidren = <>
        {!props.request.accepted && <div className="d-grid gap-2" style={{flexGrow: 1}}>
            {props.active && <ButtonSpin spinning={waiting} variant="secondary" skin="outline" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleRequestIgnore();
            }} classes="btn-sm">Ignorieren</ButtonSpin>}
            <ButtonSpin spinning={waiting} variant="success" skin="outline" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleRequestAccept();
            }} classes="btn-sm">Akzeptieren</ButtonSpin>
        </div>}
    </>;
    
    return <ProfileSmall user={props.request} innerChildren={innerChidren}>
        <>{props.request.text}</>
    </ProfileSmall>;
}

export default function Friends(): JSX.Element {
    usePageTitle("Netzwerk: Kontakte")

    const [error, setError] = useState("");
    const [friends, setFriends] = useState<FriendPageDto>();
    const [activeRequests, setActiveRequests] = useState<FriendRequestSimpleDto[]>();
    const [ignoredRequests, setIgnoredRequests] = useState<FriendRequestSimpleDto[]>();

    const [waiting, setWaiting] = useState(false);
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        networkFriends(1).then((response) => {
            setFriends(response.data);
            setPage(1);
        }).catch((e) => {
            setError(AxiosErrorMessageGetter(e))
        });

        networkFriendRequestsActive().then((response) => {
            setActiveRequests(response.data);
        }).catch((e) => {
            setError(AxiosErrorMessageGetter(e))
        });

        networkFriendRequestsIgnored().then((response) => {
            setIgnoredRequests(response.data);
        }).catch((e) => {
            setError(AxiosErrorMessageGetter(e))
        });
    }, []);

    
    const handlePagination = (newPage: number) => {
        setWaiting(true);
        networkFriends(newPage).then((response) => {
            setWaiting(false);
            setFriends(response.data);
            setPage(newPage);
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e))
        });
    };
    
    const request = useApiData<number, string>( NetworkEndpoint.GET.getActiveFriendRequests, {});
    const requestIgnored = useApiData<number, string>( NetworkEndpoint.GET.getIgnoredFriendRequests, {});
    
    return <>
        {error && <div className="alert alert-danger">{error}</div>}

        <Accordion defaultActiveKey="0">
            {
            activeRequests && <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <h4 className="mb-0">Kontaktanfragen <ApiCycle data={request} extractor={(data: number) => <>{data > 0 && <UINotificationBadge count={data} />}</>} suspense={{width: 16}} /></h4>
                </Accordion.Header>
                <Accordion.Body>
                {
                    activeRequests.length === 0 ? <>Keine neuen Anfragen</> :
                    activeRequests.map( (r: FriendRequestSimpleDto) => 
                    <Link key={r.id} className="text-black d-block" to={"/network/"+r.id+"/profile"}>
                        <Request active={true} list={activeRequests} request={r} setError={setError} ignoredRequests={ignoredRequests}/>
                    </Link>
                )}
                </Accordion.Body>
            </Accordion.Item>
            }
            {ignoredRequests && <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <h4 className="mb-0">Ignorierte Anfragen <ApiCycle data={requestIgnored} extractor={d => d > 0 ? <UINotificationBadge count={d} /> : <></>}/></h4>
                </Accordion.Header>
                <Accordion.Body>
                    {
                        ignoredRequests.length > 0 ?
                        ignoredRequests.map(r => 
                            <Link key={r.id} className="text-black" to={"/network/"+r.id+"/profile"}>
                                <Request active={false} list={ignoredRequests} request={r} setError={setError}/>
                            </Link>
                    ) : <>Keine ignorierten Anfragen</> }
                </Accordion.Body>                    
            </Accordion.Item>}
            {friends && <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <h4 className="mb-0">Deine Kontakte</h4>
                </Accordion.Header>
                <Accordion.Body>
                {
                    friends.total > 0 ? <>{
                    friends.content.map( f => 
                    <Link key={f.id} className="text-black" to={"/network/"+f.id+"/profile"}>
                        <ProfileSmall user={f}/>
                    </Link>)}
                    <Pagination page={page} total={friends.total} pageSize={friends.pageSize} waiting={waiting} handlePagination={handlePagination}/>
                    </>: <>Du hast noch keine Kontakte.</>
                }
                </Accordion.Body>
            </Accordion.Item>}
        </Accordion>
    </>;
}
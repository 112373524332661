import {Field, Formik, FormikValues} from "formik";
import {EntityExport, entityExportFromDictionary, FormikDataComponentProps, FormikSubmitCancel} from "..";
import {UserGroup} from "../../../modules/Auth";
import * as Fields from "../../Fields";
import * as Yup from "yup";
import {useActions, useApiData} from "react-api-data";
import React, {useState} from "react";
import {MemberHs, MemberVbc} from "../../../modules/API/APIInterfaces";
import {MemberChoirEndpoint} from "../../../store/APIStore";
import ApiCycle from "../../../modules/API/ApiCycle";

export const SalutationsEntity : EntityExport = entityExportFromDictionary([{
    id: 1,
    display: "Herr",
    alternate: "HERR"
}, {
    id: 2,
    display: "Frau",
    alternate: "FRAU"
}], "display", "alternate")

/*export const MembershipFeesEntity : EntityExport = entityExportFromDictionary([{
    "value": "Jungalumni",
    "label": "Jung-Alumni – kein Mitgliedsbeitrag"
}, {
    "value": "Student",
    "label": "Studierende, Zivildiener, und Wehrdiener – 15 € im Jahr"
}, {
    "value": "Vollmitglied",
    "label": "UNISONO Alumni – 30 € im Jahr"
}], "label", "value")*/

export const legalConsentEntity : EntityExport = entityExportFromDictionary([{
    value: 'data_correctness',
    label: 'Ich bestätige, dass ich ehemaliger Wiener Sängerknabe bzw. AbsolventIn des Oberstufenrealgymnasium der Wiener Sängerknaben bin. Meine Angaben sind korrekt.'
}, {
    value: 'statutes_and_privacy',
    label: "Ich habe die Statuten und Datenschutzerklärung gelesen und akzeptiere diese in der geltenden Fassung. Das bedeutet insbesondere, dass UNISONO meine Daten elektronisch speichern und weiterverarbeiten darf."
}, {
    value: 'marketing_material',
    label: "Wenn ich Events besuche, bin ich damit einverstanden, dass ggf. von mir Bild- und/oder Tonmaterial aufgenommen wird. Dieses darf UNISONO für Marketingzwecke verwenden."
}], "label", "value")

/*export const ChoirsEntity : EntityExport = entityExportFromDictionary([{
    id: "Haydn", 
    name: "Haydnchor"
}, {
    id: "Bruckner", 
    name: "Brucknerchor"
}, {
    id: "Schubert", 
    name: "Schuberchor"
}, {
    id: "Mozart",
    name: "Mozartchor"
}], "name", "id")*/

export const UserTypesEntity : EntityExport = entityExportFromDictionary<{id: UserGroup, name: string, disabled?: boolean}>([{
    id: UserGroup.Admin,
    name: "Administrator"
}, {
    id: UserGroup.Board,
    name: "Vorstand"
}, {
    id: UserGroup.Circle,
    name: "Circle"
},  {
    id: UserGroup.Alumni,
    name: "Alumni (Standard)"
},{
    id: UserGroup.NotVerified,
    name: "Nicht Verifiziert"
}, {
    id: UserGroup.NotRegistered,
    name: "Nicht Registriert"
}, {
    id: UserGroup.Left,
    name: "Ausgetreten",
    disabled: true
}], "name", "id")

export const UserRolesEntity : EntityExport = entityExportFromDictionary<{id: UserGroup, name: string}>([{
    id: UserGroup.Alumni,
    name: "Alumni (Standard)"
}, {
    id: UserGroup.NotVerified,
    name: "Nicht Verifiziert"
}], "name", "id")

export interface HTMLPersonalDataFormElements extends FormikValues {
    "salutation": Fields.HTMLFieldSingleChoice,
    "titlePrefix": Fields.HTMLFieldOptionalInput,
    "titleSuffix": Fields.HTMLFieldOptionalInput,
    "firstName": Fields.HTMLFieldInput,
    "lastName": Fields.HTMLFieldInput,
    "birthday": Fields.HTMLFieldInput,
    "telephone": Fields.HTMLFieldInput,
    "address": Fields.HTMLFieldInput,
    "postalCode": Fields.HTMLFieldInput,
    "city": Fields.HTMLFieldInput,
    "country": Fields.HTMLFieldInput,
    "state": Fields.HTMLFieldInput,
    memberVbc: MemberVbc,
    memberHs: MemberHs,
}

/*export interface HTMLVbcHsFormElements extends FormikValues {
    "vbcStartYear": Fields.HTMLFieldOptionalInput,
    "vbcEndYear": Fields.HTMLFieldOptionalInput,
    "vbcChoir": Fields.HTMLFieldOptionalInput,
    "hsStartYear": Fields.HTMLFieldOptionalInput,
    "hsEndYear": Fields.HTMLFieldOptionalInput
}*/

export const MemberPersonalDataYupValidation = Yup.object({
    salutation: Yup.string().oneOf(SalutationsEntity.asSingleValueCollection).required(),
    titlePrefix: Yup.string().optional(),
    titleSuffix: Yup.string().optional(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    birthday: Yup.date().required(),
    telephone: Yup.string().required(),
    address: Yup.string().required(),
    postalCode: Yup.string().required(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    state: Yup.string().optional()
})

export default function MemberPersonalDataFormik(props: FormikDataComponentProps<HTMLPersonalDataFormElements>): JSX.Element {
    
    const [showDetailedStatusDescription, setShowDetailedStatusDescription] = useState(false)

    const action = useActions();
    
    const memberChoirs = useApiData<{name: string}[], string>(MemberChoirEndpoint.GET.memberChoirs);
    
    return <Formik 
            initialValues={props.initial}
            validationSchema={MemberPersonalDataYupValidation}
            onSubmit={ (values, { setSubmitting,setStatus, resetForm } ) => {
                
                if (props.onSubmit) {
                    const submitResult = props.onSubmit(values);

                    if (typeof submitResult === "boolean") {
                        if (!submitResult) {
                            setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', {}])
                        }
                    } else {
                        submitResult.then(value => {
                            // value.invalidateCache()
                            action.invalidateCache('getUserDataWithID', {id: value.request.params?.id || 0})
                            action.invalidateCache('getUsers')
                            resetForm()
                        }).catch(reason => {
                            setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', reason])
                        })
                    }

                } 
                setSubmitting(false)
            }}>
                { formik => <>
                    {formik.status ? 
                    <div className="alert alert-danger">
                        <p>{formik.status[0]}</p>
                        <p className="mb-0"><button className="btn btn-link alert-link" onClick={() => setShowDetailedStatusDescription(!showDetailedStatusDescription)}>Server Antwort {showDetailedStatusDescription ? "verbergen" : "anzeigen"}</button></p>
                        { showDetailedStatusDescription ? 
                            <div>
                                <pre className="mb-0">{JSON.stringify(formik.status[1], null, "  ")}</pre>
                            </div> : null}
                    </div> : null}
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <Fields.FieldCheck name="salutation" label="Anrede" renderAsButtonGroup choices={SalutationsEntity.asSingleValueCollection} />
                        <Fields.FieldSpacer />
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="titlePrefix" label="Titel, vorangestellt (optional)" formik={formik} />
                            <Fields.FieldInput name="titleSuffix" label="Title, nachgestellt (optional)" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="firstName" label="Vorname" formik={formik} />
                            <Fields.FieldInput name="lastName" label="Nachname" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldSpacer />
                        <Fields.FieldInput name="address" label="Addresse" formik={formik} />
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="postalCode" label="Postleitzahl" formik={formik} />
                            <Fields.FieldInput name="city" label="Stadt" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="state" label="Bundesstaat / Bundesland (optional)" formik={formik} />
                            <Fields.FieldInput name="country" label="Land" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldSpacer />
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="birthday" label="Geburtstag" type="date" formik={formik} />
                            <Fields.FieldInput name="telephone" label="Telefonnummer" type="tel" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldSpacer />
                        
                        <h4>WSK</h4>
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="memberVbc.startYear" label="Start Jahr" type="number" formik={formik} />
                            <Fields.FieldInput name="memberVbc.endYear" label="End Jahr" type="number" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldColumn>
                            <div>
                                <label className="mb-2">Klasse</label>
                                <Field className="form-control form-select" as="select" name="memberVbc.startClass" label="Chor" formik={formik}>
                                    <option value="">Keiner</option>
                                    <option value="1">1. Klasse</option>
                                    <option value="2">2. Klasse</option>
                                    <option value="3">3. Klasse</option>
                                    <option value="4">4. Klasse</option>
                                </Field>
                            </div>
                            <div>
                                <label className="mb-2">Chor</label>
                                <ApiCycle data={memberChoirs} extractor={(data: {name: string}[]) => <Field className="form-control form-select" as="select" name="memberVbc.memberChoir.name" label="Chor" formik={formik}>
                                    <option value="">Keiner</option>
                                    {data.map((choir) => <option key={choir.name} value={choir.name}>{choir.name}</option>)}
                                </Field>} />
                                
                            </div>
                        </Fields.FieldColumn>

                        <h4 className="mt-3">ORG</h4>
                        <Fields.FieldColumn>
                            <Fields.FieldInput name="memberHs.startYear" label="Start Jahr" type="number" formik={formik} />
                            <Fields.FieldInput name="memberHs.endYear" label="End Jahr" type="number" formik={formik} />
                        </Fields.FieldColumn>
                        <Fields.FieldColumn>
                            <div>
                                <label className="mb-2">Klasse</label>
                                <Field className="form-control form-select" as="select" name="memberHs.startClass" label="Chor" formik={formik}>
                                    <option value="">Keiner</option>
                                    <option value="5">5. Klasse</option>
                                    <option value="6">6. Klasse</option>
                                    <option value="7">7. Klasse</option>
                                    <option value="8">8. Klasse</option>
                                </Field>
                            </div>
                        </Fields.FieldColumn>
                        <FormikSubmitCancel formik={formik} onSubmitText={props.onSubmitText} onCancelText={props.onCancelText} />
                    </form>
                </>}
            </Formik>
}
import React from 'react'
import classNames from 'classnames/bind'
// import * as styles from './style.scss'
import "./style.scss"

// let cx = classNames.bind(styles)

type BootstrapVariants = "primary" | "danger" | "success" | "secondary" | "light" | "dark"
type BootstrapButtonSkins = "fill" | "outline" | "link"

type ButtonSpinProps = {
    type?: "submit" | "button" | "reset",
    variant?: BootstrapVariants,
    skin?: BootstrapButtonSkins,
    spinning?: boolean,
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    classes?: string,
    flex?: boolean,
    spinColor?: string
}

const DefaultButtonSpinProps: ButtonSpinProps = {
    type: "button",
    variant: "primary",
    skin: "fill",
    spinning: false,
    disabled: false,
    onClick: undefined,
    spinColor: "#FFFFFF"
}

export default function ButtonSpin(props: React.PropsWithChildren<ButtonSpinProps> = DefaultButtonSpinProps) : JSX.Element {
    const prop = {
        ...DefaultButtonSpinProps,
        ...props
    }
    let classnames = classNames({
        'btn': true,
        'btn-spin': true,
        'flex-fill': props.flex!==undefined ? props.flex : true,
        'spinning': prop.spinning,
        [`btn-outline-${prop.variant}`]: prop.skin === "outline",
        [`btn-${prop.variant}`]: prop.skin === "fill",
        [`btn-link link-${prop.variant}`]: prop.skin === "link"
    }) + (props.classes ? ' ' + props.classes : '')

    let spinColor = {'--btn-spin-color': props.spinColor || "#FFF"} as React.CSSProperties

    return <button className={classnames} disabled={props.spinning || props.disabled} type={props.type || "button"} onClick={props.onClick} style={spinColor}><span className="btn-spin-text">{props.children}</span></button>
}
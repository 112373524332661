import Cookies, { CookieSetOptions } from "universal-cookie/es6";

// export interface CookieSetOptions {
//     path?: string;
//     expires?: Date;
//     maxAge?: number;
//     domain?: string;
//     secure?: boolean;
//     httpOnly?: boolean;
//     sameSite?: boolean | 'none' | 'lax' | 'strict';
//     encode?: (value: string) => string;
// }

const C = new Cookies();

export const AppCookiesSetOptions: CookieSetOptions = {
    domain: "club.unisono-alumni.loc"
}

export default C

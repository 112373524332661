import {Route, Switch} from "react-router-dom";
import MemberDetail from "./MemberDetail";
import LockedDetail from "./LockedDetail";
import MembersOverview from "./MembersOverview";
import ApiCycle from "../../../modules/API/ApiCycle";
import {useApiData} from "react-api-data";
import {UserEndpoint} from "../../../store/APIStore";
import NavigationSegment from "../../../components/UI/Generic/UINavigationSegment/NavigationSegment";
import {NavigationItemType} from "../../../components/UI/Generic/UINavigationItem/UINavigationItem";
import RegistrationsOverview from "../Registrations/RegistrationsOverview";
import React from "react";
import RegistrationsNotDoneOverview from "../Registrations/RegistrationNotDoneOverview";

export default function MembersController() : JSX.Element {    

    const navItems: NavigationItemType[] = [{
        to: "/apacis/members",
        name: "Mitglieder",
        exact: true,
        badge: <UserBadge />
    }, {
        to: "/apacis/members/locked",
        name: "Gesperrt",
        exact: false,
        badge: <LockedUserBadge />
    }, {
        to: "/apacis/members/registrations",
        name: "Anmeldungen",
        exact: false,
        badge: <RegistrationsUserBadge />
    }, {
        to: "/apacis/members/registrationsNotDone",
        name: "Registrierungen",
        exact: false,
        badge: <RegistrationsNotDoneUserBadge />
    }]

    return  <div className="d-flex flex-column">
            <NavigationSegment items={navItems} />
            <Switch>
                <Route path="/apacis/members" component={MembersOverview} exact />
                <Route path="/apacis/members/view/:uid"><MemberDetail page="members" /></Route>
                <Route path="/apacis/members/locked" component={LockedDetail} exact />
                <Route path="/apacis/members/registrations" component={RegistrationsOverview} exact />
                <Route path="/apacis/members/registrationsNotDone" component={RegistrationsNotDoneOverview} exact />
                <Route path="/apacis/members/registrations/view/:uid"><MemberDetail page="registrations" /></Route>
            </Switch>
    </div>
}

const badgeExtractor = (data: number) : JSX.Element => {
    if (data > 0) return <>: {data}</>;
    return <></>;
}

const UserBadge = () => {
    const request = useApiData<number, string>( UserEndpoint.GET.usersCount )
    return <ApiCycle data={request} extractor={badgeExtractor}/>
}

const LockedUserBadge = () => {
    const request = useApiData<number, string>( UserEndpoint.GET.usersCount, {locked: 1 } )
    return <ApiCycle data={request} extractor={badgeExtractor} />
}

const RegistrationsUserBadge = () => {
    const request = useApiData<number, string>( UserEndpoint.GET.usersCount, {usertype: "unverified", locked: 0} )
    return <ApiCycle data={request} extractor={badgeExtractor} />
}

const RegistrationsNotDoneUserBadge = () => {
    const request = useApiData<number, string>( UserEndpoint.GET.usersCount, {usertype: "unregistered", locked: 0} )
    return <ApiCycle data={request} extractor={badgeExtractor} />
}

export function escapeRegExp(tmp: string) : string {
  return tmp.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function isStringArray(value: any): value is string[] {
  if (Array.isArray(value)) {
    value.forEach(function(item) {
      if (typeof item !== 'string') {
        return false
      }
    })
    return true
  }
  return false
}

export function splitHost( hostname: String ): { 'tld': String; 'base': String; 'sub': Array<String>|null} {
  const split = hostname.split('.');
  const count = split.length;

  if (count < 2) {
    throw new Error('Splitting up hostname returns less than two elements. Are you sure that ' + hostname + ' is a valid domain?');
  }

  var subdomains = null;
  const tld = split[count - 1];
  split.pop();
  const base = split[count - 2];
  if (count > 2) {
    split.pop();
    subdomains = split.reverse();
  }
  return(
    {
      'tld': tld,
      'base': base,
      'sub': subdomains
    }
  );
}

export const countriesRecord: Record<string, string> = {
  AF: "Afghanistan",
  EG: "Ägypten",
  AX: "Åland",
  AL: "Albanien",
  DZ: "Algerien",
  AS: "Amerikanisch-Samoa",
  VI: "Amerikanische Jungferninseln",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktis",
  AG: "Antigua und Barbuda",
  GQ: "Äquatorialguinea",
  AR: "Argentinien",
  AM: "Armenien",
  AW: "Aruba",
  AC: "Ascension",
  AZ: "Aserbaidschan",
  ET: "Äthiopien",
  AU: "Australien",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesch",
  BB: "Barbados",
  BY: "Belarus (Weißrussland)",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivien",
  BA: "Bosnien und Herzegowina",
  BW: "Botswana",
  BV: "Bouvetinsel",
  BR: "Brasilien",
  VG: "Britische Jungferninseln",
  IO: "Britisches Territorium im Indischen Ozean",
  BN: "Brunei Darussalam",
  BG: "Bulgarien",
  BF: "Burkina Faso",
  BI: "Burundi",
  EA: "Ceuta, Melilla",
  CL: "Chile",
  CN: "Volksrepublik China",
  CP: "Clipperton",
  CK: "Cookinseln",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire (Elfenbeinküste)",
  DK: "Dänemark",
  DE: "Deutschland",
  DG: "Diego Garcia",
  DM: "Dominica",
  DO: "Dominikanische Republik",
  DJ: "Dschibuti",
  EC: "Ecuador",
  SV: "El Salvador",
  ER: "Eritrea",
  EE: "Estland",
  FK: "Falklandinseln",
  FO: "Färöer",
  FJ: "Fidschi",
  FI: "Finnland",
  FR: "Frankreich",
  GF: "Französisch-Guayana",
  PF: "Französisch-Polynesien",
  GA: "Gabun",
  GM: "Gambia",
  GE: "Georgien",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Grenada",
  GR: "Griechenland",
  GL: "Grönland",
  GB: "Großbritannien",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey (Kanalinsel)",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard- und McDonald-Inseln",
  HN: "Honduras",
  HK: "Hongkong",
  IN: "Indien",
  ID: "Indonesien",
  IM: "Insel Man",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irland",
  IS: "Island",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaika",
  JP: "Japan",
  YE: "Jemen",
  JE: "Jersey (Kanalinsel)",
  JO: "Jordanien",
  KY: "Kaimaninseln",
  KH: "Kambodscha",
  CM: "Kamerun",
  CA: "Kanada",
  IC: "Kanarische Inseln",
  CV: "Kap Verde",
  KZ: "Kasachstan",
  QA: "Katar",
  KE: "Kenia",
  KG: "Kirgisistan",
  KI: "Kiribati",
  CC: "Kokosinseln",
  CO: "Kolumbien",
  KM: "Komoren",
  CD: "Demokratische Republik Kongo",
  KP: "Demokratische Volksrepublik Korea (Nordkorea)",
  KR: "Republik Korea (Südkorea)",
  HR: "Kroatien",
  CU: "Kuba",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettland",
  LB: "Libanon",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macao",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Malediven",
  ML: "Mali",
  MT: "Malta",
  MA: "Marokko",
  MH: "Marshallinseln",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  YT: "Mayotte",
  MK: "Mazedonien",
  MX: "Mexiko",
  FM: "Mikronesien",
  MD: "Moldawien (Republik Moldau)",
  MC: "Monaco",
  MN: "Mongolei",
  ME: "Montenegro",
  MS: "Montserrat",
  MZ: "Mosambik",
  MM: "Myanmar (Burma)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NC: "Neukaledonien",
  NZ: "Neuseeland",
  NI: "Nicaragua",
  NL: "Niederlande",
  AN: "Niederländische Antillen",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  MP: "Nördliche Marianen",
  NF: "Norfolkinsel",
  NO: "Norwegen",
  OM: "Oman",
  XO: "Orbit",
  AT: "Österreich",
  TL: "Osttimor (Timor-Leste)",
  PK: "Pakistan",
  PS: "Palästinensische Autonomiegebiete",
  PW: "Palau",
  PA: "Panama",
  PG: "Papua-Neuguinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippinen",
  PN: "Pitcairninseln",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",TW: "Republik China (Taiwan)",
  CG: "Republik Kongo",
  RE: "Réunion",
  RW: "Ruanda",
  RO: "Rumänien",
  RU: "Russische Föderation",
  BL: "Saint-Barthélemy",
  MF: "Saint-Martin",
  SB: "Salomonen",
  ZM: "Sambia",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé und Príncipe",
  SA: "Saudi-Arabien",
  SE: "Schweden",
  CH: "Schweiz",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seychellen",
  SL: "Sierra Leone",
  ZW: "Simbabwe",
  SG: "Singapur",
  SK: "Slowakei",
  SI: "Slowenien",
  SO: "Somalia",
  ES: "Spanien",
  LK: "Sri Lanka",
  SH: "St. Helena",
  KN: "St. Kitts und Nevis",
  LC: "St. Lucia",
  PM: "Saint-Pierre und Miquelon",
  VC: "St. Vincent und die Grenadinen",
  ZA: "Südafrika",
  SD: "Sudan",
  GS: "Südgeorgien und die Südlichen Sandwichinseln",
  SR: "Suriname",
  SJ: "Svalbard und Jan Mayen",
  SZ: "Swasiland",
  SY: "Syrien",
  TJ: "Tadschikistan",
  TZ: "Tansania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad und Tobago",
  TA: "Tristan da Cunha",
  TD: "Tschad",
  CZ: "Tschechische Republik",
  TN: "Tunesien",
  TR: "Türkei",
  TM: "Turkmenistan",
  TC: "Turks- und Caicosinseln",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  HU: "Ungarn",
  UY: "Uruguay",
  UZ: "Usbekistan",
  VU: "Vanuatu",
  VA: "Vatikanstadt",
  VE: "Venezuela",
  AE: "Vereinigte Arabische Emirate",
  US: "Vereinigte Staaten von Amerika (USA)",
  VN: "Vietnam",
  WF: "Wallis und Futuna",
  CX: "Weihnachtsinsel",
  EH: "Westsahara",
  CF: "Zentralafrikanische Republik",
  CY: "Zypern"
}

export const countriesArray: Array<string> = [
  "Afghanistan",
  "Ägypten",
  "Åland",
  "Albanien",
  "Algerien",
  "Amerikanisch-Samoa",
  "Amerikanische Jungferninseln",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarktis",
  "Antigua und Barbuda",
  "Äquatorialguinea",
  "Argentinien",
  "Armenien",
  "Aruba",
  "Ascension",
  "Aserbaidschan",
  "Äthiopien",
  "Australien",
  "Bahamas",
  "Bahrain",
  "Bangladesch",
  "Barbados",
  "Belarus (Weißrussland)",
  "Belgien",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivien",
  "Bosnien und Herzegowina",
  "Botswana",
  "Bouvetinsel",
  "Brasilien",
  "Britische Jungferninseln",
  "Britisches Territorium im Indischen Ozean",
  "Brunei Darussalam",
  "Bulgarien",
  "Burkina Faso",
  "Burundi",
  "Ceuta, Melilla",
  "Chile",
  "Volksrepublik China",
  "Clipperton",
  "Cookinseln",
  "Costa Rica",
  "Côte d'Ivoire (Elfenbeinküste)",
  "Dänemark",
  "Deutschland",
  "Diego Garcia",
  "Dominica",
  "Dominikanische Republik",
  "Dschibuti",
  "Ecuador",
  "El Salvador",
  "Eritrea",
  "Estland",
  "Falklandinseln",
  "Färöer",
  "Fidschi",
  "Finnland",
  "Frankreich",
  "Französisch-Guayana",
  "Französisch-Polynesien",
  "Gabun",
  "Gambia",
  "Georgien",
  "Ghana",
  "Gibraltar",
  "Grenada",
  "Griechenland",
  "Grönland",
  "Großbritannien",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey (Kanalinsel)",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard- und McDonald-Inseln",
  "Honduras",
  "Hongkong",
  "Indien",
  "Indonesien",
  "Insel Man",
  "Irak",
  "Iran",
  "Irland",
  "Island",
  "Israel",
  "Italien",
  "Jamaika",
  "Japan",
  "Jemen",
  "Jersey (Kanalinsel)",
  "Jordanien",
  "Kaimaninseln",
  "Kambodscha",
  "Kamerun",
  "Kanada",
  "Kanarische Inseln",
  "Kap Verde",
  "Kasachstan",
  "Katar",
  "Kenia",
  "Kirgisistan",
  "Kiribati",
  "Kokosinseln",
  "Kolumbien",
  "Komoren",
  "Demokratische Republik Kongo",
  "Demokratische Volksrepublik Korea (Nordkorea)",
  "Republik Korea (Südkorea)",
  "Kroatien",
  "Kuba",
  "Kuwait",
  "Laos",
  "Lesotho",
  "Lettland",
  "Libanon",
  "Liberia",
  "Libyen",
  "Liechtenstein",
  "Litauen",
  "Luxemburg",
  "Macao",
  "Madagaskar",
  "Malawi",
  "Malaysia",
  "Malediven",
  "Mali",
  "Malta",
  "Marokko",
  "Marshallinseln",
  "Martinique",
  "Mauretanien",
  "Mauritius",
  "Mayotte",
  "Mazedonien",
  "Mexiko",
  "Mikronesien",
  "Moldawien (Republik Moldau)",
  "Monaco",
  "Mongolei",
  "Montenegro",
  "Montserrat",
  "Mosambik",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Neukaledonien",
  "Neuseeland",
  "Nicaragua",
  "Niederlande",
  "Niederländische Antillen",
  "Niger",
  "Nigeria",
  "Niue",
  "Nördliche Marianen",
  "Norfolkinsel",
  "Norwegen",
  "Oman",
  "Orbit",
  "Österreich",
  "Osttimor (Timor-Leste)",
  "Pakistan",
  "Palästinensische Autonomiegebiete",
  "Palau",
  "Panama",
  "Papua-Neuguinea",
  "Paraguay",
  "Peru",
  "Philippinen",
  "Pitcairninseln",
  "Polen",
  "Portugal",
  "Puerto Rico",
  "Republik China (Taiwan)",
  "Republik Kongo",
  "Réunion",
  "Ruanda",
  "Rumänien",
  "Russische Föderation",
  "Saint-Barthélemy",
  "Saint-Martin",
  "Salomonen",
  "Sambia",
  "Samoa",
  "San Marino",
  "São Tomé und Príncipe",
  "Saudi-Arabien",
  "Schweden",
  "Schweiz",
  "Senegal",
  "Serbien",
  "Seychellen",
  "Sierra Leone",
  "Simbabwe",
  "Singapur",
  "Slowakei",
  "Slowenien",
  "Somalia",
  "Spanien",
  "Sri Lanka",
  "St. Helena",
  "St. Kitts und Nevis",
  "St. Lucia",
  "Saint-Pierre und Miquelon",
  "St. Vincent und die Grenadinen",
  "Südafrika",
  "Sudan",
  "Südgeorgien und die Südlichen Sandwichinseln",
  "Suriname",
  "Svalbard und Jan Mayen",
  "Swasiland",
  "Syrien",
  "Tadschikistan",
  "Tansania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad und Tobago",
  "Tristan da Cunha",
  "Tschad",
  "Tschechische Republik",
  "Tunesien",
  "Türkei",
  "Turkmenistan",
  "Turks- und Caicosinseln",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "Ungarn",
  "Uruguay",
  "Usbekistan",
  "Vanuatu",
  "Vatikanstadt",
  "Venezuela",
  "Vereinigte Arabische Emirate",
  "Vereinigte Staaten von Amerika (USA)",
  "Vietnam",
  "Wallis und Futuna",
  "Weihnachtsinsel",
  "Westsahara",
  "Zentralafrikanische Republik",
  "Zypern"
]

import { Formik } from "formik";
import { FormikDataComponentProps, FormikSubmitCancel } from "..";
import * as Fields from "../../Fields";
import * as Yup from "yup";
import { useState } from "react";
import { useActions } from "react-api-data";
import { Table } from "react-bootstrap";

export const MemberAccountDataYupValidation = Yup.object({
    email: Yup.string().email().required(),
    passwordResetLink: Yup.string().nullable()
})

export type HTMLMemberPersonalDataFormElements = {
    passwordResetLink: string, 
    email: string
}
export default function MemberAccountDataFormik(props: FormikDataComponentProps<HTMLMemberPersonalDataFormElements>): JSX.Element {
    
    const [showDetailedStatusDescription, setShowDetailedStatusDescription] = useState(false)

    const action = useActions();
    
    return  <Formik
                initialValues={props.initial}
                validationSchema={MemberAccountDataYupValidation}
                onSubmit={ (values, { setSubmitting,setStatus, resetForm } ) => {
                    
                    if (props.onSubmit) {
                        const submitResult = props.onSubmit(values);

                        if (typeof submitResult === "boolean") {
                            if (!submitResult) {
                                setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', {reason: 'Function returned FALSE'}])
                            }
                        } else {
                            submitResult.then(value => {
                                value.invalidateCache()
                                action.invalidateCache('getUserDataWithID', {id: value.request.params?.id || 0})
                                resetForm()
                            }).catch(reason => {
                                setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', reason])
                            })
                        }

                    } 
                    setSubmitting(false)
                }}>
                    {
                        formik =>   <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                        <Table variant="light" hover className="table-grid">
                                            <tbody>
                                                {formik.status &&
                                                <tr aria-colspan={2}>
                                                    <div className="alert alert-danger m-4">
                                                        <p>{formik.status[0]}</p>
                                                        <p className="mb-0"><button className="btn btn-link alert-link" onClick={() => setShowDetailedStatusDescription(!showDetailedStatusDescription)}>Server Antwort {showDetailedStatusDescription ? "verbergen" : "anzeigen"}</button></p>
                                                        { showDetailedStatusDescription ? 
                                                            <div>
                                                                <pre className="mb-0">{JSON.stringify(formik.status[1], null, "  ")}</pre>
                                                            </div> : null}
                                                    </div>
                                                </tr>}
                                                <tr>
                                                    <th scope="row">
                                                        <h6 className="mb-0">E-Mail Adresse</h6>
                                                    </th>
                                                    <td><Fields.FieldInput name="email" label="E-Mail" type="email" formik={formik} noLabel noStyle/></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <FormikSubmitCancel formik={formik} onSubmitText={props.onSubmitText} onCancelText={props.onCancelText} />
                                    </form>
                    }
            </Formik>
}
import { Binding } from "react-api-data";
import APISuspense from "./APISuspense";

type ApiCycleProps<T> = {
    data: Binding<T, any>,
    textual?: boolean, 
    extractor?: (data: T, props?: {[key: string]: any}) => JSX.Element,
    extractorProps?: {[key: string]: any},
    suspense? : {
        width?: number, maxWidth?: number, top?: number
    }
}

export default function ApiCycle<T>(props: ApiCycleProps<T>) : JSX.Element {
    
    if (props.data.request.networkStatus === "loading") {
        return props.textual ? <>Loading...</> : <APISuspense type="wait" width={props.suspense?.width} maxWidth={props.suspense?.maxWidth} top={props.suspense?.top} />
    } else if (props.data.request.networkStatus === "failed") {
        console.warn("APICycle request failed", props.data)
        return props.textual ? <>Failed!</> : <APISuspense type="fail" width={props.suspense?.width} maxWidth={props.suspense?.maxWidth} top={props.suspense?.top} />
    } else if (props.data.request.networkStatus === "success") {

        if (typeof props.data.data === "object") {
            if (props.extractor === undefined) {console.error("Extractor needs to be defined when object is expected as result."); return <></>}
            return props.extractor(props.data.data, props.extractorProps )
        }

        if (Array.isArray(props.data.data)) {
            if (props.extractor === undefined) {console.error("Extractor needs to be defined when object is expected as result."); return <></>}
            return props.extractor(props.data.data, props.extractorProps)
        }

        if (props.extractor && props.data.data !== undefined) {
            return props.extractor(props.data.data, props.extractorProps)
        }

        return <>{props.data.data}</>
    }/* else if (props.data.request.networkStatus === "ready") {
        props.data.perform()
            .then()//props.extractor?.(r.data!) || <></>)
            .catch(r => console.warn("Could not handle request with networkStatus===ready.", r))
    }*/
    return <></>
}
import _ from "lodash";
import {Binding, useApiData} from "react-api-data"
import {Route, Switch, useParams} from "react-router"
import MemberAccountDataFormik, {HTMLMemberPersonalDataFormElements} from "../../../components/Data/MemberAccountData";
import MemberMembershipDataFormik, {HTMLMemberMembershipDataFormElements} from "../../../components/Data/MemberMembershipData";
import MemberPersonalDataFormik, {HTMLPersonalDataFormElements} from "../../../components/Data/MemberPersonalData";
import ApiCycle from "../../../modules/API/ApiCycle"
import {UserDto} from "../../../modules/API/APIInterfaces";
import {PaymentEndpoint, UserEndpoint} from "../../../store/APIStore";
import React, {useCallback, useState} from "react";
import AdministrationComponent from "./controllers/AdministrationComponent";
import ListNavigation from "../../../components/UI/Generic/ListNavigation";
import {Button} from "react-bootstrap";
import {NavigationItemType} from "../../../components/UI/Generic/UINavigationItem/UINavigationItem";
import {PaymentDto, paymentsConfirm} from "../../../modules/API/Services/PaymentService";
import {str_to_datestr} from "../../../modules/API/Services/Misc";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import {MessageDisplay, MessageState} from "../../../components/MessageState";
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";

export default function MemberDetail(props: { page: "members" | "registrations" } = {page: "members"} ): JSX.Element {
    
    const userId = useParams<{uid: string}>().uid

    const navItems: NavigationItemType[] = [{
        name: "Persönliche Daten",
        to: "/apacis/" + props.page + "/view/" + userId + "/",
        exact: true
    }, {
        name: "Account",
        to: "/apacis/" + props.page + "/view/" + userId + "/account"
    }, {
        name: "Mitgliedschaft",
        to: "/apacis/" + props.page + "/view/" + userId + "/membership"
    },{
        name: "Admin & Moderation",
        to: "/apacis/" + props.page + "/view/" + userId + "/admin"
    }]

    return  <div>
                <MemberDetailHeader userID={userId} page={props.page} />
                <div className="d-grid" style={{gridTemplateRows: "1fr", gridTemplateColumns: "4fr minmax(150px, 1fr)"}}>
                    <div className="me-0 me-md-4">
                        <Switch>
                            <Route exact path={"/apacis/" + props.page + "/view/" + userId + "/"}><PersonalDataComponent userID={userId} /></Route>
                            <Route path={"/apacis/" + props.page + "/view/" + userId + "/account"}><AccountComponent userID={userId} /></Route>
                            <Route path={"/apacis/" + props.page + "/view/" + userId + "/membership"}><MembershipComponent userID={userId} /></Route>
                            <Route path={"/apacis/" + props.page + "/view/" + userId + "/admin"}><AdministrationComponent userID={userId} /></Route>
                        </Switch>
                    </div>
                    <aside className="mb-4 mb-md-0">
                        <ListNavigation navItems={navItems} />
                        <div className="d-grid my-2">
                            <Button variant="outline-secondary">Show Debug Information</Button>
                        </div>
                    </aside>
                </div>
            </div>
    
}
export type MemberDetailSubComponentProps = {
    userID: string,
    page?: string
}

const MemberDetailHeader = (props: MemberDetailSubComponentProps) => {

    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})
     // TODO: FILTER User

    const DataExtractor = (data: UserDto) : JSX.Element => {
        return <>{props.page }&#35;{data.id} &mdash; {data.firstName} {data.lastName}</>
    }

    return <div className="d-block bg-light border border-secondary text-secondary px-2 py-3 mb-3 fw-bold rounded-1 text-uppercase">
                <ApiCycle extractor={DataExtractor} data={user} />
            </div>
}

const PersonalDataComponent = (props: MemberDetailSubComponentProps) => {
    const [isDebug, setIsDebug] = useState(false);
    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})

    const DataExtractor = (data: UserDto) : JSX.Element => {
        const putUser = useApiData<string, string>(UserEndpoint.PUT.user, {id: props.userID})

        const initial = _.pick(data, [
            "salutation",
            "titlePrefix",
            "titleSuffix",
            "firstName",
            "lastName",
            "birthday",
            "telephone",
            "address",
            "postalCode",
            "city",
            "country",
            "state",
            "memberVbc",
            "memberHs",
        ]) as HTMLPersonalDataFormElements

        const mutable = _.cloneDeep(initial)

        const handleSubmit = (values: HTMLPersonalDataFormElements) : Promise<Binding<string, any>> => {
            // history.push("/apacis/members/view/"+props.data?.id+"/")

            const updateValues = {
                ...data,
                ...values
            }
            
            return putUser.perform({ id: data.id }, updateValues)
        }

        return <>
            <button className="btn btn-secondary w-100" onClick={() => setIsDebug(!isDebug)}>Debug Information</button>
            {isDebug && <pre>
                    {JSON.stringify(data, null, "  ")}
                </pre>}
            <div className="py-2"/>
            <MemberPersonalDataFormik initial={mutable} onSubmit={handleSubmit} />
        </>;
    }

    return <ApiCycle data={user} extractor={DataExtractor} />
}

const AccountComponent = (props: MemberDetailSubComponentProps) => {

    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})

    const DataExtractor = (data: UserDto) : JSX.Element => {
        
        const putUser = useApiData<string, string>(UserEndpoint.PUT.user, {id: props.userID})
        
        const initial = _.pick(data, [
            "email"
        ]) as HTMLMemberPersonalDataFormElements

        const mutable = _.cloneDeep(initial)

        const handleSubmit = (values: HTMLMemberPersonalDataFormElements) : Promise<Binding<string, any>> => {
            // history.push("/apacis/members/view/"+props.data?.id+"/")
    
            const updateValues = {
                ...data,
                ...values
            }
            
            return putUser.perform({ id: data.id }, updateValues)
        }
    
        return <MemberAccountDataFormik initial={mutable} onSubmit={handleSubmit} />
    }

    return <ApiCycle data={user} extractor={DataExtractor} />
}

const MembershipComponent = (props: MemberDetailSubComponentProps) => {
    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);
    
    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})
    const history = useApiData<PaymentDto[], string>(PaymentEndpoint.GET.history, {id: props.userID, page: 1, type: ''})

    const DataExtractor = (data: UserDto) : JSX.Element => {
        const putUser = useApiData<string, string>(UserEndpoint.PUT.user, {id: props.userID})

        const initial = _.pick(data, [
            "nextYearMembershipFeeType",
            "membershipFeeDate",
        ]) as HTMLMemberMembershipDataFormElements
        if(!initial.membershipFeeDate) {
            initial.membershipFeeDate = "";
        }

        const mutable = _.cloneDeep(initial)

        const handleSubmit = (values: HTMLMemberMembershipDataFormElements) : Promise<Binding<string,any>> => {
            const updateValues = {
                ...data,
                ...values
            }
            console.log("Handle Submit:", updateValues);
            return putUser.perform({id: data.id}, updateValues)
        }

        return <MemberMembershipDataFormik initial={mutable} onSubmit={handleSubmit} />
    }

    const handleConfirm = useCallback((uid: number, pid: number) => {
        if(waiting) return;

        setWaiting(true);
        paymentsConfirm(uid, pid).then(() => {
            setMessage({type: "success", message: "Zahlung bestätigt!"});
            history.invalidateCache();
        }).catch(e => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        }).finally(() => {
            setWaiting(false);
        });
    }, [history, waiting]);
    
    return <>
        <ApiCycle data={user} extractor={DataExtractor} />
        <MessageDisplay message={message}/>
        <ApiCycle data={history} extractor={(list: PaymentDto[]) => <div>
            {list.map((payment: PaymentDto) => <div key={payment.id}>
                <span>{payment.title}:</span> 
                {!payment.confirmedAt ? 
                    <div className="d-inline-block"><span className="text-danger">Nicht bezahlt</span>, zu zahlen bis {str_to_datestr(payment.dueAt)} <ButtonSpin spinning={waiting} type="button" skin="outline" variant="primary" onClick={() => handleConfirm(payment.uid, payment.id)}>Bestätigen</ButtonSpin></div>
                    : <div className="d-inline-block"><span className="text-success">Bezahlt.</span> Bestätigt am {str_to_datestr(payment.confirmedAt)}</div>}</div>)}
        </div>} />
    </>;

}
import { useCallback, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import ButtonSpin from "../Buttons/ButtonSpin";
import LogoType from "./LogoType";

type BSModalProps = {
    title: string,
    show: boolean,
    setShow: (show: boolean) => void,
    submitLabel?: string,
    cancelLabel?: string,
    submitIsDestructive?: boolean,
    renderLogoType?: boolean,
    staticBackdrop?: boolean,
    userCanCancel?: boolean,
    userCanSubmit?: boolean,
    spinning?: boolean,
    onSubmit?: () => void | boolean | Promise<boolean>,
    onCancel?: () => void | boolean | Promise<boolean>,
    onHide?: () => void | boolean | Promise<boolean>, //pressing x (&times;)
}

export default function BSModal(props: React.PropsWithChildren<BSModalProps>) : JSX.Element {

    const [error, setError] = useState<string>()

    const onSubmit = useCallback(() => {

        const submit = props.onSubmit?.()

        if (submit !== undefined) {
            if (typeof submit !== "boolean") {
                submit.then((v) => props.show === v && props.setShow(!v)).catch((reason) => {
                    if (typeof reason === "string") {
                        setError(reason)
                    } else {
                        console.warn("[BSModal.onSubmit] Could not set error state in BSModal as reason is not of type string.")
                    }
                })
            } else { 
                if (props.show === submit) props.setShow(!submit)
            }
        } else { props.setShow(false) }

    }, [props])

    const onCancel = useCallback(() => {

        const cancel = props.onCancel?.()

        if (cancel !== undefined) {
            if (typeof cancel === "boolean") {
                if ( props.show === cancel) props.setShow(!cancel)
            } else {
                cancel.then((v) => {
                    if (props.show === v) props.setShow(v)
                }).catch(reason => {
                    if (typeof reason === "string") {
                        setError(reason)
                    } else {
                        console.warn("[BSModal.onCancel] Could not set error state in BSModal as reason is not of type string.")
                    }
                })
            }
        } else {
            props.setShow(false)
        }
        
    }, [props])

    const onHide = useCallback(() => {
        //onCancel()
        const submit = props.onHide?.()

        if (submit !== undefined) {
            if (typeof submit !== "boolean") {
                submit.then((v) => props.show === v && props.setShow(!v)).catch((reason) => {
                    if (typeof reason === "string") {
                        setError(reason)
                    } else {
                        console.warn("[BSModal.onSubmit] Could not set error state in BSModal as reason is not of type string.")
                    }
                })
            } else {
                if (props.show === submit) props.setShow(!submit)
            }
        } else { props.setShow(false) }

    }, [props])

    return  <Modal show={props.show} onHide={onHide} backdrop={!props.userCanCancel || props.staticBackdrop ? "static" : undefined} keyboard={props.userCanCancel || false}>
                <Modal.Header closeButton={props.userCanCancel} closeLabel={props.cancelLabel || "Abbrechen"}>
                        {props.renderLogoType && <LogoType style={{marginRight: "1rem"}} className="h4 mb-0" />}
                        <Modal.Title className="mb-0">{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant="danger" dismissible><h4 className="alert-heading">Es ist ein Fehler aufgetrete.</h4><p className="mb-0">{error}</p></Alert>}
                    {props.children}
                </Modal.Body>
                {props.userCanSubmit && <Modal.Footer>
                    {props.userCanCancel && <Button variant="outline-secondary" className="flex-fill" onClick={onCancel}>{props.cancelLabel || "Abbrechen"}</Button>}
                    <ButtonSpin spinning={props.spinning} variant={props.submitIsDestructive ? "danger" : "success"} onClick={onSubmit} type="button">{props.submitLabel || "Weiter"}</ButtonSpin>
                </Modal.Footer>}
            </Modal>
}
import {
    dateString,
    getTok,
    ProfileDto,
    ProfileJob,
    ProfileJobSort
} from "../../../../modules/API/APIInterfaces";
import React, {useEffect, useState} from "react";
import {
    networkFriendRequest,
    networkFriendRequestAccept,
    networkFriendRequestIgnore,
    networkGet
} from "../../../../modules/API/Services/NetworkService";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import * as HTTPErrorPage from "../../../../components/HTTPErrorPage";
import {AnimatePresence, motion} from "framer-motion";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import {useParams} from "react-router";
import * as Icons from "../../../../components/Icons";
import {TextareaControlled} from "../../../../components/Fields/InputControlled";
import {useActions, useApiData} from "react-api-data";
import {NetworkEndpoint} from "../../../../store/APIStore";
import {UINotificationBadge} from "../../../../components/UI/Generic/UINotificationBadge";
import ApiCycle from "../../../../modules/API/ApiCycle";
import AbstractPermission from "../../../../modules/Auth/AbstractPermission";
import {UserPermission} from "../../../../modules/Auth";
import ProfileEdit from "./ProfileEdit";
import UserAvatar from "../../../../components/UI/User/UserAvatar";
import {Link} from "react-router-dom";
import BSModal from "../../../../components/UI/Generic/BSModal";
import usePageTitle from "../../../../hooks/usePageTitle";

export function ProfilePath(props: {loggedInUser: ProfileDto}): JSX.Element {
    const userId = useParams<{uid: string}>().uid;
    return <ProfileLoad uid={+userId} loggedInUser={props.loggedInUser}/>
}

export function sortJobsStudiesEhrenamt(u: ProfileDto): void {
    u.jobs.sort(ProfileJobSort);
    u.studies.sort(ProfileJobSort);
    u.ehrenamt.sort(ProfileJobSort);
}

export function ProfileLoad(props: {loggedInUser: ProfileDto, uid: number, onlyPicture?: boolean}): JSX.Element {
    const userId = props.uid;

    const [error, setError] = useState("");
    const [user, setUser] = useState<ProfileDto>();
    


    useEffect(() => {
        if(isNaN(userId)) return;
        
        networkGet(+userId).then((response) => {
            const u = response.data;
            sortJobsStudiesEhrenamt(u);
            setUser(u);
        }).catch((error: any) => {
            setError(AxiosErrorMessageGetter(error));
        });
    }, [userId]);

    const title = user === undefined ? "Netzwerk: Profil ansehen" : "Netzwerk: " + user.firstName + " " + user.lastName + "s Profil"
    
    usePageTitle(title);

    if(isNaN(userId)) {
        return <HTTPErrorPage.NotFound/>;
    }
    
    if(!user) {
        return <>{error && <div className="alert alert-danger">{error}</div>}</>;
    }
    const permissions = AbstractPermission.LoadFromToken();
    if(permissions.hasPermission(UserPermission.EditAllProfiles).all) {
        return <>
            {error && <div className="alert alert-danger">{error}</div>}
            <ProfileEdit user={user} setUser={setUser} userForeignEdit={true} />
        </>;
    }
    
    return <>
        {error && <div className="alert alert-danger">{error}</div>}
        {/* <Helmet title={user.firstName+" "+user.lastName}/> */}
        <Profile user={user} loggedInUser={props.loggedInUser} setUser={setUser} onlyPicture={props.onlyPicture}/>
    </>;
}

type FriendStatus = "none" | "friend" | "requestMe" | "requestOther";
function FriendBadge(props: {children?: JSX.Element, classes?: string, style?: React.CSSProperties}): JSX.Element {
    const style: React.CSSProperties = {width: "30px", height: "50px"};
    if(props.style) {
        if(props.style.width===undefined) props.style.width = "30px";
        if(props.style.height===undefined) props.style.height = "50px";
    }
    
    return <div className={"ribbon-straight text-white p-0 pt-1 text-center "+props.classes} style={props.style ? props.style : style}>{props.children}</div>;
}
const friendBadges: Record<FriendStatus, JSX.Element> = {
    none: <></>,
    friend: <FriendBadge classes="bg-success"><Icons.PersonCheck size={20}/></FriendBadge>,
    requestMe: <FriendBadge classes="bg-warning"><Icons.Clock size={20}/></FriendBadge>,
    requestOther: <FriendBadge classes="bg-primary"><>?</></FriendBadge>,
};

function FreundschaftsanfrageModal(props: {open: boolean, setOpen: (o: boolean) => void, name: string, loggedInUser: ProfileDto, user: ProfileDto, setUser?: CallableFunction}): JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState("");
    const [messageObj, setMessageObj] = useState<{message: string}>({message: ""});
    
    const canClose = () => {
        props.setOpen(false);
        setMessageObj({message: ""});
        return true;
    };

    const handleFriend = () => {
        setWaiting(true);
        networkFriendRequest(props.user.id, messageObj.message).then(() => {
            setWaiting(false);
            if(props.setUser!==undefined) {
                const u = {...props.user};
                u.friendRequest = {fromUser: {id: props.loggedInUser.id}, text: "", toUser: {id: props.user.id}};
                props.setUser(u);
            }
            props.setOpen(false);
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    };
    
    return <BSModal show={props.open} setShow={props.setOpen} title="Kontaktanfrage" userCanCancel={true} onHide={canClose}>
        <h5>{props.name}</h5>
        {error && <div className="alert alert-danger">{error}</div>}

        <div>Nachricht zur Kontaktanfrage</div>
        <TextareaControlled value={""} className="form-control" onChange={(v) => messageObj.message = v}/>
        <div className="d-flex justify-content-between mt-2">
            <ButtonSpin spinning={waiting} flex={false} skin="outline" type="button" classes="mx-auto" onClick={handleFriend}>Kontaktanfrage schicken</ButtonSpin>
        </div>
    </BSModal>
}

function FreundschaftsanfrageAcceptModal(props: {open: boolean, setOpen: (o: boolean) => void, message: string, name: string, loggedInUser: ProfileDto, user: ProfileDto, setUser?: CallableFunction}): JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState("");
    const actions = useActions();

    const canClose = () => {
        props.setOpen(false);
        return true;
    };

    const handleFriendAccept = () => {
        setWaiting(true);
        networkFriendRequestAccept(props.user.id).then(() => {
            window.location.reload(); //get email
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    };

    const handleFriendIgnore = () => {
        setWaiting(true);
        networkFriendRequestIgnore(props.user.id).then(() => {
            setWaiting(false);
            props.setOpen(false);
            if(props.setUser!==undefined) {
                const u = {...props.user};
                u.friendRequest.ignored = true;
                props.setUser(u);
                actions.invalidateCache(NetworkEndpoint.GET.getActiveFriendRequests, {});
            }
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    };

    return <BSModal show={props.open} setShow={props.setOpen} title="Kontaktanfrage" userCanCancel={true} onHide={canClose}>
        <h5>Von: {props.name}</h5>
        {error && <div className="alert alert-danger">{error}</div>}

        <label>Nachricht zur Kontaktanfrage</label>
        <div><span className="text-muted pre-line">{props.message}</span></div>
        <div className="d-flex justify-content-between mt-2">
            <ButtonSpin spinning={waiting} flex={false} type="button" skin="outline" variant="secondary" classes="mx-auto" onClick={handleFriendIgnore} disabled={props.user.friendRequest.ignored}>Ignorieren</ButtonSpin>
            <ButtonSpin spinning={waiting} flex={false} type="button" skin="outline" variant="success" classes="mx-auto" onClick={handleFriendAccept}>Akzeptieren</ButtonSpin>
        </div>
    </BSModal>;
}

export function ProfileImage(props: {id: number, image?: string, size: number | string, editable?: boolean}): JSX.Element {
    const request = useApiData<Blob>(NetworkEndpoint.GET.profileImage, {id: props.id});
    const bearerID = getTok().uid as number;
    const permissions = AbstractPermission.LoadFromToken();
    const editable = (props.editable===undefined || props.editable) && (bearerID===props.id || permissions.hasPermission(UserPermission.EditAllProfiles).all);
    
    return <ApiCycle data={request} extractor={(data: Blob) => (
        <UserAvatar size={props.size} userId={props.id} src={URL.createObjectURL(data)} editable={editable} setImage={() => request.invalidateCache()} />
    )}/>;
}

export function profileClassFromTo(member: {startYear: number, endYear: number, startClass: number}, max: number): string {
    const endClass = Math.min(max, Math.max(member.startClass, member.startClass+member.endYear-member.startYear-1));
    if(member.startClass===endClass) {
        return member.startClass+".";
    }
    return member.startClass+".-"+endClass+".";
}

export default function Profile(props: {loggedInUser: ProfileDto, user: ProfileDto, setUser?: CallableFunction, image?: string, onlyPicture?: boolean, editable?: boolean}): JSX.Element {
    const tok = getTok();
    const u = props.user;
    
    const [modal, setModal] = useState(false);
    
    let friendStatus: FriendStatus = "none";
    if(u.id!==tok.uid && props.loggedInUser.visible) {
        if(!!u.email) {
            friendStatus = "friend";
        } else if(u.friendRequest) {
            if(u.friendRequest.fromUser.id===tok.uid) {
                //from me (wait for answer)
                friendStatus = "requestMe";
            } else {
                //from other person (can accept)
                friendStatus = "requestOther";
            }
        }
    }
    
    const profileInfo = <>
        <div className="mb-1 text-muted">
            <h2 className="d-block d-sm-inline-block mb-0">{u.firstName}</h2> <h2 className="d-block d-sm-inline-block">{u.lastName}</h2>
        </div>
        <div className="mb-3 text-muted">
            {u.memberVbc && <h5 className="mb-0">{u.memberVbc.memberChoir.name==='Anderer' ? 'Wiener Sängerknabe' : u.memberVbc.memberChoir.name + ' Chor'}: {u.memberVbc.startYear}-{u.memberVbc.endYear} ({profileClassFromTo(u.memberVbc,4)}&nbsp;Klasse)</h5>}
            {u.memberHs && <h5 className="mb-0">Oberstufe: {u.memberHs.startYear}-{u.memberHs.endYear} ({profileClassFromTo(u.memberHs,8)}&nbsp;Klasse)</h5>}
        </div>
        <p className="text-secondary small mb-0">Mitglied seit {dateString(u.joinedAt)}</p>
    </>;
    
    let modalJsx = <></>;
    if(friendStatus==="none") {
        modalJsx = <FreundschaftsanfrageModal open={modal} setOpen={setModal} name={u.firstName+" "+u.lastName} user={u} loggedInUser={props.loggedInUser} setUser={props.setUser} />;
    }
    else if(friendStatus==="requestOther") {
        modalJsx = <FreundschaftsanfrageAcceptModal open={modal} setOpen={setModal} name={u.firstName+" "+u.lastName} user={u} loggedInUser={props.loggedInUser} message={u.friendRequest.text} setUser={props.setUser}/>;
    }

    const request = useApiData<number, string>( NetworkEndpoint.GET.getActiveFriendRequests, {});
    
    return <div className="p-2">
        {modalJsx}
        
        <div className="row mb-3">
            {/*Profilbild, Name, Chor, ORG*/}
            <div className="col-12 col-lg-8 mb-3">
                <div className="d-flex align-items-end">
                    <div className="text-secondary position-relative">
                        {!props.editable && props.user.id===tok.uid && <Link to="/network/profile">
                            <ProfileImage id={u.id} image={props.image} size={160} editable={props.editable}/>
                        </Link>}
                        {(props.editable || props.user.id!==tok.uid) && <ProfileImage id={u.id} image={props.image} size={160} editable={props.editable}/>}
                        
                        <div className="position-absolute top-0 start-0">
                            {friendBadges[friendStatus]}
                        </div>
                    </div>
                    <div className="d-none d-sm-block px-4">
                        {profileInfo}
                    </div>
                </div>
                <div className="d-block d-sm-none text-end" style={{marginTop: (u.firstName.length>15 ? "6px" : "-24px")}}>
                    {profileInfo}
                </div>
            </div>
            <div className="col-12 col-lg-4 pt-3 align-items-md-end d-flex">
                {/*Only show Friends stuff if not self*/tok.uid!==u.id && <>
                    {/*if visible show contact stuff*/props.loggedInUser.visible && <div className="w-100 d-flex">
                        {friendBadges[friendStatus]}
                        <div className="px-2">
                            {friendStatus!=="none" && <h5 className="mb-1">Kontakt</h5>}
                            {friendStatus!=="friend" && <div>
                                {u.friendRequest && <>
                                    {friendStatus==="requestMe" && <span className="text-secondary">Warte auf Antwort</span>}
                                    {friendStatus==="requestOther" && <>
                                        <button type="button" className="btn btn-link px-0 pt-0" onClick={() => setModal(true)}>
                                            Anfrage von {u.firstName} {!u.friendRequest.ignored && <span className="badge bg-danger">!</span>}
                                        </button>
                                    </>}
                                </>}
                                {!u.friendRequest && <button type="button" className="btn btn-link" onClick={() => setModal(true)}><h5>Kontaktanfrage</h5></button>}
                            </div>}
                            {friendStatus==="friend" && <div className="mb-3">
                                <div><span className="text-success">Vernetzt</span></div>
                                <div><span>E-Mail: {u.email}</span></div>
                            </div>}
                        </div>
                    </div>}
                    {/*if not visible link to visible settings*/!props.loggedInUser.visible && <div className="w-100">
                        <div className="alert alert-warning">Wenn du <Link to="/network/profile">Dein Profil angelegt hast und Dich sichtbar</Link> geschalten hast, kannst Du {u.firstName} eine Kontaktanfrage schicken.</div>
                    </div>}
                </>}
                
                {tok.uid===u.id && <div>
                    <div><span>E-Mail: {u.email}</span></div>
                    <div className="alert alert-secondary mt-2">
                        Deine E-Mail ist nur für <Link to="/network/friends">Deine Kontakte<ApiCycle data={request} extractor={(data: number) => <>{data > 0 && <UINotificationBadge count={data} />}</>} suspense={{width: 16}} /></Link> sichtbar.
                    </div>
                </div>}
            </div>
        </div>
        
        {!props.onlyPicture && <>
            {props.user.description && <div className="mb-3">
                <h4>Beschreibung</h4>
                <ProfileDescription description={props.user.description}/>
            </div>}
            
            <div className="row">
                {/*Stacking wenn in Hälfte geteilt*/}
                <div className="col-12 d-none d-xl-block col-xl-6 d-xxl-none">
                    <div>
                        <h3>Berufserfahrung</h3>
                        <div className="mb-3">
                            <Jobs jobs={u.jobs}/>
                        </div>
                    </div>
                    
                    <div>
                        <h3>Ehrenamt</h3>
                        <div className="mb-3">
                            <Jobs jobs={u.ehrenamt}/>
                        </div>
                    </div>
                </div>
                
                {/*normale Ansicht*/}
                <div className="col-12 d-xl-none d-xxl-block col-xxl-4">
                    <h3>Berufserfahrung</h3>
                    <div className="mb-3 position-relative">
                        <Jobs jobs={u.jobs}/>
                    </div>
                </div>
                <div className="col-12 col-xl-6 col-xxl-4">
                    <h3>Ausbildung</h3>
                    <div className="mb-3 position-relative">
                        <Jobs jobs={u.studies}/>
                    </div>
                </div>
                <div className="col-12 d-xl-none d-xxl-block col-xxl-4">
                    <h3>Ehrenamt</h3>
                    <div className="mb-3 position-relative">
                        <Jobs jobs={u.ehrenamt}/>
                    </div>
                </div>
            </div>
        </>}
    </div>;
}

function Jobs(props: {jobs: ProfileJob[]}): JSX.Element {
    return <>
        {props.jobs.map((job: ProfileJob) => <Job key={job.id} job={job}/>)}
    </>;
}

export function Job(props: {job: ProfileJob}): JSX.Element {
    const [show, setShow] = useState(false);
    
    const hasMoreDescription = props.job.description.length>60;
    
    return <div key={props.job.id} className="py-2 px-3 position-relative border-orange">
        <div className="fw-bold small mb-2 text-orange">{props.job.endYear ? props.job.endYear : "Heute"}</div>
        <div className={"px-3 py-2 mx-2 rounded grid-card " + (hasMoreDescription ? "grid-card-hover pointer "+(!show ? "border-bottom border-primary":"") : "")} onClick={() => {setShow(!show)}}>
            <h4 className="mb-1">{props.job.name}</h4>
            <div><span>{props.job.institution}{props.job.city ? ", "+props.job.city : ""}</span></div>
            <div><span>{props.job.types.map((type: {name: string}, index: number, array: {name: string}[]) => <span key={type.name} className="text-secondary">{type.name}{index+1<array.length && ", "}</span>)}</span></div>
            {props.job.description && <div>
                <AnimatePresence>
                    <span key={1} className="text-muted pre-line">{props.job.description.substring(0,60)}{!show && props.job.description.length>60 ? "...":""}</span>
                    {show && <motion.span key={2} initial={{height: 0, opacity: 0}} animate={{height:"auto", opacity: 1}} exit={{height: 0, opacity: 0}} className="text-muted pre-line">{props.job.description.substring(60)}</motion.span>}
                </AnimatePresence>
            </div>}
        </div>
        <div className="fw-bold small mt-2 text-orange">{props.job.startYear ? props.job.startYear : "Heute"}</div>
    </div>;
}

export function ProfileDescription(props: {description: string}) {
    return <span className="pre-line">{props.description}</span>;
}
import React, {useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../../../components/MessageState";
import {
    PaymentDto,
    paymentsConfirm,
    paymentsOverdue,
    paymentsRest,
    paymentsWaiting
} from "../../../modules/API/Services/PaymentService";
import {AxiosResponse} from "axios";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";
import {useApiData} from "react-api-data";
import {PaymentEndpoint} from "../../../store/APIStore";
import ApiCycle from "../../../modules/API/ApiCycle";
import {str_to_datestr} from "../../../modules/API/Services/Misc";
import {Link} from "react-router-dom";

function removePaymentFromList(list: PaymentDto[], pid: number): void {
    const l = list.length;
    for(let i=0; i<l; ++i) {
        if(list[i].id===pid) {
            list.splice(i, 1);
            break;
        }
    }
}

export default function FinanceController() : JSX.Element {
    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);
    
    const [overduePayments, setOverduePayments] = useState<PaymentDto[]>();
    const [waitingPayments, setWaitingPayments] = useState<PaymentDto[]>();
    const [restPayments, setRestPayments] = useState<PaymentDto[]>();
    
    const overdue = useApiData<number, number>( PaymentEndpoint.GET.restCount );
    
    useEffect(() => {
        paymentsOverdue().then((response: AxiosResponse<PaymentDto[]>) => {
            setOverduePayments(response.data);
        }).catch(e => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        });
        paymentsWaiting().then((response: AxiosResponse<PaymentDto[]>) => {
            setWaitingPayments(response.data);
        }).catch(e => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        });
    }, []);

    const loadRest = useCallback(() => {
        if(restPayments) return;
        if(waiting) return;

        setWaiting(true);
        paymentsRest().then((response: AxiosResponse<PaymentDto[]>) => {
            setRestPayments(response.data);
        }).catch(e => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        }).finally(() => {
            setWaiting(false);
        });
    }, [restPayments, waiting]);
    
    const handleConfirm = useCallback((uid: number, pid: number, list: PaymentDto[]) => {
        if(waiting) return;
        
        setWaiting(true);
        paymentsConfirm(uid, pid).then(() => {
            setMessage({type: "success", message: "Zahlung bestätigt!"});
            removePaymentFromList(list, pid);
        }).catch(e => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        }).finally(() => {
            setWaiting(false);
        });
    }, [waiting]);
    
    return <>
        <h2>Zahlungen</h2>
        {overduePayments && overduePayments.length>0 && <div>
            <h3>Zu späte Zahlungen</h3>
            <PaymentList list={overduePayments} handleConfirm={handleConfirm} waiting={waiting}/>
        </div>}
        
        {waitingPayments && waitingPayments.length>0 && <div>
            <h3>Wartende Zahlungen</h3>
            <PaymentList list={waitingPayments} handleConfirm={handleConfirm} waiting={waiting}/>
        </div>}
        
        {overduePayments && waitingPayments && overduePayments.length===0 && waitingPayments.length===0 && <h5 className="text-success">Keine wartenden/zu späten Zahlungen.</h5>}
        
        <div>
            <h3>Rest der offenen Zahlungen: <ApiCycle data={overdue} extractor={(data: number) => <span>
                {data} {data>0 && <ButtonSpin onClick={loadRest} spinning={waiting} classes="btn-secondary">Lade Zahlungen</ButtonSpin>}
                </span>}/></h3>
            {restPayments && <PaymentList list={restPayments} handleConfirm={handleConfirm} waiting={waiting}/>}
        </div>
        
        <MessageDisplay message={message}/>
    </>
}

function PaymentList(props: {list: PaymentDto[], handleConfirm(uid: number, pid: number, list: PaymentDto[]): void, waiting: boolean}) {
    return <>{props.list.map((payment: PaymentDto) => <div key={payment.uid+"-"+payment.id} className="grid-card py-3 px-2">
        <div className="d-flex">
            <div className="mx-3">
                <h5>{payment.title}</h5>
                <div>Referenz: {payment.reference}</div>
                <Link to={'/apacis/members/view/'+payment.uid+'/membership'}><div>Name: {payment.firstName} {payment.lastName}</div></Link>
                <div>Betrag: {payment.cent/100} €</div>
                <div>Ausstelldatum: {str_to_datestr(payment.createdAt)}</div>
                <div>Zu zahlen bis: {str_to_datestr(payment.dueAt)}</div>
                {payment.mayHaveBeenPayedAt && <div>Abgehakt: {str_to_datestr(payment.mayHaveBeenPayedAt)}</div>}
                <ButtonSpin classes="success" skin="outline" type="button" spinning={props.waiting} onClick={() => props.handleConfirm(payment.uid, payment.id, props.list)}>Zahlung bestätigen</ButtonSpin>
            </div>
        </div>
    </div>)}</>;
}
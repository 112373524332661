import {AnimatePresence, motion} from "framer-motion";
import React, {useCallback, useEffect, useState} from "react";
import AxiosErrorMessageGetter from "../../../../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../../../../components/UI/Buttons/ButtonSpin";
import {Choir, getTok, MemberHs, MemberVbc, UserDto} from "../../../../../../modules/API/APIInterfaces";
import {authVerifyEmail, authWaitForVerification} from "../../../../../../modules/API/Services/AuthService";
import {memberChoirsGet, memberChoirSort} from "../../../../../../modules/API/Services/MemberChoirService";
import {usersEdit, usersGet} from "../../../../../../modules/API/Services/UserService";
import InputControlled, {SelectControlled} from "../../../../../../components/Fields/InputControlled";
import ShowHide from "../../../../../../components/UI/Generic/ShowHide";
import {MessageDisplay, MessageState} from "../../../../../../components/MessageState";
import useAppToken from "../../../../../../hooks/useAppToken";
import {AxiosResponse} from "axios";
import C from "../../../../../../modules/cookies";
import { Alert, FormControl, InputGroup } from "react-bootstrap";
import { FinishSignUpAlert, NotVerifiedAlert } from "../../../../../../components/SpecificAlerts";


export default function PersonalDataController() : JSX.Element {
    return <MyDataForm />;
}

interface MemberVbcInput {
    startYear: string,
    endYear: string,
    startClass: string,
    choir: string
}
interface MemberHsInput {
    startYear: string,
    startClass: string,
    endYear: string
}
// WR3BR
function VerifyEmail(props: {waiting: boolean, handleSubmit: (otp: string) => void}) {
    const [otp, setOtp] = useState("");

    return  <form className="mb-5" onSubmit={(e) => {e.preventDefault(); props.handleSubmit(otp)} }>
                <InputGroup>
                    <InputGroup.Text>OTP</InputGroup.Text>
                    <FormControl placeholder="XXXXX" value={otp} onChange={e => setOtp(e.target.value)} required />
                    <ButtonSpin spinning={props.waiting} variant="success" skin="fill" type="submit" flex={false}>E-Mail verifizieren</ButtonSpin>
                </InputGroup>
            </form>
}

function MemberForm(props: {user: UserDto, vbcInit?: MemberVbc, hsInit?: MemberHs, choirs: Choir[], isUnregistered: boolean}) {
    const initVbc: MemberVbcInput = props.vbcInit ? {
        startYear: ""+props.vbcInit.startYear,
        endYear: ""+props.vbcInit.endYear,
        startClass: ""+props.vbcInit.startClass,
        choir: props.vbcInit.memberChoir.name
    } : {
        startYear: "",
        endYear: "",
        startClass: "",
        choir: ""
    }
    const initHs: MemberHsInput = props.hsInit ? {
        startYear: ""+props.hsInit.startYear,
        endYear: ""+props.hsInit.endYear,
        startClass: ""+props.hsInit.startClass
    } : {
        startYear: "",
        endYear: "",
        startClass: ""
    }

    const [vbc, setVbc] = useState<MemberVbcInput>(initVbc);
    const [hs, setHs] = useState<MemberHsInput>(initHs);
    const [showInfo, setShowInfo] = useState(false);

    const hasVbc = vbc.startYear && vbc.endYear && vbc.choir && vbc.startClass;
    const hasHs = hs.startYear && hs.endYear && hs.startClass;
    const hasVbcOrHs = hasVbc || hasHs;
    const vbcClasses = (!hasVbcOrHs ? "is-invalid":(hasVbc ? "is-valid":""));
    const hsClasses = (!hasVbcOrHs ? "is-invalid":(hasHs ? "is-valid":""));

    //da memberVbc und memberHs Objekte sind und null sein können, bilden die Inputs hier auf vbc, hs states ab, die erst beim submit im user gespeichert werden
    //returns true if vbc || hs is set
    const saveVbcHs = () => {
        if(!vbc || !hs || !props.user) {
            return;
        }

        if(vbc.startYear && vbc.endYear && vbc.choir && vbc.startClass) {
            if(!props.user.memberVbc) setVbc({...vbc});
            props.user.memberVbc = {startYear: +vbc.startYear, endYear: +vbc.endYear, memberChoir: {name: vbc.choir}, startClass: +vbc.startClass};
        } else {
            if(props.user.memberVbc) setVbc({...vbc});
            props.user.memberVbc = undefined;
        }

        if(hs.startYear && hs.endYear && hs.startClass) {
            if(!props.user.memberHs) setHs({...hs});
            props.user.memberHs = {startYear: +hs.startYear, endYear: +hs.endYear, startClass: +hs.startClass};
        } else {
            if(props.user.memberHs) setHs({...hs});
            props.user.memberHs = undefined;
        }
    };

    const animate = <motion.div initial={{height: 0, opacity: 0}} animate={{height:"auto", opacity: 1}} exit={{height: 0, opacity: 0}} style={{overflow: "hidden"}}>
        <div className="alert alert-primary mb-2" style={{
            borderWidth: "1px",
            boxShadow: "none",
        }}>
            <p className="mb-0">Um für bestimmte Jahrgänge Events zu veranstalten, müssen wir wissen, in welchem Jahrgang du warst. Mit nur Start- und Endjahr haben wir diese Information noch nicht.</p>
        </div>
    </motion.div>;

    if (!props.isUnregistered) {
        return  <div className="row mb-3">
                    <div className="col-md-6">
                        <h5>Deine Zeit als Wiener Sängerknabe</h5>
                        {props.vbcInit ? <>
                        <p className="mb-0">{vbc.startYear} bis {vbc.endYear}</p>
                        <p className="mb-0">Du warst im {vbc.choir}chor und hast in der {vbc.startClass}. Klasse begonnen.</p></> : <>Keine Angabe</>}
                    </div>
                    <div className="col-md-6">
                        <h5>Deine Zeit in der WSK Oberstufe</h5>
                        {props.hsInit ? <>
                        <p className="mb-0">{hs.startYear} bis {hs.endYear}</p>
                        <p className="mb-0">Du hast in der {hs.startClass}. Klasse begonnen.</p></> : <>Keine Angabe</>}
                    </div>
                </div>
    }

    return <>
        <div className="mb-3">
            <div className="row">
                <div className="col-md-6">
                    <label>Wiener Sängerknabe – Erstes Jahr (im Format JJJJ)</label>
                    <InputControlled className={"form-control " + vbcClasses} value={""+vbc.startYear} type="text" disabled={!props.isUnregistered}
                                     onChange={ (e) => {vbc.startYear=e;saveVbcHs();} }/>
                </div>
                <div className="col-md-6 mb-3">
                    <label>Wiener Sängerknabe – Letztes Jahr (im Format JJJJ)</label>
                    <InputControlled className={"form-control " + vbcClasses} value={""+vbc.endYear} type="text" disabled={!props.isUnregistered}
                                     onChange={ (e) => {vbc.endYear=e;saveVbcHs();} }/>
                </div>
                <div className="col-md-6 mb-3">
                    <label>Wiener Sängerknabe – Chor</label>
                    <SelectControlled className={"form-control form-select " + vbcClasses} value={vbc.choir} disabled={!props.isUnregistered} onChange={ (e) => {
                        vbc.choir=e;
                        saveVbcHs();
                    } }><>
                        <option value="">Keiner</option>
                        { props.choirs && props.choirs.map((c: Choir) => <option value={c.name} key={c.name}>{c.name}</option>) }
                    </></SelectControlled>
                </div>
                <div className="col-md-6 mb-3">
                    <label>Wiener Sängerknabe – Start Klasse <button tabIndex={-1} className="btn btn-link small py-0" type="button" onClick={() => {setShowInfo(!showInfo)}}>?</button></label>
                    <SelectControlled className={"form-control form-select " + vbcClasses} value={""+vbc.startClass} disabled={!props.isUnregistered} onChange={ (e) => {
                        vbc.startClass=e;
                        saveVbcHs();
                    } }><>
                        <option value=""> </option>
                        <option value="1">1. Klasse</option>
                        <option value="2">2. Klasse</option>
                        <option value="3">3. Klasse</option>
                        <option value="4">4. Klasse</option>
                    </></SelectControlled>
                </div>
                <AnimatePresence>
                    {showInfo ? animate : null}
                </AnimatePresence>
            </div>
        </div>
        <div className="mb-5">
            <div className="row mb-3">
                <div className="col-md-6">
                    <label>WSK Oberstufe – Erstes Jahr (im Format JJJJ)</label>
                    <InputControlled className={"form-control " + hsClasses} value={""+hs.startYear} type="text" disabled={!props.isUnregistered}
                                     onChange={ (e) => {hs.startYear=e;saveVbcHs();} }/>
                </div>
                <div className="col-md-6">
                    <label>WSK Oberstufe – Letztes Jahr (im Format JJJJ)</label>
                    <InputControlled className={"form-control " + hsClasses} value={""+hs.endYear} type="text" disabled={!props.isUnregistered}
                                     onChange={ (e) => {hs.endYear=e;saveVbcHs();} }/>
                </div>
            </div>
            <div>
                <label>WSK Oberstufe – Start Klasse <button tabIndex={-1} className="btn btn-link small py-0" type="button" onClick={() => {setShowInfo(!showInfo)}}>?</button></label>
                <SelectControlled className={"form-control form-select " + hsClasses} value={""+hs.startClass} disabled={!props.isUnregistered} onChange={ (e) => {
                    hs.startClass=e;
                    saveVbcHs();
                } }><>
                    <option value=""> </option>
                    <option value="5">5. Klasse</option>
                    <option value="6">6. Klasse</option>
                    <option value="7">7. Klasse</option>
                    <option value="8">8. Klasse</option>
                </></SelectControlled>
            </div>

        </div>
    </>;
}

function MyDataForm() {
    const [user, setUser] = useState<UserDto>();
    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);

    const [choirs, setChoirs] = useState<Choir[]>();
    const [emailVerified, setEmailVerified] = useState(false);
    
    const isUnregistered = user?.userType === "unregistered";
    const jung = (birthday: string): boolean => ((new Date().getTime()-new Date(birthday).getTime())/(1000*60*60*24)<18*365);
    
    const token = useAppToken();
    
    //loading data
    useEffect(() => {
        const tok = getTok();
        if(tok && tok.uid) {
            usersGet(tok.uid).then((response) => {
                setMessage(undefined);
                setUser(response.data);
            }).catch((error: any) => {
                setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
            });

            memberChoirsGet().then((response) => {
                setMessage(undefined);
                setChoirs(response.data.sort(memberChoirSort));
            }).catch((error: any) => {
                setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
            });
        }
    }, []);

    //zwischen speichern
    const handleSave = useCallback(() => {
        setWaiting(false);
        if(!user) {
            return;
        }

        usersEdit(user).then((response) => {
            setMessage({type: "success", message: "Änderungen wurden gespeichert!"});
            setWaiting(false);
            user.version = response.data;
        }).catch((error: any) => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
            setWaiting(false);
        });
    }, [user]);

    //email verifizierung
    const handleVerifyEmail = useCallback((otp: string) => {
        if(!user) {
            return;
        }
        setWaiting(true);

        authVerifyEmail(otp).then(() => {
            setMessage(undefined);
            setWaiting(false);

            let u: UserDto = {...user};
            u.emailVerification = true;
            setUser(u);
            setEmailVerified(true);
        }).catch((error: any) => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
            setWaiting(false);
        });
    }, [user]);

    //speichern / verifizierung warten
    const handleSubmit = useCallback(() => {
        if(!user) {
            return;
        }

        setWaiting(true);
        if(!isUnregistered) {
            //just save changed values
            handleSave();
        } else {
            //vbc/hs
            if(!user.memberVbc && !user.memberHs) {
                setMessage({type: "warning", message: "Du musst entweder WSK oder ORG Daten eingeben."});
                return;
            }

            //save values and then verify
            usersEdit(user).then((response) => {
                setMessage(undefined);
                user.version = response.data;

                authWaitForVerification().then((response: AxiosResponse<string>) => {
                    setMessage(undefined);
                    setWaiting(false);
                    const u = {...user};
                    u.userType = "unverified";
                    setUser(u);
                    
                    //update token
                    token?.destroy();
                    C.set("_token", response.data.substring("Bearer ".length), {path: "/"});
                }).catch((error: any) => {
                    setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
                    setWaiting(false);
                });
            }).catch((error: any) => {
                setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
                setWaiting(false);
            });
        }
    }, [user, isUnregistered, handleSave, token]);

    if (user && choirs) {
        if(user.userType==="unverified") {
            return <NotVerifiedAlert />
            // return <div className="alert alert-success mb-4">
            //     <h4 className="alert-heading">Warte auf Verifizierung</h4>
            //     <p className="mb-0">So lange dein Profil von uns nicht verifiziert wurde, kannst Du Deine Daten nicht ändern. Du bekommst eine E-Mail sobald du verifiziert bist! Für weitere Fragen wende Dich an <a href="mailto:info@unisono-alumni.at">info@unisono-alumni.at</a></p>
            // </div>;
        }

        const notEmpty = (v: string) => v.length>0;

        return <>
            {isUnregistered && <FinishSignUpAlert emailVerified={user.emailVerification || true} />}
            {emailVerified && <Alert variant="success" dismissible><p className="mb-0 fw-bold">Deine E-Mail wurde verifiziert!</p></Alert>}
            {!user.emailVerification && <VerifyEmail waiting={waiting} handleSubmit={handleVerifyEmail}/>}
            <MessageDisplay message={message}/>

            <form onSubmit={(e) => {e.preventDefault(); handleSubmit()} }>
                <ShowHide showInit={false} className="btn btn-link"
                          buttonContainer={(props: {children: JSX.Element}) => <h4>Über Dich {props.children}</h4>}
                          buttonChildren={<>Wieso muss ich das ausfüllen?</>}
                >
                    <div className="alert alert-primary mb-2">
                        <p className="mb-0">Als Verein sind wir gesetzlich verpflichtet ein Verzeichnis über unsere Mitglieder zu führen und deren Kontaktdaten zu erheben und zu pflegen.</p>
                    </div>
                </ShowHide>

                {/*Anrede*/}
                <div className="mb-3">
                    <label>Anrede</label>
                    <div className="form-check">
                        <input className={"form-check-input "+(user.salutation?.length>0?"is-valid":"is-invalid")} type="radio" name="salutation" id="MyDataForm-anrede-input1" required checked={user.salutation==="Herr"} onChange={() => {
                            const u = {...user};
                            u.salutation = "Herr";
                            setUser(u);
                        }}/>
                        <label className="form-check-label" htmlFor="MyDataForm-anrede-input1">Herr</label>
                    </div>
                    <div className="form-check">
                        <input className={"form-check-input "+(user.salutation?.length>0?"is-valid":"is-invalid")} type="radio" name="salutation" id="MyDataForm-anrede-input2" required checked={user.salutation==="Frau"} onChange={() => {
                            const u = {...user};
                            u.salutation = "Frau";
                            setUser(u);
                        }}/>
                        <label className="form-check-label" htmlFor="MyDataForm-anrede-input2">Frau</label>
                    </div>
                </div>

                {/*Name*/}
                <div className="mb-3">
                    <div className="row">
                        <div className="col-6">
                            <label>Titel, vorangestellt (optional)</label>
                            <InputControlled className="form-control" value={user.titlePrefix} type="text"
                                             onChange={ (e) => {user.titlePrefix=e;} }/>
                        </div>
                        <div className="col-6">
                            <label>Titel, nachgestellt (optional)</label>
                            <InputControlled className="form-control" value={user.titleSuffix} type="text"
                                             onChange={ (e) => {user.titleSuffix=e;} }/>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Vorname</label>
                            <InputControlled disabled={!isUnregistered} className="form-control" checkValid={notEmpty} value={user.firstName ? user.firstName : ""} type="text"
                                             onChange={ (e) => {user.firstName=e;} } required/>
                        </div>
                        <div className="col-md-6">
                            <label>Nachname</label>
                            <InputControlled disabled={!isUnregistered} className="form-control" checkValid={notEmpty} value={user.lastName ? user.lastName : ""} type="text"
                                             onChange={ (e) => {user.lastName=e;} } required/>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Geburtstag</label>
                            <InputControlled className="form-control" checkValid={notEmpty} disabled={!isUnregistered} value={user.birthday ? user.birthday : ""} type="date"
                                             onChange={ (e) => {
                                                 const u={...user};
                                                 u.birthday=e;
                                                 if(u.nextYearMembershipFeeType==="Jungalumni" && !jung(u.birthday)) {
                                                     u.nextYearMembershipFeeType = "";
                                                 }
                                                 setUser(u);
                                             } } required/>
                        </div>
                        <div className="col-md-6">
                            <label>Telefonnummer</label>
                            <InputControlled className="form-control" checkValid={notEmpty} value={user.telephone} type="text"
                                             onChange={ (e) => {user.telephone=e;} } required/>
                        </div>
                    </div>
                </div>

                {/*Adresse*/}
                <h4>Wohnort</h4>
                <div className="mb-3">
                    <label>Adresse</label>
                    <InputControlled className="form-control" checkValid={notEmpty} value={user.address} type="text"
                                     onChange={ (e) => {user.address=e;} } required/>
                </div>
                <div className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Postleitzahl</label>
                            <InputControlled className="form-control" checkValid={notEmpty} value={user.postalCode} type="text"
                                             onChange={ (e) => {user.postalCode=e;} } required/>
                        </div>
                        <div className="col-md-6">
                            <label>Stadt</label>
                            <InputControlled className="form-control" checkValid={notEmpty} value={user.city} type="text"
                                             onChange={ (e) => {user.city=e;} } required/>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Bundesstaat / Bundesland (optional)</label>
                            <InputControlled className="form-control" value={user.state} type="text"
                                             onChange={ (e) => {user.state=e;} }/>
                        </div>
                        <div className="col-md-6">
                            <label>Land</label>
                            <InputControlled className="form-control" checkValid={notEmpty} value={user.country} type="text"
                                             onChange={ (e) => {user.country=e;} } required/>
                        </div>
                    </div>
                </div>

                {/*Chor/Oberstufe*/}
                <h4>Wann warst du bei den Wiener Sängerknaben?</h4>
                <p>
                    {isUnregistered && <>Bitte gib an, in welchen Jahren Du Wiener Sängerknabe bzw. in der Oberstufe warst.</>}
                    {!isUnregistered && <>Diese Daten können im Nachhinein nicht mehr geändert werden. Solltest Du einen Fehler entdecken, schreibe bitte eine E-Mail an <a href="mailto:technik@unisono-alumni.at">technik@unisono-alumni.at</a>.</>}
                </p>
                <MemberForm user={user} choirs={choirs} isUnregistered={isUnregistered} vbcInit={user.memberVbc} hsInit={user.memberHs}/>

                { isUnregistered && <div className="mb-3">
                    <h4>Deine Mitgliedschaft</h4>
                    <p>
                        Wähle die Mitgliedschaft aus, welche zu Dir am besten passt. Bist Du noch unter 18 Jahren, kannst Du Dich nur als Jung-Alumni registrieren – Deine Mitgliedschaft ist bist Zu Deinem 18. Geburtstag kostenlos.
                    </p>

                    <div className="py-2">
                        {user.nextYearMembershipFeeType==="Erlassen" && <>
                            <div className="form-check">
                                <input className={"form-check-input "+(user.nextYearMembershipFeeType?.length>0?"is-valid":"is-invalid")} type="radio" name="membershipFee" id="nextYearMembershipFeeType-3" disabled required checked onChange={() => {
                                    const u = {...user};
                                    u.nextYearMembershipFeeType = "Erlassen";
                                    setUser(u);
                                }}/>
                                <label className="form-check-label" htmlFor="nextYearMembershipFeeType-3">Erlassen - kein Mitgliedsbeitrag</label>
                            </div>
                        </>}
                        <div className="form-check">
                            <input className={"form-check-input "+(user.nextYearMembershipFeeType?.length>0?"is-valid":"is-invalid")} type="radio" name="membershipFee" id="nextYearMembershipFeeType-0" disabled={isUnregistered ? (user.birthday ? !jung(user.birthday) : false) : true} required checked={user.nextYearMembershipFeeType==="Jungalumni"} onChange={() => {
                                const u = {...user};
                                u.nextYearMembershipFeeType = "Jungalumni";
                                setUser(u);
                            }}/>
                            <label className="form-check-label" htmlFor="nextYearMembershipFeeType-0">Jung-Alumni - kein Mitgliedsbeitrag</label>
                        </div>
                        <div className="form-check">
                            <input className={"form-check-input "+(user.nextYearMembershipFeeType?.length>0?"is-valid":"is-invalid")} type="radio" name="membershipFee" id="nextYearMembershipFeeType-1" disabled={!isUnregistered} required checked={user.nextYearMembershipFeeType==="Student"} onChange={() => {
                                const u = {...user};
                                u.nextYearMembershipFeeType = "Student";
                                setUser(u);
                            }}/>
                            <label className="form-check-label" htmlFor="nextYearMembershipFeeType-1">Studierende, Zivildiener, und Wehrdiener – 15 € im Jahr</label>
                        </div>
                        <div className="form-check">
                            <input className={"form-check-input "+(user.nextYearMembershipFeeType?.length>0?"is-valid":"is-invalid")} type="radio" name="membershipFee" id="nextYearMembershipFeeType-2" disabled={!isUnregistered} required checked={user.nextYearMembershipFeeType==="Vollmitglied"} onChange={() => {
                                const u = {...user};
                                u.nextYearMembershipFeeType = "Vollmitglied";
                                setUser(u);
                            }}/>
                            <label className="form-check-label" htmlFor="nextYearMembershipFeeType-2">UNISONO Alumni – 30 € im Jahr</label>
                        </div>
                    </div>
                </div> }

                { isUnregistered && <>
                    <h4>Rechtliche Zustimmung</h4>
                    <div role="group" aria-labelledby="legalConsent-label" className="mb-3">
                        <div className="form-check">
                            <label htmlFor="legalConsent-0" className="form-check-label"><span className="text-danger">*</span> Ich bestätige, dass ich ehemaliger Wiener Sängerknabe bzw. AbsolventIn des Oberstufenrealgymnasium der Wiener Sängerknaben bin. Meine Angaben sind korrekt.</label>
                            <input name="legalConsent" type="checkbox" id="legalConsent-0" className="form-check-input" value="data_correctness" required disabled={!isUnregistered}/>
                        </div>
                        <div className="form-check">
                            <label htmlFor="legalConsent-1" className="form-check-label"><span className="text-danger">*</span> Ich habe die Statuten und Datenschutzerklärung gelesen und akzeptiere diese in der geltenden Fassung. Das bedeutet insbesondere, dass UNISONO meine Daten elektronisch speichern und weiterverarbeiten darf.</label>
                            <input name="legalConsent" type="checkbox" id="legalConsent-1" className="form-check-input" value="statutes_and_privacy" required disabled={!isUnregistered}/>
                        </div>
                        <div className="form-check">
                            <label htmlFor="legalConsent-2" className="form-check-label"><span className="text-danger">*</span> Wenn ich Events besuche, bin ich damit einverstanden, dass ggf. von mir Bild- und/oder Tonmaterial aufgenommen wird. Dieses darf UNISONO für Marketingzwecke verwenden.</label>
                            <input name="legalConsent" type="checkbox" id="legalConsent-2" className="form-check-input" value="marketing_material" required disabled={!isUnregistered}/>
                        </div>
                    </div>
                    <p>Link zu der <a href="https://unisono-alumni.at/datenschutz" rel="noreferrer" target="_blank">Datenschutz Erklärung</a> und den <a href="https://unisono-alumni.at/statuten" target="_blank" rel="noreferrer">Statuten</a>.</p>
                </>}

                {/*Submit*/}
                <MessageDisplay message={message}/>
                <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
                    <div className="d-grid" style={{gap: "1rem", gridTemplateColumns: "1fr 1fr"}}>
                        {isUnregistered && <ButtonSpin spinning={waiting} disabled={waiting} variant="primary" type="button" skin="outline" onClick={handleSave}>Zwischenspeichern</ButtonSpin>}
                        <ButtonSpin spinning={waiting} disabled={waiting} variant={isUnregistered?"success":"primary"} type="submit" skin="outline">{isUnregistered?"Abschicken":"Speichern"}</ButtonSpin>
                    </div>
                </div>
            </form>
        </>
    } else { return <><MessageDisplay message={message}/></>}

}
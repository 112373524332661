import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import EventController from './controller/EventController';
import IndexController from './controller/IndexController';
import NetworkController from './controller/NetworkController';
import SettingsController from './controller/SettingsController';

import * as HTTPErrorPage from '../../components/HTTPErrorPage';
import { ShowIf } from '../../hoc/Authenticated';
import { UserPermission } from '../../modules/Auth';
import AbstractPermissions from '../../modules/Auth/AbstractPermission';
import ApacisController from '../ApacisController';

import './style.scss';
import { bootstrapQueries, larger, useBreakpoint } from '../../context/Breakpoint';
import FeedbackController from './controller/FeedbackController';
import {Helmet} from "react-helmet-async";
import VorstandController from "./controller/VorstandController";
import FooterImpressumStatuten from "../../components/FooterImpressumStatuten";
import PageTitle from '../../components/UI/Generic/Title';
import ChoirController from "./controller/ChoirController";
import classNames from 'classnames';
import useAppToken from '../../hooks/useAppToken';

export default function UNISONOClub() {

    const breakpoints: typeof bootstrapQueries = useBreakpoint();
    const token = useAppToken()

    const pageContainerCX = classNames({
        "page-container": true,
        "view-desktop": larger("md", breakpoints)
    })

    return(<>
        {token && token.payload.tok.utp === "unverified" && <div className='bg-warning'>
            <p className='mb-0 text-uppercase text-black-50 text-center py-1 px-2 fw-bold' style={{fontSize: "65%"}}>Dein Profil wurde noch nicht verifiziert.</p>
        </div>}
        {token && token.payload.tok.utp === "unregistered" && <div className='bg-warning'>
            <p className='mb-0 text-uppercase text-black-50 text-center py-1 px-2 fw-bold' style={{fontSize: "65%"}}>Deine Anmeldung ist unvollständig. <Link to="/settings/personal-data" className='text-black-50 text-decoration-underline'>Weiter</Link></p>
        </div>}
        {token && token.payload.tok.utp === "admin" && <div className='bg-danger'>
            <p className='mb-0 text-uppercase text-black-50 text-center py-1 px-2 fw-bold' style={{fontSize: "65%"}}>Achtung! Administrator Account</p>
        </div>}
        <div className={pageContainerCX}>
            <div className="site-content">
                <PageTitle />
                <div className="site-content-body">
                    <Switch>
                        <Route exact path="/">
                            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.User)} fallback={HTTPErrorPage.Forbidden()}><IndexController /></ShowIf>
                        </Route>
                        <Route path="/events">
                            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.ViewEvents)} fallback={HTTPErrorPage.Forbidden()}><EventController /></ShowIf>
                        </Route>
                        <Route path="/network">
                            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.User)} fallback={HTTPErrorPage.Forbidden()}><NetworkController /></ShowIf>
                        </Route>
                        <Route path="/vorstand">
                            <VorstandController />
                        </Route>
                        <Route path="/settings">
                            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.User)} fallback={HTTPErrorPage.Forbidden()}><SettingsController /></ShowIf>
                        </Route>
                        <Route path="/choir">
                            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.Verified)} fallback={HTTPErrorPage.Unverified()}><ChoirController /></ShowIf>
                        </Route>
                        <Route path="/apacis">
                            <ApacisController />
                        </Route>
                        <Route path="/feedback">
                            <FeedbackController />
                        </Route>
                        <Route path="*" sensitive>
                            <Helmet titleTemplate="%s">
                                <title>404 – Seite nicht gefunden</title>
                            </Helmet>
                            <HTTPErrorPage.NotFound />
                        </Route>
                    </Switch>
                    {/* <Route path="/apacis">
                        <ApacisController />
                    </Route> */}
                </div>
                <div className="site-content-footer">
                    <FooterImpressumStatuten/>
                </div>
            </div>
            <NavigationBar />
        </div>
    </>);
}
import React, { useEffect, useState } from "react";
import useAppToken from "./useAppToken";

type LocalStorageType = string | boolean | number
export type LocalStorageReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export default function useLocalStorage<T extends LocalStorageType = LocalStorageType>(key: string, initialValue: T | undefined): LocalStorageReturnType<T | undefined>  {
    const [state, setState] = useState<T | undefined>(initialValue)
    const token = useAppToken()

    // Clear LocalStorage when token is undefined
    useEffect(() => {
        if (token?.payload === undefined) {
            localStorage.removeItem(key)
            setState(undefined)
        }
    }, [key, token?.payload])

    // Get LocalStorage on first render
    useEffect(() => {
        const storedValue = localStorage.getItem(key)
        if (storedValue) {
            const booleanValue = storedValue === "true" ? true : (storedValue === "false" ? false : undefined)
            const numericValue = Number(storedValue)
            if (booleanValue !== undefined) {
                // @ts-ignore
                setState( booleanValue )
            } else if (!isNaN(numericValue)) {
                // @ts-ignore
                setState(numericValue)
            } else { 
                // @ts-ignore
                setState(storedValue)
            }
        }
    }, [key, initialValue])

    // Set LocalStorage after setState
    useEffect(() => localStorage.setItem(key, state !== undefined ? state.toString() : ''), [key, state])

    return [state, setState]
}

export function useLocalBoolean(key: string, initialValue?: boolean): LocalStorageReturnType<boolean | undefined> {
    return useLocalStorage<boolean>(key, initialValue) 
}

export function useLocalNumber(key: string, initialValue?: number): LocalStorageReturnType<number | undefined> {
    return useLocalStorage<number>(key, initialValue)
}

export function useLocalString(key: string, initialValue?: string): LocalStorageReturnType<string | undefined> {
    return useLocalStorage<string>(key, initialValue)
}
import React from "react";
import {useApiData} from "react-api-data";
import {ChoirInfo} from "../../../modules/API/Services/ChoirService";
import {ChoirEndpoint} from "../../../store/APIStore";
import ApiCycle from "../../../modules/API/ApiCycle";
import {Table} from "react-bootstrap";
import usePageTitle from "../../../hooks/usePageTitle";

export default function ChoirApacisController(): JSX.Element {
    const request = useApiData<ChoirInfo[]>(ChoirEndpoint.GET.all);
    usePageTitle("Alumni Chor")
    
    return <ApiCycle data={request} extractor={(list: ChoirInfo[]) => <>
        <Table><thead>
            <tr>
                <td>Vorname</td>
                <td>Nachname</td>
                <td>Stimmlage</td>
                <td>E-Mail</td>
                <td>Tel. Nr.</td>
            </tr>
        </thead><tbody>
            {list.map((info: ChoirInfo) => <tr key={info.uid}>
                <td>{info.firstName}</td>
                <td>{info.lastName}</td>
                <td>{info.stimmlage}</td>
                <td>{info.email}</td>
                <td>{info.telephone}</td>
            </tr>)}
        </tbody></Table>
    </>}/>;
}
import { createContext } from "react"
import { AppToken } from "../hooks/useAppToken"

const AppTokenContext = createContext<{
    token: AppToken | undefined,
    setToken: (token: AppToken | undefined) => void
}>({
    token: undefined,
    setToken: (token: AppToken | undefined) => {}
})

export default AppTokenContext
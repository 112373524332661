import React, {useCallback, useEffect, useState} from "react";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import * as Icons from "../../../../components/Icons";
import {getTok, ProfileDto, ProfileJob} from "../../../../modules/API/APIInterfaces";
import {
    FriendDto,
    FriendPageDto,
    networkMembers,
    networkSearch,
    networkSearchYear,
    NetworkSearchYearDto,
    ProfilePageDto
} from "../../../../modules/API/Services/NetworkService";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import Profile, {ProfileImage} from "./Profile";
import Pagination from "../../../../components/UI/Generic/Pagination";
import AbstractPermission from "../../../../modules/Auth/AbstractPermission";
import {UserPermission} from "../../../../modules/Auth";
import {Link} from "react-router-dom";
import usePageTitle from "../../../../hooks/usePageTitle";
import {NotRegisteredAlert, NotVerifiedAlert} from "../../../../components/SpecificAlerts";
import {AxiosResponse} from "axios";
import {MessageDisplay, MessageState} from "../../../../components/MessageState";
import InputControlled, {SelectControlled} from "../../../../components/Fields/InputControlled";

function SearchInput(props: {searchInit: string, waiting: boolean, handleSearch: (query: string) => void}) {
    const [value, setValue] = useState(props.searchInit);
    
    useEffect(() => {
        setValue(props.searchInit);
    }, [props.searchInit]);
    
    const submit = () => {
        props.handleSearch(value);
    }

    return <div className="input-group">
        <input className="form-control" placeholder="Name, ..." type="text" autoComplete="off" name="search" onKeyDown={(e) => {if(e.key==="Enter") submit();}} value={value} onChange={(e) => {setValue(e.target.value)}} />
        <ButtonSpin classes="border-bottom-only" skin="outline" spinning={props.waiting} flex={false} onClick={submit}><Icons.Search size={20}/></ButtonSpin>
    </div>;
}
function SearchInputYear(props: {searchInit: NetworkSearchYearDto, waiting: boolean, handleSearch: (searchYearDto: NetworkSearchYearDto) => void}) {
    const [value, setValue] = useState<NetworkSearchYearDto>({year: "", choir: ""});

    useEffect(() => {
        setValue(props.searchInit);
    }, [props.searchInit]);

    const submit = () => {
        props.handleSearch(value);
    }

    return <div className="input-group">
        <div className="form-control bg-transparent p-0 border-bottom-0">
            <InputControlled className="form-control" style={{borderBottomRightRadius: 0}} type="number" placeholder="JJJJ" name="search-year" onKeyDown={(e) => {if(e.key==="Enter") submit();}} value={value.year} onChange={(e: string) => value.year=e} />
            <SelectControlled className="form-control form-select" style={{borderBottomRightRadius: 0}} name="search-choir" onKeyDown={(e) => {if(e.key==="Enter") submit();}} value={value.choir} onChange={(e: string) => value.choir = e}>
                <option value="">Suche nach Chor/Oberstufe</option>
                <option value="_org">Oberstufe</option>
                <option value="Bruckner">Brucknerchor</option>
                <option value="Haydn">Haydnchor</option>
                <option value="Mozart">Mozartchor</option>
                <option value="Schubert">Schubertchor</option>
            </SelectControlled>
        </div>
        
        <ButtonSpin classes="border-bottom-only" skin="outline" spinning={props.waiting} flex={false} onClick={submit}><Icons.Search size={20}/></ButtonSpin>
    </div>;
}

export interface searchFromTo {
    from: number,
    to: number
}

export function searchCheckInside(founds: searchFromTo[], index: number, length: number): boolean {
    const l = founds.length;
    const from = index;
    const to = index+length;
    for(let i=0; i<l; ++i) {
        const found = founds[i];
        const a = found.from;
        const b = found.to;
        
        //not overlapping: left || right 
        if(to<a || b<from) {
            continue;
        }
        //fully inside
        if(a<=from && to<=b) {
            return true;
        }
        //left outside - make ranger bigger
        if(from<a) {
            found.from = from;
        }
        //right outside - make range bigger
        if(to>b) {
            found.to = to;
        }
        return true;
    }
    return false;
}

export function searchFoundsToElement(text: string, founds: searchFromTo[], key?: string): JSX.Element {
    founds = founds.sort((a: searchFromTo, b: searchFromTo) => a.from-b.from);
    
    const children: JSX.Element[] = [];
    let last = 0;
    founds.forEach((found: searchFromTo, i: number) => {
        children.push(<span key={i+"-fromLast"}>{text.substring(last, found.from)}</span>);
        children.push(<span key={i+"-found"} className="text-success">{text.substring(found.from, found.to)}</span>);
        last = found.to;
    })
    children.push(<span key={founds.length}>{text.substring(last)}</span>); //to end
    return <span key={key}>{children}</span>;
}

function fieldToData(uid: number, array: JSX.Element[], titleStatic: string, title: string, fields: string[], split: string[]) {
    let founds: searchFromTo[] = []; //contains list of ranges [start, end]
    let text: string = title;
    let addedText = false;

    const l = split.length;
    const fieldLength = fields.length;
    for(let i=0; i<l; ++i) {
        const searchString = split[i];
        const searchLength = searchString.length;

        //search in each field
        for(let j=0; j<fieldLength; ++j) {
            const field = fields[j];
            const index = field.toLowerCase().indexOf(searchString);
            if(index===-1) continue;
            if(text.includes(field)) continue;

            //make text bigger
            if(!addedText) {
                text += ": ";
                addedText = true;
            } else {
                text += ", ";
            }
            const start = Math.max(index-20,0);
            const end = Math.min(index+searchString.length+20, field.length);

            if(start!==0) {
                text += "...";
            }
            text += field.substring(start,end);
            if(end<field.length) {
                text += "...";
            }
        }

        //title already includes
        let titleStart = 0;
        let titleIndex = text.toLowerCase().indexOf(searchString);
        while(titleIndex!==-1) {
            //check if inside existing range already
            if(!searchCheckInside(founds, titleIndex, searchLength)) {
                //add new range
                founds.push({from: titleIndex, to: titleIndex+searchLength});
            }

            titleStart = titleIndex+searchLength;
            titleIndex = text.toLowerCase().indexOf(searchString, titleStart);
        }
    }

    //found nothing
    if(founds.length===0) {
        return;
    }
    array.push(<div key={uid+"-"+titleStatic+"-"+title}>{titleStatic}{searchFoundsToElement(text, founds)}</div>);
}
function jobToData(uid: number, array: JSX.Element[], title: string, list: ProfileJob[], split: string[]) {
    list.forEach((j: ProfileJob) => {
        fieldToData(uid, array, title+": "+j.startYear+"-"+(j.endYear ? j.endYear : "Heute") + ", ", j.name, [j.institution, j.city], split);
    });
}
//find where searched query is in
function setDataWithResult(query: string, dto: ProfilePageDto, setResult: (value: {dto: ProfilePageDto, data: Record<number, JSX.Element> }) => void) {
    const data: Record<number, JSX.Element> = {};
    const split: string[] = query.toLowerCase().split(/ +/);
    dto.content.forEach((u: ProfileDto) => {
        const array: JSX.Element[] = [];
        fieldToData(u.id, array, "Name","", [u.firstName+" "+u.lastName], split);
        fieldToData(u.id, array, "Beschreibung","", [u.description || ""], split);
        jobToData(u.id, array, "Berufserfahrung", u.jobs, split);
        jobToData(u.id, array, "Ausbildung", u.studies, split);
        jobToData(u.id, array, "Ehrenamt", u.ehrenamt, split);
        data[u.id] = <>{array}</>;
    });
    setResult({dto, data});
}

function SearchResultList(props: {result: FriendPageDto}): JSX.Element {
    return <>{props.result.content.map((user: FriendDto) => 
        <Link key={user.id} to={"/network/"+user.id+"/profile"} className="text-black"><div className="grid-card grid-card-hover py-2 px-3">
            <div className="d-flex">
                <div>
                    <ProfileImage id={user.id} size={80} editable={false}/>
                </div>
                <div className="mx-3">
                    <span className="text-primary">{user.firstName} {user.lastName}</span>
                    <p className="mb-0 text-muted small">{user.memberVbc && <>{user.memberVbc.memberChoir.name}chor ({user.memberVbc.startYear} – {user.memberVbc.endYear}) </>}{user.memberVbc && user.memberHs && <br/>}{user.memberHs && <>Oberstufe ({user.memberHs.startYear} – {user.memberHs.endYear})</>}</p>
                </div>
            </div>
        </div></Link>)}</>
}

function NetworkSearch(props: {user: ProfileDto}) {
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState("");
    const [errorYear, setErrorYear] = useState("");
    
    const [result, setResult] = useState<{dto: ProfilePageDto, data: Record<number, JSX.Element> } | undefined>();
    const [page, setPage] = useState(1);
    
    const [resultYear, setResultYear] = useState<FriendPageDto>();
    const [pageYear, setPageYear] = useState(1);

    const [querySearched, setQuerySearched] = useState("");
    const [yearSearched, setYearSearched] = useState<NetworkSearchYearDto>({year: "", choir: ""});

    const user = props.user;

    const permissions = AbstractPermission.LoadFromToken();

    const handleSearch = useCallback((query: string) => {
        if(query==="") {
            setError("Du musst Text eingeben!");
            return;
        }
        setWaiting(true);
        setResult(undefined);
        networkSearch(query, 1).then((response) => {
            setWaiting(false);
            setPage(1);
            setError("");
            setDataWithResult(query, response.data, setResult);
            setQuerySearched(query);
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    }, []);
    
    const handleSearchYear = useCallback((yearSearch: NetworkSearchYearDto) => {
        if(yearSearch.year==="" && yearSearch.choir==="") {
            setErrorYear("Du musst einen Suchparameter angeben (Jahr/Chor/ORG)!");
            return;
        }
        setWaiting(true);
        setResultYear(undefined);
        networkSearchYear(yearSearch, 1).then((response) => {
            setWaiting(false);
            setPage(1);
            setErrorYear("");
            setResultYear(response.data);
            setYearSearched(yearSearch);
        }).catch((e) => {
            setWaiting(false);
            setErrorYear(AxiosErrorMessageGetter(e));
        });
    }, []);

    const handlePagination = useCallback((p: number) => {
        if(!result || !result.dto) {
            return;
        }
        //no page left
        if(p<1 || p>Math.ceil(result.dto.total/result.dto.pageSize)) {
            return;
        }

        setWaiting(true);
        setResult(undefined);
        networkSearch(querySearched, p).then((response) => {
            setWaiting(false);
            setPage(p);
            setError("");
            setDataWithResult(querySearched, response.data, setResult);
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    }, [querySearched, result]);

    const handlePaginationYear = useCallback((p: number) => {
        if(!resultYear) {
            return;
        }
        //no page left
        if(p<1 || p>Math.ceil(resultYear.total/resultYear.pageSize)) {
            return;
        }

        setWaiting(true);
        setResult(undefined);
        networkSearchYear(yearSearched, p).then((response) => {
            setWaiting(false);
            setErrorYear("");
            setPageYear(p);
            setResultYear(response.data);
        }).catch((e) => {
            setWaiting(false);
            setErrorYear(AxiosErrorMessageGetter(e));
        });
    }, [yearSearched, resultYear]);

    if(!user) {
        return <></>;
    }

    const editAll = permissions.hasPermission(UserPermission.EditAllProfiles).all;
    return(<>
        {!editAll && !user.visible && <>
            <div className="alert alert-warning">Du kannst das Netzwerk benutzen, sobald Du <Link to="/network/profile">Dein Profil angelegt und Dich sichtbar</Link> geschalten hast.</div>
        </>}
        {(editAll || user.visible) && <>
            <h3>Suche</h3>
            <p className="mb-1">Suche nach Name, Berufsbezeichnung, Firma, Studienrichtung, Uni, FH oder Institution.</p>
            <SearchInput waiting={waiting} handleSearch={handleSearch} searchInit={querySearched}/>

            <div className="mt-3">
                {error && <div className="alert alert-danger">{error}</div>}
                {result && <>
                    {result.dto.total===0 && <div className="alert alert-warning mt-3">Wir haben nichts gefunden - Es passt leider niemand zu Deiner Suchanfrage.</div>}

                    {/*Search Result*/ result.dto.content.map((user: ProfileDto) => <Link key={user.id} to={"/network/"+user.id+"/profile"} className="text-black"><div className="grid-card grid-card-hover py-2 px-3">
                        <div className="d-flex">
                            <div>
                                <ProfileImage id={user.id} size={80} editable={false}/>
                            </div>
                            <div className="mx-3">
                                <span className="text-primary">{user.firstName} {user.lastName}</span>
                                <div className="">{result.data[user.id]!==undefined && result.data[user.id]}</div>
                            </div>
                        </div>
                    </div></Link>)}

                    <div className="mt-3">
                        <Pagination page={page} total={result.dto.total} pageSize={result.dto.pageSize} waiting={waiting} handlePagination={handlePagination}/>
                    </div>
                </>}
            </div>

            <h3 className="mt-3">Jahrgang Suche</h3>
            <p className="mb-1">Suche nach WSK Jahr mit Chor oder WSK Oberstufen Jahr.</p>
            <SearchInputYear waiting={waiting} handleSearch={handleSearchYear} searchInit={yearSearched}/>
            <div className="mt-3">
                {errorYear && <div className="alert alert-danger">{errorYear}</div>}
                {resultYear && <>
                    {resultYear.total===0 && <div className="alert alert-warning mt-3">Wir haben nichts gefunden - Es passt leider niemand zu Deiner Suchanfrage.</div>}
                    {/*Search Result*/<SearchResultList result={resultYear}/>}

                    <div className="mt-3">
                        <Pagination page={pageYear} total={resultYear.total} pageSize={resultYear.pageSize} waiting={waiting} handlePagination={handlePaginationYear}/>
                    </div>
                </>}
            </div>
        </>}
    </>);
}

function NetworkList() {
    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);
    
    const [page, setPage] = useState(1);
    const [friends, setFriends] = useState<FriendPageDto>();
    
    const handlePagination = useCallback((newPage: number) => {
        setWaiting(true);
        setPage(newPage);
        setFriends(undefined);
        networkMembers(newPage).then((response: AxiosResponse<FriendPageDto>) => {
            setWaiting(false);
            setFriends(response.data);
        }).catch(() => {
            setMessage({type: "danger", message: "Wir konnten andere Mitglieder nicht laden. Probiere es später erneut."});
            setWaiting(false);
        });
    }, [setWaiting, setPage, setFriends, setMessage]);
    
    useEffect(() => {
        handlePagination(1);
    }, [handlePagination]);

    return <>
        <MessageDisplay message={message}/>
        
        {friends && friends.total > 0 ? <>
            <h3 className="mt-4">Mitglieder mit Profil</h3>
            {/*Search Result*/<SearchResultList result={friends}/>}
            
            <Pagination page={page} total={friends.total} pageSize={friends.pageSize} waiting={waiting}
                        handlePagination={handlePagination}/>
        </> : <>Du hast noch keine Kontakte.</>}
    </>;
}

export default function Network(props: {user: ProfileDto}): JSX.Element {
    usePageTitle("Netzwerk")
    
    const tok = getTok();
    if(tok.utp==="unregistered") { return <NotRegisteredAlert />}
    else if (tok.utp==="unverified") { return <NotVerifiedAlert />}
    
    return <>
        <h3><Link to="/network/profile">Dein Profil</Link></h3>
        <Profile loggedInUser={props.user} user={props.user} onlyPicture={true} editable={false}/>
        
        <NetworkSearch user={props.user}/>
        
        <NetworkList/>
    </>;
}


import { DataViewTableProps } from "."
import usePagination from "../../../../hooks/usePagination"
import useSearch from "../../../../hooks/useSearch"
import useSort from "../../../../hooks/useSort"
import DataViewPagination, { DataViewPageIndicator } from "../../DataViewPagination"
import DataViewSearch from "../../DataViewSearch"
import DataViewSort from "../../DataViewSort"
import styles from "./styles.module.scss"
import DataRow from "./DataViewTableRow"
import React from "react"
import { DataViewColumns } from "../.."

export default function DataViewTable(props: DataViewTableProps): JSX.Element {

    const sort = useSort(Object.keys(props.headers)[0])
    const pagination = usePagination(props.content.length)
    const search = useSearch("", pagination)

    let rows = props.content

    if (props.useSort) rows.sort(sort.sortCallback)
    if (props.useSearch) rows = rows.filter(search.filterCallback)
    if (props.usePagination) rows = pagination.subset(rows)

    // table table-striped table-borderless table-hover table-sm
    return  <div className={styles.DataViewTableGroup} role="group">
                {(props.useSort || props.useSearch || props.usePagination) &&
                    <DataViewTableHeader>
                        {(props.useSort || props.useSearch) &&
                            <DataViewTableHeaderRow>
                                {props.useSearch &&
                                    <DataViewSearch searchString={search.state.value} setSearchString={search.state.dispatch} />}
                                {props.useSort &&
                                    <DataViewSort columns={props.headers} sortByColumn={sort.state.value} setSortByColumn={sort.state.dispatch} />}
                            </DataViewTableHeaderRow>}
                        {props.usePagination && pagination.enabled.value &&
                            <DataViewTableHeaderRow>
                                <DataViewPageIndicator page={pagination.page} limit={pagination.limit} total={pagination.total} />
                            </DataViewTableHeaderRow>}
                    </DataViewTableHeader>
                }
                <DataViewTableBody>
                    <DataViewTableBodyHeader headers={props.headers}/>
                    <div role="rowgroup">
                        {
                            rows.length > 0 ? 
                                rows.map( 
                                    (value) => 
                                    <DataRow row={value} key={value.id} columns={props.headers} onClick={props.rowOnClick} />
                                ) 
                            : <div className="progress-bar progress-bar-striped bg-light rounded py-2 text-black-50 my-4">Keine Daten vorhanden</div>
                        }
                    </div>                    
                    <DataViewTableBodyHeader headers={props.headers}/>
                </DataViewTableBody>
                <DataViewTableFooter>
                    {props.usePagination && pagination.enabled.value &&
                        <DataViewPagination page={pagination.page} limit={pagination.limit} total={pagination.total} />}
                </DataViewTableFooter>
            </div>
}

const DataViewTableHeader = (props: React.PropsWithChildren<{}>) => {
    return  <div className={styles.DataViewTableHeader} role="group">{props.children}</div>
}

const DataViewTableHeaderRow = (props: React.PropsWithChildren<{}>) => {
    return  <div role="row" className={styles.DataViewTableHeaderRow}>{props.children}</div>
}

const DataViewTableBody = (props: React.PropsWithChildren<{}> ) => {
    return  <div className={styles.DataViewTable} role="table">{props.children}</div>
}

const DataViewTableBodyHeader = (props: {headers: DataViewColumns}) => {
    return  <div role="columnheader" className={styles.DataViewTableRowHeader}>
                {
                    Object.keys(props.headers).length > 0 && Object.keys(props.headers).map(h => 
                        <div role="columnheader" key={h} className={styles.DataViewTableRowHeaderItem}>{props.headers[h]}</div>    
                    )
                }
            </div>
}

const DataViewTableFooter = (props: React.PropsWithChildren<{}>) => {
    return  <div className={styles.DataViewTableFooter} role="group">
                {props.children}                
            </div>
}
import React, {useCallback, useState} from "react";
import {useApiData} from "react-api-data";
import {Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import AxiosErrorMessageGetter from "../../../../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../../../../components/UI/Buttons/ButtonSpin";
import usePageTitle from "../../../../../../hooks/usePageTitle";
import ApiCycle from "../../../../../../modules/API/ApiCycle";
import {getTok, UserDto} from "../../../../../../modules/API/APIInterfaces";
import {authChangeEmailPassword, authVerifyEmail} from "../../../../../../modules/API/Services/AuthService";
import {UserEndpoint} from "../../../../../../store/APIStore";
import {MessageDisplay, MessageState} from "../../../../../../components/MessageState";


export default function AccountDataController() : JSX.Element {

    usePageTitle("Einstellungen")

    return  <EmailPassword />
}


function EmailPassword() : JSX.Element {

    const tok = getTok();
    const user = useApiData<UserDto>(UserEndpoint.GET.user, {id: tok.uid})

    const DataExtractor = (data: UserDto): JSX.Element => {
        const emailInit = data.email
        const emailChange = data.emailChange
        const [email, setEmail] = useState(emailInit)
        const [password, setPassword] = useState("")
        const [otp, setOtp] = useState("")
        const [message, setMessage] = useState<MessageState>()
        const [isSubmitting, setIsSubmitting] = useState(false)

        const history = useHistory();

        const logout = useCallback(() => {
            setTimeout(() => {
                history.push("/sign-out");
            }, 3000);
        }, [history]);
        
        const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
            e.preventDefault()
            setIsSubmitting(true)
            authChangeEmailPassword(email, password)
                .then(() => {
                    if(password.length>0) {
                        setMessage({type: "success", message: "Du wirst gleich ausgeloggt, weil sich dein Passwort geändert hat."})
                        logout();
                    } else {
                        data.emailChange = email;
                        setEmail(emailInit);
                    }
                })
                .catch((error: any) => setMessage({type: "danger", message: AxiosErrorMessageGetter(error)}))
                .finally(() => setIsSubmitting(false))
        }
        const handleReset: React.FormEventHandler<HTMLFormElement> = (e) => {
            e.preventDefault()
            setEmail(emailInit)
            setPassword("")
            setOtp("")
            setMessage(undefined)
            setIsSubmitting(false)
        }
        const handleVerifyEmail = () => {
            if(!emailChange) return
            
            setIsSubmitting(true)
    
            authVerifyEmail(otp)
                .then(() => {
                    setMessage({type: "success", message: "Du wirst gleich ausgeloggt, weil sich deine E-Mail Adresse geändert hat."})
                    logout();
                })
                .catch((error: any) => setMessage({type: "danger", message: AxiosErrorMessageGetter(error)}) )
                .finally(() => setIsSubmitting(false))
        };

        return <>

        <form onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
            <Table variant="white" hover className="table-grid">
                <tbody>
                    {message && <tr><td aria-colspan={2} colSpan={2}>
                        <MessageDisplay message={message}/>
                    </td></tr>}
                    <tr>
                        <th scope="row">
                            <label className="h6 mb-0" htmlFor="settings-account-email-address">E-Mail Adresse</label>
                            {emailInit !== email && <p className="text-muted mb-0" style={{color: "var(--bs-orange)"}}>Deine E-Mail muss erneut verifiziert werden.</p>}
                        </th>
                        <td><input className="form-control" value={email} autoComplete="false" type="text" id="settings-account-email-address" onChange={e => setEmail(e.target.value)}/></td>
                    </tr>
                    {emailChange && <tr>
                        <th scope="row">
                            <label className="h6 mb-0" htmlFor="settings-account-otp">Bestätigungscode von {emailChange}</label>
                        </th>
                        <td>
                            <input className="form-control me-2" value={otp} onChange={e => setOtp(e.target.value)} id="settings-account-otp" type="text" autoComplete="off"/>
                            <ButtonSpin spinning={isSubmitting} variant="success" skin="outline" type="button" onClick={handleVerifyEmail}>E-Mail verifizieren</ButtonSpin>
                        </td>
                    </tr>}
                    <tr>
                        <th scope="row">
                            <label className="h6 mb-0" htmlFor="settings-account-pw">Neues Passwort</label>
                        </th>
                        <td>
                            <input className="form-control" id="settings-account-pw" value={password} type="password" autoComplete="false" onChange={e => setPassword(e.target.value)}/>
                        </td>
                    </tr>
                </tbody>
            </Table>
            
            {/*Submit*/}
            {(email !== emailInit || password !== "") && <div className="m-auto" style={{maxWidth: "400px"}}>
                <div className="d-grid" style={{gap: "1rem", gridTemplateColumns: "1fr 1fr"}}>
                    <ButtonSpin spinning={isSubmitting} variant="secondary" skin="outline" type="reset">Abbrechen</ButtonSpin>
                    <ButtonSpin spinning={isSubmitting} type="submit" skin="outline">Speichern</ButtonSpin>
                </div>
            </div>}
        </form>
    </>;
    }

    return <ApiCycle data={user} extractor={DataExtractor} />

    // const [loaded, setLoaded] = useState(false);
    // const [emailInit, setEmailInit] = useState("");
    // const [error, setError] = useState("");
    // const [waiting, setWaiting] = useState(false);

    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    
    // const [otp, setOtp] = useState("");
    // const [emailChange, setEmailChange] = useState("");

    // if(!loaded) {
    //     setLoaded(true);
    //     const tok = getTok();
    //     if(tok && tok.uid) {
    //         usersGet(tok.uid).then((response) => {
    //             setError("");
    //             setEmail(response.data.email);
    //             setEmailInit(response.data.email);
    //             if(response.data.emailChange) setEmailChange(response.data.emailChange);
    //         }).catch((error: any) => {
    //             setError(AxiosErrorMessageGetter(error));
    //         });
    //     }
    // }
    
    // if(!emailInit) {
    //     return <>{error && <div className="alert alert-danger">{error}</div>}</>
    // }
    
    // const handleSubmit = () => {
    //     setWaiting(true);
    //     authChangeEmailPassword(email, password).then(() => {
    //         setError("");
    //         setWaiting(false);
            
    //         if(email!==emailInit) {
    //             setEmailChange(email);
    //             setEmail(emailInit);
    //         }
    //     }).catch((error: any) => {
    //         setError(AxiosErrorMessageGetter(error));
    //         setWaiting(false);
    //     });
    // };

    // const handleReset = () => {
    //     setEmail(emailInit);
    //     setPassword("");
    // };

    // const handleVerifyEmail = () => {
    //     if(!emailChange) {
    //         return;
    //     }
    //     setWaiting(true);

    //     authVerifyEmail(otp).then(() => {
    //         setError("");
    //         setWaiting(false);
    //         window.location.href = "/sign-out";
    //     }).catch((error: any) => {
    //         setError(AxiosErrorMessageGetter(error));
    //         setWaiting(false);
    //     });
    // };
    
    // return <>
    //     {error && <div className="alert alert-danger">{error}</div>}

    //     <form onSubmit={(e) => {e.preventDefault(); handleSubmit()} } autoComplete="off">
    //         <div className="mb-3">
    //             <div className="mb-2">
    //                 <label>E-Mail</label>
    //                 <input className="form-control" value={email} type="text"
    //                        onChange={ (e) => {setEmail(e.target.value);} }/>
    //                 <p className="text-muted">Hinweis: Bevor deine E-Mail geändert wird, musst du sie verifizieren.</p>
    //             </div>
    //             {emailChange && <>
    //                 <div className="mb-3">
    //                     <label>Bestätigungscode von deiner neuen E-Mail: {emailChange}</label>
    //                     <input className="form-control" value={otp} onChange={(e) => {setOtp(e.target.value)} } type="text" autoComplete="off"/>
    //                     <ButtonSpin spinning={waiting} disabled={waiting} variant="success" skin="outline" type="button" onClick={handleVerifyEmail}>Verifiziere deine E-Mail Adresse</ButtonSpin>
    //                 </div>

    //             </>}
    //         </div>

    //         <div className="mb-3">
    //             <label>Neues Passwort</label>
    //             <input className="form-control" value={password} type="password" autoComplete="off"
    //                    onChange={ (e) => {setPassword(e.target.value);} }/>
    //         </div>
            
    //         {/*Submit*/}
    //         <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
    //             <div className="d-flex justify-content-between ">
    //                 <ButtonSpin spinning={waiting} disabled={waiting} variant="secondary" skin="outline" type="button" onClick={handleReset}>Zurücksetzen</ButtonSpin>
    //                 <ButtonSpin spinning={waiting} disabled={waiting} type="submit" skin="outline">Speichere Änderungen</ButtonSpin>
    //             </div>
    //         </div>
    //     </form>
    // </>;
}
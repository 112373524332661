import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken, MemberHs, MemberVbc, ProfileDto, ProfileJob, ProfileTypes} from "../APIInterfaces";

export function networkImagePost(id: number, image: File): Promise<AxiosResponse<void>> {
    let data = new FormData()
    data.append('image', image)

    return axios.post(API_BASE + "/network/" + id + "/image", data, { headers: {
        Authorization: "Bearer " + getToken(),
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data`
    }})
}

export function networkVorstand(): Promise<AxiosResponse<ProfileDto[]>> {
    return axios.get(API_BASE + "/network/vorstand", {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkCircle(): Promise<AxiosResponse<ProfileDto[]>> {
    return axios.get(API_BASE + "/network/circle", {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkMembers(page: number): Promise<AxiosResponse<FriendPageDto>> {
    return axios.get(API_BASE + "/network/members", {headers: {Authorization: "Bearer " + getToken()}, params: {page}});
}

export function networkGet(id: number): Promise<AxiosResponse<ProfileDto>> {
    return axios.get(API_BASE + "/network/"+id,{headers: {Authorization: "Bearer " + getToken()}});
}

export function networkVisible(id: number): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/network/"+id+"/visibility", {}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkEdit(id: number, job: ProfileJob, type: ProfileTypes): Promise<AxiosResponse<number>> {
    return axios.put(API_BASE + "/network/"+id+"/"+type, job, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkEditDescription(id: number, description: string): Promise<AxiosResponse<void>> {
    return axios.put(API_BASE + "/network/"+id+"/description", {description}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkDelete(id: number, job: ProfileJob, type: ProfileTypes): Promise<AxiosResponse<void>> {
    return axios.delete(API_BASE + "/network/"+id+"/"+type+"/"+job.id, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkSearch(query: string, page: number): Promise<AxiosResponse<ProfilePageDto>> {
    return axios.get(API_BASE + "/network/search", {params: {query, page}, headers: {Authorization: "Bearer " + getToken()}});
}

export function networkSearchYear(searchYearDto: NetworkSearchYearDto, page: number): Promise<AxiosResponse<FriendPageDto>> {
    return axios.get(API_BASE + "/network/search/year", {params: {...searchYearDto, page}, headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriendRequest(otherUserId: number, message: string): Promise<AxiosResponse<boolean>> {
    return axios.post(API_BASE + "/network/"+otherUserId, {message}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriendRequestAccept(otherUserId: number): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/network/"+otherUserId+"/accept", {}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriendRequestIgnore(otherUserId: number): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/network/"+otherUserId+"/ignore", {}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriends(page: number): Promise<AxiosResponse<FriendPageDto>> {
    return axios.get(API_BASE + "/network/friends", {params: {page}, headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriendRequestsActive(): Promise<AxiosResponse<FriendRequestSimpleDto[]>> {
    return axios.get(API_BASE + "/network/friends/requests/active", {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkFriendRequestsIgnored(): Promise<AxiosResponse<FriendRequestSimpleDto[]>> {
    return axios.get(API_BASE + "/network/friends/requests/ignored",  {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkGetInstitutionList(): Promise<AxiosResponse<string[]>> {
    return axios.get(API_BASE + "/network/institutions",  {headers: {Authorization: "Bearer " + getToken()}});
}

export function networkGetNamesList(): Promise<AxiosResponse<string[]>> {
    return axios.get(API_BASE + "/network/names",  {headers: {Authorization: "Bearer " + getToken()}});
}

export interface NetworkSearchYearDto {
    year: string,
    choir: string | "_org",
}

export interface FriendRequestSimpleDto {
    text: string,
    id: number,
    firstName: string,
    lastName: string,
    memberVbc?: MemberVbc,
    memberHs?: MemberHs,
    
    accepted?: boolean
}

export interface FriendDto {
    id: number,
    firstName: string,
    lastName: string,
    memberVbc?: MemberVbc,
    memberHs?: MemberHs
}

export interface FriendPageDto {
    content: FriendDto[],
    total: number,
    pageSize: number
}

export interface ProfilePageDto {
    content: ProfileDto[],
    total: number,
    pageSize: number
}
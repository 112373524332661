import React, {useRef, useState} from "react";
import ButtonSpin from "../../components/UI/Buttons/ButtonSpin";
import axios from "axios";
import {API_BASE} from "../../store/API";
import {ChevronLeft} from "../../components/Icons";
import {motion} from "framer-motion";
import {escapeRegExp} from "../../_global/Helper";
import AxiosErrorMessageGetter from "../../components/AxiosErrorMessageGetter";

export default function PasswordResetController(): React.ReactElement {
    const [isWaiting, setIsWaiting] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [error, setError] = useState("");

    const [username, setUsername] = useState("");
    const [otp, setOTP] = useState("");
    const [password, setPassword] = useState("");

    const otpRef = useRef<HTMLInputElement>(null);

    const submit: React.FormEventHandler<HTMLFormElement> = (e: React.FormEvent<HTMLFormElement>) => {
        setIsWaiting(true);
        if(!isSent) {
            //request OTP
            axios.post(API_BASE+"/auth/reset", {email: username}).then(() => {
                //request OTP
                setIsSent(true);
                setError("");
                setIsWaiting(false);
                otpRef.current && otpRef.current.focus();
            }).catch((error: any) => {
                setError(AxiosErrorMessageGetter(error));
                setIsWaiting(false);
            });
        } else {
            //password reset
            const checkPw = {
                patterns: ["[A-Z]+",
                    "[a-z]+",
                    "[0-9]+",
                    "^.{8,}$",
                    "[" + escapeRegExp("!§$%&/()=?*+'#-_.:,;<>\"`´\\€@") + "]+"
                ],
                errorMsg: "Dein Passwort muss aus Groß- und Kleinbuchstaben bestehen, mindestens 8 Zeichen lang sein und mindestens eine Ziffer und ein Sonderzeichen enthalten: !§$%&/()=?*+'#-_.:,;<>\"`´\\€@"
            };
            //check password requirements
            let passed = true;
            for(let i=0; i<checkPw.patterns.length; ++i) {
                if(!password.match(checkPw.patterns[i])) {
                    passed = false;
                    break;
                }
            }
            
            if(passed) {
                //send request
                axios.put(API_BASE+"/auth/reset", {email: username, newPassword: password, otp: otp}).then(() => {
                    setIsDone(true);
                    setIsWaiting(false);
                    setError("");
                }).catch((error: any) => {
                    setError(AxiosErrorMessageGetter(error));
                    setIsWaiting(false);
                });
            } else {
                //pw not right
                setError(checkPw.errorMsg);
                setIsWaiting(false);
            }
        }

        e.preventDefault();
    }
    const reset: React.FormEventHandler<HTMLFormElement> = (e: React.FormEvent<HTMLFormElement>) => {
        setIsSent(false);
        e.preventDefault();
    }


    return <div className="position-relative h-100">
        <a href="/"><h4 className="d-inline-block mt-4 mx-4"><ChevronLeft size={36} />Zurück</h4></a>
        <motion.div className="p-4" style={{width: '100%', height: 'auto', position: 'relative',left:'0%', top: '40%', maxWidth:'400px', margin:'0 auto', transform: 'translateY(-50%)'}}>
            <div>
                <img src="/logo@3.png" alt="UNISONO Logo" style={{height: '40px', width: 'auto', margin: '2rem 0'}} />
                <h4 className="mb-2"> Passwort vergessen</h4>
                <p className="text-muted small">Verwende Deine E-Mail-Addresse, welche Du angegeben hast, als Du Deinen Account erstellt hast. Du bekommst einen Bestätigungscode auf deine Email (schaue auch im Spam), mit dem du dein Passwort zurücksetzen kannst.</p>

                { error && <div className="alert alert-danger">{error}</div> }

                { !isDone &&
                <form onSubmit={submit} onReset={reset}>

                    <label className="form-label">E-Mail</label>
                    <input required disabled={isSent} className='form-control mb-3' type="email" onChange={(e) => setUsername(e.target.value)} value={username}/>

                    { isSent && <>
                        <label className="form-label">Bestätigungscode</label>
                        <input className='form-control mb-3' required type="text" onChange={(e) => setOTP(e.target.value)} value={otp} ref={otpRef}/>

                        <label className="form-label">Neues Passwort</label>
                        <input className='form-control mb-3' required type="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
                    </> }

                    <div className="d-flex flex-column">
                        {isSent && <ButtonSpin spinning={false} type="reset" variant="danger" skin="link">Zurück</ButtonSpin>}
                        <ButtonSpin spinning={isWaiting} disabled={isWaiting} type="submit" skin="outline">{!isSent ? "Bestätigungscode anfordern" : "Passwort zurücksetzen"}</ButtonSpin>
                        {!isSent && username && <ButtonSpin spinning={false} type="button" variant="success" skin="outline" onClick={() => setIsSent(true)}>Du hast bereits einen Code?</ButtonSpin>}
                    </div>
                </form>
                }
                { isDone && <div className="alert alert-success">Passwort wurde zurückgesetzt. Klicke <a href="/">hier</a> um zum Login zu kommen.</div>}
            </div>
        </motion.div>
    </div>;
}
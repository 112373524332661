import {Route, Switch} from "react-router-dom";
import ListNavigation from "../../../../components/UI/Generic/ListNavigation";
import PersonalDataController from "./controller/PersonalDataController";
import AccountDataController from "./controller/AccountDataController";
import usePageTitle from "../../../../hooks/usePageTitle";
import { larger, smaller, useBreakpoint } from "../../../../context/Breakpoint";
import React, { CSSProperties } from "react";
import classNames from "classnames";
import NotificationController from "./controller/NotificationController";
import AbstractPermissions from "../../../../modules/Auth/AbstractPermission";
import {UserPermission} from "../../../../modules/Auth";
import {UINotificationBadge} from "../../../../components/UI/Generic/UINotificationBadge";
import {ShowIfNot} from "../../../../hoc/Authenticated";
import { NavigationItemType } from "../../../../components/UI/Generic/UINavigationItem/UINavigationItem";

export default function SettingsController(): JSX.Element {

    usePageTitle("Einstellungen")
    const bp = useBreakpoint();

    const navItems: NavigationItemType[] = [{
        to: "/settings",
        name: "Account",
        exact: true
    }, {
        to: "/settings/personal-data",
        name: "Mitgliedsdaten",
        badge: <ShowIfNot permission={AbstractPermissions.withPermission(UserPermission.Verified)}><UINotificationBadge count={"!"}/></ShowIfNot>
    }, {
        to: "/settings/notifications",
        name: "Benachrichtigungen"
    }]

    const gridTemplateMobile: CSSProperties = {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr'
    }

    const gridTemplate: CSSProperties = {
        gridTemplateColumns: "4fr 1fr",
        gridTemplateRows: "1fr"
    }

    const gridItem = (reverse: boolean): CSSProperties => { return {
        order: reverse ? -1 : 1
    }}

    const cx = classNames({
        "me-4": larger("lg", bp, false),
        "mt-4": smaller("lg", bp, true)
    })
    
    return  <div className="d-grid" style={larger("lg", bp, false) ? gridTemplate : gridTemplateMobile}>
                <div style={larger("lg", bp, false) ? gridItem(false) : undefined}>
                    <ListNavigation navItems={navItems} />
                </div>
                <div className={cx} style={larger("lg", bp, false) ? gridItem(true) : undefined}>
                    <Switch>
                        <Route exact path="/settings">
                            <AccountDataController/>
                        </Route>
                        <Route exact path="/settings/personal-data">
                            <PersonalDataController/>
                        </Route>
                        <Route exact path="/settings/notifications">
                            <NotificationController/>
                        </Route>
                    </Switch>
                </div>
            </div>
}
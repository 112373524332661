import {Link, useLocation} from "react-router-dom"
import React from "react";

export function HTTPErrorMessage(props: {message: string, redirect?: string, statusCode?: number, errorCode?: string | number}) {
    const location = useLocation();
    const message = props.message.split("%path%");
    const newMessage: React.ReactNode[] = [];
    message.forEach((value, index) => {
        newMessage.push(value);
        if(index + 1 !== message.length) newMessage.push(<span key={index} className="rounded fs-4 position-relative text-white p-2 bg-secondary text-break" style={{top: "-2px"}}>{location.pathname}</span>);
    })

    return(
        <div className="p-4 position-relative top-50 start-50 translate-middle d-table" style={{maxWidth: "600px"}}>
            <p className="fs-2 text-secondary fw-bold lh-base mb-4">{newMessage}</p>
            <Link to={props.redirect ? props.redirect : "/"} className="btn btn-lg btn-outline-primary">Zurück {props.redirect ? 'zu ' + props.redirect : 'zur Startseite' }</Link>
            {props.statusCode || props.errorCode ? <p className="mb-0 text-black-50 mt-3 small">
            {props.statusCode ? <span>HTTP Status Code: {props.statusCode}</span> : null}{props.errorCode? <span>Application Error Code: {props.errorCode}</span> : null}
            </p> : null}
        </div>
    )
}

export function GenericUserError(props: {redirect?: string, statusCode?: number, errorCode?: string | number}) {
    return (<HTTPErrorMessage message="Da ist etwas schief gelaufen – bitte versuche es nochmal. Sollte das Problem weiterhin bestehen, wende Dich an technik@unisono-alumni.at." redirect={props.redirect} statusCode={props.statusCode} errorCode={props.errorCode} />)
}

// 400
export function BadRequest(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={400} errorCode={props.errorCode} />);
}
// 401
export function Unauthenticated() {
    return(<HTTPErrorMessage message="Du musst angemeldet sein, um die Seite %path% aufzurufen." statusCode={401} />)
}
// 402 skipped

// 403
export function Forbidden() {
    return(<HTTPErrorMessage message="Du hast nicht die notwendigen Berechtigung, um die Seite %path% aufzurufen." statusCode={403} />)
}
export function Unverified() {
    return <HTTPErrorMessage message="Du musst verifiziert sein, um die Seite %path% aufzurufen." />
}
// 404
export function NotFound() {
    return(<HTTPErrorMessage message="Wir konnten die Seite %path% nicht finden." statusCode={404} />)
}
// 405
export function MethodNotAllowed(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={405} errorCode={props.errorCode} />);
}
// 408
export function RequestTimeOut(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={408} errorCode={props.errorCode} />);
}
// 409
export function Conflict(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={409} errorCode={props.errorCode} />);
}
// 410
export function Gone() {
    return(<NotFound />);
}
// 429
export function TooManyRequests(props: {errorCode? : string | number}) {
    return(<HTTPErrorMessage message="Der Server hat Deine Anfrage blockiert. Bitte warte einige Zeit und versuche es erneut." statusCode={429} errorCode={props.errorCode} />)
}


// 500
export function InternalServerError(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={500} errorCode={props.errorCode} />);
}
// 502
export function BadGateway(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={502} errorCode={props.errorCode} />);
}
// 503
export function ServiceUnavailable(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={503} errorCode={props.errorCode} />);
}
// 504
export function GatewayTimeout(props: {errorCode? : string | number}) {
    return(<GenericUserError statusCode={504} errorCode={props.errorCode} />);
}
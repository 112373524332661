import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import {JobYears, ProfileDto, ProfileJob} from "../../modules/API/APIInterfaces";

import "./style.scss"
import {ProfileImage} from "../../controllers/ClubPageController/controller/NetworkController/Profile";


export function ProfileCarousel(props: PropsWithChildren<{ items: ProfileDto[], itemsPerRow: number }>): JSX.Element {
    return <div className="profile-carousel" style={{gridTemplate: `repeat(${ Math.max(1, Math.ceil((props.items.length / props.itemsPerRow) || 1) ) }, 1fr) / repeat(${props.itemsPerRow}, 1fr)`}}>{
            props.items.map(item => <ProfileCarouselItem userID={item.id} firstName={item.firstName} lastName={item.lastName} unisonoJob={item.ehrenamt} key={item.id}/>)
        }</div>
}

export function ProfileCarouselItem(props: {userID: number, firstName: string, lastName: string, unisonoJob?: ProfileJob[]}): JSX.Element {
    let unisonoJob: ProfileJob | undefined
    props.unisonoJob?.forEach( job => {
        if (job.institution.toLowerCase().includes("unisono")) {
            if(unisonoJob!==undefined && !job.name.includes("Vorstand")) {
                return;
            }
            unisonoJob = {...job};
            unisonoJob.name = unisonoJob.name.replace(" (Vorstand)", "");
            unisonoJob.name = unisonoJob.name.replace(" Vorstand ", " ");
            unisonoJob.name = unisonoJob.name.replace("Vorstand ", "");
            unisonoJob.name = unisonoJob.name.replace("Vorstand: ", "");
        }
    })
    
    return  <div className="profile-carousel-item" style={{position: "relative"}}>
                <div className="profile-carousel-img rounded-circle overflow-hidden position-relative mx-2 mx-md-auto mb-4">
                    <ProfileImage id={props.userID} size="100%"/>
                    <div className="profile-carousel-img position-absolute w-100 h-100 rounded-circle" style={{top: 0, left: 0, margin: 0}}/>
                </div>
                <div className="profile-carousel-description">
                    <p className="h5 mb-0">{props.firstName} {props.lastName}</p>
                    {unisonoJob && <p className="mb-0 fw-bold">{unisonoJob.name}<br/><span className="text-muted">{JobYears(unisonoJob)}</span></p>}
                </div>
                <Link className="profile-carousel-link stretched-link" to={"/network/" + props.userID + "/profile"}>Profil besuchen</Link>
            </div>
}
export default function AxiosErrorMessageGetter(error: any): string {
    const e = error.response;
    if(!e) {
        if(""+error==="Error: Network Error") return "Wir konnten Dich nicht zum Server verbinden - versuche es noch einmal!";
        return "";
    }
    console.error(e);

    if(e.status===0 || e.data==null){
        return "Wir konnten Dich nicht zum Server verbinden - versuche es noch einmal!";
    }
    
    if (typeof e.data === "object") {
        return e.data.error;
    } else {
        return e.data;
    }
}
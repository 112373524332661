import classNames from "classnames"
import React from "react"
import { StateObject } from "../../../types/types"
import styles from "../UI/DataViewTable/styles.module.scss"

export type DataViewAbstractPaginationProps = {
    page: StateObject<number>,
    limit: StateObject<number | undefined>,
    total: number,
}

export default function DataViewPagination(props: DataViewAbstractPaginationProps): JSX.Element {

    const currentPage = props.page.value
    const totalPages = Math.ceil(props.total / (props.limit.value || 25))
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

    if (totalPages <= 1) return <></>

    return <div className="text-end">
                <div className="btn-group table-pagination" role="group" aria-label="Table Pagination">
                    {
                        pages.map(index => <PaginationButton key={index} current={currentPage} index={index} setCurrentPage={props.page.dispatch} />)
                    }
                </div>
            </div>
}

type PaginationButtonProps = {
    current: number,
    index: number,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

function PaginationButton(props: PaginationButtonProps) : JSX.Element {

    const isDisabled = props.current === props.index

    const cx = classNames({
        'btn': true, 'btn-sm': true, 'btn-outline-primary': true,
        disabled: isDisabled
    })

    const onClick = () => {
        props.setCurrentPage(props.index)
    }

    return  <button type="button" disabled={isDisabled} className={cx} onClick={onClick} aria-current={isDisabled}>
                {props.index}
            </button>
}

export type DataViewPageProps = {
    pagination: DataViewAbstractPaginationProps,
    columnCount: number
}

export function DataViewPageIndicator(props: DataViewAbstractPaginationProps) : JSX.Element {

    const l = props.limit.value || 25
    let rangeEnd = props.page.value * l;
    if (rangeEnd > props.total) rangeEnd = props.total
    const rangeStart = props.page.value * l - l + 1

    if (props.total <= l)
        return  <div className={styles.DataViewTableHeaderRowPagination}>
                    <PageLimitButtons value={props.limit.value} dispatch={props.limit.dispatch} />
                    {props.total} Datensätze
                </div>

    return  <div className={styles.DataViewTableHeaderRowPagination}>
                <PageLimitButtons value={props.limit.value} dispatch={props.limit.dispatch} />
                {rangeStart} – {rangeEnd} von {props.total} Datensätzen
            </div>
}

const PageLimitButtons = (props: StateObject<number | undefined>) => {
    const limits = [2, 10, 25, 50, 100]
    return  <div style={{display: "flex", justifyContent: "flex-end"}}>
                Zeige
                <ul className={styles.DataViewSortList} style={{
                    display: "inline-flex",
                    margin: "0 .25rem",
                    position: "relative",
                    top: "-1px"
                }}>
                    {
                        limits.map(l => <li key={l} className={styles.DataViewSortListItem + ' ' + (props.value === l ? styles.active : '')}><button onClick={() => props.dispatch(l)}>{l}</button></li>)
                    }
                </ul> pro Seite
            </div>
}
import { useApiData } from "react-api-data";
import { useHistory } from "react-router-dom";
// import { DataTableColumnProps, DataTableRow } from "../../../components/DataTable";
import ApiCycle from "../../../modules/API/ApiCycle";
import { UserEndpoint } from "../../../store/APIStore";
import usePageTitle from "../../../hooks/usePageTitle";
import { larger, useBreakpoint } from "../../../context/Breakpoint";
import DataViewTable from "../../../components/DataView/UI/DataViewTable/DataViewTable";
import { useCallback } from "react";
import { DataViewColumns, DataViewRow } from "../../../components/DataView";
// import useResponsiveConst from "../../../hooks/useResponsiveConst";

export interface IUserAdminOverview {
    id: number,
    // role: string,
    // roleAdds: string[],
    // ehrenMitglied: boolean,
    // userType: string,
    // joinedAt: string,
    // titlePrefix: string | null,
    // titleSuffix: string | null,
    firstName: string,
    lastName: string,
    email: string
}

export type MemberRow = { [key in Extract<keyof IUserAdminOverview, string>]?: any }

function APIDataExtractor(data: IUserAdminOverview[]) : JSX.Element {
        
    const history = useHistory()
    const viewMember = useCallback((row: DataViewRow) => history.push(`/apacis/members/view/${row.id}`), [history])
    const bp = useBreakpoint()
    
    const rows: MemberRow[] = data.map(value => {
        return {id: value.id, firstName: value.firstName, lastName: value.lastName, email: value.email}
    })

    const headers: DataViewColumns = larger("md", bp, true) ? {
        id: "ID",
        firstName: 'Vorname',
        lastName: "Nachname",
        email: "E-Mail-Adresse"
    } : {
        firstName: "Vorname",
        lastName: "Nachname"
    }

    // const limits = [2, 10, 20, 35, 50]

    return  <DataViewTable
                headers={headers}
                content={rows}
                useSearch={true}
                useSort={true}
                usePagination={true}
                rowOnClick={viewMember}
            />
}

export default function MembersOverview(): JSX.Element {

    usePageTitle("Mitglieder");

    const users = useApiData<IUserAdminOverview[], any>(UserEndpoint.GET.users)

    return <ApiCycle data={users} extractor={APIDataExtractor} />
}
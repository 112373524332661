import Alert from "react-bootstrap/esm/Alert";
import { Link } from "react-router-dom";


export function NotRegisteredAlert(props: {showButton?: boolean}) : JSX.Element {
    return  <Alert variant="info">
                <Alert.Heading>Deine Anmeldung ist unvollständig.</Alert.Heading>
                <p className="mb-0">Sobald Du Deine Anmeldung abschließt und Du verifiziert bist, kannst Du alle Seiten sehen.</p>                
                {props.showButton && <Link className="btn btn-outline-primary" to="/settings/personal-data">Anmeldung abschließen</Link>}
            </Alert>
}

export function NotVerifiedAlert() : JSX.Element {
    return  <Alert variant="info" style={{margin:'3rem 25%'}}>
                <Alert.Heading>Dein Profil wartet auf Verifizierung</Alert.Heading>
                <p className="mb-0">Bitte habe noch ein bisschen Geduld, wir bearbeiten gerade Deine Anmeldung. Wir senden Dir eine E-Mail, sobald Dein Profil verifiziert ist.</p>
            </Alert>
}

export function FinishSignUpAlert(props: {emailVerified?: boolean}) : JSX.Element {
    return  <Alert variant="info">
                <Alert.Heading>Deine Anmeldung ist unvollständig.</Alert.Heading>
                <p className="mb-0">Sobald Du {!props.emailVerified && <>Deine Email verifizierst und</>} das Formular ausfüllst, kannst Du alle Seiten sehen.</p>
            </Alert>
}
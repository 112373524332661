import {ProfileDto} from "../../../../modules/API/APIInterfaces";
import usePageTitle from "../../../../hooks/usePageTitle";
import { ProfileCarousel } from "../../../../components/ProfileCarousel";
import { larger, useBreakpoint } from "../../../../context/Breakpoint";
import { useApiData } from "react-api-data";
import { NetworkEndpoint } from "../../../../store/APIStore";
import ApiCycle from "../../../../modules/API/ApiCycle";
import React from "react";

export default function VorstandController() : JSX.Element {

    usePageTitle("Circle")
    const bp = useBreakpoint()

    const circle = useApiData<ProfileDto[]>(NetworkEndpoint.GET.circle)

    let itemsPerRow = 2
    if( larger("xl", bp, false) ) itemsPerRow = 4;
    else if( larger("sm", bp, false) ) itemsPerRow = 3;

    const CircleDataExtractor = (data: ProfileDto[]): JSX.Element => {
        if (data.length > 0)
            return  <>
                    <ProfileCarousel items={data} itemsPerRow={itemsPerRow}/>
                    </>
        return <></>
    }

    return <ApiCycle data={circle} extractor={CircleDataExtractor} />;
}
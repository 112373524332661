import { Formik } from "formik";
import { EntityExport, entityExportFromDictionary, FormikDataComponentProps, FormikSubmitCancel } from "..";
import * as Fields from "../../Fields";
import * as Yup from "yup";
import { useState } from "react";
import { useActions } from "react-api-data";

export const MemberMembershipDataYupValidation = Yup.object({
    nextYearMembershipFeeType: Yup.string().required()
})

export type HTMLMemberMembershipDataFormElements = {
    nextYearMembershipFeeType: string
    membershipFeeDate: string
}

export const MembershipTypesEntity : EntityExport = entityExportFromDictionary([{
    id: "Erlassen",
    display: "Erlassen – EUR 0,00"
}, {
    id: "Jungalumni",
    display: "Jungalumni – EUR 0,00"
}, {
    id: "Student",
    display: "Student – EUR 15,00"
}, {
    id: "Vollmitglied",
    display: "Vollmitglied – EUR 30,00"
}], "display", "id")


export default function MemberMembershipDataFormik(props: FormikDataComponentProps<HTMLMemberMembershipDataFormElements>): JSX.Element {
    
    const [showDetailedStatusDescription, setShowDetailedStatusDescription] = useState(false)

    const action = useActions();
    
    return  <Formik
                initialValues={props.initial}
                validationSchema={MemberMembershipDataYupValidation}
                onSubmit={ (values, { setSubmitting,setStatus, resetForm } ) => {
                    
                    if (props.onSubmit) {
                        const submitResult = props.onSubmit(values);

                        if (typeof submitResult === "boolean") {
                            if (!submitResult) {
                                setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', {reason: 'Function returned FALSE'}])
                            }
                        } else {
                            submitResult.then(value => {
                                value.invalidateCache()
                                action.invalidateCache('getUserDataWithID', {id: value.request.params?.id || 0})
                                resetForm()
                            }).catch(reason => {
                                setStatus(['Es ist ein Fehler bei der Übertragung aufgetreten. Bitte kontrollieren Sie die Daten und versuchen es erneut.', reason])
                            })
                        }

                    } 
                    setSubmitting(false)
                }}>
                    {
                        formik => <>
                            {formik.status ? 
                                <div className="alert alert-danger">
                                    <p>{formik.status[0]}</p>
                                    <p className="mb-0"><button className="btn btn-link alert-link" onClick={() => setShowDetailedStatusDescription(!showDetailedStatusDescription)}>Server Antwort {showDetailedStatusDescription ? "verbergen" : "anzeigen"}</button></p>
                                    { showDetailedStatusDescription ? 
                                        <div>
                                            <pre className="mb-0">{JSON.stringify(formik.status[1], null, "  ")}</pre>
                                        </div> : null}
                                </div> : null}
                                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                    <table className="table table-hover table-light table-grid">
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <p className="table-row-title">Mitgliedschaft</p>
                                                </th>
                                                <td>
                                                    <Fields.FieldCheck name="nextYearMembershipFeeType" label="Mitgliedschaft" choices={MembershipTypesEntity.asKeyValueCollection} renderAsButtonGroup noLabel noStyle/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <FormikSubmitCancel formik={formik} onSubmitText={props.onSubmitText} onCancelText={props.onCancelText} />
                            </form>
                            
                        </>
                    }
            </Formik>
}

import classNames from "classnames"
import React, {useCallback, useEffect, useState} from "react"
import {Area} from "react-easy-crop/types"
import {getTok} from "../../../../modules/API/APIInterfaces"
import {networkImagePost} from "../../../../modules/API/Services/NetworkService"
import ImageCropper from "../../../../modules/ImageCropper"
import getCroppedImg from "../../../../modules/ImageCropper/image"
import {Camera} from "../../../Icons"
import BSModal from "../../Generic/BSModal"
import "./styles.scss"

export default function UserAvatar(props: {size: number | string, userId: number, src: string, setImage?: (imageURL: string) => void, editable?: boolean, onClick?: React.MouseEventHandler<HTMLDivElement>}): JSX.Element {
    const [showEditModal, setShowEditModal] = useState(false)

    const classnames = classNames({
        "avatar-container": true,
        "avatar-editable": props.editable,
    })
    
    const size = typeof props.size==="string" ? props.size : props.size+"px";

    return  <>
            <div className={classnames} onClick={props.editable ? () => setShowEditModal(true) : props.onClick} 
                 style={{maxWidth: size, /*height: size, borderWidth: "1px"*/}}>
                <img className="avatar-img" src={props.src} alt="Profil" />
                {props.editable && <div className="avatar-overlay text-white d-flex flex-column justify-content-center align-items-center">
                    <Camera size={32} />
                    <p className="mb-0 smaller text-center">Profilbild<br/>bearbeiten...</p>
                </div>}
            </div>
            {props.editable && <UserAvatarEditModal userId={props.userId} show={showEditModal} setShow={setShowEditModal} setImage={props.setImage} />}
            </>
}

interface ImageURL {
    file: File,
    url: string
}

const UserAvatarEditModal = (props: {userId: number, show: boolean, setShow: (show: boolean) => void, setImage?: (imageURL: string) => void}) => {

    const [step, setStep] = useState<"choosing" | "cropping" | "verifying">("choosing")
    const [chosenImage, setChosenImage] = useState<ImageURL>()
    const [croppedImage, setCroppedImage] = useState<ImageURL>()
    const [area, setArea] = useState<Area>()
    // const [readCroppedImage, setReadCroppedImage] = useState<boolean>(false)

    const [spinning, setSpinning] = useState<boolean>(false)

    const resetValues = useCallback((): void => {
        setChosenImage(undefined);
        setCroppedImage(undefined);
        setArea(undefined);
        setStep("choosing");
    }, []);
    
    useEffect(() => {
        if (chosenImage !== undefined && step === "choosing") setStep("cropping")
    }, [chosenImage, step])

    const handleModalSubmit = useCallback((): Promise<boolean> | boolean => {
        if ( step === "cropping" ) {
            if(chosenImage && area) {
                const image = getCroppedImg(chosenImage.file, area)

                if (image) {
                    setSpinning(true)
                    image.then(value => {
                        const imageFile = new File([value], chosenImage.file.name, {type: chosenImage.file.type});
                        console.log("UserAvatar: imagefile size:", imageFile.size)
                        setCroppedImage({file: imageFile, url: URL.createObjectURL(imageFile)})
                    }).catch().finally(() => {
                        setSpinning(false)
                    })

                    setStep("verifying")
                } else {

                }
            }

        } else if (step === "verifying") {
            return new Promise<boolean>((resolve, reject) => {

                if (croppedImage) {
                    setSpinning(true)
                    const tok = getTok().uid as number
                    // tok.sub
                    if (tok) networkImagePost(props.userId, croppedImage.file)
                        .then(value => {
                            if(props.setImage) props.setImage(croppedImage.url);
                            resetValues();
                            return value.status === 200 ? resolve(true) : reject(`Could not complete upload. Status Code is ${value.status}`);
                        })
                        .catch(reason => reject(`AJAX request rejected with reason "${reason}".`))
                        .finally(() => setSpinning(false))
                }
                
                // resolve(true)
                // reject("Could not upload Picture")
            })
        }

        return false

    }, [area, chosenImage, croppedImage, step, props, resetValues])

    const handleModalCancel = useCallback(() : boolean => {
        if ( step === "verifying") {
            setCroppedImage(undefined)
            setStep("cropping")
            return false
        } else if (step === "cropping") {
            setCroppedImage(undefined)
            setChosenImage(undefined)
            setStep("choosing")
            return false
        } else if (step === "choosing") {
            return true
        }
        return true
    }, [step])

    const handleModalHide = useCallback((): boolean => {
        //resetValues();
        return true;
    }, []);
    
    const onCropComplete = useCallback((area: Area) => {
        setArea(area)
    }, [])

    return  <BSModal
                title="Profilbild bearbeiten"
                show={props.show} setShow={props.setShow} 
                userCanCancel
                renderLogoType 
                userCanSubmit={step !== "choosing"}
                onSubmit={handleModalSubmit}
                onCancel={handleModalCancel}
                onHide={handleModalHide}
                submitLabel={step === "cropping" ? "Ausschneiden" : "Speichern"}
                cancelLabel="Zurück"
                spinning={spinning}>
                    {step === "choosing"  &&                 <ChoosePicture setImage={setChosenImage}/>}
                    {(step === "cropping" || step === "verifying")  && chosenImage && <ImageCropper show={step==="cropping"} image={chosenImage.url} onCropComplete={onCropComplete} />}
                    {step === "verifying" && <>
                            <p className="h4">Vorschau</p>
                            <p className="small">So wird Dein neues Profilbild aussehen. Wenn es Dir gefällt, drücke auf Speichern.</p>
                            <div className="d-flex justify-content-center align-items-center" style={{height:"150px", margin: "2rem 0 1rem 0"}}>
                                {croppedImage && <UserAvatar userId={props.userId} src={croppedImage.url} size={150}/>}
                            </div>
                        </>}
            </BSModal>
}

const ChoosePicture = (props: {setImage: (image: ImageURL) => void}) => {
    const [chosenImageError, setChosenImageError] = useState<string>()
    const [isDropping, setIsDropping] = useState(false)

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList: FileList | null = event.target.files

        if (fileList === null) {
            console.warn("Could not handle onFileChange because FileList is null.")
            return
        }

        const selectedImage = fileList.item(0)

        if (selectedImage === null) {
            console.warn("Could not handle onFileChange because File is null.")
            return
        }

        props.setImage( {file: selectedImage, url: URL.createObjectURL(selectedImage)} )
    }

    /*
     * Drag and Drop Images
     */

    const validateFile = useCallback((file: File) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        return validTypes.indexOf(file.type) !== -1;
        
    }, [])

    const handleFile = useCallback((file: File) => {
        if (validateFile(file)) {
            setChosenImageError(undefined)
            props.setImage( {file: file, url: URL.createObjectURL(file)} )
        } else {
            // NOT A VALID FILE
            setChosenImageError("Datei ist kein Bild! Lade ein PNG, JPG, oder JPEG Bild hoch.")
        }
    }, [props, validateFile])

    const onDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDropping(true)
    }, [])

    const onDragLeave: React.DragEventHandler<HTMLDivElement> = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDropping(false)
    }, [])

    const onDrop: React.DragEventHandler<HTMLDivElement> = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const files = e.dataTransfer.files
        if (files.length === 1) {
            const droppedFile = files.item(0)
            droppedFile !== null && handleFile(droppedFile)
        } else if (files.length > 1) {
            setChosenImageError("Bitte nur ein Bild auswählen!")
        }
        setIsDropping(false)
    }, [handleFile])

    const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (chosenImageError === undefined) {
            const files = e.dataTransfer.files
            if (files.length === 1) {
                const droppedFile = files.item(0)
                droppedFile !== null && handleFile(droppedFile)
            } else if (files.length > 1) {
                setChosenImageError("Bitte nur ein Bild auswählen!")
            }
        }
    }, [chosenImageError, handleFile, setChosenImageError])

    /*
     * END  Drag and Drop Images
     */

    const styleX: React.CSSProperties = {
        aspectRatio: "1"
    }

    const cx = classNames({
        "user-avatar-file-drop": true,
        "dropping": isDropping
    })
    
    return  <div
                className={cx}
                style={styleX}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}>
                <div>
                    <label htmlFor="formFile" className="btn btn-outline-primary d-block" onClick={e => e.stopPropagation()}>Klicke um ein Bild zu wählen
                        <input className="file-input-btn" type="file" accept="image/png,image/jpg,image/jpeg" id="formFile" onChange={handleFileInput}  />
                    </label>
                    <p className="small text-black-50 my-2 text-center">Oder zieh ein Bild und lege es hier ab.</p>
                    { chosenImageError && <p className="small text-danger my-2 fw-bold text-center">{chosenImageError}</p> }
                </div>
            </div>
}

import { FormikProps, FormikValues } from "formik"
import { Binding } from "react-api-data"
import { HTMLFieldCheckChoiceObject } from "../Fields"
import ButtonSpin from "../UI/Buttons/ButtonSpin"


export type FormikDataComponentProps<T extends FormikValues> = {
    initial: T,
    onSubmit?: (values: T) => boolean | Promise<Binding<string, any>>,
    onSubmitText?: string,
    onCancel?: React.MouseEventHandler<HTMLButtonElement>,
    onCancelText?: string
}

type FormikSubmitCancelProps<T extends FormikValues> = {
    formik: FormikProps<T>,
    onCancelText?: string, 
    onSubmitText?: string,
    hideCancel?: boolean,
    hideSubmit?: boolean
}

export function FormikSubmitCancel<T extends FormikValues>(props: FormikSubmitCancelProps<T>): JSX.Element {
    return  <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "300px"}}>
                <div className="d-inline-flex justify-content-between" style={{gap: "1rem"}}>
                    {!props.hideCancel && <ButtonSpin variant="secondary" skin="outline" type="reset" disabled={props.formik.isSubmitting} spinning={false}>{props.onCancelText || "Abbrechen"}</ButtonSpin>}
                    {!props.hideSubmit && <ButtonSpin variant="success" type="submit" spinning={props.formik.isSubmitting} disabled={!props.formik.isValid}>{props.onSubmitText || "Speichern"}</ButtonSpin>}
                </div> 
            </div>
            
}

export type EntityExport = {
    asDictionaryCollection: Record<string, any>[],
    asSingleValueCollection: any[],
    asMultipleValueCollection: any[][],
    asKeyValueCollection: any[],
    asHTMLFieldCheckChoices: HTMLFieldCheckChoiceObject[]
}

export function entityExportFromDictionary<D extends Record<string,any>, K extends Extract<keyof D, string> = Extract<keyof D, string>>(dict: D[], valueKey: K, idKey: K): EntityExport {
    const singleValueCollection: any[] =  []
    dict.forEach(value => {
        if (value[valueKey]) singleValueCollection.push(value[valueKey])
    })

    const multipleValueCollection: any[][] = []
    dict.forEach(value => multipleValueCollection.push(Object.values(value)) )

    const keyValueCollection: any[] = []
    dict.forEach(value => {if (value[idKey]) keyValueCollection.push(value[idKey])})

    const HTMLFieldChoices: HTMLFieldCheckChoiceObject[] = []
    dict.forEach(value => HTMLFieldChoices.push({ label: value[valueKey], value: value[idKey] }))

    return {
        asDictionaryCollection: dict,
        asSingleValueCollection: singleValueCollection,
        asMultipleValueCollection: multipleValueCollection,
        asKeyValueCollection: keyValueCollection,
        asHTMLFieldCheckChoices: HTMLFieldChoices
    }
}
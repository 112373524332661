import classNames from "classnames";
import { ErrorMessage, Field, FormikProps, FormikValues, getIn } from "formik";
import React from "react";

export interface HTMLFieldCheckChoiceObject {
    value: string | boolean | number,
    label: string | number
}
export type HTMLFieldCheckChoicesArray = string[];
export type HTMLFieldCheckChoicesObject = HTMLFieldCheckChoiceObject[];

export type HTMLFieldInput = string | number;
export type HTMLFieldOptionalInput = HTMLFieldInput | undefined;
export type HTMLFieldSingleChoice = string | number;
export type HTMLFieldMultipleChoice = string[] | number[];

export function FieldInput<T extends FormikValues, K extends Extract<keyof FormikValues, string>> (props: {name: K, label: string, placeholder?: string, formik: FormikProps<T>, type?: string, noLabel?: boolean, noStyle?: boolean}) : JSX.Element {

    const cx = classNames({
        'form-control': true,
        'mb-3': !props.noStyle,
        'is-invalid': getIn(props.formik.errors, props.name) && getIn(props.formik.touched, props.name),
        'is-valid': !(getIn(props.formik.errors, props.name) && getIn(props.formik.touched, props.name))
    })
    
    var styles = {}
    if (props.type === "date") styles = {...styles, height: "44px"};

    return(<>
        {!props.noLabel && <label htmlFor={props.name} id={props.name + "-label"} className="form-label">{props.label}</label>}
        <div>
            <Field name={props.name} type={props.type === undefined ? 'text' : props.type} className={cx} placeholder={props.placeholder} aria-describedby={props.name + "-label"} style={styles} />
            <ErrorMessage name={props.name} render={msg => <><div className='invalid-feedback mb-2'>{msg}</div></>} />
        </div>
    </>);
}

export function FloatingFieldInput<T extends FormikValues, K extends Extract<keyof FormikValues, string>>(props:{name: K, label: string, placeholder?: string, formik: FormikProps<T>, type?: string, noStyle?: boolean}): JSX.Element {

    const isInvalid = getIn(props.formik.errors, props.name) && getIn(props.formik.touched, props.name)
    const isValid = !getIn(props.formik.errors, props.name) && getIn(props.formik.touched, props.name)

    const input = classNames({
        'form-control': true,
        'is-invalid': isInvalid,
        'is-valid': isValid
    })

    const inputGroup = classNames({
        'input-group': true,
        'flex-column': true,
        'mb-3': !props.noStyle
    })

    const floatGroup = classNames({
        "form-floating": true,
        "is-invalid": isInvalid,
        "is-valid": isValid
    })
    
    var styles = {}
    if (props.type === "date") styles = {...styles, height: "44px"};

    return  <div className={inputGroup}>
                <div className={floatGroup}>
                    <Field name={props.name} type={props.type || 'text'} className={input} placeholder={props.placeholder} aria-describedby={props.name + "-label"} style={styles} />
                    <label htmlFor={props.name} id={props.name + "-label"} className="form-label">{props.label}</label>
                </div>
                <ErrorMessage name={props.name} render={msg => <><div className='invalid-feedback px-3 pt-1'>{msg}</div></>} />
            </div>

}

export function FieldCheck<T extends FormikValues, K extends Extract<keyof T, string>>(props: {name: K, label: string, choices: HTMLFieldCheckChoicesArray | HTMLFieldCheckChoicesObject, valueKey?: string, labelKey?:string, multiple?: boolean, disabled?: boolean | Array<boolean>, renderAsButtonGroup?: boolean, noLabel?: boolean, noStyle?: boolean }) : JSX.Element {
    const disabled: boolean[] = [];
    if (props.disabled === undefined) {
        props.choices.forEach( () => {
            disabled.push(false);
        });
    } else if (!Array.isArray(props.disabled)) {
        const tmp = props.disabled;
        props.choices.forEach( () => {
            disabled.push(tmp);
        });
    } else {
        const tmp = props.disabled;
        props.choices.forEach( (value, index) => {
            disabled.push(tmp.slice(index, index + 1)[0]);
        })
    }
    const items : JSX.Element[] = [];

    if ( isHTMLFieldCheckChoicesObject(props.choices) ) {
        if (props.renderAsButtonGroup) {
            props.choices.forEach((value, index) =>{
                const styles = disabled[index] === true ? {color: "rgba(0,0,0,0.3)"} : undefined;

                items.push(
                <React.Fragment key={props.name + "-" + index}>
                    <Field type={(props.multiple === undefined || props.multiple === false) ? "radio" : "checkbox"} name={props.name} value={value.value === null ? undefined : value.value} id={props.name + "-" + index} className="btn-check" disabled={disabled[index]} />
                    {<label htmlFor={props.name + "-" + index} className="btn btn-outline-primary" style={styles}>{value.label}</label>}
                </React.Fragment>
                );
            })
        } else {
            props.choices.forEach((value, index) => {
                
                const styles = disabled[index] === true ? {color: "rgba(0,0,0,0.3)"} : undefined;

                items.push(
                <div className="form-check" key={props.name + "-" + index}>
                    {<label htmlFor={props.name + "-" + index} className="form-check-label" style={styles}>{value.label}</label>}
                    <Field type={(props.multiple === undefined || props.multiple === false) ? "radio" : "checkbox"} name={props.name} value={value.value === null ? undefined : value.value} id={props.name + "-" + index} className="form-check-input" disabled={disabled[index]} checked={true} />
                </div>)
                ;
            })
        }
    } else {
        if (props.renderAsButtonGroup) {
            props.choices.forEach((value, index) => {
                items.push(
                <React.Fragment key={props.name + "-" + index}>
                    <Field type={(props.multiple === undefined || props.multiple === false) ? "radio" : "checkbox"} name={props.name} value={value === null ? undefined : value} id={props.name + "-" + index} className="btn-check" disabled={disabled[index]} />
                    {<label htmlFor={props.name + "-" + index} className="btn btn-outline-primary">{value}</label>}
                </React.Fragment>
                );
            })
        } else {
            props.choices.forEach((value, index) => {
                items.push(<div className="form-check" key={props.name + "-" + index}>
                    {<label htmlFor={props.name + "-" + index} className="form-check-label">{value}</label>}
                    <Field type={(props.multiple === undefined || props.multiple === false) ? "radio" : "checkbox"} name={props.name} value={value === null ? undefined : value} id={props.name + "-" + index} className="form-check-input" disabled={disabled[index]} />
                </div>);
            })
        }
    }

    const cx = classNames({
        "btn-group": props.renderAsButtonGroup,
        "mb-3": !props.noStyle,
        "d-block": props.renderAsButtonGroup
    })

    return(<>
        {!props.noLabel && <label id={props.name + "-label"} htmlFor={props.name}>{props.label}</label>}
        <div role="group" aria-labelledby={props.name + "-label"} className={cx}>
            {items}
            <ErrorMessage name={props.name} render={msg => <><div className='invalid-feedback mb-4'>{msg}</div></>} />
        </div>
    </>);
}

export function FieldColumn(props: {children: React.ReactNode}) : JSX.Element {
    var columns = "col-md-6";
    if (props.children !== undefined && props.children !== null) {
        // return(<>{props.children}</>);
        
        let children = React.Children.toArray(props.children);

        if (children.length === 1) {columns = "col"}
        else if (children.length === 3) {columns = "col-md-4"}

        const items : JSX.Element[] = [];

        children.forEach((value, index) => {
            items.push(
            <div className={columns} key={index}>
                 {value}
            </div>);
        })

        return(
            <div className="row g-2">
                {items}
            </div>
        );
    }
    return(<></>);
}

export function FieldSpacer() : JSX.Element{ 
    return(<hr style={{height: "5px", background: "transparent"}} />)
}

/*** HELPER FUNCTIONS ***/

function isHTMLFieldCheckChoicesObject(choices: HTMLFieldCheckChoicesArray | HTMLFieldCheckChoicesObject): choices is HTMLFieldCheckChoicesObject {
    if (choices.length === 0) return(true);
    return(!(typeof choices[0] === "string"));
}
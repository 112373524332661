import usePageTitle from "../../../../hooks/usePageTitle";
import {UserGroup, UserPermission} from "../../../../modules/Auth";
import usePermissionBag from "../../../../hooks/usePermissionBag";
import React, {useCallback, useState} from "react";
import * as HTTPErrorPage from "../../../../components/HTTPErrorPage";
import {NotRegisteredAlert, NotVerifiedAlert} from "../../../../components/SpecificAlerts";
import {MessageDisplay, MessageState} from "../../../../components/MessageState";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import {useActions, useApiData} from "react-api-data";
import {EventEndpoint} from "../../../../store/APIStore";
import {
    eventDateCommentRequest,
    EventDateDto,
    eventDateEditRequest,
    eventDateNewRequest, EventDateRegisterDto,
    eventDateRegisterRequest, eventDelete, eventImageCopy,
    eventImageSave,
    UserNameDto, UserNameEmailDto
} from "../../../../modules/API/Services/EventService";
import ApiCycle from "../../../../modules/API/ApiCycle";
import * as Icons from "../../../../components/Icons";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import BSModal from "../../../../components/UI/Generic/BSModal";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import InputControlled, {CheckboxControlled} from "../../../../components/Fields/InputControlled";
import {
    getDateStringFromDateAndTime,
    getDefaultDate,
    isDayGoneBy,
    PageDto,
    quillModules,
    str_to_datestr, str_to_monthstr
} from "../../../../modules/API/Services/Misc";
import Pagination from "../../../../components/UI/Generic/Pagination";
import {Link} from "react-router-dom";
import {ProfileImage} from "../NetworkController/Profile";
import {AxiosResponse} from "axios";
import {bootstrapQueries, larger, smaller, useBreakpoint} from "../../../../context/Breakpoint";

export default function EventController(): JSX.Element {
    usePageTitle("Events")

    const permission = usePermissionBag()
    const isForbidden = permission.isGroup(UserGroup.None, true)
    const isNotR = permission.isGroup(UserGroup.NotRegistered, true)
    const isNotV = permission.isGroup(UserGroup.NotVerified, true)

    return isForbidden ? HTTPErrorPage.Unauthenticated() : isNotR ?
        <NotRegisteredAlert/> : isNotV ? <NotVerifiedAlert/> :
            <>
                <Events/>
            </>
}

const eventDateNewObject: EventDateDto = {
    id: -1,
    name: '',
    description: '',
    date: getDefaultDate(),
    datePreview: false,
    hour: '20',
    minute: '00',
    duration: 60,
    capacityLimit: 0,
    adult: false,
    address: "",
    dateRegisterChange: getDefaultDate(),
    datePublication: getDefaultDate(),
    registerCount: 0
};

function eventDateShow(d: EventDateDto) {
    return d.datePreview ? str_to_monthstr(d.date) : getDateStringFromDateAndTime(d, true);
}

function Events() {
    const [page, setPage] = useState<number>(1);
    const [eventDateNew, setEventDateNew] = useState<EventDateDto>({...eventDateNewObject});
    const [eventDate, setEventDate] = useState<EventDateDto>();
    const actions = useActions();

    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState<MessageState>();
    const [eventDateEdit, setEventDateEdit] = useState(false);
    const [eventDateCopy, setEventDateCopy] = useState<number>();
    const [eventEditImage, setEventEditImage] = useState<File>();
    const [showRecent, setShowRecent] = useState(false);

    const eventList = useApiData<PageDto<EventDateDto>, string>(EventEndpoint.GET.eventDates_upcoming, {page: page});

    const permissions = usePermissionBag();

    const handlePagination = useCallback((p: number) => {
        setPage(p);
    }, [setPage]);

    const stopShow = useCallback(() => {
        setEventDate(undefined);
        setEventDateEdit(false);
    }, [setEventDate]);

    const startNewEventDate = useCallback(() => {
        setEventDateEdit(true);
        setEventDate(eventDateNew);
    }, [eventDateNew]);

    //save edited/new event
    const handleSaveFinish = useCallback((newEvent: boolean) => {
        setEventEditImage(undefined);
        setEventDateEdit(false);
        setMessage({type: "success", message: "Event gespeichert!"});

        if (newEvent) {
            setEventDate(undefined);
            eventList.invalidateCache();
        }
    }, [eventList]);
    const handleSaveImage = useCallback((id: number, newEvent: boolean, copyEvent?: number) => {
        if (eventEditImage) {
            eventImageSave(id, eventEditImage).then(() => {
                actions.invalidateCache(EventEndpoint.GET.event_image, {id: id});
                handleSaveFinish(newEvent);
            }).catch((e) => {
                setMessage({type: "danger", message: AxiosErrorMessageGetter(e)})
            }).finally(() => {
                setWaiting(false);
            });
        } else if (copyEvent !== undefined) {
            eventImageCopy(id, copyEvent).then(() => {
                handleSaveFinish(true);
            }).catch((e) => {
                setMessage({type: "danger", message: AxiosErrorMessageGetter(e)})
            }).finally(() => {
                setWaiting(false);
            });
        } else {
            handleSaveFinish(newEvent);
        }
    }, [eventEditImage, handleSaveFinish, actions]);
    const saveEvent = useCallback(() => {
        if (waiting) return;
        if (!eventDate) return;

        setWaiting(true);
        //new date
        if (eventDate.id < 0) {
            eventDateNewRequest(eventDate).then((response: AxiosResponse<number>) => {
                setMessage({type: "success", message: "Event Date gespeichert!"})
                setWaiting(false);

                handleSaveImage(response.data, true, eventDateCopy);
                setEventDateCopy(undefined);
                setEventEditImage(undefined);
                setEventDateNew({...eventDateNewObject});
            }).catch((e) => {
                setWaiting(false);
                setMessage({type: "danger", message: AxiosErrorMessageGetter(e)})
            });
        } else {
            //edit date
            eventDateEditRequest(eventDate).then(() => {
                setMessage({type: "success", message: "Event Date gespeichert!"})
                setWaiting(false);

                handleSaveImage(eventDate.id, false);
            }).catch((e) => {
                setWaiting(false);
                setMessage({type: "danger", message: AxiosErrorMessageGetter(e)})
            });
        }
    }, [eventDate, handleSaveImage, waiting, eventDateCopy]);
    const handleDeleteEvent = useCallback(() => {
        if(!eventDate) return;

        eventDelete(eventDate.id).then(() => {
            setMessage({type: "success", message: "Event Date gespeichert!"})
            setWaiting(false);

            eventList.invalidateCache();

            setEventDate(undefined);
            setEventEditImage(undefined);
            setEventDateEdit(false);
        }).catch((e) => {
            setWaiting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)})
        });
    }, [eventDate, eventList])

    const startEdit = useCallback(() => {
        setEventDateEdit(true);
    }, []);
    const stopEdit = useCallback(() => {
        setEventDateEdit(false);
    }, []);

    const clickEvent = useCallback((e: EventDateDto) => {
        setEventDate(e);
        setEventDateEdit(false);
    }, []);

    const copyEvent = useCallback((e: EventDateDto) => {
        const eventDate = {...e};
        eventDate.id = -2;
        setEventDateCopy(e.id);
        setEventDate(eventDate);
        setEventDateEdit(true);
    }, []);

    const hasEventAdminPermission = permissions.hasPermission(UserPermission.EditCreateEvent).all;
    return <>
        <BSModal title='' show={eventDate !== undefined} setShow={stopShow} userCanCancel>
            {eventDate && <>
                {!eventDateEdit && <EventModalContent eventDate={eventDate} copyEvent={copyEvent}
                                                      startEdit={hasEventAdminPermission ? startEdit : undefined}
                                                      stopShow={stopShow}
                                                      updateSelf={() => setEventDate({...eventDate})}/>}
                {eventDateEdit &&
                    <EventEditModalContent eventDate={eventDate} message={message} setFile={setEventEditImage}
                                           stopEdit={stopEdit} stopShow={stopShow} saveEvent={saveEvent}
                                           waiting={waiting} deleteEvent={handleDeleteEvent}/>}
            </>}
        </BSModal>

        <ApiCycle data={eventList} extractor={(pageDto: PageDto<EventDateDto>) => <div>
            <MessageDisplay message={message}/>

            <div className="row mx-0">
                {hasEventAdminPermission && <div className="col-12 p-2">
                    <div className="grid-card grid-card-hover pointer p-0 overflow-hidden" onClick={() => {
                        setShowRecent(!showRecent)
                    }}>
                        <div className="px-3 py-2">
                            <div className="fs-4 text-center">Zeige alte Events an {showRecent ?
                                <Icons.ChevronUp size={20}/> : <Icons.ChevronDown size={20}/>}</div>
                        </div>
                    </div>
                    {showRecent && <EventsRecent clickEvent={clickEvent} setCopy={() => {
                    }}/>}

                    <div className="grid-card grid-card-hover pointer p-0 overflow-hidden mt-3"
                         onClick={startNewEventDate}>
                        <div className="px-3 py-2">
                            <div className="fs-3 text-center">+</div>
                        </div>
                    </div>

                </div>}
                <EventListDisplay pageDto={pageDto} clickEvent={clickEvent}/>

                {pageDto.total === 0 &&
                    <h4>Aktuell sind keine Events sichtbar. Es befinden sich bereits Events in der Planung wofür die
                        Termine noch nicht fix feststehen. Bitte schau in ein paar Tagen wieder vorbei.</h4>}
            </div>
            <Pagination page={page} total={pageDto.total} pageSize={pageDto.pageSize} waiting={false}
                        handlePagination={handlePagination}/>
        </div>}/>
    </>;
}

function EventsRecent(props: { setCopy: (event: EventDateDto) => void, clickEvent: (event: EventDateDto) => void }) {
    const [page, setPage] = useState<number>(1);

    const handlePagination = useCallback((p: number) => {
        setPage(p);
    }, [setPage]);

    const eventList = useApiData<PageDto<EventDateDto>, string>(EventEndpoint.GET.eventDates_recent, {page: page});


    return <ApiCycle data={eventList} extractor={(pageDto: PageDto<EventDateDto>) => <div>
        <div className="d-flex justify-content-center">
            <div className="d-flex">
                <Pagination page={page} total={pageDto.total} pageSize={pageDto.pageSize} waiting={false}
                            handlePagination={handlePagination}/>
            </div>
        </div>
        <EventListDisplay pageDto={pageDto} clickEvent={props.clickEvent}/>
    </div>}/>
}

function EventListDisplay(props: {pageDto: PageDto<EventDateDto>, clickEvent: (event: EventDateDto) => void}) {
    //col-12 col-sm-6 col-xl-4
    const bp: typeof bootstrapQueries = useBreakpoint()
    let count = 1;
    if(!smaller("sm", bp, false)) {
        count = larger("xl", bp, true) ? 3 : 2;
    }

    const ar = new Array(count).fill(0);
    return <div className={"row mx-0 row-cols-"+count}>
        {/*loop of columns: */ ar.map((value, nrIndex: number) => <div key={nrIndex} className="col">
            {/*loop of items: */props.pageDto.content.map((e: EventDateDto, index: number) => index%count!==nrIndex ? <div key={e.id}></div> : <div key={e.id} className="p-2">
                <div className="grid-card grid-card-hover pointer p-0 overflow-hidden"
                     onClick={() => props.clickEvent(e)}>
                    <EventImage eventID={e.id} className="w-100"/>
                    <div className="px-3 py-2">
                        <div className="html-container fs-4" dangerouslySetInnerHTML={{__html: e.name}}/>
                        <div>{eventDateShow(e)}</div>
                        {e.datePreview ? <></> : (e.selfRegistered ? <div className='text-success'>Angemeldet</div> :
                            <div>Anmeldung bis {str_to_datestr(e.dateRegisterChange)}</div>)}
                        {e.registerCount !== null &&
                            <div>{e.registerCount}{e.capacityLimit > 0 && "/" + e.capacityLimit} Angemeldet</div>}
                        {new Date() < new Date(e.datePublication) &&
                            <div>Öffentlich sichtbar ab {str_to_datestr(e.datePublication)}</div>}
                    </div>
                </div>
            </div>)}
        </div>)}
        {props.pageDto.total === 0 && <h4>Keine alten Events gefunden.</h4>}
    </div>;
}

function EventModalImage(props: { eventDate: EventDateDto, children: JSX.Element }): JSX.Element {
    return <div className="position-relative mb-2"
                style={{marginLeft: '-16px', marginRight: '-16px', marginTop: '-65px'}}>
        <EventImage eventID={props.eventDate.id}
                    style={{borderTopLeftRadius: '0.6rem', borderTopRightRadius: '0.6rem'}}/>
        <div className="position-absolute top-0 end-0 pe-2">
            {props.children}
        </div>
    </div>;
}

function EventModalRegisteredList(props: { eventDate: EventDateDto }): JSX.Element {
    const registered = useApiData<UserNameEmailDto[], string>(EventEndpoint.GET.eventDates_registered, {id: props.eventDate.id});
    return <ApiCycle data={registered} extractor={(list: UserNameEmailDto[]) => <>
        {list.length > 0 && <div className="mb-3">
            <table className="table table-striped">
                <tbody>
                {list.map((user: UserNameEmailDto) => <tr key={user.id}>
                    <td><Link className="d-inline-block" to={'/network/' + user.id + '/profile'}
                              title={user.firstName + " " + user.lastName}><ProfileImage editable={false} id={user.id}
                                                                                         size={50}/></Link></td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                </tr>)}
                </tbody>
            </table>
        </div>}
    </>}/>;
}

function EventModalContent(props: { eventDate: EventDateDto, stopShow: () => void, updateSelf: () => void, startEdit?: () => void, copyEvent: (e: EventDateDto) => void }) {
    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState<MessageState>();
    const [showList, setShowList] = useState(false);
    const [comment, setComment] = useState<EventDateRegisterDto>({comment: ''});
    const permissions = usePermissionBag();

    const hasEventsPermission = permissions.hasPermission(UserPermission.EditCreateEvent).all;
    const hasViewRegisteredPermission = permissions.hasPermission(UserPermission.ViewAllAttendeesData).all;
    const d = props.eventDate;
    const friendList = useApiData<UserNameDto[], string>(EventEndpoint.GET.eventDates_friends, {id: d.id});

    const handleRegister = useCallback(() => {
        if (waiting) return;
        if (props.eventDate.selfRegistered === undefined) return;

        setWaiting(true);
        eventDateRegisterRequest(props.eventDate.id, !props.eventDate.selfRegistered).then(() => {
            setWaiting(false);
            setMessage({
                type: "success",
                message: "Erfolgreich " + (props.eventDate.selfRegistered ? 'ab' : 'an') + "gemeldet!"
            });

            if (!props.eventDate.selfRegistered) ++props.eventDate.registerCount;
            else --props.eventDate.registerCount;

            props.eventDate.selfRegistered = !props.eventDate.selfRegistered;
            props.updateSelf();
        }).catch((error) => {
            setWaiting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
        });
    }, [waiting, props]);

    const handleComment = useCallback(() => {
        if (waiting) return;
        if (comment.comment === '') return;

        setWaiting(true);
        eventDateCommentRequest(props.eventDate.id, comment).then(() => {
            setWaiting(false);
            setMessage({
                type: "success",
                message: "Kommentar gespeichert!"
            });

            //update comment list
            if (!props.eventDate.selfComments) props.eventDate.selfComments = comment.comment;
            else props.eventDate.selfComments += ";" + comment.comment;

            setComment({comment: ''});
        }).catch((error) => {
            setWaiting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
        });
    }, [waiting, comment, props]);

    return <>
        <EventModalImage eventDate={props.eventDate}><>
            {props.startEdit &&
                <div className="d-inline-block pointer me-3 text-primary fs-2" onClick={props.startEdit}><Icons.Pencil
                    size={20}/></div>}
            <div className="d-inline-block pointer fs-2" onClick={props.stopShow}>&times;</div>
        </>
        </EventModalImage>

        <div className="html-container fs-4" dangerouslySetInnerHTML={{__html: d.name}}/>
        <div className="fs-5">
            <div>{eventDateShow(d)}</div>
            <div>{d.address}</div>
        </div>
        <hr className="mt-2"/>

        {/*registered friend list*/}
        <ApiCycle data={friendList} extractor={(friends: UserNameDto[]) => <>
            {friends.length > 0 && <div className="mb-1">
                <div>Angemeldete Kontakte</div>
                {friends.map((user: UserNameDto) => <Link className="d-inline-block" key={user.id}
                                                          to={'/network/' + user.id + '/profile'}><ProfileImage
                    id={user.id} size={50}/></Link>)}
            </div>}
        </>}/>

        {/*register/deregister*/}
        <MessageDisplay message={message}/>
        {d.datePreview ? <div>Anmeldung ist möglich, wenn das Datum bekannt ist.</div> : <>
            {d.registerCount && <div>{d.registerCount}{d.capacityLimit > 0 && "/" + d.capacityLimit} Angemeldet</div>}
            <div className="text-end">
                <div className="d-inline-block me-3">An-/Abmeldefrist: {str_to_datestr(d.dateRegisterChange)}</div>
                <ButtonSpin disabled={isDayGoneBy(new Date(d.dateRegisterChange))} classes="d-inline-block" type="button"
                            variant={d.selfRegistered ? 'danger' : 'success'} skin="outline" spinning={waiting}
                            onClick={handleRegister}>{d.selfRegistered ? 'Abmelden' : 'Anmelden'}</ButtonSpin>
            </div>
        </>}

        {/*comments*/}
        <div className="mt-3">
            <p className="mb-0">Deine Kommentare</p>
            <div className="mb-2">
                {d.selfComments && d.selfComments.split(";").map((comment: string, i: number) => <div key={i}>
                    <b>{i+1}.</b> {comment}
                </div>)}
            </div>

            <InputControlled maxlength={500} value={comment.comment} className="form-control" onChange={(v: string) => comment.comment=v} type="text" disabled={waiting} />
            <ButtonSpin disabled={isDayGoneBy(new Date(d.dateRegisterChange))} classes="d-inline-block float-end" type="button"
                        variant="dark" skin="outline" spinning={waiting}
                        onClick={handleComment}>Kommentar abschicken</ButtonSpin>
        </div>

        {/*copy event*/}
        {hasEventsPermission && <div className="mt-3">
            <button className="btn btn-outline-primary" onClick={() => props.copyEvent(props.eventDate)}>Kopiere Event
            </button>
        </div>}

        {/*registered list*/}
        {hasViewRegisteredPermission && <>
            <div>Angemeldete Mitglieder <button className="btn btn-outline-primary"
                                                onClick={() => setShowList(!showList)}>{showList ?
                <Icons.ChevronUp size={20}/> : <Icons.ChevronDown size={20}/>}</button></div>
            {showList && <>
                <EventModalRegisteredList eventDate={d}/>
            </>}
        </>}

        <hr/>
        {d.adult && <div className="text-orange">Nur für Erwachsene (18 Jahre)</div>}
        <div className="html-container" dangerouslySetInnerHTML={{__html: d.description}}/>

        <div className="text-end">
            <div>Veröffentlicht: {str_to_datestr(d.datePublication)}</div>
        </div>
    </>;
}

function EventEditModalContent(props: { eventDate: EventDateDto, message?: MessageState, stopShow: () => void, setFile: (f: File) => void, saveEvent: () => void, waiting: boolean, stopEdit?: () => void, deleteEvent: () => void }) {
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const d = props.eventDate;

    return <>
        {props.eventDate.id >= 0 && <EventModalImage eventDate={props.eventDate}><>
            <div className="d-inline-block pointer me-3 text-primary fs-2" onClick={props.stopEdit}><Icons.Pencil
                size={20}/></div>
            <div className="d-inline-block pointer fs-2" onClick={props.stopShow}>&times;</div>
        </>
        </EventModalImage>}

        <MessageDisplay message={props.message}/>

        <label>Event Bild{props.eventDate.id === -2 &&
            <span> (Falls nicht ausgefüllt, wird das Bild vom kopierten Event übernommen)</span>}</label>
        <input className="form-control mb-3" type="file" accept="image/jpeg, image/png" onChange={(e) => {
            if (!e.target.files) return;
            const selectedImage: File | null = e.target.files.item(0);
            if (selectedImage === null) return;
            props.setFile(selectedImage);
        }}/>

        <label>Event Titel</label>
        <ReactQuill className="mb-3" value={d.name} onChange={(value: string) => d.name = value}
                    modules={quillModules}/>

        <label>Event Beschreibung</label>
        <ReactQuill className="mb-3" value={d.description} onChange={(value: string) => d.description = value}
                    modules={quillModules}/>

        <div className="row">
            <div className="col-12 col-sm-6 mb-3">
                <label>Datum (nur Monat: <CheckboxControlled value={!!d.datePreview}
                                                             onChange={() => d.datePreview = !d.datePreview}/>)</label>
                <InputControlled className="form-control" value={d.date} type="date"
                                 onChange={(e: string) => d.date = e}/>
            </div>
            <div className="col-12 col-sm-6">
                <label>Uhrzeit (hh:mm)</label>
                <div className="row">
                    <div className="col-6 mb-3">
                        <InputControlled className="form-control" value={d.hour} type="number"
                                         onChange={(e: string) => d.hour = e}/>
                    </div>
                    <div className="col-6 mb-3">
                        <InputControlled className="form-control" value={d.minute} type="number"
                                         onChange={(e: string) => d.minute = e}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-sm-6 mb-3">
                <label>An-/Abmeldung bis</label>
                <InputControlled className="form-control" value={d.dateRegisterChange} type="date"
                                 onChange={(e: string) => d.dateRegisterChange = e}/>
            </div>
            <div className="col-12 col-sm-6 mb-3">
                <label>Sichtbar ab Datum</label>
                <InputControlled className="form-control" value={d.datePublication} type="date"
                                 onChange={(e: string) => d.datePublication = e}/>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-sm-6 mb-3">
                <label>Dauer (Minuten)</label>
                <InputControlled className="form-control" value={'' + d.duration} type="number"
                                 onChange={(e: string) => d.duration = (+e)}/>
            </div>
            <div className="col-12 col-sm-6 mb-3">
                <label>Kapazität (0 = unlimitiert)</label>
                <InputControlled className="form-control" value={'' + d.capacityLimit} type="number"
                                 onChange={(e: string) => d.capacityLimit = (+e)}/>
            </div>
        </div>
        <div className="mb-3">
            <label>Addresse</label>
            <InputControlled className="form-control" value={d.address} type="text"
                             onChange={(e: string) => d.address = e}/>
        </div>
        <div className="mb-3">
            <label>Event ab 18?</label>
            <input className="form-check" value={'' + d.adult} type="checkbox" onChange={() => d.adult = !d.adult}/>
        </div>

        <div>
            <ButtonSpin classes="float-end" type="button" variant='success' spinning={props.waiting}
                        onClick={props.saveEvent}>Speichern</ButtonSpin>
        </div>
        <div>
            {!deleteConfirm && <ButtonSpin classes="float-end" type="button" variant='danger' spinning={props.waiting}
                         onClick={() => setDeleteConfirm(true)}>Event Löschen (1/2)</ButtonSpin>}
            {deleteConfirm && <>
                <ButtonSpin classes="float-start" type="button" variant='danger' spinning={props.waiting}
                            onClick={props.deleteEvent}>Event Löschen (2/2)</ButtonSpin>
                <ButtonSpin classes="float-end" type="button" variant='secondary' spinning={props.waiting}
                            onClick={() => setDeleteConfirm(false)}>Nicht löschen</ButtonSpin>
            </>}
        </div>
    </>;
}

function EventImage(props: { eventID: number, className?: string, style?: any }) {
    const request = useApiData<Blob>(EventEndpoint.GET.event_image, {id: props.eventID});
    return <ApiCycle data={request} extractor={(data: Blob) => <img className={props.className}
                                                                    style={{maxWidth: '100%', ...props.style}}
                                                                    src={URL.createObjectURL(data)} alt='Event'/>}/>;
}
import {ProfileDto, ProfileJob, ProfileJobSort, ProfileTypes} from "../../../../modules/API/APIInterfaces";
import {useCallback, useEffect, useState} from "react";
import {
    networkDelete,
    networkEdit,
    networkEditDescription,
    networkGetInstitutionList, networkGetNamesList,
    networkVisible
} from "../../../../modules/API/Services/NetworkService";
import Profile, {Job, ProfileDescription, sortJobsStudiesEhrenamt} from "./Profile";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import * as Icons from "../../../../components/Icons";
import {AnimatePresence, motion} from "framer-motion";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import InputControlled, {TextareaControlled} from "../../../../components/Fields/InputControlled";
import usePageTitle from "../../../../hooks/usePageTitle";
import {Link, Route, Switch} from "react-router-dom";
import {searchCheckInside, searchFoundsToElement, searchFromTo} from "./Network";
import BSModal from "../../../../components/UI/Generic/BSModal";
import {MessageDisplay, MessageState} from "../../../../components/MessageState";
import { Alert } from "react-bootstrap";
import NavigationSegment from "../../../../components/UI/Generic/UINavigationSegment/NavigationSegment";
import { NavigationItemType } from "../../../../components/UI/Generic/UINavigationItem/UINavigationItem";

//eher grobe Kategorien, erweiterte Suche: such in nur Arbeit/Ausbildung/Ehrenamt, Kategorie dazu
const StudyCategories = [
    "Bau, Architektur, Vermessung",
    "Dienstleistung",
    "Elektro",
    "Gesundheit",
    "IT, Computer",
    "Kunst, Kultur, Gestaltung",
    "Landwirtschaft, Natur, Umwelt",
    "Medien",
    "Metall, Maschinenbau",
    "Naturwissenschaften",
    "Produktion, Fertigung",
    "Soziales, Pädagogik",
    "Technik, Technologiefelder",
    "Verkehr, Logistik",
    "Wirtschaft, Verwaltung",
];

const namesAutofill: string[] = [].sort();

//autofill (Stadt muss im Namen enthalten sein) bei Ausbildung sofort (Stadt/Institution ein bisl ausgefüllt), auch autofill bei Job (muss aber ein Wort eingegeben haben)
const institutionAutofill: string[] = [
    //Unis Wien (Liste der Wiener Linien bei Anmeldung zu Semesterticket)
    "Akademie der bildenden Künste Wien",
    "Fachhochschule Burgenland [FH]",
    "Fachhochschule St. Pölten [FH]",
    "Fachhochschule Wiener Neustadt [FH]",
    "FH Campus Wien [Fachhochschule]",
    "FH des BFI Wien [Fachhochschule]",
    "FH Technikum Wien [Fachhochschule]",
    "FHWien-Studiengänge der WKW [Fachhochschule]",
    "Hochschule für Agrar und Umweltpädagogik Wien",
    "IMC Fachhochschule Krems",
    "Kirchliche Pädagogische Hochschule Wien/Krems [PH]",
    "Medizinische Universität Wien (MedUni)",
    "Montanuniversität Leoben",
    "Musik und Kunst Privatuniversität der Stadt Wien (MUK)",
    "Pädagogische Hochschule Burgenland [PH]",
    "Pädagogische Hochschule Niederösterreich (PH NÖ)",
    "Pädagogische Hochschule Wien [PH]",
    "Sigmund Freud Privatuniversität (SFU) [Wien]",
    "Technische Universität Wien (TU)",
    "Universität für angewandte Kunst Wien (Die Angewandte)",
    "Universität für Bodenkultur Wien (BOKU)",
    "Universität für Musik und darstellende Kunst Wien (MDW)",
    "Universität Wien (Hauptuni, Alma Mater Rudolphina)",
    "Veterinärmedizinische Universität Wien (VetMed)",
    "Wirtschaftsuniversität Wien (WU)",
    "Wiener Sängerknaben (WSK)",

    //Unis Österreich
    "Alpen-Adria-Universität Klagenfurt",
    "Gustav Mahler Privatuniversität für Musik",
    "Danube Private University",
    "Karl Landsteiner Privatuniversität für Gesundheitswissenschaften",
    "New Design University",
    "Bertha von Suttner Privatuniversität",
    "Universität für Weiterbildung Krems",
    "Anton Bruckner Privatuniversität",
    "Johannes Kepler Universität Linz",
    "Katholische Privat-Universität Linz",
    "Universität für künstlerische und industrielle Gestaltung Linz",
    "Paracelsus Medizinische Privatuniversität",
    "Paris-Lodron-Universität Salzburg",
    "Privatuniversität Schloss Seeburg",
    "Universität Mozarteum Salzburg",
    "Karl-Franzens-Universität Graz",
    "Medizinische Universität Graz",
    "Technische Universität Graz",
    "Universität für Musik und darstellende Kunst Graz",
    "Leopold-Franzens-Universität Innsbruck",
    "Medizinische Universität Innsbruck",
    "Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik",
    "Central European University",
    "Jam Music Lab",
    "Modul University Vienna",
    "Webster Vienna Private University",

    //PHs Österreich
    "Private Pädagogische Hochschule Burgenland, Eisenstadt [PH]",
    "Pädagogische Hochschule Kärnten, Klagenfurt [PH]",
    "Pädagogische Hochschule Niederösterreich, Baden [PH]",
    "Pädagogische Hochschule Oberösterreich, Linz [PH]",
    "Pädagogische Hochschule Salzburg, Salzburg [PH]",
    "Pädagogische Hochschule Steiermark, Graz [PH]",
    "Pädagogische Hochschule Tirol, Innsbruck [PH]",
    "Pädagogische Hochschule Vorarlberg, Feldkirch [PH]",
    "Kirchliche Pädagogische Hochschule Graz [PH]",
    "Private Pädagogische Hochschule der Diözese Linz [PH]",
    "Kirchliche Pädagogische Hochschule – Edith Stein (Feldkirch, Innsbruck, Salzburg oder Stams) [PH]",

    //FHs Österreich
    "Fachhochschule Burgenland [FH]",
    "Fachhochschule Kärnten [FH]",
    "IMC Fachhochschule Krems [FH]",
    "Fachhochschule St. Pölten [FH]",
    "Ferdinand Porsche Fern-Fachhochschule [FH]",
    "Theresianische Militärakademie [FH]",
    "FH Oberösterreich [Fachhochschule]",
    "FH Gesundheitsberufe Oberösterreich [Fachhochschule]",
    "Fachhochschule Salzburg [FH]",
    "Campus 02 [FH]",
    "FH Joanneum [Fachhochschule]",
    "FH Kufstein [Fachhochschule]",
    "FH Gesundheit Tirol [Fachhochschule]",
    "Management Center Innsbruck [FH, Fachhochschule]",
    "Fachhochschule Vorarlberg [FH]",
    "Lauder Business School [FH, Fachhochschule]",

    //Schulen Wien
    "Abendgymnasium Wien",
    "AHS Contiweg",
    "AHS Heustadelgasse",
    "AHS WIEN WEST",
    "Akademisches Gymnasium (Wien)",
    "Albertus-Magnus-Schule Wien",
    "Amadeus International School",
    "Amerlinggymnasium",
    "Bernoulligymnasium",
    "Bertha-von-Suttner-Gymnasium (Wien)",
    "BG/BRG Billrothstraße 73, (GRG 19)",
    "BGRG 8 Albertgasse",
    "Bildungsanstalt für Elementarpädagogik Maria Regina",
    "Bildungsanstalt für Elementarpädagogik Wien 8",
    "Bildungsanstalt für Elementarpädagogik Wien 10",
    "Bildungszentrum Kenyongasse",
    "Billrothgymnasium",
    "BORG Hegelgasse 12",
    "BRG 14 Linzer Straße",
    "BRG 19 Krottenbachstraße",
    "BRG 22 AHS Theodor Kramer",
    "BRG Seestadt",
    "Bundesgymnasium Maroltingergasse",
    "Bundesgymnasium und Bundesrealgymnasium Ettenreichgasse",
    "Bundesgymnasium und Bundesrealgymnasium Franklinstraße 21",
    "Bundesgymnasium und Bundesrealgymnasium Geringergasse",
    "Bundesgymnasium und Bundesrealgymnasium Gottschalkgasse",
    "Bundesgymnasium und Bundesrealgymnasium Pichelmayergasse",
    "Bundesgymnasium und Bundesrealgymnasium Wien III",
    "Bundesgymnasium und Bundesrealgymnasium Zirkusgasse",
    "Bundesgymnasium Wien 8",
    "Bundesgymnasium Wien 18 Klostergasse",
    "Bundeshandelsakademie und Bundeshandelsschule Favoriten",
    "Bundeshandelsakademie und Bundeshandelsschule Geringergasse",
    "Bundeshandelsakademie und Bundeshandelsschule Wien 22",
    "Bundesrealgymnasium Lessinggasse",
    "Bundesrealgymnasium Marchettigasse",
    "Bundesrealgymnasium Schuhmeierplatz",
    "Bundesrealgymnasium und Bundesoberstufenrealgymnasium Wien 15",
    "Bundesrealgymnasium Waltergasse",
    "Bundesschulen Kalvarienberg",
    "Business Academy Maygasse (Schumpeter-Handelsakademie)",
    "Camillo Sitte Lehranstalt",
    "Caritas Ausbildungszentrum für Sozialberufe",
    "Diefenbach Gymnasium",
    "Döblinger Gymnasium",
    "Ella Lingens Gymnasium",
    "ERG Donaustadt",
    "Erich Fried Realgymnasium",
    "Evangelisches Gymnasium und Werkschulheim",
    "Gastgewerbefachschule Wien",
    "Goethe-Gymnasium Wien (GRG 14 Astgasse)",
    "GRG 6 Rahlgasse",
    "GRG 10 Laaerberg",
    "GRG 12 Erlgasse",
    "GRG 12 Rosasgasse",
    "GRG 15 Auf der Schmelz",
    "GRG 23 Alterlaa",
    "GRG 23 Draschestraße",
    "GRG7 Kandlgasse",
    "GRG21 Ödenburger Straße",
    "GWIKU 18 Haizingergasse",
    "Gymnasium am Augarten",
    "Gymnasium Fichtnergasse",
    "Gymnasium Franklinstraße 26",
    "Gymnasium Hagenmüllergasse",
    "Gymnasium Kundmanngasse",
    "Gymnasium Maria Regina",
    "Gymnasium Radetzkystraße",
    "Gymnasium Sacre Coeur Wien",
    "Gymnasium Stubenbastei",
    "Gymnasium und Oberstufenrealgymnasium St. Ursula",
    "Gymnasium Wasagasse",
    "Gymnasium Wenzgasse",
    "Handelsakademie und Handelsschule Sacré Coeur Wien",
    "Hernalser Gymnasium Geblergasse",
    "Hertha Firnberg Schulen für Wirtschaft und Tourismus",
    "HLMW9 Michelbeuern",
    "HLW10 Reumannplatz",
    "HLW19 Straßergasse",
    "Höhere Bundeslehr- und Forschungsanstalt für Gartenbau Schönbrunn",
    "Höhere Bundeslehr- und Versuchsanstalt für chemische Industrie",
    "Höhere Bundeslehranstalt für Wirtschaft und Tourismus Bergheidengasse",
    "Höhere Graphische Bundes-Lehr- und Versuchsanstalt",
    "Höhere Lehranstalt für Wirtschaft und Tourismus Wien 21",
    "Höhere Lehranstalt für wirtschaftliche Berufe",
    "Höhere Lehranstalt für wirtschaftliche Berufe Landstraße",
    "HTBLuVA Wien 5 Spengergasse",
    "HTL Donaustadt",
    "HTL Wien 3 Rennweg",
    "HTL Wien 10",
    "HTL Wien West",
    "International Business College Hetzendorf",
    "Islamisches Realgymnasium Wien",
    "Joseph Haydn-Realgymnasium",
    "Kollegium Kalksburg",
    "Lycée Français de Vienne",
    "Musikgymnasium Wien",
    "Neulandschule Grinzing",
    "Neulandschule Laaerberg",
    "Oberstufenrealgymnasium Landstraßer Hauptstraße",
    "Oberstufenrealgymnasium Rudolf Steiner Wien 13",
    "Oberstufenrealgymnasium Schützengasse",
    "Öffentliches Gymnasium der Stiftung Theresianische Akademie",
    "Parhamergymnasium",
    "Phoenix Realgymnasium",
    "Polgargymnasium",
    "Private Fachschule und Aufbaulehrgang für wirtschaftliche Berufe der Dominikanerinnen Wien",
    "Privates Gymnasium und wirtschaftskundliches Realgymnasium der Dominikanerinnen",
    "Privatgymnasium Avicenna",
    "Privatschule INFINUM",
    "Rainergymnasium",
    "Realgymnasium Schottenbastei",
    "Realgymnasium und Oberstufenrealgymnasium der Schulbrüder Marianum",
    "Realgymnasium und Wirtschaftskundliches Realgymnasium Feldgasse",
    "Schopenhauer Realgymnasium",
    "Schottengymnasium",
    "Schulen der Wiener Sängerknaben (WSK, ORG)",
    "Schulen des BFI Wien",
    "Schulverein De La Salle Strebersdorf",
    "Schulzentrum Friesgasse",
    "Schulzentrum Herbststraße für Mode und Kunst",
    "Schulzentrum Ungargasse",
    "Sigmund-Freud-Gymnasium",
    "Sperlgymnasium",
    "Technologisches Gewerbemuseum",
    "Vienna Business School Akademiestraße",
    "Vienna Business School Augarten",
    "Vienna Business School Floridsdorf",
    "Vienna Business School Hamerlingplatz",
    "Vienna Business School Schönborngasse",
    "w@lz Wiener Lernzentrum",
    "Wiedner Gymnasium",
    "Wiener Ballsportgymnasium",
    "WMS/RG/ORG antonkriegergasse (AKG)",
    "Zwi Perez Chajes Schule",

    //worlwide companies
    "Apple Inc.",
    "Microsoft Corp.",
    "Google (Alphabet Inc.)",
    "Amazon.com Inc.",
    "Tesla Inc.",
    "Facebook (Meta Platforms Inc.)",
    "NVIDIA Corp.",

    //austria companies
    "Rewe International AG",
    "Spar Österreich",
    "Strabag SE",
    "Voestalpine AG",
    "ÖBB (Österreichische Bundesbahnen-Holding AG)",
    "Signa Retail GmbH",
    "Trenkwalder Group AG",
    "Porsche Holding GmbH",
    "Swarovski",
    "AMS AG",
    "Heiru A. & R. Heinz GmbH",

    //sanitäter
    "Österreichisches Bundesheer",
    "Arbeiter-Samariter-Bund (ASB)",
    "Grünes Kreuz",
    "Johanniter-Unfall-Hilfe",
    "Malteser Hospitaldienst Austria",
    "Sozial Medizinischer Dienst (SMD)",
    "Österreichisches Rotes Kreuz (RK)",

    //Österreich NGO's: https://de.m.wikipedia.org/wiki/Liste_der_gr%C3%B6%C3%9Ften_NGOs_%C3%96sterreichs
    "Caritas Österreich",
    "SOS-Kinderdorf",
    "Ärzte ohne Grenzen",
    "Dreikönigsaktion",
    "Licht ins Dunkel",
    "Greenpeace",
    "St. Anna Kinderkrebsforschung",
    "Licht für die Welt Österreich",
    "Vier Pfoten",
    "Haus der Barmherzigkeit",
    "Missio - Päpstliche Missionswerk",
    "Paracelsus Medizinische Privatuniversität",
    "Rote Nasen Clowndoctors",
    "Umweltverband WWF",
    "CARE Österreich",
    "Diakonie - Brot für die Welt",
    "Nachbar in Not",
    "Amnesty International",
    "DEBRA  Austria",
    "World Vision",
    "MIVA - Austria",
    "UNICEF",
    "Jugend eine Welt",
    "CONCORDIA Sozialprojekte",
    "Volkshilfe Österreich",
    "Pro Juventute",
    "Plan International Österreich"
].sort();

export default function ProfileEdit(props: {user: ProfileDto, setUser: CallableFunction, userForeignEdit?: boolean}) : JSX.Element {
    usePageTitle("Netzwerk: Dein Profil")
    const [type, setType] = useState<ProfileTypes>("jobs");
    const [edit, setEdit] = useState<ProfileJob>();
    const [user, setUser] = useState<ProfileDto>(JSON.parse(JSON.stringify(props.user)));

    const handleDelete = () => {
        if(!edit || !user[type]) {
            return;
        }
        const index = user[type].indexOf(edit);
        if(index===-1) {
            return;
        }
        user[type].splice(index, 1);
        setUser({...user});
    };

    const handleSetUser = () => {
        const u = {...user};
        u.jobs.sort(ProfileJobSort);
        u.studies.sort(ProfileJobSort);
        u.ehrenamt.sort(ProfileJobSort);
        setUser(u);
    };

    const navItems: NavigationItemType[] = [{
        to: props.userForeignEdit === true ? "/network/" + props.user.id + "/profile" : "/network/profile",
        name: (props.userForeignEdit === true ? "Dein " : "") + "Profil bearbeiten",
        exact: true
    }, {
        to: props.userForeignEdit === true ? "/network/" + props.user.id + "/profile/preview" : "/network/profile/preview",
        name: "Vorschau"
    }]

    return(<>

        <JobEditModal user={user} setShow={() => setEdit(undefined)} updateUser={() => {
            const u = {...user};
            sortJobsStudiesEhrenamt(u);
            setUser(u);
        }} userId={user.id} study={edit} type={type} stopEdit={() => {setEdit(undefined)}} delete={handleDelete}/>

        <div className="m-md-4 m-sm-2 d-flex flex-column">

            <NavigationSegment items={navItems} />

            <Switch>
                <Route exact path={navItems[0].to}>
                    <Profile loggedInUser={props.user} user={user} onlyPicture={true}/>

                    <Visibility user={user} setUser={(u: ProfileDto) => {
                        setUser(u);
                        props.setUser(u);
                    }}/>

                    <Description user={user} updateUser={() => setUser({...user})}/>

                    <h3 className="d-inline-block">Berufserfahrung</h3>
                    <ProfileStudiesOrJobs user={user} setUser={handleSetUser} setEdit={(job: ProfileJob) => {setEdit(job);setType("jobs")}} list={user.jobs}/>

                    <h3 className="d-inline-block">Ausbildung</h3>
                    <ProfileStudiesOrJobs user={user} setUser={handleSetUser} setEdit={(job: ProfileJob) => {setEdit(job);setType("studies")}} list={user.studies}/>

                    <h3 className="d-inline-block">Ehrenamt</h3>
                    <ProfileStudiesOrJobs user={user} setUser={handleSetUser} setEdit={(job: ProfileJob) => {setEdit(job);setType("ehrenamt")}} list={user.ehrenamt}/>

                </Route>
                <Route path={navItems[1].to}>
                    <Profile loggedInUser={props.user} user={user} editable={false}/>
                </Route>
            </Switch>
        </div>
    </>);
}

function Visibility(props: {user: ProfileDto, setUser: CallableFunction}): JSX.Element {
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState("");
    const [showVisibility, setShowVisibility] = useState(false); //true: show Visibility Setting

    const handleVisible = () => {
        setWaiting(true);
        networkVisible(props.user.id).then(() => {
            setError("");
            setWaiting(false);
            const u = {...props.user};
            u.visible = !u.visible;
            props.setUser(u);
            setShowVisibility(false);
        }).catch((e) => {
            setWaiting(false);
            setError(AxiosErrorMessageGetter(e));
        });
    };

    const user = props.user;
    if(!user) {
        return <></>;
    }

    return <div className="mb-3">
        {error && <div className="alert alert-danger">{error}</div>}
        {user.visible && <>
            <button type="button" className="btn btn-link" onClick={() => setShowVisibility(!showVisibility)}><Icons.Settings size={20  }/> Sichtbarkeitseinstellung</button>
            <AnimatePresence>
                {showVisibility ?
                    <motion.div initial={{height: 0, opacity: 0}} animate={{height:"auto", opacity: 1}} exit={{height: 0, opacity: 0}} style={{overflow: "hidden"}}>
                        <div className="alert alert-success mb-1">Du bist sichtbar für andere Benutzer*innen. Wenn Du Dich unsichtbar schaltest, können Dich andere Mitglieder sowie Deine Kontakte nicht mehr finden. Bitte bleibe wenn möglich sichtbar, damit das Netzwerk wachsen kann. Du kannst Deinen Sichtbarkeitsstatus jederzeit wieder ändern. Du kannst nur die Profile von anderen Mitgliedern sehen, wenn Du sichtbar geschaltet bist.
                            <br/><ButtonSpin spinning={waiting} disabled={waiting} type="button" skin="outline" variant="danger" onClick={handleVisible}>Schalte Dich unsichtbar</ButtonSpin>
                        </div>
                    </motion.div>
                    : null}
            </AnimatePresence>
        </>}
        {!user.visible && <>
            <div className="alert alert-warning mb-1">Du bist noch nicht sichtbar für andere Benutzer*innen. Um Dich sichtbar zu schalten, musst Du mindestens ein(e) Berufserfahrung/Ausbildung/Ehrenamt unten angeben - Du kannst als Beispiel die <Link to="/vorstand">Profile vom Vorstand anschauen</Link>. Du kannst Deinen Sichtbarkeitsstatus jederzeit wieder ändern.
                <br/><ButtonSpin spinning={waiting} disabled={waiting} type="button" skin="outline" onClick={handleVisible}>Schalte Dich sichtbar</ButtonSpin>
            </div>
        </>}
    </div>;
}

function ProfileStudiesOrJobs(props: {user: ProfileDto, setUser: CallableFunction, list: ProfileJob[], setEdit: CallableFunction}) {
    return <>
        <button type="button" className="btn btn-link mb-2 fs-4" onClick={() => {
            const job = {city: "", institution: "", name: "", description: "", types: [{name: ""}]};
            props.setEdit(job);
        }}>+</button>
        <div className="mb-3" style={{marginTop: "-12px"}}>
            {props.list.map((study: ProfileJob) => <div key={study.id}>
                {/*Button: Edit*/}
                <div className="position-relative">
                    <Job job={study} />

                    <div className="position-absolute" style={{right: 0, top: 0}}>
                        <button className="btn btn-link" onClick={() => {
                            props.setEdit(study);
                        }}><Icons.Pencil size={20}/></button>
                    </div>
                </div>
            </div>)}
        </div>
    </>;
}

function JobEquals(a: ProfileJob, b: ProfileJob) {
    if(a.types.length!==b.types.length) {
        return false;
    }
    for(let i=a.types.length-1; i>=0; --i) {
        if(a.types[i].name!==b.types[i].name) {
            return false;
        }
    }

    return a.name===b.name &&
        a.institution===b.institution &&
        a.city===b.city &&
        a.description===b.description &&
        a.startYear===b.startYear &&
        a.endYear===b.endYear;
}

interface CountOccurrences {
    element: JSX.Element,
    count: number,
    value: string
}

function searchList(list: CountOccurrences[], queries: string[], v: string, i: number, isStudies: boolean, city: string) {
    //must include city
    if(isStudies && city.length>0 && v.indexOf(city)===-1) return;

    //count hits, make JSX.Element
    let count = 0;
    const founds: searchFromTo[] = [];
    const lower = v.toLowerCase();
    queries.forEach((q: string) => {
        if(q==="") return;

        const index = lower.indexOf(q.toLowerCase());
        if(index===-1) return;

        //only search for string beginnings
        if(index>0 && " ([-,.".indexOf(v.charAt(index-1))===-1) return;

        if(!searchCheckInside(founds, index, q.length)) {
            founds.push({from: index, to: index+q.length});
        }
        ++count;
    })

    if(isStudies && city.length>0 && count<2) return;
    if(count<1) return;
    list.push({element: searchFoundsToElement(v, founds, ""+i), value: v, count: count});
}

function JobEditModalCityInstitution(props: {study: ProfileJob, type: ProfileTypes}): JSX.Element {
    const [list, setList] = useState<CountOccurrences[]>([]);
    const [message, setMessage] = useState<MessageState>();

    const updateList = useCallback(() => {
        const l: CountOccurrences[] = [];

        const queries = props.study.institution.split(/ +/);
        const isStudies = props.type==="studies";
        if(props.study.city && isStudies) queries.push(props.study.city);

        institutionAutofill.forEach((v: string, i: number) => searchList(l, queries, v, i, isStudies, props.study.city));

        setList(l.sort((a: CountOccurrences,b: CountOccurrences) => b.count-a.count));
        if(l.length===0 && props.study.institution.length) {
            setMessage({type: "warning", message: "Bitte überprüfe, ob es die Institution/Firma schon gibt, indem du etwaige andere Namen in das Feld eingibst. "+
                    "Wenn es sie nicht gibt, bitte benutze den vollständigen Namen."});
        } else {
            setMessage(undefined);
        }
    }, [props.study, props.type]);

    return <>
        <MessageDisplay message={message}/>
        <div className="col-12 col-sm-6 mb-3">
            <label>Stadt<span className="text-danger">*</span></label>
            <InputControlled className="form-control" value={props.study.city} onChange={(e) => {
                props.study.city = e;
            }} />
        </div>
        <div className="col-12 col-sm-6 mb-3">
            <label>Institution/Firma<span className="text-danger">*</span></label>
            <input onFocus={updateList} onKeyDown={(e) => {
                if(e.key!=="Enter" || list.length===0) return;
                //press enter: select first
                props.study.institution = list[0].value;
                setList([]);
                setMessage(undefined);
            }} onBlur={() => setList([])}
                   className="form-control" value={props.study.institution} onChange={(e) => {
                props.study.institution = e.target.value;
                updateList();
            }} />
            <div className="position-relative w-100">
                <div className="position-absolute w-100 btn-group-vertical overflow-auto d-block" style={{top:0,maxHeight:"250px"}}>
                    {list.map((s: CountOccurrences) => <button key={s.value} className="btn btn-outline-primary text-black w-100" style={{zIndex: 99999}} onMouseDown={() => {
                        props.study.institution = s.value;
                    }}>{s.element}</button>)}
                </div>
            </div>
        </div>
    </>;
}

function JobEditModalName(props: {study: ProfileJob, type: ProfileTypes}) {
    const [list, setList] = useState<CountOccurrences[]>([]);

    const updateList = useCallback(() => {
        const l: CountOccurrences[] = [];

        const queries = props.study.name.split(/ +/);
        namesAutofill.forEach((v: string, i: number) => searchList(l, queries, v, i, false, props.study.city));

        setList(l.sort((a: CountOccurrences,b: CountOccurrences) => b.count-a.count));
    }, [props.study]);

    return <div className="col-12 mb-3">
        <label>Bezeichnung{props.type!=="studies"?"/Funktion":""}<span className="text-danger">*</span></label>
        <input onFocus={updateList} onKeyDown={(e) => {
            if(e.key!=="Enter" || list.length===0) return;
            props.study.name = list[0].value;
            setList([]);
        }} onBlur={() => setList([])}
               className="form-control" value={props.study.name} onChange={(e) => {
            props.study.name = e.target.value;
            updateList();
        }} />
        <div className="position-relative w-100">
            <div className="position-absolute w-100 btn-group-vertical overflow-auto d-block" style={{top:0,maxHeight:"250px"}}>
                {list.map((s: CountOccurrences) => <button key={s.value} className="btn btn-outline-primary text-black w-100" style={{zIndex: 99999}} onMouseDown={() => {
                    props.study.name = s.value;
                }}>{s.element}</button>)}
            </div>
        </div>
    </div>;
}

function orderedListRemoveDuplicates(list: string[]) {
    let l = list.length;
    for(let i=1; i<l; ++i) {
        if(list[i]===list[i-1]) {
            list.splice(i, 1);
            --l;
            --i;
        }
    }
}

function JobEditModal(props: {userId: number, study: ProfileJob | undefined, user: ProfileDto, type: ProfileTypes, setShow: (show: boolean) => void, stopEdit: CallableFunction, updateUser: CallableFunction, delete: CallableFunction}) {
    const [study, setStudy] = useState<ProfileJob>();
    const [cancel, setCancel] = useState("");
    const [institutionLoaded, setInstitutionLoaded] = useState(false);

    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if(props.study) {
            const s = {...props.study};
            //alte kategorien
            s.types.forEach(t => {
                if(t.name!=="" && !StudyCategories.includes(t.name)) {
                    t.name = StudyCategories[0];
                }
            });
            setStudy(s);
        }
    }, [props.study]);

    useEffect(() => {
        if(!props.study) return;
        if(institutionLoaded) return;
        setInstitutionLoaded(true);

        networkGetInstitutionList().then((response) => {
            const list = institutionAutofill;
            //concat if not already in it
            response.data.forEach(v => {
                if(list.includes(v)) return;
                if(!v) return;
                list.push(v);
            })
            orderedListRemoveDuplicates(list);
        }).catch(e => {
            console.error(e);
        });

        networkGetNamesList().then((response) => {
            const list = namesAutofill;
            //concat if not already in it
            response.data.forEach(v => {
                if(list.includes(v)) return;
                if(!v) return;
                list.push(v);
            })
            orderedListRemoveDuplicates(list);
        }).catch(e => {
            console.error(e);
        });
    }, [props.type, props.study, institutionLoaded]);

    //actually close window
    const canClose: () => boolean = () => {
        return handleStop(false);
    };

    const handleSave = () => {
        if(waiting || !study) {
            return;
        }
        
        //everything set
        if(!study.name || !study.city || !study.institution || !study.startYear) {
            const mes: string[] = [];
            if(!study.name) mes.push("Name");
            if(!study.city) mes.push("Stadt");
            if(!study.institution) mes.push("Institution/Firma");
            if(!study.startYear) mes.push("Start Jahr");
            setMessage({type: "warning", message: mes.join(", ")+" müssen ausgefüllt sein!"});
            return;
        }
        
        //all categories not empty string
        for(let i=study.types.length-1; i>=0; --i) {
            if(study.types[i].name==="") {
                setMessage({type: "warning", message: "Die Kategorien müssen ausgewählt sein!"});
                return;
            }
        }
        
        //duplicate categories
        const categoriesDup: Record<string, boolean> = {};
        for(let i=study.types.length-1; i>=0; --i) {
            const n = study.types[i].name
            if(categoriesDup[n]) {
                setMessage({type: "warning", message: "Es darf keine Kategorie doppelt sein ("+n+")!"});
                return;
            }
            categoriesDup[n] = true;
        }
        
        setWaiting(true);
        networkEdit(props.userId, study, props.type).then((response) => {
            setMessage(undefined);
            setWaiting(false);
            if(props.study) {
                if(!props.study.id) {
                    props.user[props.type].push(props.study);
                    props.user[props.type].sort(ProfileJobSort);
                    props.study.id = response.data;
                }

                props.study.name = study.name;
                props.study.institution = study.institution;
                props.study.city = study.city;
                props.study.description = study.description;
                props.study.startYear = study.startYear;
                props.study.endYear = study.endYear;
                props.study.types = study.types;
            }
            props.updateUser();
            props.stopEdit();
            setStudy(undefined);
        }).catch((e) => {
            setWaiting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        });
    };

    const handleDelete = () => {
        if(waiting || !study) {
            return;
        }
        setWaiting(true);
        networkDelete(props.userId, study, props.type).then(() => {
            setMessage(undefined);
            setWaiting(false);
            props.delete();
            props.updateUser();
            props.stopEdit();
            setCancel("");
            setStudy(undefined);
        }).catch((e) => {
            setWaiting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        });
    }

    //checks if job was changed and ask for confirmation to cancel
    const handleStop = (toDelete: boolean = false) => {
        if(!study || !props.study) {
            return true;
        }
        setMessage(undefined);

        if(cancel) {
            if(cancel==="delete") {
                handleDelete();
                return true;
            }
            setCancel("");
            setStudy(undefined);
            props.stopEdit();
            return true;
        }

        if( study.id && toDelete ) {
            setCancel("delete");
            return false;
        }

        //changed something
        if( !cancel && !JobEquals(study, props.study) ) {
            setCancel("cancel");
            return false;
        }

        setStudy(undefined);
        props.stopEdit();
        return true;
    }

    const title: Record<ProfileTypes, string> = {
        jobs: "Berufserfahrung",
        studies: "Ausbildung",
        ehrenamt: "Ehrenamt"
    };

    return <BSModal title={(study && study.id ? "Bearbeite" : "Neue"+(props.type==="ehrenamt" ? "s":"")) + " "+title[props.type]} show={props.study!==undefined} setShow={props.setShow}
                    onSubmit={handleSave} userCanCancel={true} onHide={canClose}>
        <MessageDisplay message={message}/>

        {study && <>
            {/*Input Form*/}
            {!cancel && <>
                <div className="p-1 mt-2">
                    <div className="row">
                        <JobEditModalName study={study} type={props.type}/>
                        <JobEditModalCityInstitution study={study} type={props.type}/>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label>Start Jahr<span className="text-danger">*</span></label>
                            <InputControlled className="form-control" value={study.startYear ? ""+study.startYear : ""} type="number" onChange={(e) => {
                                study.startYear = e==="" ? undefined: +e;
                            }} />
                        </div>
                        <div className="col-6">
                            <label>End Jahr</label>
                            <InputControlled className="form-control" value={study.endYear ? ""+study.endYear : ""} type="number" onChange={(e) => {
                                study.endYear = e==="" ? undefined: +e;
                            }} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label>Kategorien<span className="text-danger">*</span></label>
                        {study.types.map((type, index: number, list) => <div key={index} className="input-group mb-1">
                            <select className="form-control form-select" value={type.name} onChange={(e) => {
                                type.name = e.target.value; setStudy({...study});
                            }}>
                                <option value=""/>
                                {StudyCategories.map((s: string) => <option key={s}>{s}</option>)}
                            </select>
                            {list.length>1 && <button className="btn btn-outline-danger input-group-text border-bottom-only border-2 border-bottom border-danger" onClick={() => {
                                list.splice(index, 1); setStudy({...study});
                            }}><Icons.Trash size={16}/></button>}
                        </div>)}
                        {study.types.length<3 && <button className="btn btn-link btn-sm" onClick={() => {
                            study.types.push({name: StudyCategories[0]}); setStudy({...study});
                        }}>Weitere Kategorie hinzufügen</button>}
                    </div>

                    <div className="mb-3">
                        <label>Beschreibung</label>
                        <TextareaControlled className="form-control" value={study.description} onChange={(e) => {
                            study.description = e;
                        }} />
                    </div>
                </div>

                <div>
                    {study.id && <ButtonSpin type="button" variant="secondary" skin="outline" spinning={waiting} disabled={waiting} onClick={() => handleStop(true)}><Icons.Trash size={20}/> Löschen</ButtonSpin>}
                    <div className="float-end">
                        <ButtonSpin type="button" variant="primary" skin="outline" spinning={waiting} disabled={waiting} onClick={handleSave}>Speichern</ButtonSpin>
                    </div>
                </div>
            </>}

            {/*Cancel Confirmation*/}
            {cancel && <>
                <div className="alert alert-warning">{cancel==="delete" ? title[props.type]+" wirklich löschen?" : "Änderungen wirklich verwerfen?"}</div>
                <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
                    <div className="d-flex justify-content-between ">
                        <ButtonSpin variant="secondary" skin="outline" type="button" onClick={() => setCancel("")}>Zurück</ButtonSpin>
                        <ButtonSpin variant="danger" skin="outline" onClick={() => {
                            handleStop();
                        }}>{cancel==="delete"?"Löschen":"Verwerfen"}</ButtonSpin>
                    </div>
                </div>
            </>}
        </>}
    </BSModal>
}

function Description(props: {user: ProfileDto, updateUser: CallableFunction}): JSX.Element {
    const [desc, setDesc] = useState<{description: string}>({description: props.user.description});
    const [cancel, setCancel] = useState(false);
    const [open, setOpen] = useState(false);

    const [error, setError] = useState("");
    const [waiting, setWaiting] = useState(false);

    const handleClose: () => boolean = () => {
        if (cancel) {
            setCancel(false)
            return false
        } else {
            if(!cancel && desc.description!==props.user.description) {
                setCancel(true);
                return false;
            }
    
            setOpen(false);
            setDesc({description: props.user.description});
            setCancel(false);
            return true;
        }
        
    };

    const handleSave = () => {
        if (cancel) {
            setCancel(false);
            setOpen(false);
        } else if (waiting) { return 
        } else {
            setWaiting(true);
            networkEditDescription(props.user.id, desc.description).then(() => {
                setWaiting(false);
                props.user.description = desc.description; // TODO: FIX this and use useApiData
                props.updateUser();
                setOpen(false);
            }).catch(error => {
                setError(AxiosErrorMessageGetter(error));
                setWaiting(false);
            });
        }
    };

    return <div className="mb-3">
        <div className="position-relative">
            <h4 className="position-relative">Beschreibung <button className="btn btn-link" onClick={() => setOpen(true)} style={{marginTop: "-6px"}}><Icons.Pencil size={20}/></button></h4>
            <ProfileDescription description={props.user.description}/>
        </div>

        <BSModal show={open} setShow={setOpen} title="Beschreibung" userCanSubmit userCanCancel submitLabel={cancel ? "Verwerfen" : "Speichern"} cancelLabel={cancel ? "Zurück" : "Abbrechen"} submitIsDestructive={cancel} onSubmit={handleSave} onHide={handleClose} onCancel={handleClose}>
            {error && <div className="alert alert-danger">{error}</div>}
            {!cancel && <>
                <p>Was bewegt Dich? Was macht Dich aus? Was sind Deine Hobbies? Was interessiert Dich? ...</p>
                <TextareaControlled value={desc.description || ""} className="form-control" onChange={(e) => desc.description=e} />
            </>}
            {cancel && <>
                <Alert variant="warning">
                    <Alert.Heading>Änderungen nicht gespeichert</Alert.Heading>
                    <p className="mb-0">Möchtest Du Deine Änderungen wirklich verwerfen?</p>
                </Alert>
            </>}
        </BSModal>
    </div>;
}
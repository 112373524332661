import { DataViewSortProps } from ".."

import styles from "../UI/DataViewTable/styles.module.scss"

export default function DataViewSort(props: DataViewSortProps) : JSX.Element {

    const options = Object.keys(props.columns)

    return  <div className={styles.DataViewTableHeaderRowItem}>
                <ul className={styles.DataViewSortList}>
                    {
                        options.map(column =>
                            <li key={column} className={styles.DataViewSortListItem + ' ' + (props.sortByColumn === column ? styles.active : '')}>
                                <button type="button" onClick={() => props.setSortByColumn(column)}>
                                    {props.columns[column]}
                                </button>
                            </li>)
                    }
                </ul>
            </div>
}

import axios from "axios";
import { decode, JwtPayload } from "jsonwebtoken";
import C from "../modules/cookies";
import * as APIError from './APIError';
import * as APII from './APIInterfaces';
import {API_BASE} from "../store/API";

/**
 * TODO: Need to add API Error handler
 * Converts HTTP Responses to API Error objects
 * 401 – Bad credentials (wrong username or password)
 */

export const URL = API_BASE;
export const AuthRequest = axios.create({
    baseURL: URL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'}
});
export const APIRequest = axios.create({
    baseURL: URL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'}
});

export function getAuthHeader(token: string): {'Content-Type': string; 'Authorization': string} {
    return( {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token} );
}

export function authenticate(username: string, password: string, callback: (jwt: {str: string; tok: JwtPayload})=>void, errorCallback: (error: Error) => void) : void {

    const payload = {
        email: username,
        password: password
    };

    AuthRequest.post("/auth/bearer", payload, {responseType: 'text'})
    .then(
        (response) => {
            if (response.status === 200) {
                const encodedToken = response.data.split(" ")[1];

                try {
                    const token = decode(encodedToken);
                    if (token !== null && typeof token !== 'string' && 'sub' in token) {
                        // TODO: set correct domain!!
                        C.set("_token", encodedToken, {path: "/"}); // , { domain: 'club.unisono-alumni.loc' }

                        if(token.utp==="unregistered") {
                            window.location.pathname = "/settings/personal-data";
                        } else {
                            window.location.pathname = "/";
                        }
                        // callback({
                        //     str: encodedToken,
                        //     tok: token
                        // })
                    }
                } catch (error) {
                    throw new APIError.APIBrokenToken();
                }
            } else if (response.status === 401) {
                throw new APIError.APIBadCredentials();                    
            } else {
                throw new APIError.APIBadResponse();
            }
        }
    ).catch(
        (reason: Error) => {
            errorCallback(reason)
        }
    );
    
}

export function signOut() {
    C.remove("_token");
    window.location.pathname = "/";
}

export function createUser(username: string, password: string, callback: (userObject : APII.SignUpResponse) => void) : void {
    var payload = {
        email: username,
        password: password
    }

    AuthRequest.post(
        "/auth/create",
        payload
    ).then((response) => {
        console.log("AUTHREQUEST post response:", response)
        if (response.status === 201) {
            // reposne, created
            callback(response.data);
        } else if (response.status >= 400 && response.status < 500) {
            // SERVER ERROR
            throw new APIError.APIBadRequest();
            // USER ERROR
        } else if (response.status >= 500) {
            // SERVER ERROR
            throw new APIError.APIServerError();
        } else {
            // uncaught error
            throw new APIError.APIBadResponse();
        }
    }).catch((reason) => {
        console.log("Could not complete request: [" + reason + "] ", reason)
    });
}
export function updateCreatedUser(userData : Record<string, any>, token: APII.AppToken, callback: CallableFunction) : void {
    APIRequest.put(
        "/users/register",
        userData,
        {
            headers: getAuthHeader(token.str)
        }
    ).then(response => {
        callback(response.data);
    }).catch(response => {
        console.log(response);
    })
}

// /**
//      * UserCan
//      * Generic function to check whether a user holds permission to do something.
//      * slug: A string representing a right a user holds or a group a user belongs to.
//      */
//  public UserCan(slug: string) : boolean {
//     if (this.token === undefined) throw new API.Error.APINotAuthenticated();
    
//     if ( typeof(this.token) !== 'string' ) {
//         if (this.token.roles)
//         return (slug in this.token.roles);
//     }

//     throw new API.Error.APIBadToken();
// }

// /**
//  * UserCanAdministrate
//  */
// public UserCanAdministrate() : boolean {
//     return this.UserCan(F_ADMINISTRATE);
// }

// /**
//  * UserCanModerate
//  */
// public UserCanModerate() : boolean {
//     return this.UserCan(F_MODERATE);
// }

// /**
//  * UserIsMember
//  */
// public UserIsMember() : boolean {
//     return this.UserCan(G_MEMBER);
// }

// /**
//  * UserIsBoard
//  */
// public UserIsBoard() : boolean {
//     return this.UserCan(G_BOARD);
// }

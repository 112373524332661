import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken} from "../APIInterfaces";

export function authCreate(email: string, password: string): Promise<AxiosResponse> {
    return axios.post(API_BASE + "/auth/create", {email, password});
}

export function authWaitForVerification(): Promise<AxiosResponse<string>> {
    return axios.post(API_BASE + "/auth/confirm", {}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function authVerifyEmail(otp: string): Promise<AxiosResponse> {
    return axios.post(API_BASE + "/auth/verify-email", {otp}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function authReloadToken(): Promise<AxiosResponse<string>> {
    return axios.post(API_BASE + "/auth/update", {}, {headers: {Authorization: "Bearer " + getToken()}});
}

export function authChangeEmailPassword(email: string, password: string): Promise<AxiosResponse> {
    return axios.post(API_BASE + "/auth/change-email-password", {
        email,
        password
    }, {headers: {Authorization: "Bearer " + getToken()}});
}
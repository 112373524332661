import React from "react";

interface Props<C extends React.ElementType> extends React.HTMLAttributes<HTMLElement> {
    variant?: "auto" | "light" | "dark", 
    as?: (C & keyof JSX.IntrinsicAttributes)
}

type LogoTypeProps<C extends React.ElementType = "span"> = Props<C> & Omit<React.ComponentPropsWithoutRef<C>, keyof Props<C>>

export default function LogoType(props: LogoTypeProps) : JSX.Element {
    
    const Component = props.as || 'span'
    // const variant = props.variant || "auto"
    
    
    return  <Component {...props} className={props.className + " ci-stylized d-inline-block"}>
                <span style={{color: "var(--bs-black)"}}>
                    <span style={{color: "var(--bs-red)"}}>u</span>nisono
                </span>
            </Component>
}
import React, {useCallback, useState} from "react"
import Cropper from "react-easy-crop"
import {Area, Point} from "react-easy-crop/types"

type ImageCropperProps = {
    show: boolean,
    image: string,
    resizeAfterCrop?: boolean,
    maxSize?: number,    
    onCropComplete: (area: Area) => void
}


export default function ImageCropper(props: ImageCropperProps): JSX.Element {
    /* Crop State */
    const [cropCoordinates, setCropCoordinates] = useState<Point>({x:0,y:0})
    const [zoomLevel, setZoomLevel] = useState<number>(1)

    const onCropComplete = useCallback((_: Area, areaPixel: Area) => {
        props.onCropComplete(areaPixel)
    }, [props])

    return  <>                
                <div className={!props.show ? "d-none":""} style={{position: "relative", width: "300px", height: "300px", margin: "auto"}}>
                    <Cropper 
                        image={props.image}
                        crop={cropCoordinates}
                        zoom={zoomLevel}
                        aspect={1}
                        cropShape="round"
                        onCropChange={setCropCoordinates}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoomLevel}
                    />
                </div>
            </>
}
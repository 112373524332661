import axios, {AxiosResponse} from "axios";
import {getToken} from "../APIInterfaces";
import {API_BASE} from "../../../store/API";

export interface UserNameDto {
    id: number,
    firstName: string,
    lastName: string,
}

export interface UserNameEmailDto extends UserNameDto {
    email: string,
}

export interface EventDateDto {
    id: number,

    name: string;
    description: string;
    date: string;
    datePreview?: boolean;
    hour: string;
    minute: string;
    
    duration: number; //in minutes
    capacityLimit: number; //number of people allowed in the location
    adult: boolean; //true if only people >= 18 are allowed
    address: string;
    dateRegisterChange: string; //up to this date people can register and unregister
    datePublication: string;

    registered?: UserNameDto[];
    selfRegistered?: boolean;
    selfComments?: string;
    registerCount: number;
}

export interface EventDateListDto {
    id: number,

    name: string;
    date: string;
    datePreview?: boolean;
}

export function eventImageSave(id: number, image: File): Promise<AxiosResponse<void>> {
    let data = new FormData()
    data.append('image', image)

    return axios.post(API_BASE + "/eventDates/" + id + "/image", data, { headers: {
            Authorization: "Bearer " + getToken(),
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data`
        }})
}

export function eventDelete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete(API_BASE + "/eventDates/" + id, {headers: {Authorization: "Bearer " + getToken()}})
}

export function eventImageCopy(id: number, copyId: number): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/eventDates/" + id + "/image/" + copyId, {}, {headers: {Authorization: "Bearer " + getToken()}})
}

export function eventDateNewRequest(eventDate: EventDateDto): Promise<AxiosResponse<number>> {
    return axios.post(API_BASE + "/eventDates", eventDate,{headers: {Authorization: "Bearer " + getToken()}});
}

export function eventDateEditRequest(eventDate: EventDateDto): Promise<AxiosResponse<void>> {
    return axios.put(API_BASE + "/eventDates", eventDate,{headers: {Authorization: "Bearer " + getToken()}});
}

export interface EventDateRegisterDto {
    comment: string
}

export function eventDateRegisterRequest(eventDateId: number, register: boolean): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/eventDates/"+eventDateId+'/'+(!register?'de':'')+"register", {},{headers: {Authorization: "Bearer " + getToken()}});
}

export function eventDateCommentRequest(eventDateId: number, registerDto: EventDateRegisterDto): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/eventDates/"+eventDateId+'/comment', registerDto,{headers: {Authorization: "Bearer " + getToken()}});
}
import React, {useCallback, useState} from "react";
import ButtonSpin from "../../../../../components/UI/Buttons/ButtonSpin";
import {useApiData} from "react-api-data";
import {EmailEndpoint} from "../../../../../store/APIStore";
import ApiCycle from "../../../../../modules/API/ApiCycle";
import {Field, Formik} from "formik";
import {MessageDisplay, MessageState} from "../../../../../components/MessageState";
import { ShowIf, ShowIfType } from "../../../../../hoc/Authenticated";
import AbstractPermission from "../../../../../modules/Auth/AbstractPermission";
import { UserGroup, UserPermission } from "../../../../../modules/Auth";
import { NotRegisteredAlert, NotVerifiedAlert } from "../../../../../components/SpecificAlerts";


interface Preference {
    name: string,
    description: string
}

//TEXT: um 20:00 kommen E-Mails

//website spezifisch

//UNISONO Jahresbericht/Halbjahresbericht (eher website)
//{name: "Todesanzeigen", description: "Einmal pro Jahr"}, //im Profil angezeigt
//{name: "Interviews mit bekannten Ehemaligen", description: ""}, //näher weil im Profil angezeigt

//noch nicht reingeben
//{name: "Spritbuam", description: "?"},
//{name: "Mentoringprogramm", description: "News, Aufrufe für MentorInnen, ..."},

const fixfixPreferences: Preference[] = [
    {name: "Vereinsspezifisches", description: "Jahresbericht/Quartalsbericht, Generalversammlung, ..."},
    {name: "Neuigkeiten zu Events, für die Du Dich angemeldet hast", description: "z.B. Uhrzeit-/Terminänderung"},
];

/*const vereinPreferences: Preference[] = [
    //Einmal pro tag/woche/monat zusammen alle vereine die angehakt. DISCLAIMER: selten können dringende E-Mails früher verschickt werden.
    //admin: neue hinzufügen - sollte in News stehen (dass es ein neues gibt)
    {name: "UNISONO Chor", description: "Konzerte, News, ..."},
    {name: "Wiener Sängerknaben", description: "Konzerte, News, ..."},
    {name: "Oberstufe", description: "Chorus Juventus, News, ..."},
    {name: "MuTh", description: "News"},
    {name: "Chorus Viennensis", description: "Konzerte, News, ..."},
    {name: "Fußballclub Ehemalige Sowieso", description: "Addresse Dortundhier"},
];*/

interface EmailOptInOut {
    listOrder: number,
    preference: number,
    type: string,
    description: string,
    optIn?: boolean
}

interface NotificationSettingsDto {
    friendsFrequency: number,
    promotionFrequency: number,
    emailOptInOut: EmailOptInOut[]
}

export default function NotificationController() {
    const [message, setMessage] = useState<MessageState>();
    
    const request = useApiData<NotificationSettingsDto, string>(EmailEndpoint.GET.getPreferences, {});
    const putRequest = useApiData<string, string>(EmailEndpoint.PUT.editPreferences);
    
    const handleSubmit = useCallback((values: NotificationSettingsDto) => {
        return putRequest.perform({}, values).then((response) => {
            const status = response.request.networkStatus;
            if(status==="success") {
                setMessage({type: "success", message: "Änderungen gespeichert!"});
            } else if (status==="failed") {
                setMessage({type: "danger", message: response.request.errorBody});
            }
            request.invalidateCache();
        });
    }, [putRequest, request])
    
    return  <>
            <ShowIfType type={UserGroup.NotVerified}>
                <NotVerifiedAlert />
            </ShowIfType>
            <ShowIfType type={UserGroup.NotRegistered}>
                <NotRegisteredAlert />
            </ShowIfType>
            <ShowIf permission={AbstractPermission.withPermission(UserPermission.EditOwnProfile)}>
            <h2>E-Mail Benachrichtigungen</h2>
            <span>Egal bei welcher Einstellung kommen E-Mails immer um 20 Uhr, falls es Neuigkeiten gibt.</span>
            
            <div className="my-3">
                <h4>Benachrichtigungen, die du als Vereinsmitglied bekommst</h4>
                {fixfixPreferences.map((p: Preference) => <div key={p.name} className="form-check">
                    <input className="form-check-input" type="checkbox" name="salutation" id={"NotificationController-fix-"+p.name} disabled checked/>
                    <label className="form-check-label" htmlFor={"NotificationController-fix-"+p.name}>{p.name} {p.description && <span>({p.description})</span>}</label>
                </div>)}
            </div>

            <div className="mb-4">
                <ApiCycle data={request} extractor={(settings: NotificationSettingsDto) => {
                    settings.emailOptInOut = settings.emailOptInOut.sort((a: EmailOptInOut,b: EmailOptInOut) => a.listOrder-b.listOrder);
                    settings.emailOptInOut.forEach(opt => {
                        if(!opt.optIn) opt.optIn = false; //setting null|undefined to false
                    })
                    
                    return <Formik initialValues={settings} onSubmit={handleSubmit}>{formik => <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <div className="mb-3">
                            <h4>Club spezifische Benachrichtigungen</h4>
                            <label>Häufigkeit für neue Kontaktanfragen</label>
                            <Field as="select" name="friendsFrequency" className="form-control form-select">
                                <option value={0}>Nie</option>
                                <option value={1}>Höchstens einmal pro Tag</option>
                                <option value={7}>Höchstens einmal pro Woche</option>
                                <option value={30}>Höchstens einmal pro Monat</option>
                            </Field>
                        </div>
                        
                        <div className="mb-3">
                            <h4>Promotions</h4>
                            <label>Häufigkeit für unten ausgewählte Punkte</label>
                            <Field as="select" className="form-control form-select mb-2" name="promotionFrequency" label="Häufigkeit">
                                <option value={1}>Höchstens einmal pro Tag</option>
                                <option value={7}>Höchstens einmal pro Woche</option>
                                <option value={30}>Höchstens einmal pro Monat</option>
                            </Field>

                            {formik.values.emailOptInOut.map((e: EmailOptInOut, index: number) => <div key={e.preference} className="form-check">
                                <label>
                                    <Field type="checkbox" className="form-check-input" name={"emailOptInOut["+index+"].optIn"} />
                                    {e.type} {e.description && <span>({e.description})</span>}
                                </label>
                            </div>)}
                        </div>

                        <MessageDisplay message={message}/>
                        <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
                            <div className="d-flex justify-content-between ">
                                <ButtonSpin variant="secondary" skin="link" type="reset">Abbrechen</ButtonSpin>
                                <ButtonSpin skin="outline" type="submit">Änderungen Speichern</ButtonSpin>
                            </div>
                        </div>
                    </form>}</Formik>;
                }}/>
            </div>
        </ShowIf>
    </>;
}

import { createRef, useCallback } from "react"
import { DataViewSearchProps } from ".."
import { Search } from "../../Icons"
import styles from "../UI/DataViewTable/styles.module.scss"

export default function DataViewSearch(props: DataViewSearchProps): JSX.Element {

    const inputRef = createRef<HTMLInputElement>()

    const keyDownEventHandler = useCallback((event) => {
        if (event.key === "Escape") {
            inputRef.current?.blur()
            props.setSearchString("")
        } else if (event.key === "Enter") {
            inputRef.current?.blur()
        }
    }, [inputRef, props])

    const onBlur = useCallback(() => {
        document.removeEventListener("keydown", keyDownEventHandler, false)
    }, [keyDownEventHandler])

    const onFocus = useCallback(() => {
        document.addEventListener("keydown", keyDownEventHandler, false)
    }, [keyDownEventHandler])

    const onChange = useCallback((e) => {
        props.setSearchString(e.target.value)
    }, [props])

    return  <div className={styles.DataViewTableHeaderRowItem}>
                <form className={styles.DataViewSearchForm} onSubmit={e => e.preventDefault()}>
                    <Search />
                    <input  type="text" 
                            className={'form-control form-control-sm ' + styles.DataViewSearchFormInput} 
                            onChange={onChange} 
                            onBlur={onBlur} 
                            onFocus={onFocus}
                            value={props.searchString} 
                            ref={inputRef}
                            placeholder="Suche" />
                </form>
            </div>
}
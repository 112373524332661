import React, {useCallback, useState} from "react"
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin"
import {feedbackRequest} from "../../../../modules/API/Services/UserService";
import {MessageDisplay, MessageState} from "../../../../components/MessageState";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";


export default function FeedbackController() : JSX.Element {

    const [submitting, setSubmitting] = useState(false);
    const [message, setMessage] = useState<MessageState>();

    const [name, setName] = useState("")
    const [subject, setSubject] = useState("FEEDBACK")
    const [userMessage, setUserMessage] = useState("")
    const [agreement, setAgreement] = useState(false)
    
    const submitFeedback = useCallback(() => {
        setSubmitting(true);
        feedbackRequest(name, subject, userMessage).then(() => {
            setSubmitting(false);
            setMessage({type: "success", message: "Feedback erhalten - Danke für Dein Feedback!"});
        }).catch((e) => {
            setSubmitting(false);
            setMessage({type: "danger", message: AxiosErrorMessageGetter(e)});
        });

        setTimeout(() => setSubmitting(false), 2000)
    }, [name, subject, userMessage]);

    return  <div style={{maxWidth: "600px", margin: "0 auto"}}>
                <h2>Feedback Formular</h2>
                <p className="lead">Danke für Dein Feedback!</p>
                <form action="#" method="POST" onSubmit={(e) => {
                    e.preventDefault();
                    submitFeedback();
                }}>
                    <label htmlFor="feedback-name" id="feedback-name-label" className="form-label">Dein Name</label>
                    <input type="text" autoComplete="name" id="feedback-name" className="form-control mb-3" value={name} onChange={(e) => setName(e.target.value)} required disabled={submitting} />

                    <label htmlFor="feedback-subject" id="feedback-subject-label" className="form-label">Bitte wähle ein Thema</label>
                    <select id="feedback-subject" className="form-select mb-3" value={subject} onChange={(e) => setSubject(e.target.value)} required disabled={submitting} >
                        <option value="FEEDBACK">Feedback</option>
                        <option value="FEATURE">Etwas geht besser / Vorschlag</option>
                        <option value="BUG">Etwas funktioniert nicht</option>
                    </select>

                    <label htmlFor="feedback-message" id="feedback-message-label" className="form-label">Deine Nachricht</label>
                    <textarea id="feedback-message" className="form-control mb-3" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} disabled={submitting} />

                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" value="YES" checked={agreement} onChange={() => setAgreement(!agreement)} id="feedback-agreement" required disabled={submitting}/>
                        <label className="form-check-label" htmlFor="feedback-agreement">Diese Angaben sind nicht mit meinem Nutzerprofil verknüpft. Ich stimme zu, dass meine Angaben gespeichert werden und ich ggf. kontaktiert werden kann.</label>
                    </div>
                    <div className="mt-4 text-center">
                        <MessageDisplay message={message}/>
                        <ButtonSpin spinning={submitting} type="submit">Senden</ButtonSpin>
                    </div>
                </form>
            </div>
}
import C from "../cookies";
import {decode, JwtPayload} from "jsonwebtoken";


export interface MemberVbc {
    id?: number,
    startYear: number,
    endYear: number,
    startClass: number,
    memberChoir: {
        name: string
    }
}

export interface MemberHs {
    id?: number,
    startYear: number,
    endYear: number,
    startClass: number,
}

export interface RoleDto {
    role: string;
}

export interface UserDto {
    id: number,
    version: number,
    role: string,
    roleAdds: RoleDto[],
    ehrenMitglied: boolean,
    userType: string,
    joinedAt: string,
    password: string,
    passwordResetLink: string,
    salutation: string,
    birthday: string,
    telephone:string,
    email: string,
    titlePrefix: string,
    titleSuffix: string,
    firstName: string,
    lastName: string,
    country: string,
    state: string,
    city: string,
    postalCode: string,
    address: string,
    memberVbc?: MemberVbc,
    memberHs?: MemberHs,
    nextYearMembershipFeeType: string,
    membershipFeeDate: string,
    visible: boolean,
    locked: boolean,
    emailVerification?: boolean
    emailChange?: string,
    leftDate?: string,
    leftReason?: string
}

export type ProfileTypes = "jobs"|"studies"|"ehrenamt";

export interface ProfileJob {
    id?: number,
    types: {name: string}[],
    city: string,
    institution: string,
    name: string,
    description: string,
    startYear?: number,
    endYear?: number
}

export interface FriendRequestDto {
    fromUser: { id: number },
    toUser: { id: number },
    text: string,
    ignored?: boolean
}

export interface ProfileDto {
    id: number,
    joinedAt: string,
    email: string,
    titlePrefix: string,
    titleSuffix: string,
    firstName: string,
    lastName: string,
    visible: boolean,
    description: string,
    
    friendRequest: FriendRequestDto,
    
    memberVbc?: MemberVbc,
    memberHs?: MemberHs,
    
    jobs: ProfileJob[],
    studies: ProfileJob[],
    ehrenamt: ProfileJob[],
}

export interface Choir {
    name: string;
}

export function dateString(dateString: string) {
    if(!dateString) return "";
    const date = new Date(dateString);
    return date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear();
}

export function getToken(): string {
    return C.get("_token");
}
    
export function getTok(): JwtPayload {
    // Get token
    const encodedToken = getToken();

    // Decode the token
    return decode(encodedToken) as JwtPayload
}

export function JobYears(job: ProfileJob): string {
    if(job.startYear && job.endYear) {
        return job.startYear + "-" + job.endYear;
    }
    if(job.startYear) return "Seit "+job.startYear;
    return "Bis "+job.endYear;
}

export function ProfileJobSort(a: ProfileJob, b: ProfileJob): number {
    if(!a.startYear || !b.startYear) return 0;
    if(a.startYear!==b.startYear) {
        return b.startYear-a.startYear;
    }
    if(a.endYear && b.endYear) return b.endYear-a.endYear;
    if(a.endYear) return 1;
    if(b.endYear) return -1;
    return 0;
}
import React, {useCallback, useEffect, useState} from "react";
import usePageTitle from "../../../../hooks/usePageTitle";
import {useApiData} from "react-api-data";
import {ChoirEndpoint} from "../../../../store/APIStore";
import {ChoirInfo, editChoirInfo} from "../../../../modules/API/Services/ChoirService";
import ApiCycle from "../../../../modules/API/ApiCycle";
import useAppToken from "../../../../hooks/useAppToken";
import {SelectControlled} from "../../../../components/Fields/InputControlled";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import {MessageDisplay, MessageState} from "../../../../components/MessageState";

export default function ChoirController(): JSX.Element {
    usePageTitle("Chor");
    const token = useAppToken();

    if(!token || !token.payload.tok.uid) {
        return <></>;
    }
    
    return <>
        <div className="mb-3">
            Nach langer Planung und vielen Besprechungen haben wir es geschafft, einen gemischten Alumni-Chor ins Leben zu rufen. Dieser gemischte Chor soll für alle jungen Ehemaligen sein, die auch noch nach ihrer Schulzeit Interesse haben zu singen und aufzutreten. Ziele sind für den Anfang Auftritte im MuTh und in Kirchen und langfristig auch Chorreisen. Für den 23. Juni 2022 ist ein erster Auftritt in der Leopoldskirche geplant.
            <br/><br/>
            Das Wichtigste zusammengefasst:<br/>
            <div className="px-4">
                Unsere erste Probe: Donnerstag 3. März 18:30-20:30, Szenischer Proberaum Augartenpalais<br/>
                Weitere Proben: Jeden Donnerstag 18:30-20:30, Szenischer Proberaum Augartenpalais<br/>
                Chorleitung: Daniel Erazo-Muñoz<br/>
                Organisation: Viola Silbermayr<br/>
                <span className="text-danger">Corona: Bitte bringe für jede Probe ein negatives PCR Testergebnis mit, das bis zum Ende der Probe gilt.</span><br/>
                Ideen oder Fragen: <a href="mailto:chor@unisono-alumni.at" target="_blank" rel="noreferrer">chor@unisono-alumni.at</a>
            </div>
        </div>
        
        <Load id={+token.payload.tok.uid}/>
    </>;
}

function Load(props: {id: number}){
    const [message, setMessage] = useState<MessageState>();
    const request = useApiData<ChoirInfo, string>( ChoirEndpoint.GET.choirInfo, {id: props.id});
    
    const onSave = useCallback(() => {
        request.invalidateCache();
    }, [request]);
    
    return <ApiCycle data={request} extractor={(info: ChoirInfo) => <>
        <MessageDisplay message={message}/>
        <ChoirInput id={props.id} info={info} onSave={onSave} setMessage={setMessage}/>
    </>}/>;
}

function ChoirInput(props: {id: number, info: ChoirInfo, onSave: () => void, setMessage: (m: MessageState) => void}) {
    const [waiting, setWaiting] = useState(false);
    const [info, setInfo] = useState<ChoirInfo>();
    
    useEffect(() => {
        setInfo(props.info);
    }, [props.info])
    
    const handleCancel = useCallback(() => {
        setInfo(props.info);
    }, [props.info])
    
    const handleSubmit = useCallback(() => {
        if(!info) return;
        setWaiting(true);
        editChoirInfo(props.id, info).then(() => {
            props.setMessage({type: "success", message: "Änderungen gespeichert!"});
            props.onSave();
        }).catch((er) => {
            props.setMessage({type: "success", message: AxiosErrorMessageGetter(er)});
            setWaiting(false);
        });
    }, [info, props])
    
    if(!info) {
        return <></>;
    }
    
    return <>
        <div className="form-check mb-3">
            <input className="form-check-input" type="checkbox" id="choir-participate-input" checked={info.participate || false} onChange={() => {
                const i = {...info};
                i.participate = !i.participate;
                setInfo(i);
            }}/>
            <label className="form-check-label" htmlFor="choir-participate-input">Ich will beim <b>Chor teilnehmen und E-Mails</b> bzgl. Chorproben und Auftritten erhalten.</label>
        </div>

        {info.participate && <>
            <label>Stimmlage</label>
            <SelectControlled value={info.stimmlage || ""} className="form-control form-select" onChange={(v) => info.stimmlage=v}>
                <option value=""/>
                <option value="Sopran">Sopran</option>
                <option value="Alt">Alt</option>
                <option value="Tenor">Tenor</option>
                <option value="Bass">Bass</option>
            </SelectControlled>
        </>}

        <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
            <div className="d-flex justify-content-between ">
                <ButtonSpin spinning={waiting} variant="secondary" skin="link" onClick={handleCancel}>Abbrechen</ButtonSpin>
                <ButtonSpin spinning={waiting} skin="outline" onClick={handleSubmit}>Änderungen Speichern</ButtonSpin>
            </div>
        </div>
    </>;
}
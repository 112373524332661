import { Route, Switch } from "react-router";
// import "../../assets/table_pagination.scss";
import DashboardController from "./Dashboard";
import ModerationController from "./Moderation";
import RegistrationsController from "./Registrations";
import FinanceController from "./Finance";
import MembersController from "./Members";
import ChoirsController from "./Choirs";
import ChoirApacisController from "./Choir";
import React from "react";
import MailController from "./Mails";

export default function ApacisController() : JSX.Element {
    return <Switch>
        <Route path="/apacis/choir" component={ChoirApacisController} />
        <Route path="/apacis/choirs" component={ChoirsController} />
        <Route exact path="/apacis" component={DashboardController} />
        <Route path="/apacis/finance" component={FinanceController} />
        <Route path="/apacis/mails" component={MailController} />
        <Route path="/apacis/members" component={MembersController} />
        <Route path="/apacis/moderation" component={ModerationController} />
        <Route path="/apacis/registrations" component={RegistrationsController} />
    </Switch>;
}
import classNames from "classnames";
import { useCallback } from "react";
import { DataViewTableRowProps } from ".";

import styles from "./styles.module.scss"

export default function DataRow(props: DataViewTableRowProps) {

    const onClick = useCallback(() => props.onClick ? props.onClick(props.row) : null, [props])

    return  <div role="row" onClick={onClick} className={styles.DataViewTableRow}>
                {   props.columns &&

                    Object.keys(props.columns).map((key, i, arr) => 
                        <DataRowItem index={i} count={arr.length} key={props.row.id + "+" + key}>{props.row[key]}</DataRowItem>
                    )

                }
            </div>
}

function DataRowItem(props: React.PropsWithChildren<{index: number, count: number}>) {

    const cx = classNames({
        [styles.DataViewTableRowDataItem]: true,
        [styles.DataViewTableRowDataItemFirst]: props.index === 0,
        [styles.DataViewTableRowDataItemLast]: props.index === props.count - 1
    })

    return  <div role={props.index === 0 ? "rowheader" : "row"} className={cx}>
                {props.children}
            </div>

}
export class APIError {
    name: string;
    code: number;
    stack?: APIError | object | string | undefined;

    constructor(code: number, name: string, stack?: APIError | object | string | undefined) {
        this.name = name;
        this.code = code;
        this.stack = stack;
    }
}
export class APIBrokenToken extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(1, "Token could not be verifed.", stack);
    }
}
export class APIBadToken extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(2, "Token does not contain expected values.", stack);
    }
}
export class APINotAuthenticated extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(3, "User needs to be authenticated first.", stack);
    }
}
export class APINotAuthorized extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(4, "User is not authorized to be perform this action according to JWT.", stack);
    }
}
export class APIBadResponse extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(5, "API-call received a bad response.", stack);
    }
}
export class APIBadRequest extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(6, "API-call received a Bad Request error message.", stack);
    }
}
export class APINotFound extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(7, "API-call received a Not Found error message.", stack);
    }
}
export class APIBadCredentials extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(7, "Credentials provided for API request were bad.", stack);
    }
}
export class APIServerError extends APIError {
    constructor(stack?: APIError | object | string | undefined) {
        super(8, "Server collapsed.", stack);
    }
}
import { useApiData } from "react-api-data"
import GridCard from "../../../components/GridCard"
import usePageTitle from "../../../hooks/usePageTitle"
import ApiCycle from "../../../modules/API/ApiCycle"
import { UserEndpoint } from "../../../store/APIStore"

export default function DashboardController() : JSX.Element {
    
    usePageTitle("Dashboard")

    return  <>
                
                <div className="grid">
                    <GridCardUserCount />
                    <GridCardRegistrationCount />
                    <GridCardLockedCount />
                </div>
            </>
}



const GridCardUserCount = () => {

    const request = useApiData<number, string>( UserEndpoint.GET.usersCount) //"getUserCount")
    
    return  <GridCard
                title="Mitglieder"
                cta={{
                    path: "/apacis/members",
                    title: "Alle Mitglieder",
                    chevron: true
                }}
                hoverEffect>
                <ApiCycle data={request} />
            </GridCard>
    
}

const GridCardRegistrationCount = () => {

    const request = useApiData<number, string>(UserEndpoint.GET.usersCount, { usertype: "unverified", locked: "false" })

    return  <GridCard
               title="Anmeldungen"
                cta={{
                    path: "/apacis/registrations",
                    title: "Alle Anmeldungen",
                    chevron: true
                }}
                hoverEffect>
                <ApiCycle data={request} />
            </GridCard>

}

const GridCardLockedCount = () => {
    const request = useApiData<number, string>(UserEndpoint.GET.usersCount, { locked: "true" } )

    return  <GridCard
               title="Gesperrte User"
                cta={{
                    path: "/apacis/members/locked",
                    title: "Alle gesperrte User",
                    chevron: true
                }}
                hoverEffect>
                <ApiCycle data={request} />
            </GridCard>
}
/**
 * @module AuthenticationModule
 * 
 * Handles logic of user permissions, user groups, and the user status.
 */


/**
 * Revokes (removes) permissions from an array of {@link UserPermission}s.
 * 
 * @param granted An array of {@link UserPermission}.
 * @param permissions {@link UserPermission}s which are to be revoked from `granted`.
 * @returns An array of {@link UserPermission} with the result.
 * 
 * @since 1.0.0 
 */
const revokePermission = (granted: UserPermission[], ...permissions: UserPermission[]) : UserPermission[] => {
    let tmpGranted = [...granted];
    permissions.forEach((value) => {
        const index: number = tmpGranted.indexOf(value);
        if (index !== -1) tmpGranted.splice(index, 1);
    });

    return tmpGranted;
};

/**
 * An enum providing all available user permissions. The values are identical to the ones being used on the server.
 * @since 1.0.0
 */
export enum UserPermission {
	User = "USER", //i am a user
	Verified = "VERIFIED", //user verified

	//############################### basic user data
	ViewAllUserData = "VIEW_ALL_USER_DATA",
	EditAllUserData = "EDIT_ALL_USER_DATA",
	EditMemberChoirs = "EDIT_MEMBER_CHOIRS",

	//############################### user permissions/type
	ViewUserPermissions = "VIEW_USER_PERMISSIONS",
	ViewUserType = "VIEW_USER_TYPE",

	EditUserPermissions = "EDIT_USER_PERMISSIONS",
	EditUserType = "EDIT_USER_TYPE",

	//############################### fees
	ViewAllPaymentHistory = "VIEW_ALL_PAYMENT_HISTORY",
	EditAllPaymentHistory = "EDIT_ALL_PAYMENT_HISTORY",

	ViewFinancialStatistics = "VIEW_FINANCIAL_STATISTICS",
	EditFinancialStatistics = "EDIT_FINANCIAL_STATISTICS",

	ViewNextYearsFees = "VIEW_NEXT_YEARS_FEES",
	EditNextYearsFees = "EDIT_NEXT_YEARS_FEES",

	//############################### network
	EditOwnProfile = "EDIT_OWN_PROFILE",
	ViewAllProfiles = "VIEW_ALL_PROFILES",

	EditAllProfiles = "EDIT_ALL_PROFILES",

	//############################### notifications
	EditListEmailingPreferences = "EDIT_LIST_E-MAILING_PREFERENCES",

	//############################### email sending
	SendEmailToMembers = "SEND_EMAIL_TO_MEMBERS",

	//############################### news
	EditCreateNews = "EDIT_CREATE_NEWS",
	DeleteNews = "DELETE_NEWS",

	//############################### choir
	ViewAllUserChoirData = "VIEW_ALL_USER_CHOIR_DATA",
	EditAllUserChoirData = "EDIT_ALL_USER_CHOIR_DATA",

	//############################### events
	ViewEvents = "VIEW_EVENTS",
	ViewRegisterEvent = "VIEW_EVENT_DETAILS",
	EditCreateEvent = "EDIT_CREATE_EVENT",
	DeleteEvent = "DELETE_EVENT",
	ViewAllAttendeesData = "VIEW_ALL_ATTENDEES_DATA",

	/////probably not needed
	ViewRegisterRehearsals = "VIEW_REGISTER_REHEARSALS",
	CreateRehearsals = "CREATE_REHEARSALS",
	EditRehearsals = "EDIT_REHEARSALS",
	DeleteRehearsals = "DELETE_REHEARSALS",
	ViewRegisterPerformances = "VIEW_REGISTER_PERFORMANCES",
	CreatePerformances = "CREATE_PERFORMANCES",
	EditPerformances = "EDIT_PERFORMANCES",
	DeletePerformances = "DELETE_PERFORMANCES",
}

/**
 * An enum providing all available user groups. Used to provide logic on application level, only.
 * To make use of user groups, use an instance of {@link PermissionBag} instead.
 * @since 1.0.0
 */
export enum UserGroup {
    Admin = "admin",
    Board = "vorstand",
    Circle = "circle",
    Alumni = "default",
    NotVerified = "unverified",
	NotRegistered = "unregistered",
	Left = "left",
    None = ""
}

/**
 * @ignore
 */
const tmpChoirPermissions: UserPermission[] = [UserPermission.ViewRegisterRehearsals, UserPermission.CreateRehearsals, UserPermission.EditRehearsals, UserPermission.DeleteRehearsals, UserPermission.ViewRegisterPerformances, UserPermission.CreatePerformances, UserPermission.EditPerformances, UserPermission.DeletePerformances];

/**
 * @ignore
 */
const adminPermission = revokePermission(Object.values(UserPermission), ...tmpChoirPermissions);

/**
 * @ignore
 */
const alumniPermission = [UserPermission.ViewEvents, UserPermission.ViewRegisterEvent, UserPermission.User, UserPermission.EditOwnProfile];

const notVerifiedPermissions = [UserPermission.ViewEvents, UserPermission.User];

const notRegisteredPermissions = [...revokePermission(notVerifiedPermissions, UserPermission.ViewEvents), UserPermission.User]
/**
 * An object mapping all {@link UserGroup}s to an array of {@link UserPermission}.
 * 
 * @since 1.0.0
 */
export const mappedGroupsToPermissions: Record<UserGroup, UserPermission[]> = {
    [UserGroup.Admin]: adminPermission,
    [UserGroup.Board]: alumniPermission,
    [UserGroup.Alumni]: alumniPermission,
    [UserGroup.Circle]: alumniPermission,
    [UserGroup.NotVerified]: notVerifiedPermissions,
    [UserGroup.NotRegistered]: notRegisteredPermissions,
	[UserGroup.Left]: notRegisteredPermissions,
    [UserGroup.None]: []
}
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { configure, EndpointConfig, reducer } from 'react-api-data';
import thunk from 'redux-thunk';

import C from '../modules/cookies';
import {API_BASE, APIDelete, APIGet, APIPost, APIPut} from './API';

type StaticEndpoint = {
    [key: string]: EndpointConfig
}
///////////////////
// API ENDPOINTS //
///////////////////



// export enum Endpoint {
//     GET_CurrentUser = 'getCurrentUser',
//     GET_AllUsers = 'getUsers',
//     GET_AllUsersCount = 'getUserCount',
//     GET_UnverifiedUsersCount = 'getUnverifiedCount',
//     GET_UnverifiedUsers = 'getUsersUnverified',
//     GET_LockedUsersCount = 'getLockedCount',
//     GET_UserDataWithID = "getUserDataWithID",
//     PUT_UserDataWithID = "putUserDataWithID",
//     POST_UserPasswordReset = "postUserPasswordReset", // NOT IMPLEMENTED
//     GET_FeesNextYear = "getFeesNextYear",
//     POST_UnlockUser = "postUsersUnlocked",
//     POST_LockUser = "postUsersLocked",
//     GET_LockedUsers = "getUsersLocked",
//     DELETE_UserWithID = "deleteUserWithID",
//     POST_VerificationUserWithID = "postVerificationUserWithID"
// }

export const UserEndpoint: {
    GET: {
        users: string,
        usersCount: string,
        user: string,
        basePermissions: string,
    },
    PUT: {
        user: string
    },
    DELETE: {
        user: string
    },
    POST: {
        verification: string
    }
} = {
    GET: {
        users: "getUsers",
        usersCount: "getUserCount",
        user: "getUserDataWithID",
        basePermissions: "getUserBasePermissions",
    },
    PUT: {
        user: "putUserDataWithID"
    },
    DELETE: {
        user: "deleteUserWithID"
    },
    POST: {
        verification: "postVerificationUserWithID"
    }
}

const UserEndpointConfig: StaticEndpoint = {
    getUsers:                   APIGet("/users"),               // ?usertype=string,locked=boolean
    getUserCount:               APIGet("/users/count"),         // ?usertype=string,locked=boolean
    getUserDataWithID:          APIGet("/users/:id"), 
    putUserDataWithID:          APIPut("/users/:id"),
    deleteUserWithID:           APIDelete("/users/:id"),
    postVerificationUserWithID: APIPost("/users/:id/verify"),   // { accept: boolean, reason: string }    
    getUserBasePermissions: APIGet("/users/:id/permissions/base")
}

export const AuthEndpoint = {
    PUT: {
        resetPassword: "putResetPassword"
    },
    POST: {
        resetPasswort: "postResetPassword",
        bearer: "postBearer",
        confirmRegistration: "postConfirmRegistration",
        createUser: "postCreateUser",
        verifyEmail: "postVerifyEmail"
    }
}

const AuthEndpointConfig: StaticEndpoint = {
    postLogin:                  APIPost("/auth"),
    postBearer:                 APIPost("/auth/bearer"),
    postConfirmRegistration:    APIPost("/auth/confirm"),
    postCreateUser:             APIPost("/auth/create"),
    postVerifyEmail:            APIPost("/auth/verify"),
    postResetPassword:          APIPost("/auth/reset"),
    putResetPassword:           APIPut("/auth/reset")
}

export const NetworkEndpoint: {
    GET: {
        profile: string,
        profileImage: string,
        vorstand: string,
        circle: string,
        searchResult: string,
        getActiveFriendRequests: string,
        getIgnoredFriendRequests: string,
        getInstitutionList: string,
    },
    PUT: {
        profile: string
    },
    DELETE: {
        profileImage: string
    },
    POST: {
        friendRequest: string,
        acceptFriendRequest: string,
        profileImage: string
    }
} = {
    GET: {
        profile: "getProfile",
        profileImage: "getProfileImage",
        vorstand: "getVorstand",
        circle: "getCircle",
        searchResult: "getSearchResult",
        getActiveFriendRequests: "getActiveFriendRequests",
        getIgnoredFriendRequests: "getIgnoredFriendRequests",
        getInstitutionList: "getInstitutionList"
    },
    PUT: {
        profile: "putProfile"
    },
    POST: {
        friendRequest: "postFriendRequest",
        acceptFriendRequest: "postAcceptFriendRequest",
        profileImage: "postProfileImage",
    },
    DELETE: {
        profileImage: "deleteProfileImage"
    }
}

const NetworkEndpointConfig: StaticEndpoint = {
    getProfile:             APIGet("/network/:id"),
    putProfile:             APIPut("/network/:id"),
    postFriendRequest:      APIPost("/network/:id"),
    postAcceptFriendRequest:APIPost("/network/:id/accept"),
    postProfileImage:       APIPost("/network/:id/image"),
    deleteProfileImage:     APIDelete("/network/:id/image"),
    getVorstand:            APIGet("/network/vorstand"),
    getCircle:            APIGet("/network/circle"),
    getSearchResult:        APIGet("/network/search"),
    getInstitutionList:    APIGet("/network/:type"),

    //getProfileImage:       {...APIPost("/network/:id/image"), parseMethod: "formData"},
    getProfileImage: {
        url: API_BASE + "/network/:id/image",
        method: "GET",
        parseMethod: 'blob'
    },

    getActiveFriendRequests:APIGet("/network/friends/requests/active/count"),
    getIgnoredFriendRequests:APIGet("/network/friends/requests/ignored/count"),
}

export const ChoirEndpoint = {
    GET: {
        choirInfo: "getChoirInfo",
        all: "getChoirList",
    }
}

const ChoirEndpointConfig: StaticEndpoint = {
    getChoirInfo: APIGet("/choir/:id"),
    getChoirList: APIGet("/choir"),
}

export const EmailEndpoint = {
    GET: {
        getPreferences: "getUserEmailPreferences"
    },
    PUT: {
        editPreferences: "editUserEmailPreferences"
    },
}

const EmailEndpointConfig: StaticEndpoint = {
    getUserEmailPreferences: APIGet("/emails/preferences"),
    editUserEmailPreferences: APIPut("/emails/preferences"),
}


export const MemberChoirEndpoint = {
    GET: {
        memberChoirs: "getMemberChoirs"
    }
}

const MemberChoirEndpointConfig: StaticEndpoint = {
    getMemberChoirs: APIGet("/member-choirs"),
}

export const PaymentEndpoint = {
    GET: {
        overdueCount: "payment_get_overdueCount",
        waitingCount: "payment_get_waitingCount",
        restCount: "payment_get_restCount",
        history: "payment_get_history",
    }
}
const PaymentEndpointConfig: StaticEndpoint = {
    payment_get_overdueCount: APIGet("/payments/overdue/count"),
    payment_get_waitingCount: APIGet("/payments/waiting/count"),
    payment_get_restCount: APIGet("/payments/rest/count"),
    payment_get_history: APIGet("/payments/:id"),
}

export const EventEndpoint = {
    GET: {
        eventDates_upcoming: "events_upcoming",
        eventDates_list: "events_list",
        eventDates_comments: "events_get_comments",
        eventDates_registered: "event_get_registered",
        eventDates_recent: "events_recent",
        eventDates_friends: "event_get_registered_friends",
        event_image: "event_get_image",
    }
}
const EventEndpointConfig: StaticEndpoint = {
    events_upcoming: APIGet("/eventDates/find?page=:page"),
    events_recent: APIGet("/eventDates/find/recent?page=:page"),
    events_list: APIGet("/eventDates/find/list"),
    events_get_comments: APIGet("/eventDates/:id/comments"),
    event_get_registered: APIGet("/eventDates/:id/registered"),
    event_get_registered_friends: APIGet("/eventDates/:id/friends"),
    //event_get_image: APIGet("/events/:id/image"),
    //event_get_image: APIRequestScheme("/events/:id/image", "GET", {parseMethod: 'text'}),
    event_get_image: {
        url: API_BASE + "/eventDates/:id/image",
        method: "GET",
        parseMethod: 'blob'
    },
}

const endpointConfig: StaticEndpoint = {
    ...AuthEndpointConfig,
    ...UserEndpointConfig,
    ...NetworkEndpointConfig,
    ...ChoirEndpointConfig,
    ...EmailEndpointConfig,
    ...MemberChoirEndpointConfig,
    ...PaymentEndpointConfig,
    ...EventEndpointConfig,
}

// saveArticle: {
//     url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId',
//     method: 'POST',
//     afterSuccess: ({ dispatch, request, getState } ) => {
//         // After successful post, invalidate the cache of the getArticle call, so it gets re-triggered.
//         dispatch(invalidateRequest('getArticle', {articleId: request.params?.articleId ? request.params?.articleId : 0, userId: getState().userId})); 
//     }
// }

/////////////////////
// Configure Store //
/////////////////////

const _token = C.get('_token');

const bearer = typeof _token === 'string' ? _token : ''; //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJzZWN1cmUtYmFja2VuZCIsImF1ZCI6InNlY3VyZS1hcHAiLCJzdWIiOiJhZG1pbkB1bmlzb25vLmF0IiwiZXhwIjoxNjM0NTQ2NjcwLCJyb2wiOlsiREVMRVRFX0VWRU5UIiwiREVMRVRFX05FV1MiLCJFRElUX0FMTF9BU1NPQ0lBVEVEX1VTRVJfREFUQSIsIkVESVRfQUxMX05PVElGSUNBVElPTl9QUkVGRVJFTkNFUyIsIkVESVRfQUxMX1BBWU1FTlRfSElTVE9SWSIsIkVESVRfQUxMX1BFUlNPTkFMX1VTRVJfREFUQSIsIkVESVRfQUxMX1BST0ZJTEVTIiwiRURJVF9DUkVBVEVfRVZFTlQiLCJFRElUX0NSRUFURV9ORVdTIiwiRURJVF9GSU5BTkNJQUxfU1RBVElTVElDUyIsIkVESVRfTElTVF9DSE9JUlMiLCJFRElUX0xJU1RfRS1NQUlMSU5HX1BSRUZFUkVOQ0VTIiwiRURJVF9ORVhUX1lFQVJTX0ZFRVMiLCJFRElUX09XTl9BU1NPQ0lBVEVEX1VTRVJfREFUQSIsIkVESVRfT1dOX0UtTUFJTElOR19QUkVGRVJFTkNFUyIsIkVESVRfT1dOX05PVElGSUNBVElPTl9QUkVGRVJFTkNFUyIsIkVESVRfT1dOX1BFUlNPTkFMX1VTRVJfREFUQSIsIkVESVRfT1dOX1BST0ZJTEUiLCJFRElUX1VTRVJfUEVSTUlTU0lPTlMiLCJFRElUX1VTRVJfVFlQRSIsIlNFTkRfQ09ORklSTV9DT05UQUNUX1JFUVVFU1QiLCJTRU5EX0VNQUlMX1RPX01FTUJFUlMiLCJWSUVXX0FMTF9BVFRFTkRFRVNfREFUQSIsIlZJRVdfQUxMX1BBWU1FTlRfSElTVE9SWSIsIlZJRVdfQUxMX1BVQkxJQ19QUk9GSUxFUyIsIlZJRVdfQUxMX1VTRVJfREFUQSIsIlZJRVdfQVRURU5ESU5HX0ZSSUVORFMiLCJWSUVXX0VWRU5UUyIsIlZJRVdfRklOQU5DSUFMX1NUQVRJU1RJQ1MiLCJWSUVXX05FV1MiLCJWSUVXX05FWFRfWUVBUlNfRkVFUyIsIlZJRVdfT1dOX1BBWU1FTlRfSElTVE9SWSIsIlZJRVdfT1dOX1BST0ZJTEUiLCJWSUVXX09XTl9VU0VSX0RBVEEiLCJWSUVXX1JFR0lTVEVSX0VWRU5UIiwiVklFV19VU0VSX1BFUk1JU1NJT05TIiwiVklFV19VU0VSX1RZUEUiXSwidXRwIjoiYWRtaW4iLCJ1aWQiOjF9.HxICulDvm2S7czFwUxgzjG0_5z_r_t3nSd5CfAbnwDD77BvpTK_8yvuS70jNexjwlPDkMauClf1NP5IwJkAuMQ";

const store = createStore(
    combineReducers({
        apiData: reducer
    }), 
    applyMiddleware(thunk)
)

store.dispatch(configure({
    setHeaders: (defaultHeaders: any) : Object => { //removed unused parameter ", state: any"
        return {
            ...defaultHeaders,
            "Authorization": "Bearer " + bearer
        }
    },
    timeout: 7 * 1000,
    parseMethod: "json"
}, endpointConfig));

export default store
import React, {useCallback, useState} from "react";
import { useActions, useApiData } from "react-api-data";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../../components/UI/Buttons/ButtonSpin";
import ApiCycle from "../../../../modules/API/ApiCycle";
import {RoleDto, UserDto} from "../../../../modules/API/APIInterfaces";
import { UserEndpoint } from "../../../../store/APIStore";

import {usersVerifyUser} from "../../../../modules/API/Services/UserService";
import { MemberDetailSubComponentProps } from "../MemberDetail";
import { FieldCheck } from "../../../../components/Fields";
import { UserRolesEntity, UserTypesEntity } from "../../../../components/Data/MemberPersonalData";
import _ from "lodash";
import {Field, FieldArray, Formik} from "formik";
import { Alert, Table } from "react-bootstrap";
import {UserGroup, UserPermission} from "../../../../modules/Auth";
import { FormikSubmitCancel } from "../../../../components/Data";
import usePermissionBag from "../../../../hooks/usePermissionBag";
import * as Fields from "../../../../components/Fields";


export default function AdministrationComponent(props: MemberDetailSubComponentProps) : JSX.Element {
    return  <>
                <UserVerifyComponent userID={props.userID} />            
                <UserManageComponent userID={props.userID} />
            </>
}

const UserVerifyComponent = (props: { userID: string }) : JSX.Element => {

    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})

    const DataExtractor = (data: UserDto): JSX.Element => {
        const [accept, setAccept] = useState(true);
        const [reason, setReason] = useState("");
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [error, setError] = useState("");
    
        const actions = useActions()
        
        const handleVerify = () => {
            if(!accept && reason==="") {
                setError("Beim Ablehnen musst Du einen Grund angeben.");
                return;
            }
            
            setIsSubmitting(true);
            usersVerifyUser(props.userID, accept, reason).then(() => {
                setError("");
                setIsSubmitting(false);
                actions.invalidateCache(UserEndpoint.GET.user, {id: props.userID});
                actions.invalidateCache(UserEndpoint.GET.users);
                actions.invalidateCache(UserEndpoint.GET.usersCount, { usertype: "unverified", locked: 0 })
                actions.invalidateCache(UserEndpoint.GET.usersCount, { usertype: "default,circle", locked: 0 })
            }).catch((error: any) => {
                setError(AxiosErrorMessageGetter(error));
                setIsSubmitting(false);
            });
        };
    
        if (data.userType === UserGroup.NotVerified) {
    
            return <>
                <Table hover className="table-grid">
                    <tbody>
                    <tr>
                        <th scope="row">
                            <p className="fw-bold">Verifiziere BenutzerIn</p>
                            <p className="small">Dies ist notwendig, da nur Alumni aufgenommen werden dürfen.<br/>Schau bitte ob diese Person wirklich bei den WSK war.</p>
                        </th>
                        <td>
                            <div className="form-check">
                                <input id="administration-accept-registration-input" className="form-check-input" type="checkbox" checked={accept} onChange={() => setAccept(!accept)} />
                                <label htmlFor="administration-accept-registration-input" className="form-check-label">Anmeldung akzeptieren?</label>
                            </div>
                            {!accept && <div className="mb-3">
                                <label>Grund für das Ablehnen</label>
                                <input className="form-control" value={reason} onChange={(e)=> {setReason(e.target.value)} } />
                            </div>}

                            <div className="d-flex w-75 m-auto">
                                <ButtonSpin type="button" skin="outline" variant="secondary" onClick={() => {
                                    setAccept(true);
                                    setReason("");
                                } } spinning={isSubmitting}>Abbrechen</ButtonSpin>
                                <ButtonSpin type="button" variant={accept ? "success" : "danger"} onClick={handleVerify} spinning={isSubmitting}>{accept ? "Verifiziere BenutzerIn":"Lehne BenutzerIn ab"}</ButtonSpin>
                            </div>
                            { error && <Alert variant="danger">{error}</Alert>}
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </>
    
        } else { return <></> }
    }
    
    return <ApiCycle data={user} extractor={DataExtractor} />
}

const UserManageComponent = (props: { userID: string }): JSX.Element => {

    const user = useApiData<UserDto, string>(UserEndpoint.GET.user, {id: props.userID})

    const DataExtractor = (data: UserDto) => {

        const [isSubmitting, setIsSubmitting] = useState(false)
        const [error, setError] = useState<string>()

        const putUser = useApiData<UserDto, string>(UserEndpoint.PUT.user, {id: props.userID})
        const basePermissionsRequest = useApiData<string[], string>(UserEndpoint.GET.basePermissions, {id: props.userID})

        const handleSubmit = (values: AdministrationUserDto) => {
            setIsSubmitting(true)
            console.log("Updating user with data:", values, "and new:", values, "is:", { ...data, ...values })
            
            const u = {...data, ...values};
            putUser.perform({id: props.userID}, u).then(value => {
                if (value.data) user.invalidateCache()  //actions.invalidateCache(UserEndpoint.GET.user, {id: props.userID})
            }).catch(reason => {
                setError(reason)
            }).finally(() => {
                setIsSubmitting(false)
            })            
        }

        type AdministrationUserDto = {
            id: number,
            userType: string,
            ehrenMitglied: boolean,
            role: string,
            roleAdds: RoleDto[],
            locked: boolean,
            ausgetreten?: string,
            leftDate: string,
            leftReason: string
        }
        
        const initial = _.pick(data, ["id", "userType", "ehrenMitglied", "role", "roleAdds", "locked", "leftDate", "leftReason"])
        const clone = _.cloneDeep(initial) as AdministrationUserDto

        const userTypeChoices = UserTypesEntity.asHTMLFieldCheckChoices
        const userRoleChoices = UserRolesEntity.asHTMLFieldCheckChoices
        const userTypeChoicesDisabled: boolean[] = new Array(userTypeChoices.length).fill(isSubmitting);
        if(!clone.ausgetreten) userTypeChoicesDisabled[userTypeChoicesDisabled.length-1] = true;

        const includesRole = useCallback((roleAdds: RoleDto[], role: RoleDto): boolean => {
            for(let i=roleAdds.length-1; i>=0; --i) {
                if(roleAdds[i].role===role.role) {
                    return true;
                }
            }
            return false;
        }, []);
        const indexOfRole = useCallback((roleAdds: RoleDto[], role: RoleDto): number => {
            for(let i=roleAdds.length-1; i>=0; --i) {
                if(roleAdds[i].role===role.role) {
                    return i;
                }
            }
            return -1;
        }, []);
        
        const permissions = usePermissionBag();
        const hasPermissions = permissions.hasPermission(UserPermission.ViewUserType, UserPermission.ViewUserPermissions);
        if(hasPermissions.none || data.userType === UserGroup.NotVerified) {
            return <></>;
        }
        
        return <Formik
                    initialValues={clone}
                    onSubmit={handleSubmit}
                >{ formik => {
                    return <form onSubmit={formik.handleSubmit}>
                                {isSubmitting ? <>Submitting</> :
                                <>
                                    <Table hover variant="light" className="table-grid">
                                        <tbody>
                                        <tr>
                                            <th scope="row" style={{width: "30%"}}>
                                                <h6 className="mb-0">User Type</h6>
                                            </th>
                                            <td style={{width: "70%"}}>
                                                <FieldCheck disabled={userTypeChoicesDisabled} name="userType" label="User Type" choices={userTypeChoices} renderAsButtonGroup noLabel noStyle />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <h6 className="mb-0">User Berechtigung</h6>
                                                <p className="mb-0 fw-normal small">Diese Einstellung überschreibt die Berechtigungs-Stufe, die von <code>User Type</code> vererbt wird.</p>
                                            </th>
                                            <td>
                                                <FieldCheck disabled={isSubmitting} name="role" label="User Berechtigung" choices={userRoleChoices} renderAsButtonGroup noLabel noStyle />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <h6 className="mb-0">Ehrenmitgliedschaft</h6>
                                                <p className="mb-0 fw-normal small">Wenn ein User als Ehrenmitglied geführt wird, ist dieser von Zahlungen von Mitgliedsbeiträgen befreit.</p>
                                            </th>
                                            <td>
                                                <label className={ ( formik.values.ehrenMitglied ? "active " : "") + "btn btn-outline-primary"}>
                                                    <Field name="ehrenMitglied" type="checkbox" className="btn-check" />
                                                    {formik.values.ehrenMitglied ? <>Deaktivieren</> : <>Aktivieren</>}
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <h6 className="mb-0">User Sperre</h6>
                                                <p className="mb-0 fw-normal small">Wenn ein User von Administratoren gesperrt wurde, kann dieser sich nicht mehr einloggen</p>
                                            </th>
                                            <td>
                                                <label className={ ( formik.values.locked ? "active " : "") + "btn btn-outline-danger"}>
                                                    <Field name="locked" type="checkbox" className="btn-check" />
                                                    {formik.values.locked ? <>Entsperren</> : <>Sperren</>}
                                                </label>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <div className="my-2">
                                        <h5 className="mb-1">User Austritt</h5>
                                        <p className="mb-2 fw-normal small">Bitte frühestens den nächsten Tag angeben, damit es (heute) noch einfach rückgängig gemacht werden kann: Ab dem Austrittsdatum gilt ein User als ausgetreten. Wenn ein User ausgetreten ist, kann dieser sich nicht mehr einloggen, ist nicht mehr im Netzwerk sichtbar und nicht mehr in der Chor Liste.</p>
                                        <Fields.FieldColumn>
                                            <Fields.FieldInput name="leftDate" label="Austrittsdatum" formik={formik} type="date" />
                                            <Fields.FieldInput name="leftReason" label="Austrittsgrund" formik={formik} type="string" />
                                        </Fields.FieldColumn>
                                        {formik.values.leftDate!=null && new Date(formik.values.leftDate)<new Date() && <Alert variant="danger">Austritt wird sofort ausgeführt bei Speicherung</Alert>}
                                        {(formik.values.leftDate!=null || formik.values.leftReason!=null) && 
                                            <>
                                                <button type="button" className="btn w-100 btn-outline-danger" onClick={() => {formik.setFieldValue("leftDate", "");formik.setFieldValue("leftReason", "")} }>
                                                    {(clone.leftDate!=null && new Date(clone.leftDate)<new Date()) ? "Wiedereintritt" : "Austritt rückgängig machen"}
                                                </button>
                                            </>}
                                    </div>

                                    <ApiCycle data={basePermissionsRequest} extractor={(basePermissions: string[]) => <>
                                        <h5 className="mt-3">User Berechtigungen</h5>
                                        <FieldArray validateOnChange={false} name="roleAdds" render={() => <>
                                            {Object.values(UserPermission).map((perm: string) => {
                                                const baseContains = basePermissions.includes(perm);
                                                const rol: RoleDto = {role: perm};
                                                return <div key={perm} className="form-check">
                                                    <label>
                                                        <Field type="checkbox" className="form-check-input" disabled={baseContains} checked={baseContains || includesRole(formik.values.roleAdds,rol)} 
                                                               onBlur={() => {}} onChange={() => {}} onClick={() => {
                                                            const ind = indexOfRole(formik.values.roleAdds, rol);
                                                            if(ind!==-1) {
                                                                formik.values.roleAdds.splice(ind, 1);
                                                            } else {
                                                                formik.values.roleAdds.push(rol);
                                                            }
                                                            formik.setTouched({roleAdds: []}, true);
                                                        }}/>
                                                        {perm}
                                                    </label>
                                                </div>;
                                            })}
                                        </>}/>
                                            
                                        {/*Object.values(UserPermission).map((perm: string, index: number) => {
                                            const contains = basePermissions.includes(perm);
                                            return <div key={perm} className="form-check">
                                                <label>
                                                    <Field type="checkbox" className="form-check-input" name={"roleAdds["+index+"]"} disabled={contains} checked={contains} />
                                                    {perm}
                                                </label>
                                            </div>;
                                        })*/}
                                    </>}/>
                                </>}
                                <FormikSubmitCancel formik={formik} hideSubmit={false} />
                                { error && <Alert variant="danger">{error}</Alert>}
                            </form>
                }}</Formik>
    }

    return <ApiCycle data={user} extractor={DataExtractor} />

}


/* <div className="mb-3">
<label>BenutzerIn Typ</label>
<select className="form-control" onChange={(e) => {
    const u = {...user};
    u.userType = e.target.value;
    setUser(u);
}} value={user.userType}>
    <option value="unregistered">Unregistriert</option>
    <option value="unverified">Warte auf Verifizierung</option>
    <option value="default">Normal</option>
    <option value="circle">Circle</option>
    <option value="vorstand">Vorstand</option>
</select>

</div> */
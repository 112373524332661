import React from "react";
import {Helmet} from "react-helmet-async";
import usePageTitle from "../../../hooks/usePageTitle";

export function Title(props: React.PropsWithChildren<{title?: string, noRender?: boolean}>) {

    const pageName = "UNISONO Alumni Club"
    const shortPageName = "UNISONO"
    const divider = " – "
    const maxChars = 60

    const pageTitle = usePageTitle()
    
    if ( props.title && props.title !== pageTitle.pageTitle ) {
        pageTitle.setPageTitle(props.title)
    }

    let title = ""

    if (pageTitle.pageTitle === pageName) {
        title = pageName
    } else {
        title = pageName + divider + pageTitle.pageTitle
        if ( title.length > maxChars ) {
            title = shortPageName + divider + pageTitle.pageTitle
        }
    }        

    return <>
        <Helmet title={title}/>
        {
            (props.noRender === undefined || !props.noRender) && (props.children || (props.title &&
            <div className="site-content-header">
                <h2>{props.title}</h2>
            </div>))
        }
    </>;
}

export default function PageTitle(): JSX.Element {

    const pageName = "UNISONO Alumni Club"
    const shortPageName = "UNISONO"
    const divider = " – "
    const maxChars = 60

    const {pageTitle} = usePageTitle()
    
    let title = pageTitle

    if (title !== pageName) {
        title = pageName + divider + pageTitle
        if ( title.length > maxChars ) {
            title = shortPageName + divider + pageTitle
        }
    }
    
    return  <>
                <Helmet title={title}/>
                <div className="site-content-header">
                    <h1 className="text-gradient">{pageTitle}</h1>
                </div>
            </>
}
import { NavLink } from "react-router-dom"
import { NavigationItemType } from "./UINavigationItem/UINavigationItem"

interface ListNavigationProps {
    navItems: NavigationItemType[]
}

export default function ListNavigation(props: ListNavigationProps) : JSX.Element {
    return  <nav className="nav nav-pills nav-justified flex-column p-sticky bg-light p-2 rounded" style={{top: 0, left:0}}>
                {
                    props.navItems.map((value, i, arr) => {
                        return <NavLink key={i} exact={value.exact} to={value.to} className={ "nav-link " + (i < arr.length - 1 ? "mb-1" : "" )} activeClassName="active">{value.name} {value.badge}</NavLink>
                    })
                }
            </nav>
}
import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken} from "../APIInterfaces";

export type EmailBasicGroup = "choir" | "all" | "unregistered-paid" | "unregistered-unpaid" | "unpaid";

export interface EmailBasic {
    toGroup: EmailBasicGroup | string,
    subject: string,
    title: string,
    text: string,
    id?: number
}

export function emailBasicSend(email: EmailBasic): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE+"/emails/"+email.toGroup+(email.id?"/"+email.id:""), email, {headers: {Authorization: "Bearer "+getToken()}})
}
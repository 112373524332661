import './style.scss';

export default function APISuspense(props: {
    type?: "wait" | "fail",
    maxWidth?: number,
    width?: number,
    top?: number
} = { type: "wait" }) : JSX.Element {

    const errorSVG = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI0QwNzIxMiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNi45NS40MzVjLjU4LS41OCAxLjUyLS41OCAyLjEgMGw2LjUxNSA2LjUxNmMuNTguNTguNTggMS41MTkgMCAyLjA5OEw5LjA1IDE1LjU2NWMtLjU4LjU4LTEuNTE5LjU4LTIuMDk4IDBMLjQzNSA5LjA1YTEuNDgyIDEuNDgyIDAgMCAxIDAtMi4wOThMNi45NS40MzV6bTEuNC43YS40OTUuNDk1IDAgMCAwLS43IDBMMS4xMzQgNy42NWEuNDk1LjQ5NSAwIDAgMCAwIC43bDYuNTE2IDYuNTE2YS40OTUuNDk1IDAgMCAwIC43IDBsNi41MTYtNi41MTZhLjQ5NS40OTUgMCAwIDAgMC0uN0w4LjM1IDEuMTM0eiIvPgogIDxwYXRoIGQ9Ik01LjI1NSA1Ljc4NmEuMjM3LjIzNyAwIDAgMCAuMjQxLjI0N2guODI1Yy4xMzggMCAuMjQ4LS4xMTMuMjY2LS4yNS4wOS0uNjU2LjU0LTEuMTM0IDEuMzQyLTEuMTM0LjY4NiAwIDEuMzE0LjM0MyAxLjMxNCAxLjE2OCAwIC42MzUtLjM3NC45MjctLjk2NSAxLjM3MS0uNjczLjQ4OS0xLjIwNiAxLjA2LTEuMTY4IDEuOTg3bC4wMDMuMjE3YS4yNS4yNSAwIDAgMCAuMjUuMjQ2aC44MTFhLjI1LjI1IDAgMCAwIC4yNS0uMjV2LS4xMDVjMC0uNzE4LjI3My0uOTI3IDEuMDEtMS40ODYuNjA5LS40NjMgMS4yNDQtLjk3NyAxLjI0NC0yLjA1NiAwLTEuNTExLTEuMjc2LTIuMjQxLTIuNjczLTIuMjQxLTEuMjY3IDAtMi42NTUuNTktMi43NSAyLjI4NnptMS41NTcgNS43NjNjMCAuNTMzLjQyNS45MjcgMS4wMS45MjcuNjA5IDAgMS4wMjgtLjM5NCAxLjAyOC0uOTI3IDAtLjU1Mi0uNDItLjk0LTEuMDI5LS45NC0uNTg0IDAtMS4wMDkuMzg4LTEuMDA5Ljk0eiIvPgo8L3N2Zz4=" //"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICBmaWxsPSJ2YXIoLS1icy1kYW5nZXIpIiB2aWV3Qm94PSIwIDAgMTYgMTYiPjxwYXRoIGQ9Ik0xMS40Ni4xNDZBLjUuNSAwIDAgMCAxMS4xMDcgMEg0Ljg5M2EuNS41IDAgMCAwLS4zNTMuMTQ2TC4xNDYgNC41NEEuNS41IDAgMCAwIDAgNC44OTN2Ni4yMTRhLjUuNSAwIDAgMCAuMTQ2LjM1M2w0LjM5NCA0LjM5NGEuNS41IDAgMCAwIC4zNTMuMTQ2aDYuMjE0YS41LjUgMCAwIDAgLjM1My0uMTQ2bDQuMzk0LTQuMzk0YS41LjUgMCAwIDAgLjE0Ni0uMzUzVjQuODkzYS41LjUgMCAwIDAtLjE0Ni0uMzUzTDExLjQ2LjE0NnpNOCA0Yy41MzUgMCAuOTU0LjQ2Mi45Ljk5NWwtLjM1IDMuNTA3YS41NTIuNTUyIDAgMCAxLTEuMSAwTDcuMSA0Ljk5NUEuOTA1LjkwNSAwIDAgMSA4IDR6bS4wMDIgNmExIDEgMCAxIDEgMCAyIDEgMSAwIDAgMSAwLTJ6Ii8+PC9zdmc+"
    const errorAlt = "Anfrage an Server ist fehlgeschlagen."

    const loadingSVG = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kLWltYWdlOiBub25lOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBzdHJva2U9IiM2ODcyNzUiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjMwIiBzdHJva2UtZGFzaGFycmF5PSIxNDEuMzcxNjY5NDExNTQwNjcgNDkuMTIzODg5ODAzODQ2ODkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgZHVyPSIxcyIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvY2lyY2xlPjwvc3ZnPg=="
    const loadingAlt = "Anfrage wird vom Server bearbeitet."

    
    return  <div title={props.type === "fail" ? errorAlt : undefined} style={{flex: 1, maxWidth: (props.maxWidth || props.width || 24) + "px", margin: 0, padding: 0, width: (props.width || props.maxWidth || 24) + "px", aspectRatio: "1", height: (props.width || props.maxWidth || 24) + "px"}}>
                <img 
                    style={{
                        width: "auto",
                        position: props.top ?"relative" : "inherit",
                        top: props.top ? (props.top + "px") : "auto",
                        height: "100%"
                    }} //"min-width: 16px;width: auto;max-width: 128px;min-height: 16px;height: auto;max-height: 128px;flex-grow: 2"
                    src={props.type === "wait" ? loadingSVG : errorSVG} alt={props.type === "wait" ? loadingAlt : errorAlt}></img>
            </div>
}
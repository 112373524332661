import { useCallback } from "react";
import { useApiData } from "react-api-data";
import { useHistory } from "react-router-dom";
import { DataViewColumns, DataViewRow } from "../../../components/DataView";
import DataViewTable from "../../../components/DataView/UI/DataViewTable/DataViewTable";
import { larger, useBreakpoint } from "../../../context/Breakpoint";
import ApiCycle from "../../../modules/API/ApiCycle";
import { UserEndpoint } from "../../../store/APIStore";
import { IUserAdminOverview, MemberRow } from "./MembersOverview";

export default function LockedDetail() : JSX.Element {
    const users = useApiData<IUserAdminOverview[]>(UserEndpoint.GET.users, {locked: "true"})
    return <ApiCycle data={users} extractor={APIDataExtractor} />  
}

function APIDataExtractor(data: IUserAdminOverview[]) : JSX.Element {

    const history = useHistory()
    const viewMember = useCallback((row: DataViewRow) => history.push(`/apacis/members/view/${row.id}`), [history])
    const bp = useBreakpoint()
    
    const rows: MemberRow[] = data.map(value => {
        return {id: value.id, firstName: value.firstName, lastName: value.lastName, email: value.email}
    })

    const headers: DataViewColumns = larger("md", bp, true) ? {
        id: "ID",
        firstName: 'Vorname',
        lastName: "Nachname",
        email: "E-Mail-Adresse"
    } : {
        firstName: "Vorname",
        lastName: "Nachname"
    }

    return  <DataViewTable
                headers={headers}
                content={rows}
                useSearch={true}
                useSort={true}
                usePagination={true}
                rowOnClick={viewMember}
            />
}
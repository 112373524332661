import React from "react";

export interface MessageState {
    type: "danger" | "success" | "warning";
    message: string;
}

export function MessageDisplay(props: {message?: MessageState}): JSX.Element {
    if(!props.message) return <></>;
    return <div className={"alert alert-"+props.message.type}>{props.message.message}</div>;
}
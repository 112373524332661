import { decode } from "jsonwebtoken";
import { UserGroup, UserPermission } from ".";
import C from "../cookies";
import PermissionBag from "./PermissionBag";

/**
 * Revokes (removes) permissions from an array of {@link UserPermission}s.
 * 
 * @param granted An array of {@link UserPermission}.
 * @param permissions {@link UserPermission}s which are to be revoked from `granted`.
 * @returns An array of {@link UserPermission} with the result.
 * 
 * @since 1.0.0 
 */
export const revokePermission = (granted: UserPermission[], ...permissions: UserPermission[]) : UserPermission[] => {

    // clone for local use
    let tmpGranted = [...granted];

    // for each permission, do...
    // get index of permission in tmpGranted (-1 if not found)
    // splice element at index and delete 1 element
    permissions.forEach((value) => {
        const index: number = tmpGranted.indexOf(value);
        if (index !== -1) tmpGranted.splice(index, 1);
    });

    return tmpGranted;
};

/**
 * Use this class' static methods to create and manipulate arrays of {@link UserPermission} or {@link ParameterBag}s
 * @since 1.0.0
 */
export default class AbstractPermissions {

    /**
     * Loads permissions from the token stored in a cookie and creates a {@link PermissionBag} for it.
     * @returns An instance of {@link PermissionBag} with loaded permissions.
     * 
     * @since 1.0.0
     */
    static LoadFromToken(): PermissionBag {

        // Get token
        const encodedToken = C.get("_token");

        // If token is not a string, return a NotAuthenticated PermissionBag.
        if (typeof encodedToken !== 'string') return(AbstractPermissions.NotAuthenticated);

        // Decode the token
        const decodedToken = decode(encodedToken);
        
        // If token could not be decoded properly, return a NotAuthenticated PermissionBag.
        if (typeof decodedToken === "string" || decodedToken === null) return(AbstractPermissions.NotAuthenticated);
        
        // parse the permissions as string to an array of UserPermission.
        const roles = AbstractPermissions.parseStringPermission(...decodedToken.rol);

        // return a new PermissionBag, with the loaded roles being granted.
        return ( new PermissionBag(decodedToken.utp, "static.Load").grant(...roles));
    }
    
    /**
     * A static {@link ParameterBag} containing no permissions.
     */
    static NotAuthenticated: PermissionBag = new PermissionBag(UserGroup.None);

    /**
     * A static {@link ParameterBag} containing these permissions: ViewOwnUserData and ViewOwnPaymentHistory.
     */
    static Authenticated: PermissionBag = (new PermissionBag()).grant(UserPermission.User, UserPermission.User);
    
    /**
     * A static {@link ParameterBag} containing the default permissions of {@link UserGroup.Alumni}.
     */
    static Member: PermissionBag = new PermissionBag(UserGroup.Alumni, "static.Member");
        
    /**
     * A static {@link ParameterBag} containing the default permissions of {@link UserGroup.Board}.
     */
    static Board: PermissionBag = new PermissionBag(UserGroup.Board, "static.Board");
        
    /**
     * A static {@link ParameterBag} containing the default permissions of {@link UserGroup.Admin}.
     */
    static Admin: PermissionBag = new PermissionBag(UserGroup.Admin, "static.Admin");

    /**
     * Creates an empty instance of {@link ParameterBag} with certain permissions.
     * 
     * @param permission The permissions to be granted.
     * 
     * @returns An instance of {@link ParameterBag} with permissions specified in `permission`.
     * 
     * @since 1.0.0
     */
    static withPermission(...permission: UserPermission[]) : PermissionBag {
        return (new PermissionBag()).grant(UserPermission.User, UserPermission.User).grant(...permission);
    }

    /**
     * Takes a string array and returns an array of {@link UserPermission}.
     * 
     * @param permissions Strings representing permissions which will be matched to the enum {@link UserPermission}.
     * 
     * @returns An array of {@link UserPermission} with all permissions which could be matched from `permissions`. Strings which could not be mapped are ommitted.
     * 
     * @since 1.0.0
     */
    static parseStringPermission(...permissions: string[]): UserPermission[] {

        // console.log(permissions);
        

        const result: UserPermission[] = [];
        const couldNotParse: string[] = [];

        permissions.forEach(value => {

            var foundValue = false;
            
            // go through the values of the enum and if the string values match
            // add it to our result and break the forEach loop.
            Object.values(UserPermission).forEach((perm, index, arr) => {

                if (perm.valueOf() === value) {
                    result.push(perm); 
                    foundValue = true;
                } else if (!foundValue && index === arr.length -1) {
                    couldNotParse.push(value);
                }
            });
            
        });

        if (couldNotParse.length > 0) {
            console.warn("Hi, I could not parse the following permissions:", couldNotParse);
            
        }
        
        return result;
    }

    /**
     * Revokes (removes) permissions from an array of {@link UserPermission}s.
     * 
     * @param granted An array of {@link UserPermission}.
     * @param permissions {@link UserPermission}s which are to be revoked from `granted`.
     * @returns An array of {@link UserPermission} with the result.
     * 
     * @since 1.0.0 
     */
    static revokePermission = revokePermission; 
    // (granted: UserPermission[], ...permissions: UserPermission[]) : UserPermission[] {

    //     // clone for local use
    //     let tmpGranted = [...granted];

    //     // for each permission, do...
    //     // get index of permission in tmpGranted (-1 if not found)
    //     // splice element at index and delete 1 element
    //     permissions.forEach((value) => {
    //         const index: number = tmpGranted.indexOf(value);
    //         if (index !== -1) tmpGranted.splice(index, 1);
    //     });

    //     return tmpGranted;
    // }

    /**
     * Grants (adds) permissions to an array of {@link UserPermission}s.
     * 
     * @param granted An array of {@link UserPermission}.
     * @param permissions {@link UserPermission}s which are to be added to `granted`.
     * @returns An array of {@link UserPermission} with the result.
     * 
     * @since 1.0.0 
     */
    static grantPermission(granted: UserPermission[], ...permissions: UserPermission[]) : UserPermission[] {

        // clone for local use
        let tmpGranted = [...granted];

        permissions.forEach(value => !tmpGranted.includes(value) ? tmpGranted.push(value) : null);

        return tmpGranted;
    }

    /**
     * 
     * @param permission 
     * @param and 
     * @returns 
     */
    static permissionsMatch(user: PermissionBag, permission: PermissionBag | UserGroup | boolean, and: boolean) : boolean {

        let matchedPermissions: boolean = false;


            
        // permission.forEach(v => matchedPermissions.push(userHasPermission(v)))
    
        return matchedPermissions;
    }

    static userHasPermission(user: PermissionBag, permission: PermissionBag | UserGroup | boolean) : boolean {
        let userHasPermission: boolean = false;
        if (permission instanceof PermissionBag) {
            const result = user.hasPermission( ...permission.cleanedPermissions() );

            userHasPermission = result.all;
            // console.log("ALL PERMISSIONS (" + permission.cleanedPermissions().join(", ") + "): ", userHasPermission)
            // console.log(result);
            
        } else if (typeof permission === "boolean") {
            // if permission is of type boolean:
            // *true* triggers a redirect. 
            // as we check for permssion here and the *absence* of permission triggers the redirect
            // we need to inverse the boolean permissionalue here.
            // console.log("BOOLEAN: ", permission)
            userHasPermission = permission;
        } else {
            userHasPermission = user.isGroup(permission, true);
            // console.log("USER GROUP (" + permission.toString() + "): ", userHasPermission)
        }
        return (userHasPermission);
    }
}

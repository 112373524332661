import GridCard from "../../../../components/GridCard";
import {CalendarOutline, ConstructionRibbon, GitHub, Globe, ProfileRound, UICheck} from "../../../../components/Icons";
import {bootstrapQueries, is, smaller, useBreakpoint} from "../../../../context/Breakpoint";
import {ShowIfType} from "../../../../hoc/Authenticated";
import {UserGroup} from "../../../../modules/Auth";
import usePageTitle from "../../../../hooks/usePageTitle";
import { NotRegisteredAlert, NotVerifiedAlert } from "../../../../components/SpecificAlerts";
import { Link } from "react-router-dom";

export default function IndexController() : JSX.Element {

    const bp: typeof bootstrapQueries = useBreakpoint()
    usePageTitle("Startseite")
    let gridTemplateColumns = "[cstart] 1fr [c1] 1fr [c2] 1fr [cend]"
    let gridTemplateRows = "[rstart] 1fr [r1] 1fr [rend]"

    if (is("lg", bp)) {
        gridTemplateColumns = "repeat(12, 1fr)"
    } else if (is("md", bp)) {
        gridTemplateColumns = "repeat(2, 1fr)"
        gridTemplateRows = "[rstart] 1fr [r1] 1fr [r2] 1fr [rend]"
    } else if (smaller("md", bp)) {
        gridTemplateColumns = "repeat(1, 1fr)"
        gridTemplateRows = "[rstart] 1fr [rend]"
    }

    return  <>
            <ShowIfType type={UserGroup.NotVerified}>
                <div className="grid" style={{gridTemplateColumns: "1fr"}}>
                        <NotVerifiedAlert />
                </div>
            </ShowIfType>
            <ShowIfType type={UserGroup.NotRegistered}>
                <div className="grid" style={{gridTemplateColumns: "1fr"}}>
                    <NotRegisteredAlert />
                </div>
            </ShowIfType>
            <div className="grid" style={{
                gridTemplateColumns: gridTemplateColumns,
                gridTemplateRows: gridTemplateRows
            }}>
                    <GridCardEvents />
                    <GridCardNetwork />
                    <GridCardProfile />
                    <GridCardFeedback />
                    <GridCardGitHub />
            </div>
            </>
}


const GridCardEvents = () => {

    const bp: typeof bootstrapQueries = useBreakpoint()
    let cStart = "cstart"
    let cEnd = "c2"
    let rStart: string | undefined = "rstart"
    let rEnd:string | undefined = "r1"

    if (is("lg", bp)) {
        cStart = "1"
        cEnd = "7"
    } else if (is("md", bp)) {
        cStart = "1"
        cEnd = "3"
    } else if (smaller("md", bp)) {
        cStart = "1"
        cEnd = "2"
        rStart = undefined
        rEnd = undefined
    }

    return  <GridCard
                title="Events"
                cStart={cStart} cEnd={cEnd}
                rStart={rStart} rEnd={rEnd}
                hoverEffect>
                    <div className="grid-card-ribbon"><ConstructionRibbon /></div>
                    <div className="d-block">
                        <CalendarOutline  size={96} color="secondary" />
                        <Link className="h4 mt-4 stretched-link d-block" to="/events">Sieh Dir an, welche Veranstaltungen bevorstehen.</Link>
                        <p className="h5 mb-0 text-muted">Und melde Dich einfach per Klick an.</p>
                    </div>
                {/* <ApiCycle data={request} /> */}
            </GridCard>
    
}

const GridCardNetwork = () => {

    const bp: typeof bootstrapQueries = useBreakpoint()
    let cStart = "c2"
    let cEnd = "cend"
    let rStart: string | undefined = "rstart"
    let rEnd:string | undefined = "r1"

    if (is("lg", bp)) {
        cStart = "7"
        cEnd = "13"
    } else if (smaller("md", bp, true)) {
        cStart = "1"
        cEnd = "2"
        rStart = undefined
        rEnd = undefined
    }

    return  <GridCard
                title="Netzwerk"
                cStart={cStart} cEnd={cEnd}
                rStart={rStart} rEnd={rEnd}
                hoverEffect>
                    <div className="d-block">
                        <Globe  size={96} color="secondary" />
                        <Link className="h4 mt-4 stretched-link d-block" to="/network">Finde Mitglieder.</Link>
                        <p className="h5 mb-0 text-muted">Und knüpfe Kontakte.</p>
                    </div>
                {/* <ApiCycle data={request} /> */}
            </GridCard>
    
}

const GridCardProfile = () => {
    
    const bp: typeof bootstrapQueries = useBreakpoint()
    let cStart = "cstart"
    let cEnd = "c1"
    let rStart: string | undefined = "r1"
    let rEnd:string | undefined = "rend"

    if (is("lg", bp)) {
        cStart = "1"
        cEnd = "5"
    } else if (is("md", bp)) {
        cStart = "2"
        cEnd = "3"
        rStart = undefined
        rEnd = undefined
    } else if (smaller("md", bp)) {
        cStart = "1"
        cEnd = "2"
        rStart = undefined
        rEnd = undefined
    }

    return  <GridCard
                title="Dein Profil"
                cStart={cStart} cEnd={cEnd}
                rStart={rStart} rEnd={rEnd}
                hoverEffect>
                    <div className="d-block">
                        <ProfileRound  size={96} color="secondary" />
                        <Link className="h4 mt-4 stretched-link d-block" to="/network/profile">Sieh Dir Deine Daten an.</Link>
                        <p className="h5 mb-0 text-muted">Und bearbeite Deine Daten und Mitgliedschaft.</p>
                    </div>
                {/* <ApiCycle data={request} /> */}
            </GridCard>
    
}

const GridCardFeedback = () => {

    const bp: typeof bootstrapQueries = useBreakpoint()
    let cStart = "c1"
    let cEnd = "c2"
    let rStart: string | undefined = "r1"
    let rEnd:string | undefined = "rend"

    if (is("lg", bp)) {
        cStart = "5"
        cEnd = "9"
    } else if (smaller("md", bp, true)) {
        cStart = "1"
        cEnd = "2"
        rStart = undefined
        rEnd = undefined
    }

    return  <GridCard
                title="Feedback"
                cStart={cStart} cEnd={cEnd}
                rStart={rStart} rEnd={rEnd}
                hoverEffect>
                    <div className="d-block">
                        <UICheck  size={96} color="secondary" />
                        <Link className="h4 mt-4 stretched-link d-block" to="/feedback">Erzähl uns Dein Feedback.</Link>
                        <p className="h5 mb-0 text-muted">Oder melde einen Fehler.</p>
                    </div>
                {/* <ApiCycle data={request} /> */}
            </GridCard>
    
}

const GridCardGitHub = () => {

    const bp: typeof bootstrapQueries = useBreakpoint()
    let cStart = "c2"
    let cEnd = "cend"
    let rStart: string | undefined = "r1"
    let rEnd:string | undefined = "rend"

    if (is("lg", bp)) {
        cStart = "9"
        cEnd = "13"
    } else if (is("md", bp)) {
        cStart = "2"
        cEnd = "3"
        rStart = undefined
        rEnd = undefined
    } else if (smaller("md", bp, true)) {
        cStart = "1"
        cEnd = "2"
        rStart = undefined
        rEnd = undefined
    }

    return  <GridCard
                title="GitHub"
                cStart={cStart} cEnd={cEnd}
                rStart={rStart} rEnd={rEnd}
                hoverEffect>
                    <div className="d-block">
                        <GitHub  size={96} color="secondary" />
                        <a className="h4 mt-4 stretched-link d-block" href="https://github.com/unisonoalumniclub" target="_blank" rel="noreferrer">Sieh Dir an, wie diese Seite funktioniert.</a>
                        <p className="h5 mb-0 text-muted">Oder werde Contributor.</p>
                    </div>
                {/* <ApiCycle data={request} /> */}
            </GridCard>
    
}
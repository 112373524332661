import UNISONOClub from './controllers/ClubPageController';
import AuthenticationController, {
  AuthenticationActions,
  LogoutController
} from './controllers/AuthenticationController';
import './_global/scss/bootstrap-all.scss';
import './_global/css/fonts.css';
import {BrowserRouter as Router, Redirect as RouterRedirect, Route, Switch} from 'react-router-dom';
import {Redirect} from './hoc/Authenticated';
import AbstractPermissions from './modules/Auth/AbstractPermission';
import {UserGroup} from './modules/Auth';
import PasswordResetController from "./controllers/AuthenticationController/PasswordResetController";

export default function App() {
  return  <Router>
            <Switch>
              <RouterRedirect exact path="/sign-up/complete" to="/sign-up" />
              <Route exact path="/sign-up">
                <Redirect if="none" ofThisHolds={[AbstractPermissions.NotAuthenticated, UserGroup.NotRegistered]} to ="/">
                  <AuthenticationController action={AuthenticationActions.SIGNUP} />
                </Redirect>
                
              </Route>
              <Route exact path="/sign-in">
                <Redirect if="none" ofThisHolds={[AbstractPermissions.NotAuthenticated, UserGroup.NotRegistered]} to="/">
                  <AuthenticationController />
                </Redirect>
              </Route>
              <Route exact path="/reset">
                <Redirect if="none" ofThisHolds={[AbstractPermissions.NotAuthenticated]} to="/">
                  <PasswordResetController/>
                </Redirect>
              </Route>
              <Route exact path="/sign-out">
                <LogoutController />
              </Route>
              <Route path="*">
                <Redirect if="some" ofThisHolds={[AbstractPermissions.NotAuthenticated, UserGroup.None]}
                          to="/sign-in">
                  <UNISONOClub />
                </Redirect>
              </Route>
            </Switch>
          </Router>

}

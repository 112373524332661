import {decode, JwtPayload} from "jsonwebtoken";
import {useContext, useEffect} from "react";
import AppTokenContext from "../context/AppTokenContext";
import C from "../modules/cookies";
import {authReloadToken} from "../modules/API/Services/AuthService";
import {AxiosResponse} from "axios";

interface AppTokenPayload {
    str: string,
    tok: JwtPayload
}

export interface AppToken {
    payload: AppTokenPayload,
    destroy: () => void,
    refresh: () => void
}

export default function useAppToken(): AppToken | undefined {
    const {token, setToken} = useContext(AppTokenContext)

    // const history = useHistory()

    useEffect(() => {
        if (token === undefined) {
            const cookieToken = C.get("_token")
            if (typeof cookieToken === "string") {
                const decodedToken = decode(cookieToken)
                if (typeof decodedToken !== "string" && decodedToken !== null) {
    
                    if (expired({str: cookieToken, tok: decodedToken})) { 
                        C.remove("_token", {path: "/"}) 
                    } else {
                        setToken({
                            payload: {
                                str: cookieToken, 
                                tok: decodedToken
                            },
                            destroy: () => {
                                C.remove("_token", {path: "/"});
                                setToken(undefined);
                                window.location.href = "/";
                            },
                            refresh: () => authReloadToken().then((response: AxiosResponse<string>) => {
                                C.set("_token", response.data, {path: "/"});
                                setToken(undefined);
                            }).catch(e => {
                                console.warn("refresh token", e)
                            })
                        })
                    }
    
                }
            } else {
                
            }
        } else {
            if (expired(token?.payload)) token?.destroy()
        }
    }, [setToken, token])



    // const destroy = useCallback((): Promise<void> => {
    //     setToken(undefined)
    //     return new Promise((resolve, reject) => {
    //         if (token === undefined) resolve()
    //         reject()
    //     })
    // }, [token])

    // const refresh = useCallback((): Promise<void> => {
    //     return new Promise((resolve, reject) => {
    //         // if (token === undefined) resolve()
    //         reject("Could not refresh token, as function is not implemented.")
    //     })
    // }, [])

    // const result = useCallback<() => AppToken | undefined>(() => {
    //     return token ? {
    //         payload: token, 
    //         destroy: destroy, 
    //         refresh: refresh
    //     } : undefined
    // }, [destroy, refresh, token])

    return token
}


function expired(token?: AppTokenPayload) : boolean {
    return token !== undefined && token.tok.exp !== undefined && token.tok.exp * 1000 < Date.now()
  }
  
//   function isSet(token?: AppToken, c?: any) : boolean {
//     return !token && c !== undefined && typeof c === 'string'
//   }
  
//   function isValid(decodedToken: string | JwtPayload | null): decodedToken is JwtPayload {
//     return decodedToken !== null && typeof decodedToken !== 'string' && 'sub' in decodedToken
//   }

import classNames from "classnames"
import UINavigationItem, { NavigationItemType } from "../UINavigationItem/UINavigationItem"
import UINavigationSegment from "./style.module.scss"

export default function NavigationSegment(props: {items: NavigationItemType[]}): JSX.Element {

    const cx = classNames({
        [UINavigationSegment.NavigationSegmentElementsGroup]: true,
        [UINavigationSegment.NavigationSegmentElementsGroupOne]: props.items.length === 1,
        [UINavigationSegment.NavigationSegmentElementsGroupTwo]: props.items.length === 2,
        [UINavigationSegment.NavigationSegmentElementsGroupThree]: props.items.length === 3,
        [UINavigationSegment.NavigationSegmentElementsGroupFive]: props.items.length === 5
    })
    
    return  <div className={UINavigationSegment.NavigationSegmentContainer}>
                <div className={UINavigationSegment.NavigationSegmentContainerBox}>
                    <div className={cx}>
                        {props.items.map((item, i) => <UINavigationItem key={i} to={item.to} name={item.name} activeClassName={UINavigationSegment.active} badge={item.badge} exact={item.exact} className={'btn ' + UINavigationSegment.NavigationSegmentElement} />)}
                    </div>
                </div>
            </div>
}
import { useCallback, useState } from "react"

export type useSortType<T = string> = {
    state: {
        value: T,
        dispatch: (v: T) => void
    },
    sortCallback: SortCallback
}

export type SortCallback = (a: any, b: any) => number
export type SortItem = any;

export type SortItemBasicType = number | bigint | string | boolean
export type SortItemType = SortItemBasicType | SortItemBasicType[] | Record<string | number, SortItemBasicType>

function sort<T extends SortItemType>(a: T, b: T, objectKey: string | undefined = undefined): number {

    if (typeof b === "boolean" && typeof a === "boolean") {
        return b ? 1 : -1
    } else if (Array.isArray(b) && Array.isArray(a)) { 
        console.warn("Warning! Attempted to sort an array of arrays. Skipping.", a, b)
        return 0
    } else if ( typeof b === "object" && typeof a === "object" && 
                !Array.isArray(b) && !Array.isArray(a) &&
                b !== null && a !== null
                ) {
        const keyA = objectKey || Object.keys(a)[0]
        const keyB = objectKey || Object.keys(b)[0]
        return sort(a[keyA], b[keyB])
    } else {
        return a > b ? 1 : -1;
    }
}

export default function useSort(initial: string) : useSortType {

    const [sortOption, setSortOption] = useState(initial)

    const sortCallback = useCallback((a: any, b: any) : number => sort(a, b, sortOption), [sortOption])

    return {
        state: {
            value: sortOption,
            dispatch: setSortOption
        },
        sortCallback: sortCallback
    }
}

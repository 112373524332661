import React from "react"
import {Link} from "react-router-dom";

import './style.scss'

type GridCardCTAProps = {
    path: string, 
    title?: string, 
    chevron: boolean,
    external?: boolean
}

type GridCardFooterProps = {
    text?: string, 
    cta?: GridCardCTAProps
}

type GridCardProps = {
    title: string,
    cta?: GridCardCTAProps,
    footerText?: string,
    hoverEffect?: boolean
};

type GridCardGrid = {
    cStart?: string,
    cEnd?: string,
    rStart?: string,
    rEnd?: string
}

export function GridCardHeader(props: React.PropsWithChildren<{}>) : JSX.Element {
    return <h5 className="grid-card-header">{props.children}</h5>
}

export function GridCardBody(props: React.PropsWithChildren<{}>) : JSX.Element {
    return  <div className="grid-card-body">
                <div>{props.children}</div>
            </div>
}

export function GridCardFooter(props: GridCardFooterProps) : JSX.Element {
    if (props.cta) {
        if (props.cta.external) return <div className="grid-card-footer"><a className="grid-card-footer-link stretched-link" href={props.cta.path} target="_blank" rel="noreferrer">{props.cta.chevron ? <span className="w-icn icn-chevron-right">{props.cta.title}</span> : props.cta.title}</a></div>
        return <div className="grid-card-footer"><Link className="grid-card-footer-link stretched-link" to={props.cta.path}>{props.cta.chevron ? <span className="w-icn icn-chevron-right">{props.cta.title}</span> : props.cta.title}</Link></div>
    } else if (props.text) {
        return <div className="grid-card-footer"><p className="grid-card-footer-text">{props.text}</p></div>
    }
    return <></>
}

export default function GridCard(props: React.PropsWithChildren<GridCardProps & GridCardGrid>) : JSX.Element {
    return <div className={"pointer grid-card overflow-hidden " + (props.hoverEffect ? " grid-card-hover grid-card-hover-scale" : "")} style={{gridColumnStart: props.cStart, gridColumnEnd: props.cEnd, gridRowStart: props.rStart, gridRowEnd: props.rEnd}}>
        <GridCardHeader>{props.title}</GridCardHeader>
        <GridCardBody>{props.children}</GridCardBody>
        <GridCardFooter cta={props.cta} text={props.footerText} />
    </div>
}
import React, {useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../../../components/MessageState";
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";
import usePermissionBag from "../../../hooks/usePermissionBag";
import {UserPermission} from "../../../modules/Auth";
import {Forbidden} from "../../../components/HTTPErrorPage";
import {EmailBasic, EmailBasicGroup, emailBasicSend} from "../../../modules/API/Services/EmailService";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import InputControlled, {
    SelectControlled,
    SelectHtmlControlled,
    TextareaControlled
} from "../../../components/Fields/InputControlled";
import {useApiData} from "react-api-data";
import {EventDateListDto} from "../../../modules/API/Services/EventService";
import {EventEndpoint} from "../../../store/APIStore";
import ApiCycle from "../../../modules/API/ApiCycle";
import {str_to_datestr, str_to_monthstr} from "../../../modules/API/Services/Misc";

const EmailBasicTitle: Record<EmailBasicGroup, string> = {
    all: "Liebes UNISONO Mitglied!",
    choir: "Liebes UNISONO Chor Mitglied!",
    "unregistered-paid": "Liebes UNISONO Mitglied!",
    "unregistered-unpaid": "Liebes UNISONO Mitglied!",
    "unpaid": "Liebes UNISONO Mitglied!",
};

function eventDate(event: EventDateListDto) {
    return event.datePreview ? str_to_monthstr(event.date) : str_to_datestr(event.date, true);
}

export default function MailController() {
    const [message, setMessage] = useState<MessageState>();
    const [waiting, setWaiting] = useState(false);

    const permissions = usePermissionBag();
    
    const [email, setEmail] = useState<EmailBasic>({toGroup: "all", subject: "", title: EmailBasicTitle.all, text: ""});

    const hasEmailPermission = permissions.hasPermission(UserPermission.EditAllUserChoirData).all;
    const hasChoirPermission = permissions.hasPermission(UserPermission.SendEmailToMembers).all;
    const hasEventsPermission = permissions.hasPermission(UserPermission.ViewAllAttendeesData).all;
    
    const eventList = useApiData<EventDateListDto[], string>(EventEndpoint.GET.eventDates_list);
    
    //set default email group
    useEffect(() => {
        if(hasEmailPermission) return; //already set as default
        setEmail({toGroup: "all", subject: "", title: EmailBasicTitle.all, text: ""}); //only other alternative
    }, [setEmail, hasEmailPermission]);
    
    const handleSubmit = useCallback(() => {
        setWaiting(true);
        
        emailBasicSend(email).then(() => {
            setMessage({type: "success", message: "E-Mail wird ausgesendet!"});
            setWaiting(false);
        }).catch((error: any) => {
            setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
            setWaiting(false);
        });
    }, [setWaiting, email, setMessage]);
    
    //user has no permission to view this page
    if(!hasEmailPermission && !hasChoirPermission) {
        return <Forbidden/>;
    }
    
    return <form onSubmit={(e) => {e.preventDefault(); handleSubmit()} }>
        {/*Group*/}
        <div className="mb-3">
            <label>An</label>
            <SelectControlled className="form-control form-select" value={email.toGroup} onChange={ (e: string) => {
                email.toGroup=e;
                if(e!=="events") {
                    email.id = undefined;
                }
                if(e==="all" || e==="choir") {
                    email.title = EmailBasicTitle[e]; //set default title
                }

                setEmail({...email});
            } }>
                {hasEmailPermission && <option value="all">Alle Mitglieder</option>}
                {hasEmailPermission && <option value="unregistered-paid">Nicht Mitglieder mit gezahltem Mitgliedsbeitrag</option>}
                {hasEmailPermission && <option value="unregistered-unpaid">Nicht Mitglieder mit nicht gezahltem Mitgliedsbeitrag</option>}
                {hasEmailPermission && <option value="unpaid">Mitglieder mit nicht gezahltem Mitgliedsbeitrag</option>}
                {hasChoirPermission && <option value="choir">Chor Mitglieder</option>}
                {hasEventsPermission && <option value="events">Registrierte Mitglieder bei Event</option>}
            </SelectControlled>
        </div>
        {/*Event select*/}
        {email.toGroup==="events" && <div className="mb-3">
            <label>Event</label>
            <ApiCycle data={eventList} extractor={(eventList: EventDateListDto[]) => <>
                <SelectHtmlControlled className="p-2 px-3 border-top mt-2" value={""+email.id} onChange={ (e: string) => {
                    const dto = eventList[+e];
                    email.id = dto.id;
                    email.subject = dto.name.replaceAll(/<[^>]+>/g, "") + ", " + eventDate(dto);
                    setEmail({...email})
                } } options={eventList.map((event: EventDateListDto, index: number) => ({
                    value: ""+index,  id: event.id,
                    html: <div className='row'><div className='col-7' dangerouslySetInnerHTML={{__html: event.name}}/><div className='col-5'>{eventDate(event)}</div></div>
                }) )} selectedClass="text-success" optionClass="pointer mb-2 border-bottom"/>
            </>}/>
        </div>}
        {/*Subject*/}
        <div className="mb-3">
            <label>Betreff</label>
            <InputControlled type="text" className="form-control" value={email.subject} onChange={(e: string) => {email.subject=e;}}/>
        </div>
        {/*Title*/}
        <div className="mb-3">
            <label>Titel in der E-Mail</label>
            <InputControlled type="text" className="form-control fw-bold" value={email.title} onChange={(e: string) => {email.title=e;}}/>
        </div>
        {/*Text*/}
        <div className="mb-3">
            <label>E-Mail Text</label>
            <TextareaControlled className="form-control" value={email.text} onChange={(e: string) => {email.text=e;}}/>
        </div>
        
        {/*Submit*/}
        <MessageDisplay message={message}/>
        <div className="d-flex flex-column m-auto py-4" style={{maxWidth: "400px"}}>
            <div className="d-grid" style={{gap: "1rem", gridTemplateColumns: "1fr 1fr"}}>
                <ButtonSpin spinning={waiting} disabled={waiting} variant="primary" type="submit" skin="outline">E-Mail Abschicken</ButtonSpin>
            </div>
        </div>
    </form>;
}
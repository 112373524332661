import C from "../../modules/cookies";
import LogInSignUpController from "./LogInSignUpController";
import { useActions } from "react-api-data";
import React from "react";

export enum AuthenticationActions {
    SIGNUP = "signup",
    SIGNIN = "signin"
}

export default function AuthenticationController(props: {action?: AuthenticationActions}) : JSX.Element {
    let action = props.action;
    if (action === undefined) action = AuthenticationActions.SIGNIN;
    return(<LogInSignUpController action={action}/>);
}

export function LogoutController(props: {setToken?: CallableFunction}): JSX.Element {
    const actions = useActions();
    C.remove("_token", {path: "/"}) // , {path: "/", domain: "club.unisono-alumni.loc"});
    actions.purgeAll()
    props.setToken?.()
    window.location.pathname = "/"
    return(<></>)
}

const Route = (pathname: string, search: string = "", referrer: string = "") : RouteInterface => {
    return({
        pathname: pathname,
        search: search,
        state: { referrer: referrer }
    });
}

const Sitemap = (sitemap: SitemapInterface) : SitemapInterface => {
    return(sitemap);
}

export interface RouteInterface {
    pathname: string,
    search: string,
    state: { referrer: string }
}

export interface RouteCollection {
    index: RouteInterface | RouteCollection,
    [key: string]: RouteInterface | RouteCollection
}

export interface SitemapInterface {
    index: RouteCollection | RouteInterface,
    [key: string]: RouteCollection | RouteInterface
}



export const SiteMap = Sitemap({
    index: Route("/"),
    signup: {
        index: Route("/sign-up"),
        complete: Route("/sign-up/complete")
    },
    signin: Route("/sing-in"),
    signout: Route("/sign-out"),
    events: {
        index: Route("/events")
    }
})


/** TODO:
 *  Change Logic of AuthenticationController so that...
 *    - ON EVERY RENDER, check:
 *    - if token == null:       return a redirection to login route
 *    - if token == invalid :   return a JSX.Element containing a password form overlay (also if expired)
 *    - if token == valid: return an empty JSX.Fragement (<></>)
 *   This logic makes it possible to render AuthenticationController 
 */

/** TODO:
 *  Check if HOC logic makes sense.
 *  <Authenticated>
 *      { children }
 *  </Authenticated>
 */
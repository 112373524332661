import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BreakpointProvider, bootstrapQueries } from './context/Breakpoint';
import { Provider } from 'react-redux';
import store from './store/APIStore';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import PageTitleProvider from './hoc/PageTitleHOC';
import AppTokenProvider from './hoc/AppTokenHOC';

ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
          <Helmet defaultTitle="UNISONO Alumni Club" />
          <BreakpointProvider queries={bootstrapQueries}>
              <Provider store={store}>
                  <PageTitleProvider>
                    <AppTokenProvider>
                      <App />
                    </AppTokenProvider>
                  </PageTitleProvider>
              </Provider>
          </BreakpointProvider>
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useCallback } from "react";
import {useApiData} from "react-api-data";
import {UserEndpoint} from "../../../store/APIStore";
import {useHistory} from "react-router-dom";
import ApiCycle from "../../../modules/API/ApiCycle";
import { IUserAdminOverview, MemberRow } from "../Members/MembersOverview";
import { larger, useBreakpoint } from "../../../context/Breakpoint";
import DataViewTable from "../../../components/DataView/UI/DataViewTable/DataViewTable";
import { DataViewColumns, DataViewRow } from "../../../components/DataView";

function APIDataExtractor(data: IUserAdminOverview[]) : JSX.Element {

    const rows: MemberRow[] = data.map(value => {
        return {id: value.id, firstName: value.firstName, lastName: value.lastName, userType: value.email}
    })

    const history = useHistory()
    const viewMember = useCallback((row: DataViewRow) => history.push(`/apacis/members/view/${row.id}`), [history])
    const bp = useBreakpoint()

    const headers: DataViewColumns = larger("md", bp, true) ? {
        id: "ID",
        firstName: 'Vorname',
        lastName: "Nachname",
        email: "E-Mail-Adresse"
    } : {
        firstName: "Vorname",
        lastName: "Nachname"
    }

    return  <DataViewTable
                headers={headers}
                content={rows}
                useSearch={true}
                useSort={true}
                usePagination={true}
                rowOnClick={viewMember}
            />}

export default function RegistrationsNotDoneOverview(): JSX.Element {
    const users = useApiData<IUserAdminOverview[]>( UserEndpoint.GET.users, {usertype: "unregistered"} )
    return <ApiCycle data={users} extractor={APIDataExtractor} />
}
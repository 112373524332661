import { useCallback, useEffect, useState } from "react"
import { usePaginationType } from "./usePagination"

export type useFilterType<C, T = string> = {
    state: {
        value: T,
        dispatch: (v: T) => void
    },
    filterCallback: FilterCallback<C>
}

export type FilterCallback<T> = (value: T, index: number, array: T[]) => boolean
export type FilterItem = any;

export type FilterItemBasicType = number | bigint | string | boolean
export type FilterItemType = FilterItemBasicType | FilterItemBasicType[] | Record<string | number, FilterItemBasicType>

function find<T extends FilterItemType>(value: T, filter: string | undefined ): boolean {

    if (filter === "" || filter === undefined) return true

    if (typeof value === "boolean") {
        console.warn("Warning! Attempted to filter for boolean values using string input. Skipping.")
    } else if (typeof value === "bigint" || typeof value === "number" || typeof value === "string") {
        return value.toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0
    } else if (Array.isArray(value)) {
        console.warn("Warning! Attempted to filter an array of arrays. Skipping.")
    } else if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        const keys = Object.keys(value)
        let includes = false
        keys.forEach(key => {
            includes = includes || find(value[key], filter)
        });
        return includes
    }
    return false
}

export default function useSearch<T extends FilterItemType>(initial: string | undefined, pagination?: usePaginationType) : useFilterType<T> {
    const [searchString, setSearchString] = useState(initial)

    useEffect(() => {
        if ((searchString === "") !== pagination?.enabled.value) 
            pagination?.enabled.dispatch(searchString === "")
    }, [pagination?.enabled, searchString])

    const searchCB = useCallback((value: T, index: number, array: T[]) : boolean =>  {
        return find(value, searchString)
    }, [searchString])

    return {
        state: {
            value: searchString || "",
            dispatch: setSearchString
        },
        filterCallback: searchCB
    }
}
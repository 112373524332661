import React, { FunctionComponent } from "react";
import { Color } from "react-bootstrap/esm/types";
import { SassColor } from "sass";
import "./NavigationBar.scss";

interface IconWrapperProps {
    icon: string
    size: number;
    NavLinkWrapper?: boolean;
    style?: React.CSSProperties;
    color?: Color | CSSColor | "currentColor"
}

export class CSSColor extends SassColor {

    private toHex(value: number): string {
        const hex = value.toString(16)
        return hex.length === 1 ? "0" + hex : hex
    }
    
    public get rgba() : string {
        return "rgba(" + this.red + "," + this.green + "," + this.blue + "," + this.alpha + ")"
    }

    public get hex() : string {
        if (this.alpha === 1) return "#" + this.toHex(this.red) + this.toHex(this.green) + this.toHex(this.blue)
        return "#" + this.toHex(this.red) + this.toHex(this.green) + this.toHex(this.blue) + this.toHex(this.alpha)
    }
    public static Blue = CSSColor.fromHex("16B6F5")
    public static Indigo = CSSColor.fromHex("192CC3")
    public static Purple = CSSColor.fromHex("C230F3")
    public static Pink = CSSColor.fromHex("FF34A4")
    public static Red = CSSColor.fromHex("EB134D")
    public static Orange = CSSColor.fromHex("F68336")
    public static Yellow = CSSColor.fromHex("E1DF47")
    public static Green = CSSColor.fromHex("7BDD26")
    public static Teal = CSSColor.fromHex("58E9BB")
    public static Cyan = CSSColor.fromHex("05D7D4")

    public static fromHex(hexInput: string): CSSColor {
        const hex = hexInput.toUpperCase().split("")
        const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"]

        if (hex.length !== 6 && hex.length !== 3) throw new Error("Parameter \"hex\" of static function \"CSSColor.fromHex\" must have three or six characters.");
        if (hex.map(char => !allowedChars.includes(char)).some(v => v) ) throw new Error("Parameter \"hex\" of static function \"CSSColor.fromHex\" must not contain characters other than 0-9 and A-F.");
        
        if (hex.length === 3) {
            return new CSSColor({
                red: toNumeric(hex[0], hex[0]), 
                green: toNumeric(hex[1], hex[1]), 
                blue: toNumeric(hex[3], hex[3])
            })
        } else {
            return new CSSColor({
                red: toNumeric(hex[0], hex[1]), 
                green: toNumeric(hex[2], hex[3]), 
                blue: toNumeric(hex[4], hex[5])
            })
        }

        function toNumeric(h1: string, h2: string): number {
            const map: Record<string, number> = {
                "0":0, "1":1, "2":2, "3":3, "4":4, "5":5, "6":6, "7":7, "8":8, "9":9,
                "A": 10, "B": 11, "C": 12, "D": 13, "E": 14, "F": 15 
            }
            const n1 = map[h1] || 0
            const n2 = map[h2] || 0
            return n1 * 16 + n2
        }
    }
    
}

type CSSGradientDirection = "down" | "up" | "left" | "right"

class CSSGradient {

    private from: CSSColor
    private to: CSSColor
    private direction: CSSGradientDirection

    constructor(from: CSSColor, to: CSSColor, direction?: CSSGradientDirection) {
        this.from = from
        this.to = to
        this.direction = direction || "down"
    }

}



export interface IconProps {
    size?: number;
    NavLinkWrapper?: boolean;
    style?: React.CSSProperties;
    color?: Color | CSSColor | CSSGradient | "currentColor"
}

const Icons: Record<string, JSX.Element> = {
    Home:       <path d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z"/>,
    Calendar:   <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zM2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"/>,
    ProfileRound: <><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/><path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/></>,
    Globe:      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>,
    Settings:   <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>,
    SignOut:    <><path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/><path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/></>,
    ChevronRight: <path fillRule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>,
    ChevronLeft: <path fillRule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>,
    Menu:       <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>,
    CalendarOutline:   <><path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></>,
    UICheck:     <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>,
    GitHub:   <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>,
    Camera:   <><path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/><path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/></>,
    Cone:   <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598z"/>,
    Check:    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>,
    FlagFill:   <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>,
    PeopleFill: <><path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/><path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/><path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/></>,
    Trash: <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>,
    ChevronUp: <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>,
    ChevronDown: <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>,
    Pencil: <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>,
    Search: <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>,
    ChevronLeftNormal: <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>,
    ChevronRightNormal: <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>,
    Clock: <><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></>,
    PersonCheck: <><path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/><path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/></>,
    MusicNoteBeamed: <><path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/><path fillRule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/><path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/></>,
}

  
function IconWrapper(props : IconWrapperProps = {icon: "Home", size: 16, color: "currentColor", NavLinkWrapper: false, style: {}}) : JSX.Element {
    const color: string = props.color === undefined ? "currentColor" : (typeof props.color === "string" ? (props.color === "currentColor" ? props.color : "var(--bs-" + props.color + ")") : props.color.hex)
    const icon = <svg style={props.style} xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} fill={color} viewBox="0 0 16 16">{Icons[props.icon]}</svg>
    
    if (props.NavLinkWrapper) return <span className="NavLinkIcon">{ icon }</span>
    return icon
}

export const ConstructionRibbon = (props: IconProps) => {

    return  <svg width="100%" height="100%" viewBox="0 0 36 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{fillRule:"evenodd",clipRule:"evenodd",strokeLinejoin:"round",strokeMiterlimit:"2"}}>
                <g transform="matrix(1,0,0,1,-44,0)">
                    <g id="Artboard2" transform="matrix(0.993825,0,0,1,-0.34689,3.55271e-15)">
                        <rect x="44.622" y="-0" width="36.224" height="50" style={{fill: "none"}}/>
                        <clipPath id="_clip1">
                            <rect x="44.622" y="-0" width="36.224" height="50"/>
                        </clipPath>
                        <g clipPath="url(#_clip1)">
                            <g transform="matrix(1.00621,0,0,1,44.6224,-3.55271e-15)">
                                <path d="M0,31.152L0,48.809C0,49.019 0.124,49.21 0.316,49.296C0.508,49.383 0.732,49.348 0.89,49.208C3.643,46.756 12.077,39.246 15.98,35.772C17.131,34.746 18.869,34.746 20.02,35.772L25.793,40.912L36,36.049L36,14.001L0,31.152ZM19.107,-0L0,9.103L0,-0L19.107,-0Z" style={{fill:"rgb(5,15,18)"}}/>
                            </g>
                            <g transform="matrix(1.00621,0,0,1,44.6224,-3.55271e-15)">
                                <path d="M36,36.049L25.793,40.912L35.152,49.245C35.302,49.378 35.516,49.411 35.699,49.329C35.882,49.247 36,49.065 36,48.865L36,36.049ZM36,14.001L0,31.152L0,9.103L19.107,-0L36,-0L36,14.001Z" style={{fill:"rgb(249,234,31)"}}/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
}

const defaultProps = {
    size: 16
};



function constructIcon(icon: string) : FunctionComponent<IconProps> {
    return((props: IconProps) => <IconWrapper {...props} icon={icon}/>)
}


export const Home: FunctionComponent<IconProps> = constructIcon("Home");
export const Calendar: FunctionComponent<IconProps> = constructIcon("Calendar");
export const ProfileRound = constructIcon("ProfileRound");
export const Globe = constructIcon("Globe");
export const Settings = constructIcon("Settings");
export const SignOut = constructIcon("SignOut");
export const ChevronRight = constructIcon("ChevronRight")
export const ChevronLeft = constructIcon("ChevronLeft")
export const Menu = constructIcon("Menu")
export const CalendarOutline = constructIcon("CalendarOutline")
export const UICheck = constructIcon("UICheck")
export const GitHub = constructIcon("GitHub")
export const Camera = constructIcon("Camera")
export const Cone = constructIcon("Cone")
export const PeopleFill = constructIcon("PeopleFill")
export const Check = constructIcon("Check")
export const FlagFill = constructIcon("FlagFill")
export const Trash = constructIcon("Trash")
export const ChevronUp = constructIcon("ChevronUp")
export const ChevronDown = constructIcon("ChevronDown")
export const Pencil = constructIcon("Pencil")
export const Search = constructIcon("Search")
export const ChevronLeftNormal = constructIcon("ChevronLeftNormal")
export const ChevronRightNormal = constructIcon("ChevronRightNormal")
export const Clock = constructIcon("Clock")
export const PersonCheck = constructIcon("PersonCheck")
export const MusicNoteBeamed = constructIcon("MusicNoteBeamed")


Home.defaultProps = defaultProps;
Calendar.defaultProps = defaultProps;
ProfileRound.defaultProps = defaultProps;
Globe.defaultProps = defaultProps;
Settings.defaultProps = defaultProps;
SignOut.defaultProps = defaultProps;
ChevronRight.defaultProps = defaultProps;
ChevronLeft.defaultProps = defaultProps;
Menu.defaultProps = defaultProps;
CalendarOutline.defaultProps = defaultProps
UICheck.defaultProps = defaultProps
GitHub.defaultProps = defaultProps
Camera.defaultProps = defaultProps
Cone.defaultProps = defaultProps
PeopleFill.defaultProps = defaultProps
Check.defaultProps = defaultProps
FlagFill.defaultProps = defaultProps
Trash.defaultProps = defaultProps
ChevronUp.defaultProps = defaultProps
ChevronDown.defaultProps = defaultProps
Pencil.defaultProps = defaultProps;
Search.defaultProps = defaultProps;
ChevronLeftNormal.defaultProps = defaultProps;
ChevronRightNormal.defaultProps = defaultProps;
Clock.defaultProps = defaultProps;
PersonCheck.defaultProps = defaultProps;
MusicNoteBeamed.defaultProps = defaultProps;

IconWrapper.defaultProps = defaultProps;



import {motion} from 'framer-motion';
import React, {useCallback, useEffect, useState} from 'react';
import {useApiData} from 'react-api-data';
import {Link, NavLink, useLocation} from 'react-router-dom';
import * as Icon from '../../../../components/Icons';
import ButtonSpin from '../../../../components/UI/Buttons/ButtonSpin';
import {UINotificationBadge} from '../../../../components/UI/Generic/UINotificationBadge';
import {bootstrapQueries, is, useBreakpoint} from "../../../../context/Breakpoint";
import {ShowIf, ShowIfNot} from '../../../../hoc/Authenticated';
import useAppToken from '../../../../hooks/useAppToken';
import usePageTitle from '../../../../hooks/usePageTitle';
import ApiCycle from '../../../../modules/API/ApiCycle';
import {UserPermission} from '../../../../modules/Auth';
import AbstractPermissions from '../../../../modules/Auth/AbstractPermission';
import {NetworkEndpoint, PaymentEndpoint, UserEndpoint} from '../../../../store/APIStore';
import usePermissionBag from "../../../../hooks/usePermissionBag";

import "bootstrap-icons/font/bootstrap-icons.scss"

export default function NavigationBar() : JSX.Element {

    const breakpoints: typeof bootstrapQueries = useBreakpoint();

    const [expanded, setExpanded] = useState(false);     // if toggleMode ? show nav?
    const location = useLocation();
    const {pageTitle} = usePageTitle()

    useEffect(() => {
        if (expanded) setExpanded(false);

        // eslint tells us that we should also listen to expanded changing
        // but we actually only want to listen to location and set expanded to false, ONLY if it is true.
        // eslint-disable-next-line
    }, [location]);
    

    const ParentComponent = (props: {children: React.ReactNode}) => {
        let additionalComponents: Record<string, any> = {};
        const classNames = ["nav-main"];
        if(is("xs", breakpoints)) {
            // navbar
            // additionalComponents["variants"] = {
                // "expanded" : {
                //     height: "100vh",
                //     position: "absolute",
                //     zIndex: 9999,
                //     top: 0,
                //     left: 0,
                //     right: 0
                // },
                // "hidden": {
                //     height: "auto",
                //     position: "static",
                //     zIndex: "auto",
                //     top: 0, left: 0, right: 0,
        
                // }
            additionalComponents = {
                ...additionalComponents,
                style: {
                    // overflowY: "auto"
                }
            }
            // additionalComponents["animate"] = expanded ? "expanded" : "hidden";
        } else {
            // navigation on the left
            // classNames.push("navbar-expand");
        }
        return(<motion.div className={classNames.join(" ")} {...additionalComponents} >
            {props.children}
        </motion.div>);
    }

    const request = useApiData<number, string>( NetworkEndpoint.GET.getActiveFriendRequests, {});
    
    const permissions = usePermissionBag();
    const hasPermissions = permissions.hasPermission(
        UserPermission.SendEmailToMembers,
        UserPermission.ViewAllUserData,
        UserPermission.EditAllUserData,
        UserPermission.ViewFinancialStatistics,
        UserPermission.EditAllProfiles,
        UserPermission.EditMemberChoirs,
        UserPermission.ViewAllUserChoirData);
    const anyAdmin = hasPermissions.some || hasPermissions.all;
    
    const NavElements = () => {
        return (
            <>
            <ul className="nav-main-bar">
                <MainNavItem exact to="/" icon={Icon.Home} title="Startseite" />
                <ShowIf permission={AbstractPermissions.withPermission(UserPermission.ViewEvents)}>
                    <MainNavItem to="/events" icon={Icon.CalendarOutline} title="Events" />
                </ShowIf>
                <ShowIf permission={AbstractPermissions.withPermission(UserPermission.User)}>
                    <MainNavItem to="/network" icon={Icon.Globe} title="Netzwerk"/>
                    <ShowIf permission={AbstractPermissions.withPermission(UserPermission.Verified)}>
                        <ul className="list-unstyled" style={{paddingLeft: "32px"}}>
                            <MainNavItem to="/network/profile" icon={Icon.ProfileRound} title="Profil"/>
                            <MainNavItem to="/network/friends" icon={Icon.Check} title="Kontakte" badge={<ApiCycle data={request} extractor={(data: number) => <>{data > 0 && <UINotificationBadge count={data} />}</>} suspense={{width: 16}} />}/>
                        </ul>
                    </ShowIf>
                </ShowIf>
                <ShowIf permission={AbstractPermissions.withPermission(UserPermission.User)}>
                    <MainNavItem to="/vorstand" icon={Icon.PeopleFill} title="Unser Team" />
                </ShowIf>
                <MainNavItem to="/settings" icon={Icon.Settings} title="Einstellungen" badge={<ShowIfNot permission={AbstractPermissions.withPermission(UserPermission.Verified)}><UINotificationBadge count={"!"}/></ShowIfNot>} />
                <MainNavItem to="/choir" icon={Icon.MusicNoteBeamed} title="Chor" />
            </ul>
            {anyAdmin && <AdminNav />}
            <ul className="nav-main-bar" style={{marginTop: "auto", marginBottom: "1rem"}}>
                <LogoutButton /> 
                {/* <MainNavItem to="/sign-out" icon={Icon.SignOut} title="Abmelden" /> */}
            </ul>
            </>
        );
    }
    
    return(
        <ParentComponent>
            <div className="nav-container" style={{overflowY: "auto"}} onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}>
                <div className="nav-container-left">
                    <Link to="/" className="nav-brand"><span>u</span>nisono <span className="text-primary">club</span></Link>
                    <h2 className="nav-container-page-title px-2">{pageTitle}</h2> 
                </div>
                <div className="nav-container-right">
                    <button className="nav-toggler" type="button" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <Icon.Menu size={24} />
                    </button>
                    <div className={"nav-toggler-section" + (expanded ? " expand" : "")}> {/* style={{overflowY: "auto"}} */}
                        <NavElements />
                    </div>
                </div>
            </div>
        </ParentComponent>
    );
}

const LogoutButton = () => {

    const destroyToken = useAppToken()

    const handleLogout = useCallback(() => destroyToken?.destroy(), [destroyToken])

    return <ButtonSpin spinning={false} spinColor='var(--bs-primary)' skin="outline" onClick={handleLogout}>Logout</ButtonSpin>
}

const DashboardMitglieder = () => {
    const request = useApiData<number, string>( UserEndpoint.GET.usersCount, { usertype: "unverified", locked: 0 })
    
    return <ShowIf permission={AbstractPermissions.withPermission(UserPermission.ViewAllUserData)}>
        <MainNavItem exact to="/apacis" icon={Icon.ChevronRight} title="Dashboard" />
        <MainNavItem to="/apacis/members" icon={Icon.ChevronRight} title="Mitglieder" badge={<ApiCycle data={request} extractor={(data: number) => <>{data > 0 && <UINotificationBadge count={data} />}</>} suspense={{width: 16, top: -2}} />} />
    </ShowIf>;
}
const DashboardFinance = () => {
    const overdue = useApiData<number, number>( PaymentEndpoint.GET.overdueCount );
    const waiting = useApiData<number, number>( PaymentEndpoint.GET.waitingCount );

    return <ShowIf permission={AbstractPermissions.withPermission(UserPermission.ViewAllPaymentHistory)}>
        <MainNavItem to="/apacis/finance" icon={Icon.ChevronRight} title="Zahlungen" 
                     badge={<>
                         <ApiCycle data={overdue} extractor={(data: number) => <>{data > 0 && <UINotificationBadge count={data} />}</>} suspense={{width: 16, top: -2}} />
                         <ApiCycle data={waiting} extractor={(data: number) => <>{data > 0 && <UINotificationBadge style={{backgroundColor: "orange"}} count={data} />}</>} suspense={{width: 16, top: -2}} />
                     </>} />
    </ShowIf>;
}

const AdminNav = () => {

    
    return <>
        <ul className="nav-main-bar">
            <h6 className="nav-heading">Administration</h6>
            
            <DashboardMitglieder/>
            <DashboardFinance/>
            {/* <ShowIf permission={AbstractPermissions.withPermission(UserPermission.EditAllProfiles)}>
                <MainNavItem to="/apacis/moderation" icon={Icon.ChevronRight} title="Moderation" />
            </ShowIf> */}
            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.EditMemberChoirs)}>
                <MainNavItem to="/apacis/choirs" icon={Icon.ChevronRight} title="Chöre" />  
            </ShowIf>
            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.ViewAllUserChoirData)}>
                <MainNavItem to="/apacis/choir" icon={Icon.ChevronRight} title="Alumni Chor" />
            </ShowIf>
            <ShowIf permission={AbstractPermissions.withPermission(UserPermission.SendEmailToMembers)}>
                <MainNavItem to="/apacis/mails" icon={Icon.ChevronRight} title="E-Mails" />
            </ShowIf>
        </ul>
    </>
}

const MainNavItem = (props: {
    exact?: boolean,
    to: string,
    icon: React.FunctionComponent<Icon.IconProps> | string,
    title: string,
    badge?: JSX.Element
}) => {
    return(
        <li className="nav-item">
            <NavLink exact={props.exact} to={props.to} activeClassName="active" className="nav-link d-flex justify-content-between">
                <span>{typeof props.icon === "string" ? <span className={`bi bi-${props.icon}`}></span> : props.icon({NavLinkWrapper: true})} {props.title}</span>{props.badge}
            </NavLink>
        </li>
    );
}

import React, {useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

export default function ShowHide(props: {
    buttonContainer: (props: {children: JSX.Element}) => JSX.Element,
    buttonChildren?: JSX.Element,
    className?: string,
    style?: React.CSSProperties,
    
    children: JSX.Element, 
    showInit: boolean
}): JSX.Element {
    const [show, setShow] = useState(props.showInit);
    
    return <>
        <props.buttonContainer><button className={props.className} style={props.style} type="button" onClick={() => setShow(!show)}>{props.buttonChildren}</button></props.buttonContainer>
        
        <AnimatePresence>
            {show && <motion.div initial={{height: 0, opacity: 0}} animate={{height:"auto", opacity: 1}} exit={{height: 0, opacity: 0}} style={{overflow: "hidden"}}>
                {props.children}
            </motion.div>}
        </AnimatePresence>
    </>;
}
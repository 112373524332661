import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken} from "../APIInterfaces";

export type Stimmlage = "Sopran" | "Alt" | "Tenor" | "Bass" | string;

export interface ChoirInfo {
    uid: number,
    firstName: string,
    lastName: string,
    participate: boolean,
    stimmlage: Stimmlage,
    email: string,
    telephone: string,
}

export function editChoirInfo(id: number, choirInfo: ChoirInfo): Promise<AxiosResponse<void>> {
    return axios.put(API_BASE+"/choir/"+id, choirInfo, {headers: {Authorization: "Bearer "+getToken()}})
}
import {Area} from "react-easy-crop/types"

async function createImage(file: File): Promise<HTMLImageElement> {

  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = URL.createObjectURL(file)
  })
}
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc: File, pixelCrop: Area): Promise<Blob> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const maxSize = 400;
  
  const w = Math.min(maxSize, pixelCrop.width);
  canvas.width = w;
  canvas.height = w;
  
  if (ctx !== null) {
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        w,
        w
    )
    }
  // As Base64 string
//   return canvas.toDataURL('image/jpeg');


//   As a blob
  return new Promise<Blob>(resolve => {
    canvas.toBlob(file => {
        if (file === null) return;
        resolve(file);
    }, 'image/jpeg')
  })
}


import React, {useState} from "react";
import * as Yup from "yup";
import * as API from "../../../API/API"
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";
import {MessageDisplay, MessageState} from "../../../components/MessageState";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import {Link} from "react-router-dom";

export default function SignInForm(props: {transitionToSignUp: React.MouseEventHandler<HTMLButtonElement>}) {

    // Fields
    const [email, setEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    // Form Status
    const [submitting, setSubmitting] = useState(false);

    // Form Validation
    const [message, setMessage] = useState<MessageState>();
    const [wasValidated, setWasValidated] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false);

    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value)
        const scheme = Yup.string().email().required()
        scheme.validate(event.target.value).then( () => {
            setEmailIsValid(true)
        }).catch( () => {
            setEmailIsValid(false)
        } )
        if (!wasValidated) setWasValidated(true)
    }
    function onChangeLoginPassword(event: React.ChangeEvent<HTMLInputElement>) {
        setLoginPassword(event.target.value)
        // setPasswordIsValid(true)
        if (!wasValidated) setWasValidated(true)
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setSubmitting(true);

        setTimeout(() => {
            API.authenticate(email, loginPassword, () => {
                setSubmitting(false)
            }, (error) => {
                // alert("We sorry, but we could not log you in.\n\nThe server gave us the following message:\n\n[" + error.name + "] " + error.message)
                // console.log("Could not complete AUTH request: [" + error.name + "] " + error.message)
                setMessage({type: "danger", message: AxiosErrorMessageGetter(error)});
                setSubmitting(false)
                // setPasswordIsValid(false)
                setEmailIsValid(false)
            });
        }, 1000)
    }

    return(
        <form onSubmit={handleSubmit} className={""} noValidate>
            <h4>Mit Deinem Account Anmelden</h4>
            <p className="text-muted small">Verwende Deine E-Mail-Addresse und Dein Passwort, welche Du angegeben hast, als Du Deinen Account erstellt hast.</p>
            
            <div className="form-floating">
                <input type="email" id="login-email" placeholder="name@example.com" className={"form-control mb-3" + (!emailIsValid && wasValidated ? " is-invalid" : "")} disabled={submitting} onChange={onChangeEmail} required />
                <label htmlFor="login-email">E-Mail Adresse</label>
            </div>
            <div className="form-floating">
                <input type="password" id="login-password" placeholder="name@example.com" className="form-control" disabled={submitting} onChange={onChangeLoginPassword} required />
                <label htmlFor="login-password">Passwort</label>
            </div>

            {/* <label htmlFor="login-email" className="form-label" >E-Mail Addresse</label>
            <input type="email" placeholder="Deine E-Mail Addresse" className={"form-control mb-3" + (!emailIsValid && wasValidated ? " is-invalid" : "")} id="login-email" disabled={submitting} onChange={onChangeEmail} required /> */}
            {/* <label htmlFor="login-email" className="form-label">Passwort</label> */}
            {/* <input type="password" placeholder="Dein Passwort" className="form-control" id="login-password" disabled={submitting} onChange={onChangeLoginPassword} required /> */}
            <div className="d-flex flex-column justify-content-end">
                <Link to="/reset" className="btn btn-link link-secondary text-small">Passwort vergessen</Link>
            </div>
            
            <div className="d-flex flex-column justify-content-end pt-4 gap-2">
                <MessageDisplay message={message}/>
                <ButtonSpin spinning={submitting} disabled={email === "" || loginPassword === ""} type="submit" skin="outline" spinColor="var(--bs-primary)">Anmelden</ButtonSpin>
                <button className="btn btn-link me-2 btn-sm" type="button" disabled={submitting} onClick={props.transitionToSignUp}>Oder möchtest Du Mitglied werden?</button>
            </div>
        </form>
    );

}
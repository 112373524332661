import { useCallback, useState } from "react"
import { StateObject } from "../types/types"
import { useLocalNumber } from "./useLocalStorage"

export type usePaginationType = {
    page: StateObject<number>,
    limit: StateObject<number | undefined>,
    total: number,
    enabled: StateObject<boolean>,
    subset: (arr: any[]) => any[]
}

// export type SortCallback = (a: any, b: any) => number
// export type SortItem = any;

// export type SortItemBasicType = number | bigint | string | boolean
// export type SortItemType = SortItemBasicType | SortItemBasicType[] | Record<string | number, SortItemBasicType>

// function sort<T extends SortItemType>(a: T, b: T, objectKey: string | undefined = undefined): number {

//     if (typeof b === "boolean" && typeof a === "boolean") {
//         return b ? 1 : -1
//     } else if (Array.isArray(b) && Array.isArray(a)) { 
//         console.warn("Warning! Attempted to sort an array of arrays. Skipping.", a, b)
//         return 0
//     } else if ( typeof b === "object" && typeof a === "object" && 
//                 !Array.isArray(b) && !Array.isArray(a) &&
//                 b !== null && a !== null
//                 ) {
//         const keyA = objectKey || Object.keys(a)[0]
//         const keyB = objectKey || Object.keys(b)[0]
//         return sort(a[keyA], b[keyB])
//     } else {
//         return a > b ? 1 : -1;
//     }
// }

export default function usePagination(total: number, defaultPage: number = 1, defaultLimit: number = 10) : usePaginationType {

    const [enabled, setEnabled] = useState(true)
    const [page, setPage] = useState(defaultPage)
    const [limit, setLimit] = useLocalNumber("paginationLimit", defaultLimit)

    const disabledCB = useCallback((array: any) : any[] => array, [])

    const subsetCB = useCallback((array: any[]) : any[] => {
        const l = limit || defaultLimit
        const start = page * l - l
        let end = page * l
        if (end > total) end = total + 1
        return array.slice(start, end)
    }, [defaultLimit, limit, page, total])

    return {
        page: {
            value: page,
            dispatch: setPage
        },
        limit: {
            value: limit || defaultLimit,
            dispatch: setLimit
        },
        total: total,
        subset: enabled ? subsetCB : disabledCB,
        enabled: {
            value: enabled, 
            dispatch: setEnabled
        }

    }
}

import React, {MouseEventHandler, useEffect} from "react";
import {AnimationControls, motion, useAnimation} from "framer-motion";

// import API from "../API/API";
import SignUpForm from "./components/SignUpForm";
import SignInForm from "./components/SignInForm";
import {AuthenticationActions} from ".";
import {useHistory} from "react-router-dom";

import overlay from './../../assets/notes_overlay.png';
import {bootstrapQueries, smaller, useBreakpoint} from "../../context/Breakpoint";
import FooterImpressumStatuten from "../../components/FooterImpressumStatuten";

export default function LogInSignUpController(props: {action: AuthenticationActions} ) {
    const controls = useAnimation();
    const hist = useHistory();

    const bp: typeof bootstrapQueries = useBreakpoint()
    const small = smaller("sm", bp, true);

    const transitionToSignUp = async () => {
        // await controls.start("signup");
        hist.push("/sign-up", "UNISONO – Mitglied Werden");
    }
    const transitionToLogin = async () => {
        await controls.start("login");
        hist.push("/sign-in", "UNISONO – Mit Profil Anmelden");
    }
    return <>
        {!small && <LoginForm action={props.action} small={small} controls={controls} transitionToLogin={transitionToLogin} transitionToSignUp={transitionToSignUp}/>}
        {small && <LoginForm action={props.action} small={small} controls={controls} transitionToLogin={transitionToLogin} transitionToSignUp={transitionToSignUp}/>}
    </>;
}

function LoginForm(props: {
        small: boolean;
        action: AuthenticationActions;
        controls: AnimationControls; 
        transitionToSignUp: MouseEventHandler<HTMLButtonElement>; 
        transitionToLogin: MouseEventHandler<HTMLButtonElement>
}) {
    const small = props.small;
    const variantsSliderWidth = small ? 10 : 50;

    const variantsSignUp = {
        "login" : {
            left: '-100%',
            transition: {
                type: 'tween',
                duration: 1
            }
        },
        "signup" : {
            left: '0%',
            transition: {
                type: 'tween',
                duration: 1
            }
        },
        "working" : {}
    };

    const variantsLogin = {
        "login" : {
            left: '0%',
            transition: {
                type: 'tween',
                duration: 1
            }
        },
        "signup" : {
            left: '100%',
            transition: {
                type: 'tween',
                duration: 1
            }
        },
        "working" : {}
    };

    const variantsSlider = {
        "login": {
            left: (100-variantsSliderWidth)+'%',
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "signup" : {
            left: '0%',
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "working" : {
            left: '0%',
            transition: {
                type: 'tween',
                duration: 1.5
            }
        }
    }

    const variantsSliderContentLogin = {
        "login": {
            left: '0%',
            // zIndex: 1100,
            // width: '50%'
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "signup" : {
            left: '110%',
            // zIndex: 1100,
            // width: '50%'
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "working" : {
            left: '110%',
            // zIndex: 1100,
            // width: '100%'
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        }
    }

    const variantsSliderContentSignUp = {
        "login": {
            left: '-110%',
            // zIndex: 1100,
            // width: '50%'
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "signup" : {
            left: '50%',
            // zIndex: 1100,
            // width: '50%'
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        },
        "working" : {
            left: '-110%',
            // zIndex: 1100,
            // width: '100%'
            opacity: 0,
            transition: {
                type: 'tween',
                duration: 1.5
            }
        }
    }
    
    useEffect(() => {
        if (props.action !== AuthenticationActions.SIGNIN) {
            props.controls.start(props.action).then();
        }
    }, [props.action, props.controls])

    return <div className="login-signup-wrapper d-flex align-items-center flex-column justify-content-center position-relative"
                style={{height: '100%'}}>
        <div className="login-signup-box" style={{height: '100vh', width: '100%'}}>
            <div className="login-signup-forms-group" style={{height: '100vh', width: '100%', backgroundColor: '#FFF'}}>
                <div style={{width: (100-variantsSliderWidth)+'%', overflow: 'hidden', height: '100%', position: 'relative', top: 0, left: 0}}>
                    <motion.div className="p-4" style={{
                        width: '100%',
                        position: 'relative',
                        left: '0',
                        maxWidth: '400px',
                        margin: '0 auto',
                        top: '50%', transform: 'translateY(-50%)',
                        zIndex: 1099,
                        overflowY: "auto",
                        maxHeight: "100%"
                    }} variants={variantsLogin} initial="login" animate={props.controls}>
                        <img src="/logo@3.png" alt="UNISONO Logo"
                             style={{height: '40px', width: 'auto', margin: '2rem 0'}}/>
                        <SignInForm transitionToSignUp={props.transitionToSignUp}/>

                        <div className="p-3 small">
                            <FooterImpressumStatuten/>
                        </div>
                    </motion.div>
                </div>
                <div style={{
                    width: (100-variantsSliderWidth)+'%',
                    overflow: 'hidden',
                    height: '100%',
                    position: 'relative',
                    top: '-100%',
                    left: small?'10%':'50%'
                }}>
                    <motion.div className="p-4" style={{
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        left: '-100%',
                        maxWidth: '400px',
                        margin: '0 auto',
                        top: '50%', transform: 'translateY(-50%)',
                        overflowY: "auto",
                        maxHeight: "100%"
                    }} variants={variantsSignUp} initial="login" animate={props.controls}>
                        <div>
                            <img src="/logo@3.png" alt="UNISONO Logo"
                                 style={{height: '40px', width: 'auto', margin: '2rem 0'}}/>
                            <SignUpForm transitionToLogin={props.transitionToLogin}/>
                            
                            <div className="p-3 small text-end">
                                <FooterImpressumStatuten/>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            
            {/*Slider, SliderContent inside*/}
            <motion.div variants={variantsSlider} initial="login" animate={props.controls} style={{
                width: variantsSliderWidth+'%',
                height: '100vh',
                position: 'relative',
                left: (100-variantsSliderWidth)+'%',
                top: '-100%',
                backgroundColor: '#cf274e',
                backgroundImage: `url(${overlay}), linear-gradient(45deg, #cf274e 0%, #cfc73c 100%)`,
                backgroundPosition: "center, center",
                backgroundAttachment: "fixed, scroll",
                zIndex: 1100,
                overflow: 'hidden'
            }}>
                {/*Slider Content*/ !small && <>
                    <motion.div className="p-4" variants={variantsSliderContentLogin} initial="login" animate={props.controls}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    position: 'relative',
                                    top: '50%',
                                    left: '0',
                                    opacity: 1,
                                    maxWidth: '400px',
                                    margin: '0 auto',
                                    transform: 'translateY(-50%)'
                                }}>
                        <div className="text-light">
                            <h4>Neu hier?</h4>
                            <p>Wenn Du UNISONO Mitglied werden möchtest, benötigst Du zuerst einen Account.</p>
                            <p style={{marginBottom: '3rem'}}>Werde Mitglied und erstelle Dir einen Account – es dauert weniger als fünf Minuten.</p>
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-link link-light text-uppercase border border-light" onClick={props.transitionToSignUp}>Mitglied werden</button>
                        </div>
                    </motion.div>
                    <motion.div className="p-4" variants={variantsSliderContentSignUp} initial="login" animate={props.controls}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    opacity: 0,
                                    maxWidth: '400px',
                                    margin: '0 auto',
                                    transform: 'translate(-50%, -50%)'
                                }}>
                        <div className="text-light">
                            <h4>Schon mal hier gewesen?</h4>
                            <p>Du kannst Dich mit Deinem bereits erstelltem Account anmelden.</p>
                            <p style={{marginBottom: '3rem'}}>Hast Du schon einen Account? Hier geht's lang.</p>
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-link link-light text-uppercase border border-light" onClick={props.transitionToLogin}>Anmelden</button>
                        </div>
                    </motion.div>
                </>}
            </motion.div>
        </div>
    </div>;
}
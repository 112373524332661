import React, {useState} from "react";
import {getTok, ProfileDto, ProfileJobSort} from "../../../../modules/API/APIInterfaces";
import {Route, Switch} from "react-router-dom";
import AxiosErrorMessageGetter from "../../../../components/AxiosErrorMessageGetter";
import {networkGet,} from "../../../../modules/API/Services/NetworkService";
import {ProfilePath} from "./Profile";
import * as HTTPErrorPage from "../../../../components/HTTPErrorPage";
import Network from "./Network";
import ProfileEdit from "./ProfileEdit";
import Friends from "./Friends";
import usePermissionBag from "../../../../hooks/usePermissionBag";
import {UserGroup} from "../../../../modules/Auth";
import { NotRegisteredAlert, NotVerifiedAlert } from "../../../../components/SpecificAlerts";

export default function NetworkController() : JSX.Element {

    const tok = getTok();
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState<ProfileDto>();
    const [error, setError] = useState("");

    const permission = usePermissionBag()
    const isForbidden = permission.isGroup(UserGroup.None, true)
    const isNotR = permission.isGroup(UserGroup.NotRegistered, true)
    const isNotV = permission.isGroup(UserGroup.NotVerified, true)    //const isNotForbidden = permission.isGroup(UserGroup.Alumni, true) || permission.isGroup(UserGroup.Circle, true)

    if(isForbidden) { return HTTPErrorPage.Unauthenticated() }
    else if (isNotR) {return <NotRegisteredAlert /> }
    else if (isNotV) {return <NotVerifiedAlert /> }

    /* 
     else if (isNotForbidden) {
        return <VersionProgress scheduledFor={1.1} message={<><p className="lead">Bald wirst Du hier kommende Veranstaltungen sehen und Dich für sie anmelden können.</p><p>Komm später vorbei, wenn die neue Website verfügbar ist.</p></>} />
    }*/

    if(!loaded) {
        setLoaded(true);
        if(tok && tok.uid) {
            networkGet(tok.uid).then((response) => {
                setError("");
                const u = response.data;
                u.jobs.sort(ProfileJobSort);
                u.studies.sort(ProfileJobSort);
                u.ehrenamt.sort(ProfileJobSort);
                setUser(u);
            }).catch((error: any) => {
                setError(AxiosErrorMessageGetter(error));
            });
        }
    }

    if(!user) {
        return <>
            {error && <div className="alert alert-danger">{error}</div>}
        </>;
    }

    return(<>
        <Switch>
            <Route exact path="/network/"><Network user={user}/></Route>
            <Route path="/network/:uid/profile"><ProfilePath loggedInUser={user}/></Route>
            <Route path="/network/profile"><ProfileEdit user={user} setUser={setUser}/></Route>
            <Route exact path="/network/friends"><Friends/></Route>
            <Route exact path="/network/*"><HTTPErrorPage.NotFound/></Route>
        </Switch>
    </>);
}
import { EndpointConfig, Method } from "react-api-data";

export const API_BASE: string = (process.env.REACT_APP_API_HOST as string);

function GoodURL(url: string): string {
    // let cleaned = url.replaceAll(/\/{2,}/, "/") // replace all double / with one


    // cleaned = cleaned.replaceAll(/:{2,}/, ":") // replace all double : with one

    return url

}

export const APIRequestScheme = (url: string, method: Method, responseSchema?: any) : EndpointConfig => {
    return { 
        url: GoodURL(API_BASE + url),
        method: method, 
        responseSchema: responseSchema
    }
}

export const APIGet = (url: string, responseSchema?: any) : EndpointConfig => {
    return APIRequestScheme(url, "GET", responseSchema)
}

export const APIPost = (url: string, responseSchema?: any) : EndpointConfig => {
    return APIRequestScheme(url, "POST", responseSchema)
}

export const APIPut = (url: string, responseSchema?: any) : EndpointConfig => {
    return APIRequestScheme(url, "PUT", responseSchema)
}

export const APIPatch = (url: string, responseSchema?: any) : EndpointConfig => {
    return APIRequestScheme(url, "PATCH", responseSchema)
}

export const APIDelete = (url: string, responseSchema?: any) : EndpointConfig => {
    return APIRequestScheme(url, "DELETE", responseSchema)
}

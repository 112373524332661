import React, {useEffect, useState} from "react";

interface ControlledProps<T> {
    value: T,
    onChange?: (v: T) => void,
    className?: string,
    name?: string,
    id?: string,
    style?: React.CSSProperties,
    disabled?: boolean,
    required?: boolean,
    checkValid?: (value: T) => boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    onKeyDown?: (e: KeyboardEvent) => void,
    maxlength?: number,
}

interface InputControlledProps extends ControlledProps<string> {
    type?: "text" | "number" | "date",
    placeholder?: string,
}

function onChange<T>(v: T, setValue: React.Dispatch<React.SetStateAction<T>>, changeFunction?: (v: T) => void,) {
    setValue(v);
    if(changeFunction!==undefined) {
        changeFunction(v);
    }
}

function getCopyProps<T>(values: ControlledProps<T>): any {
    return {
        id: values.id,
        name: values.name,
        style: values.style,
        required: values.required,
        disabled: values.disabled,
        onFocus: values.onFocus,
        onBlur: values.onBlur,
        maxLength: values.maxlength,
    };
}

function getClasses<T>(value: T, values: ControlledProps<T>) {
    return values.className + (values.checkValid!==undefined ? (values.checkValid(value)?" is-valid":" is-invalid") : "");
}

export default function InputControlled(props: InputControlledProps): JSX.Element {
    const [value, setValue] = useState(props.value);
    const type = props.type ? props.type : "text";
    
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);
    if(props.placeholder) copyProps['placeholder'] = props.placeholder;

    return <input value={value} className={classes} {...copyProps} type={type} onChange={(e) => onChange(e.target.value, setValue, props.onChange)}/>;
}

export function TextareaControlled(props: ControlledProps<string>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <textarea value={value} className={classes} {...copyProps} onChange={(e) => onChange(e.target.value, setValue, props.onChange)}/>;
}

export function SelectControlled(props: React.PropsWithChildren<ControlledProps<string>>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <select value={value} className={classes} {...copyProps} onChange={(e) => onChange(e.target.value, setValue, props.onChange)}>
        {props.children}
    </select>;
}

export function CheckboxControlled(props: ControlledProps<boolean>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <input type="checkbox" checked={value} className={classes} {...copyProps} onChange={() => onChange(!value, setValue, props.onChange)}/>;
}

interface SelectHtmlControlledOption<T> {
    id: any;
    value: T;
    html: JSX.Element;
}

interface SelectHtmlControlledProps<T> extends ControlledProps<T> {
    options: SelectHtmlControlledOption<T>[];
    selectedClass: "bg-primary" | string;
    optionClass: "rounded" | string;
}

export function SelectHtmlControlled(props: SelectHtmlControlledProps<string>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <div className={classes} {...copyProps}>
        {props.options.map((option: SelectHtmlControlledOption<string>) =>
            <div className={props.optionClass + (option.value===value ? " "+props.selectedClass : "")}
                 key={option.id}
                 onClick={() => onChange(option.value, setValue, props.onChange)}>
                {option.html}
            </div>)}
    </div>;
}
import { mappedGroupsToPermissions, UserGroup, UserPermission } from ".";
import { AuthLogic } from "../../hoc/Authenticated";
import AbstractPermissions from "./AbstractPermission";

/**
 * Use an instance of this class to store and manipulate the permissions of a {@link UserGroup}.
 * 
 * @template ```typescript
 * // start with default permissions from Alumni
 * const permissions = new PermissionBag(UserGroup.Alumni);
 * 
 * // additionally grant DeleteEvent permission
 * permissions.grant(UserPermission.DeleteEvent);
 * 
 * // revoke ViewOwnPaymentHistory permission, which Alumni usually has
 * permissions.revoke(UserPermission.ViewOwnPaymentHistory);
 * 
 * // retrieve new permission set
 * console.log( permissions.flatPermissions() );
 * ``` 
 * @since 1.0.0
 */
 export default class PermissionBag {

    private userGroup?: UserGroup;
    private trace: string[] = [];

    private defaultPermissions: UserPermission[];
    private grantedPermissions: UserPermission[];
    private revokedPermissions: UserPermission[];

    /**
     * @param group The user group you want to manipulate.
     */
    constructor(group?: UserGroup, trace: string = "") {

        this.trace = [trace];

        this.grantedPermissions = [];
        this.revokedPermissions = [];

        if (group === undefined) {
            this.defaultPermissions = [];
        } else {
            this.userGroup = group;
            this.defaultPermissions = [...mappedGroupsToPermissions[group]];
        }

    }  

    /**
     * Add permissions to this instance of {@link PermissionBag}.
     * 
     * **Attention:** `grant(...)` succeedes `revoke(...)` and may add back permissions which were previously revoked.
     * 
     * @param permissions Permissions you want to add to this {@link PermissionBag}.
     * @returns This instance of {@link PermissionBag}.
     * 
     * @since 1.0.0
     */
    public grant(...permissions: UserPermission[]): PermissionBag {
        
        // For each permission to be granted...
        // 1. check if it has been granted already
        // 2. push it to the grantedPermissions array, if not
        // 3. do nothing, if yes

        permissions.forEach(value => !this.grantedPermissions.includes(value) && !this.defaultPermissions.includes(value) ? this.grantedPermissions.push(value) : null);
        
        return this;
    }

    /**
     * Remove permissions from this instance of {@link PermissionBag}.
     * 
     * **Attention:** `revoke(...)` preceedes `grant(...)` and may  as it supercedes the `revoke(...)` method.
     * @param permissions Permissions you want to revoke from the inherited permissions from {@link UserGroup}.
     * @returns This instance of {@link PermissionBag}
     * 
     * @since 1.0.0
     */
    public revoke(...permissions: UserPermission[]): PermissionBag {
        
        // For each permission to be revoked...
        // 1. check if it has been revoked already
        // 2. push it to the revokedPermissions array, if not
        // 3. do nothing, if yes

        permissions.forEach(value => !this.revokedPermissions.includes(value) ? this.revokedPermissions.push(value) : null);
        
        return this;
    }

    /**
     * Get an array containing the current permissions of this instance of {@link PermissionBag}.
     * 
     * The permissions in `defaultPermissions` and `grantedPermissions` are uniquely merged, while the permissions
     * in `revokedPermissions` are deleted. 
     * 
     * **Attention:** `grantedPermissions` succeed `revokedPermissions`, meaning that revoked permissions can be re-granted.
     * 
     * @since 1.0.0
     */
    public cleanedPermissions(debug: boolean = false): UserPermission[] {

        // clone default permissions for local use
        let tmpPermissions: UserPermission[] = [...this.defaultPermissions];

        // exclude revoked permissions from tmpPermissions and override
        tmpPermissions = AbstractPermissions.revokePermission(tmpPermissions, ...this.revokedPermissions);

        // add granted permissions to tmpPermissions and override
        tmpPermissions = AbstractPermissions.grantPermission(tmpPermissions, ...this.grantedPermissions);
        
        return tmpPermissions;
    }

    /**
     * Checks whether this instance of {@link PermissionBag} holds the same permissions as the specified {@link UserGroup}.
     * 
     * **Attention:** To check whether the user groups are identical, set shallow to `true`. Then, only the {@link UserGroup}s are compared.
     * 
     * @param group The user group which is used for comparison.
     * @param shallow A boolean indicating whether the function should perform a shallow comparison.
     * 
     * @returns Returns `true` if associated permissions can be found in this instance's flattened permissions, `false` if at least one permission is not found.
     * 
     * @since 1.0.0
     */
    public isGroup(group: UserGroup, shallow: boolean = false): boolean {

        // console.log("Is user group " + group + " equal to " + this.userGroup + "?");
        

        // If user wants a shallow comparison
        if (shallow) return ( this.userGroup === group);

        // If no permissions were revoked, it is sufficient to do a shallow comparison
        // 
        // TODO:    Do we really want this? We might want to compare the actual permissions.
        //          E.g. If we manually construct admin privileges by granting all privileges,
        //          the user holds admin permissions. Is the user an admin now?
        //
        // For now, let's stick to a shallow comparison if nothing was revoked nor granted.
        if (this.revokedPermissions.length === 0 && this.grantedPermissions.length === 0) return (this.isGroup(group, true));

        // If this instance really has some permissions granted / revoked, we need to
        // compare each one individually. Thankfully, we have this generic function.
        return ( this.hasPermission(...mappedGroupsToPermissions[group]).all );
        
    }

    /**
     * Check whether this instance of {@link PermissionBag} has certain permissions.
     * 
     * @param permissions The permissions which should be checked.
     * 
     * @returns `{all: boolean, some: boolean, none: boolean}`
     * 
     * @since 1.0.0
     */
    public hasPermission(...permissions: UserPermission[]) : AuthLogic {

        // get cleaned permissions
        const cleaned = this.cleanedPermissions();

        // if the permission array is empty (= not authenticated) &
        // the user has some permissions (= authenticated)
        // we need to catch it and return the result manually
        // if (cleaned.length > 0 && permissions.length === 0)
        //     return({all: false, some: false, none: true})

        // if (cleaned.length === 0 && permissions.length === 0)
        //     return({all: true, some: true, none: false})

        // console.info("User has these permissions: ", flattened);
        // console.info("User needs these permissions: ", permissions);

        // construct an empty array which will hold the boolean values of the comparison result
        // const holdingPermission: boolean[] = [];

        let all = ! (cleaned.length > 0 && permissions.length === 0);
        let some = cleaned.length === 0 && permissions.length === 0;

        // see if each given permission of `permissions` is included in flattened permissions
        permissions.forEach(value => {

            // check only if all = true; if it is false, we do not need to check as it already is false (and checking would not change it)
            // if it is not included, set all to false
            if (  all && !cleaned.includes(value)         ) all  = false;

            // check only if all = false, as if it is true, then some is true implicitly
            // check only if some = false, as if it is true, then we do not need to check as it already is true (and checking would not change it)
            // if it is included, set some to true
            if ( !some && cleaned.includes(value) ) some = true;
        });
        
        // set some to true if all or some is true
        // all might be true but some not, due to check from above.
        // some needs to be true, if all is true. some can also be true if all is false ==> NOT some = all
        // some = all || some;

        // none is true, if some is false
        let none = !(some || all);

        return ( {all: all, some: some, none: none} );
    }

    /**
     * Check whether this instance does not have certain permissions.
     * 
     * @param permissions The permissions which should be checked.
     * @deprecated
     * @returns A boolean indicating if this instance does not have any of the permissions given.
     */
    public hasNotPermission(...permissions: UserPermission[]) : boolean {
        return ( this.hasPermission(...permissions).none );
    }
}
import React, { useState } from "react";
import AxiosErrorMessageGetter from "../../../components/AxiosErrorMessageGetter";
import ButtonSpin from "../../../components/UI/Buttons/ButtonSpin";
import * as API from "../../../API/API";
import {authCreate} from "../../../modules/API/Services/AuthService";
import { ErrorMessage, Field, Formik, getIn } from "formik";
import { FloatingFieldInput } from "../../../components/Fields";
import { Alert } from "react-bootstrap";
import * as Yup from "yup"

export default function SignUpForm(props : {transitionToLogin: React.MouseEventHandler<HTMLButtonElement>}) {
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const values = {email: "", password: "", passwortRepeat: "", legal: false}

    function tryCreateUser(v: typeof values) {
        setSubmitting(true);
        authCreate(v.email, v.password).then(() => {
            setError("");
            API.authenticate(v.email, v.password, () => setSubmitting(false), (error) => setError(error.message))
        })
        .catch((error: any) => setError(AxiosErrorMessageGetter(error)))
        .finally(() => setSubmitting(false))
    }

    const validationScheme = Yup.object({
        email: Yup.string().email("Verwende eine gültige E-Mail-Adresse.").required("Deine E-Mail-Adresse ist erforderlich."),
        password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/, "Dein Passwort muss Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen enthalten. Erlaubte Sonderzeichen: #$^+=!*()@%&").required("Ein Passwort ist erforderlich"),
        passwordRepeat: Yup.string().required("Passwort wiederholen ist ein Pflichtfeld").oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
        legal: Yup.boolean().required("Eine Zustimmung ist notwendig").isTrue("Deine Zustimmung ist erforderlich.")
    })

    return  <Formik
                initialValues={values}
                validationSchema={validationScheme}
                onSubmit={tryCreateUser}>
                { formik => <form onSubmit={formik.handleSubmit}>
                <h4>Mitglied Werden</h4>
                <p className="text-muted small">Um UNISONO Mitglied zu werden, benötigst Du einen Account. Erstelle Dir einen mit Deiner E-Mail-Addresse und einem Passwort.</p>
                {error && <Alert variant="danger"><Alert.Heading>Es ist ein Fehler aufgetreten.</Alert.Heading><p className="mb-0">{error}</p></Alert>}
                <FloatingFieldInput name="email" label="E-Mail Adresse" placeholder="Deine E-Mail Adresse" type="email" formik={formik} />
                <FloatingFieldInput name="password" label="Wähle ein Passwort" placeholder="Wähle ein Passwort" type="password" formik={formik} />
                <FloatingFieldInput name="passwordRepeat" label="Passwort wiederholen" placeholder="Deine gewähltes Passwort" type="password" formik={formik} />
                <div className="input-group flex-column">
                    <div className={"form-check" + (!getIn(formik.errors, "form-check") ? ' is-invalid' : ' is-valid')  }>
                        <Field name="legal" className="form-check-input" id="legal" type="checkbox" />
                        <label className="form-check-label" htmlFor="legal">Ich stimme den Statuten und der Datenschutzerklärung zu.</label>
                    </div>
                    <ErrorMessage name="legal" render={msg => <><div className='invalid-feedback px-4'>{msg}</div></>} />
                </div>
                <div className="d-flex flex-column justify-content-end pt-4">
                    { !formik.isValid && <p className="small mt-2 text-black-50 text-center">Bitte überprüfe Deine Angaben.</p>}
                    <ButtonSpin spinning={submitting} disabled={!formik.dirty || !formik.isValid} variant="primary" type="submit" skin="outline" spinColor="var(--bs-primary)">Account Erstellen &amp; Weiter</ButtonSpin>
                    {/* <p>Wenn Du auf Account erstellen &amp; Weiter drückst, stimmst Du unserer Datenschutzerklärung, </p> */}
                    <button className="btn btn-link me-2 btn-sm" type="button" disabled={submitting} onClick={props.transitionToLogin}>Oder bist du bereits Mitglied?</button>
                </div>
                </form>}
            </Formik>
}
import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {getToken} from "../APIInterfaces";

export interface PaymentDto {
    uid: number,
    firstName: string,
    lastName: string,
    
    id: number,
    title: string,
    reference: string,
    cent: number,
    createdAt: string,
    dueAt: string,
    mayHaveBeenPayedAt: string,
    confirmedAt?: string,
}

export function paymentsOverdue(): Promise<AxiosResponse<PaymentDto[]>> {
    return axios.get(API_BASE + "/payments/overdue", {headers: {Authorization: "Bearer " + getToken()}});
}

export function paymentsWaiting(): Promise<AxiosResponse<PaymentDto[]>> {
    return axios.get(API_BASE + "/payments/waiting", {headers: {Authorization: "Bearer " + getToken()}});
}

export function paymentsRest(): Promise<AxiosResponse<PaymentDto[]>> {
    return axios.get(API_BASE + "/payments/rest", {headers: {Authorization: "Bearer " + getToken()}});
}

export function paymentsConfirm(userID: number, paymentID: number): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE + "/payments/" + userID + "/" + paymentID + "/confirm", {}, {headers: {Authorization: "Bearer " + getToken()}});
}
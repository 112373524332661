import axios, {AxiosResponse} from "axios";
import {API_BASE} from "../../../store/API";
import {Choir, getToken} from "../APIInterfaces";

export function memberChoirSort(a: Choir, b: Choir): number {
    return a.name.localeCompare(b.name);
}

export function memberChoirsGet(): Promise<AxiosResponse<Choir[]>> {
    return axios.get(API_BASE + "/member-choirs", {headers: {Authorization: "Bearer " + getToken()}});
}
export function memberChoirsNew(chorName: string): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE+"/member-choirs", {name: chorName}, {headers: {Authorization: "Bearer "+getToken()}});
}
export function memberChoirsDelete(memberChoirName: string): Promise<AxiosResponse<void>> {
    return axios.delete(API_BASE+"/member-choirs",{headers: {Authorization: "Bearer "+getToken()}, data: {name: memberChoirName}})
}